import React from "react";

import DialogGamificacaoView from "./DialogGamificacao.view";

export default function DialogGamificacao(): React.ReactElement {
	return (
		<DialogGamificacaoView />
	)

}
