import axios from "axios";

export const ecossistema = axios.create({
	baseURL: process.env.REACT_APP_API_ECOSSISTEMA,
});

export const ecossistemaEstavel = axios.create({
	baseURL: process.env.REACT_APP_API_ECOSSISTEMA_ESTAVEL,
});

export const centralPedagogica = axios.create({
	baseURL: process.env.REACT_APP_API_CENTRAL_PEDAGOGICA,
	// baseURL: "http://10.0.24.73:8000/api/"
	// baseURL: "https://api-centralp.uninta.edu.br/api"
});

export const blocoNotasArquivos = axios.create({
	baseURL: process.env.REACT_APP_API_BLOCO_NOTAS_ARQUIVOS,
	// baseURL: "http://10.0.254.82:5555/"
});

export const portfólio = axios.create({
	baseURL: process.env.REACT_APP_API_PORTFOLIO_URL,
});

export const eventosVirtuais = axios.create({
	baseURL: process.env.REACT_APP_API_EVENTOS_VIRTUAIS,
});

export const depoimentos = axios.create({
	baseURL: process.env.REACT_APP_API_DEPOIMENTOS,
});

export const gamificacao = axios.create({
	baseURL: process.env.REACT_APP_API_LOGS_URL_BUSCA_SCORE,
});

blocoNotasArquivos.interceptors.request.use((config) => {
	let user: any = localStorage.getItem("@Application:User");
	let token: any = localStorage.getItem("token");

	if (user && token && config.headers) {
		user = JSON.parse(user);
		token = JSON.parse(token);

		config.headers["Authorization"] = `Bearer ${token}`;
		config.headers["ra_aluno"] = user?.ra;
	}

	return config;
});

depoimentos.interceptors.request.use((config) => {
	let user: any = localStorage.getItem("@Application:User");
	let token: any = localStorage.getItem("token");

	if (user && token && config.headers) {
		user = JSON.parse(user);
		token = JSON.parse(token);

		config.headers["Authorization"] = `Bearer ${token}`;
		config.headers["ra"] = user?.ra;
	}

	return config;
});

ecossistemaEstavel.interceptors.request.use((config) => {
	let user: any = localStorage.getItem("@Application:User");
	let token: any = localStorage.getItem("token");

	if (user && token && config.headers) {
		user = JSON.parse(user);
		token = JSON.parse(token);

		config.headers["Authorization"] = `Bearer ${token}`;
		config.headers["ra_aluno"] = user?.ra;
	}

	return config;
});

centralPedagogica.interceptors.request.use((config) => {
	let user: any = localStorage.getItem("@Application:User");
	let token: any = localStorage.getItem("token");

	if (user && token && config.headers) {
		user = JSON.parse(user);
		token = JSON.parse(token);

		config.headers["Authorization"] = `Bearer ${token}`;
		config.headers["ra"] = user?.ra;
	}

	return config;
});

centralPedagogica.interceptors.response.use(function (response) {
	return response;
}, function (error) {
	if (401 === error.response.status) {
		const data = { data: { data: [] } }
		return data;
	} else {
		return Promise.reject(error);
	}
});