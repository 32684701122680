import { centralPedagogica, ecossistema } from "services/api";

// TODO: Rotas NTI
export async function getCoursesStudent(ra: string | undefined) {
	return await ecossistema
		.get(`nti/cursos/${ra}`)
		.then(({ data }: any) => {
			return data.map((dt) => {
				return {
					id: dt?.codigo_curso,
					name: dt?.nome_curso,
				};
			});
		})
		.catch((e) => {
			console.log(e);
		});
}

export async function getSubjectsStudent(ra: string | undefined) {
	return await ecossistema
		.get(`nti/disciplinas/${ra}`)
		.then(({ data }: any) => {
			return data;
		})
		.catch((e) => {
			console.log(e);
		});
}

export async function getCourses(disciplina_id?: boolean) {
	const params = disciplina_id ? { [`disciplina_id`]: "0" } : "";
	return await centralPedagogica.get(`cursos`, { params }).then(({ data }: any) => {
		return data
	}).catch((e) => { console.log(e); });
}

export async function getSubjects() {
	return await centralPedagogica.get(`disciplinas`).then(({ data }: any) => {
		return data
	}).catch((e) => { console.log(e); });
}

// TODO: Rotas centralPedagogica 
// FIXME: Para Fazer as mudanças 
export async function getListQuestions(type?: string, value?: string, page = 0) {
	// eu testei essa notação e tá correta, só não faço ideia de onde ele seta type e value 🧐
	const params = type && value ? { [`${type}`]: value, page } : { page };
	return await centralPedagogica
		.get(`aluno/perguntas`, {
			params
		})
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

export async function getListAnswersByAuthor(authorId: string) {
	return await centralPedagogica
		.get(`aluno/respostas`, {
			params: {
				ra: authorId
			}
		}).then(({ data }) => {
			return data;
		}).catch((e) => {
			return e;
		});
}


export async function getListAnswer(authorId: string, user?: any) {
	return await centralPedagogica
		.get(user ? `${user.typeUser === 'student' ? 'aluno' : 'tutor'}/respostas`: `tutor/respostas`, {
			params: {
				ra: authorId
			}
		}).then(({ data }) => {
			return data;
		}).catch((e) => {
			return e;
		});
}

export async function storeAnswer(
	ra: string,
	questionId: string,
	course: string,
	answer: string,
	isTutor: "0" | "1",
) {
	return await centralPedagogica
		.post("tutor/respostas", {
			pergunta_id: questionId,
			resposta: answer,
			curso_id: course.split(":")[0],
			tutor: isTutor,
		})
}

export async function storeReport(
	type: "pergunta" | "resposta",
	id: string,
	authorId: string,
	authorName: string,
	reason: string,
) {
	return await centralPedagogica
		.post("aluno/denunciar", {
			autor_id: authorId,
			autor_nome: authorName,
			type: type,
			reg_id: id,
			motivo: reason,
		})
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}


// TODO: Get List Questions Tutor
export async function getListQuestionsAlunoTutor(course, page = 0, user?: any) {
	const curso_id = course;
	const disciplina_id = "0";

	const params = (course)
		? { page, disciplina_id, curso_id }
		: { page, disciplina_id };


	return await centralPedagogica
		.get(user ? `${user.typeUser === 'student' ? 'aluno' : 'tutor'}/perguntas` : `aluno/perguntas`, {	
			params
		})
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

export async function getListQuestionsTutor(course, page = 0) {
	const curso_id = course;
	const disciplina_id = "0";

	const params = (course)
		? { page, disciplina_id, curso_id }
		: { page, disciplina_id };


	return await centralPedagogica
		.get(`tutor/perguntas`, {
			params
		})
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

// TODO: Get List Answers Tutor
export async function getListAnswersTutor(idQuestion: string, user?: any) {
	// const complement = idQuestion && authorId ? `${idQuestion}/${authorId}` : "";

	return await centralPedagogica
		.get(`${user ? user.typeUser === 'student' ? 'aluno' : 'tutor' : 'aluno'}/respostas`, {
			params: {
				pergunta_id: idQuestion
			},
		})
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

// TODO: Get List Comments Tutor
export async function getListCommentsTutor(idResponse: string) {
	return await centralPedagogica
		.get(`aluno/comentarios`, {
			params: {
				resposta_id: idResponse
			},
		})
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

// TODO: Get Question Tutor
export async function getQuestionTutor(identify: string, user?: any) {
	return await centralPedagogica
		.get(`${user ? user.typeUser === 'student' ? 'aluno' : 'tutor' : 'aluno'}/perguntas/${identify}`)
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

// TODO: Store Question Tutor
export async function storeQuestionTutor(question: string, nivelDeEnsino, course: any, user: any) {
	return await centralPedagogica
		.post(`${user.typeUser === 'student' ? 'aluno' : 'tutor'}/perguntas`, {
			pergunta: question,
			polo_id: String(nivelDeEnsino.codigo_nivel_ensino),
			disciplina_id: "0",
			curso_id: course.id,
		})
}

// TODO: Store Comment Tutor
export async function storeCommentTutor(idResponse: string, rate: string) {
	return await centralPedagogica
		.post("aluno/comentarios", {
			resposta_id: idResponse,
			comentario: rate,
		})
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

// TODO: Get Count Question Tutor 
export async function getCountQuestionTutor(ra: string, user?: any) {
	return await centralPedagogica
		.get(user ? `${user.typeUser === 'student' ? 'aluno' : 'tutor'}/perguntas` : "aluno/perguntas", {
			params: {
				ra: ra,
				disciplina_id: 0,
			},
		})
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

export async function getListQuestionsByAuthorTutor(ra: string, user?: any) {
	return await centralPedagogica
		.get(user ? `${user.typeUser === 'student' ? 'aluno' : 'tutor'}/perguntas` : `aluno/perguntas`, {
			params: {
				ra: ra,
				disciplina_id: 0,
			},
		})
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

// FIXME: Ainda por fazer 


// TODO: Store Report Tutor
export async function storeReportTutor(type: "perguntas" | "respostas", id: string, authorId: string, reason: string,) {
	return await centralPedagogica
		.post("aluno/denuncias", {
			ra: authorId,
			model: type,
			model_id: id,
			motivo: reason,
		})
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

// TODO: Store Favorited Tutor
export async function storeFavoritedTutor(authorId: string, idResponse: string) {
	return await centralPedagogica
		.post("aluno/favoritos", {
			ra: authorId,
			model: "respostas",
			model_id: idResponse,
		})
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

// TODO: store Rating Tutor
export async function storeRatingTutor(authorId: string, idResponse: string, rate: number) {
	return await centralPedagogica
		.post("aluno/avaliacoes", {
			ra: authorId,
			model: "respostas",
			model_id: idResponse,
			nota: rate,
		})
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}
