import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import "./styles.scss";
import DialogViewQuestion from "../Questions/DialogViewQuestion";
import { Send } from "@material-ui/icons";
import { Box, Button, CircularProgress, FormHelperText, useTheme } from "@material-ui/core";
import { Editor } from "primereact/editor";
import { removerTags } from "utils/removeTagsHTML";
import { usePedagogicalCommunication } from "context/PedagogicalCommunication/hooks/usePedagogicalCommunication";
import Notify from "utils/Notification";
import { postNotificationCentral } from "../../DialogViewProfile/duvidasacademicastutor.servicer";

import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Typography from "@material-ui/core/Typography";
import DuvidasTutoriaMensagem from "components/DuvidasTutoriaMensagem";

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%"
	},
	button: {
		marginTop: theme.spacing(1),
		marginRight: theme.spacing(1)
	},
	actionsContainer: {
		marginBottom: theme.spacing(2)
	},
	resetContainer: {
		padding: theme.spacing(3)
	}
}));

function getSteps() { return ["Filtros", "Mensagem"]; }

export default function Message() {
	const [openViewQuestion, setOpenViewQuestion] = React.useState(false);
	const [idQuestion, setIdQuestion] = React.useState("");
	const [loadingSendAnswer, setLoadingSendAnswer] = React.useState(false);
	const theme = useTheme();
	const [answer, setAnswer] = React.useState("");

	const {
		setFilterMessageRas,
		filterMessageRas,

		setFilterMessageCourse,
		filterMessageCourse,

		setFilterMessagePole,
		filterMessagePole
	} = usePedagogicalCommunication();

	const [forId, setForId] = React.useState<any>({});
	const [recipient, setRecipient] = React.useState<any>([]);

	const sendMessage = async () => {
		if (!answer) { Notify("Preencha todos campos", "warning"); return; }
		if (removerTags(answer).length > 1000) { Notify("O texto da mensagem ultrapassou 1000 caracteres.", "warning"); return; }

		setLoadingSendAnswer(true);
		await postNotificationCentral({ "tipo": forId.tipo, "para": forId.para, "mensagem": answer })
			.then(() => {
				Notify("Sua mensagem foi enviada!", "success"); setAnswer(""); setActiveStep(0); setLoadingSendAnswer(false); handleBack();
			}).catch((e) => {
				Notify("Sua mensagem não pode ser enviada!", "warning"); setLoadingSendAnswer(false); console.log(e); handleBack();
			});
	};

	const classes = useStyles();
	const [activeStep, setActiveStep] = React.useState(0);
	const steps = getSteps();

	function handleNext() {
		const array: any = [];

		if (filterMessageRas.length !== 0) {

			filterMessageRas?.map((data: any) => { return array.push(data.value); });
			setRecipient(filterMessageRas);

			setForId({
				tipo: "tutor",
				para: array,
			});
			setActiveStep((prevActiveStep) => prevActiveStep + 1);
			return
		} else if (filterMessageCourse.length !== 0) {

			filterMessageCourse?.map((data: any) => { return array.push(data.value); });
			setRecipient(filterMessageCourse)

			setForId({
				tipo: "curso",
				para: array,
			});
			setActiveStep((prevActiveStep) => prevActiveStep + 1);
			return
		} else if (filterMessagePole.length !== 0) {

			filterMessagePole?.map((data: any) => { return array.push(data.value); });
			setRecipient(filterMessagePole)

			setForId({
				tipo: "polo",
				para: array,
			});
			setActiveStep((prevActiveStep) => prevActiveStep + 1);
			return
		}
	};

	const handleBack = () => {
		setActiveStep(0);
		setFilterMessageRas([]);
		setFilterMessageCourse([]);
		setFilterMessagePole([]);
	};

	const getStepContent = (step) => {
		switch (step) {
			case 0:
				return (
					<>
						<Grid container style={{ width: "100%", justifyContent: 'center' }}>
							<DuvidasTutoriaMensagem />
						</Grid>
					</>
				);
			case 1:
				return (
					<>
						<Box className="scrollMassageCentral">
							{
								recipient.map((item: any) => (
									<>
										<Box style={{ width: "auto", border: "nome", background: "#e6e6e6", color: "#333333", borderRadius: "20px", padding: "8px", fontSize: "85%" }}>{item.label}</Box>
									</>
								))
							}
						</Box>
						<Box>
							<FormHelperText error={removerTags(answer).length > 1000} style={{ textAlign: "right" }}>
								{removerTags(answer).length} de 1000 caracteres
							</FormHelperText>
							<Editor style={{ height: "320px" }} value={answer}
								onTextChange={(e) => { setAnswer(e.htmlValue as string); }}
								id='editorNewQuestion'
							/>

							<Box display='flex' justifyContent='flex-start' style={{ marginTop: '12px' }}>
								<Button
									variant='text'
									color='primary'
									onClick={handleBack}
									disabled={loadingSendAnswer}
									style={{
										marginRight: "12px",
										color: theme.palette.primary.main
									}}>
									Cancelar
								</Button>
								<Button
									disableElevation
									variant='contained'
									onClick={() => {
										sendMessage();
									}}
									disabled={
										loadingSendAnswer
									}
									id='btnNewResponse'
									style={{
										color: "white",
										backgroundColor: theme.palette.primary.main
									}}
								>
									{loadingSendAnswer ? (
										<CircularProgress
											style={{ color: "#fff", width: "1.5rem", height: "1.5rem" }}
											thickness={4.4}
										/>
									) : (
										<>
											<span style={{ marginRight: "10px" }}>Enviar Mensagem</span>
											<Send />
										</>
									)}
								</Button>
							</Box>
						</Box>
					</>
				);
			default: return "Unknown step"; 
		}
	};

	return (
		<Container>
			<Box style={{
				padding: '12px, 24px',
				marginTop: '6vh',
				textAlign: 'center',
				marginBottom: "20px"
			}}>
				<Typography variant='h4' component='h4' style={{ fontWeight: 'bold', marginBottom: '12px' }}>
					ENVIO DE MENSAGENS
				</Typography>
				<Typography variant='body1' component='p'>
					Olá, tutor! Este é seu espaço de interação com os polos, curso e estudantes. Por meio desta central você
					poderá enviar mensagens!
				</Typography>
			</Box>


			<div className={classes.root}>
				<Stepper activeStep={activeStep} orientation="vertical">
					{steps.map((label, index) => (
						<Step key={label}>
							<StepLabel>{label}</StepLabel>
							<StepContent>
								<Box style={{ width: "100%" }}>{getStepContent(index)}</Box>
								<div className={classes.actionsContainer}>
									<div>
										{activeStep === steps.length - 1 ? (<></>) : (
											<>
												<Button
													variant="contained"
													color="primary"
													onClick={() => { handleNext() }}
													className={classes.button}
													disabled={
														(filterMessageRas.length === 0)
														&&
														(
															(filterMessagePole.length === 0)
															&&
															(filterMessageCourse.length === 0)
														)
													}
												>
													{"Proximo"}
												</Button>
											</>
										)}
									</div>
								</div>
							</StepContent>
						</Step>
					))}
				</Stepper>
			</div>

			<DialogViewQuestion open={openViewQuestion} id={idQuestion} setOpen={setOpenViewQuestion} setId={setIdQuestion} />
		</Container>
	);
}
