import React from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
// import Box from '@material-ui/core/Box';
import Button from "@material-ui/core/Button";
import Rating from "@material-ui/lab/Rating";

import Notify from "utils/Notification";

import { storeRatingTutor } from "../../../dialogquestionsanswers.services";

import { useAuth } from "context/Auth/hook";

import { DialogRatingOfQuestionProps } from "./dialogratingofquestion.model";

import "./styles.scss";

import { apiLogsDoEcossistema } from "services/Logs";
import { CENTRAL_PEDAGOGICA } from "defaults/modules";
import { ALUNO_AVALIA_RESPOSTA_DUVIDA_CONTEUDO } from "defaults/events";


export default function DialogRatingOfQuestion(props: DialogRatingOfQuestionProps) {
	const { user, nivelDeEnsino } = useAuth();
	const [disable, setDisable] = React.useState(false);
	const [rating, setRating] = React.useState(0);

	const onCloseDialog = () => {
		props.setOpen(false);
		setRating(0);
	};

	const onRating = () => {
		if (rating) {
			setDisable(true)
			storeRatingTutor(user.ra ?? "", props.idAnswer, rating)
				.then(async (data) => {
					props.setRated(true);
					props.setQtdRated(data.nota);
					props.setReload(true);
					await apiLogsDoEcossistema.post('/',
						{
							ra: user.ra as string,
							module: CENTRAL_PEDAGOGICA,
							evento: {
								label: ALUNO_AVALIA_RESPOSTA_DUVIDA_CONTEUDO,
								log_info: {
									user_type: user.typeUser,
									codigo_nivel_ensino: nivelDeEnsino.codigo_nivel_ensino,
									nome_nivel_ensino: nivelDeEnsino.nome_nivel_ensino,
									nome_curso: nivelDeEnsino.nome_curso,
									periodo: nivelDeEnsino.periodo,
								}
							},
							metadata: {
								curso: nivelDeEnsino.nome_curso,
								polo: nivelDeEnsino.nome_nivel_ensino,
								periodo: nivelDeEnsino.periodo,
							}
						}
					)
					onCloseDialog();
				})
				.catch((e) => {
					console.log(e);
				});
		} else {
			Notify("Avaliação minima é 1.", "warning");
		}
	};

	return (
		<Dialog open={props.open} onClose={() => onCloseDialog()} fullWidth maxWidth='xs'>
			<DialogContent className='body_rating'>
				<Typography className='title_rating'>Avalie a resposta</Typography>

				<Typography className='subtitle_rating'>
					Avalie para outros verem como esta resposta é útil
				</Typography>

				<Rating
					name='simple-controlled'
					value={rating}
					onChange={(e, value) => {
						setRating(value ?? 0);
					}}
				/>
			</DialogContent>

			<DialogActions className='footer_rating'>
				<Button variant='text' color='primary' id='btnCancel' onClick={() => onCloseDialog()}>
					Cancelar
				</Button>

				<Button
					id='btnAtribuirNota'
					variant='text'
					color='secondary'
					className='btn_outlined'
					onClick={onRating}
					disabled={disable}
				>
					Dê sua nota
				</Button>
			</DialogActions>
		</Dialog>
	);
}
