import React from "react";

export default function Indicador(): React.ReactElement {

	return (
		<>
			<iframe
				style={{ justifyContent: "flex-end", height: "90vh", padding: "0px" }}
				title="central_pedagogica"
				width="97%"
				frameBorder="0"
				allowFullScreen={true}
				src="https://app.powerbi.com/view?r=eyJrIjoiN2JiYWY1ZGYtOTkxMi00NzAzLWI2YmQtNDQ3ZTQ1NzQ0NzcwIiwidCI6IjY2ZDI0ZDcyLWZiMjMtNGZiOS1iODkxLWQxOGY1ODYyMzliZSJ9&pageName=ReportSection"
			></iframe>
		</>
	);
}
