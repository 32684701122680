import React, { useState } from "react";
import parse from "html-react-parser";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import { useTheme } from "@material-ui/core/styles";

import Star from "@material-ui/icons/Star";
import Favorite from "@material-ui/icons/Favorite";

import { Paginator } from "primereact/paginator";

import DialogQuestionsAnswers from "./DialogNewQuestion";
import DialogViewQuestion from "./DialogViewQuestion";

import { useAuth } from "context/Auth/hook";

import { usePedagogicalCommunication } from "context/PedagogicalCommunication/hooks/usePedagogicalCommunication";

import {
	getCoursesStudent,
	getListQuestions,
	getListQuestionsCourseAndSubject,
	getSubjectsStudent,
} from "../dialogquestionsanswers.services";
// import {
// 	getListCourses,
// 	getListSubjects,
// } from "../../ListSolicitationsReviewQuestion/listsolicitationreview.services";

import "./styles.scss";
import { Avatar, Badge, CircularProgress, Hidden } from "@material-ui/core";
// import { getUserCourses, getUserSubjects } from "services/Profiles";
import { getCourses, getSubjects } from "../../DialogQuestionsAnswersTutor/dialogquestionsanswers.services";
import { Message, Refresh, Send } from "@material-ui/icons";
import { getNameInitials } from "utils/getNameInitials";

export default function Questions(): React.ReactElement {
	const { user } = useAuth();
	const theme = useTheme();
	const { reloadListQuestions, updateNumQuestions } = usePedagogicalCommunication();
	const colorsTheme = useTheme();

	// Paginação
	const [currentPage, setCurrentPage] = React.useState(0); // Pagina atual exibida
	const [totalQuestions, setTotalQuestions] = React.useState(0); // Quantidade total de questões

	const [loadingPage, setLoadingPage] = React.useState(true); // Exibe o loader na tela

	const [filterCourse, setFilterCourse] = useState("todos"); // Código do curso
	const [filterClass, setFilterClass] = useState("todos"); // Código da disciplina

	const [openNewQuestion, setOpenNewQuestion] = useState(false);
	const [openViewQuestion, setOpenViewQuestion] = useState(false);
	const [idQuestion, setIdQuestion] = React.useState(""); // Id da questão atual selecionada

	const [listQuestions, setListQuestionsView] = useState<any[]>([]); // Lista de questões
	const [listCourses, setListCourses] = useState<any[]>([]); // Lista de cursos
	const [listSubjects, setListSubjects] = useState<any[]>([]); // Lista de disciplinas

	const handleFilterListQuestions = (
		key: "curso_id" | "disciplina_id",
		value: string,
		user: any,
		page?: number,
	) => {
		setLoadingPage(true);

		let course = filterCourse;
		let subject = filterClass;

		if (key === "curso_id") {
			course = value;
		}

		if (key === "disciplina_id") {
			subject = value;
		}

		if (course !== "todos" && subject !== "todos") {
			getListQuestionsCourseAndSubject(user, course, subject, page)
				.then((res: any) => {
					setListQuestionsView(res.data);
					setListQuestionsView(res.data);
					setTotalQuestions(res.total);
					setLoadingPage(false);
				});
		} else {
			let newKey: string = key;
			let newValue: string = value;

			if (key === "curso_id" && value === "todos") {
				if (filterClass === "todos") {
					newKey = "";
					newValue = "";
				} else {
					newKey = "disciplina_id";
					newValue = filterClass;
				}
			}

			if (key === "disciplina_id" && value === "todos") {
				if (filterCourse === "todos") {
					newKey = "";
					newValue = "";
				} else {
					newKey = "curso_id";
					newValue = filterCourse;
				}
			}

			getListQuestions(user, newKey, newValue, page)
				.then((res: any) => {
					setListQuestionsView(res.data);
					// setListQuestionsView(res.data.data);
					setTotalQuestions(res.total);
					setLoadingPage(false);
				})
				.catch((e) => {
					console.log(e);
				});
		}
	};

	// Busca questões
	const getQuestionsPaginator = (page = 1) => {
		setLoadingPage(true);
		getListQuestions(user, "", "", page)
			.then((response: any) => {
				setLoadingPage(false);
				setListQuestionsView(response.data);
				setTotalQuestions(response.total);
				setLoadingPage(false);
				setFilterCourse("todos");
				setFilterClass("todos");
			})
			.catch((e) => {
				console.log(e);
			});
	};

	// Busca cursos e disciplinas { Este é o conteúdo que quebrou a tela anterior }
	React.useEffect(() => {
		try {
			if (user.typeUser === "tutor") {
				getCourses()
					.then(({ data }) => {
						setListCourses(data);
					})
					.catch((e) => {
						console.log(e);
					});

			} else {
				getCoursesStudent(user?.ra)
					.then((data: any) => {
						setListCourses(data);
					})
					.catch((e) => {
						console.log(e);
					});
			}

		} catch (e) {
			// Pass
		}

		try {
			if (user.typeUser === "tutor") {
				getSubjects()
					.then(({ data }) => {
						setListSubjects(data);
					})
					.catch((e) => {
						console.log(e);
					});
			} else {
				getSubjectsStudent(user?.ra).then((data: any) => {
					setListSubjects(data);
				}).catch((e) => {
					console.log(e);
				});

			}
		} catch (e) {
			// Pass
		}
	}, [user?.ra, user.typeUser]);

	React.useEffect(() => {
		setCurrentPage(0);
		getQuestionsPaginator();

		try {
			if (user.typeUser === "tutor") {
				getCourses()
					.then(({ data }) => {
						setListCourses(data);
					})
					.catch((e) => {
						console.log(e);
					});

			} else {
				getCoursesStudent(user?.ra)
					.then((data: any) => {
						setListCourses(data);
					})
					.catch((e) => {
						console.log(e);
					});
			}

		} catch (e) {
			// Pass
		}

		try {
			if (user.typeUser === "tutor") {
				getSubjects()
					.then(({ data }) => {
						setListSubjects(data);
					})
					.catch((e) => {
						console.log(e);
					});
			} else {
				getSubjectsStudent(user?.ra).then((data: any) => {
					setListSubjects(data);
				}).catch((e) => {
					console.log(e);
				});

			}
		} catch (e) {
			// Pass
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reloadListQuestions, updateNumQuestions]);

	function date(data: string) {
		let arrayData = data.split(" ");
		arrayData.splice(1, 1);
		arrayData = arrayData[0].split("-");
		return arrayData.reverse().join("/");
	}

	function hora(hora: string) {
		let arrayHora = hora.split(" ");
		return arrayHora[1];
	}

	return (
		<>
			<Container>
				{
					user.typeUser === "student"
					&& <Box className='header_questions'>
						<Box className="billboard">
							<Typography variant="h5" component="h5" className="decorator">Dúvidas de conteúdo</Typography>
							<Hidden mdDown>
								<Typography
									variant="h3"
									component="h3"
									className="billboard_header">
									Olá, <span style={{ textTransform: "capitalize" }}>{user.nome.toLowerCase()}</span>!
									<br />
									O que você precisa saber?
								</Typography>
								<Typography variant="body1" component="p" className="caption">
									Utilize este módulo para enviar dúvidas sobre conteúdos e
									para interagir com os demais alunos e professores do curso.
								</Typography>

								<Button
									id='btnRealoadQuestion'
									variant='outlined'
									disableElevation
									style={{
										padding: '12px 24px',
										backgroundColor: "#f6f6f6",
										marginRight: '6px',
										color: colorsTheme.palette.primary.main,
										border: "solid 2px" + colorsTheme.palette.primary.main + " !important",
										borderRadius: '48px'
									}}
									onClick={() => {
										setCurrentPage(0);
										getQuestionsPaginator();
									}}
									endIcon={<Refresh />}
								>
									Recarregar perguntas
								</Button>
								<Button
									id='btnSendQuestion'
									variant='contained'
									disableElevation
									style={{
										padding: '12px 24px',
										backgroundColor: colorsTheme.palette.primary.main,
										color: "white",
										borderRadius: '48px'
									}}
									onClick={() => {
										setOpenNewQuestion(true);
									}}
									endIcon={<Send />}
								>
									Enviar dúvida de conteúdo
								</Button>
							</Hidden>
							<Hidden mdUp>
								<Button
									id='btnReloadQuestion'
									variant='outlined'
									disableElevation
									style={{
										backgroundColor: "#f6f6f6",
										marginRight: '6px',
										margin: '6px',
										color: colorsTheme.palette.primary.main,
										border: "solid 2px" + colorsTheme.palette.primary.main + " !important",
										borderRadius: '48px'
									}}
									onClick={() => {
										setCurrentPage(0);
										getQuestionsPaginator();
									}}
									endIcon={<Refresh />}
								>
									Recarregar perguntas
								</Button>
								<Button
									id='btnSendQuestion'
									variant='contained'
									disableElevation
									style={{
										backgroundColor: colorsTheme.palette.primary.main,
										color: "white",
										borderRadius: '48px'
									}}
									onClick={() => {
										setOpenNewQuestion(true);
									}}
									endIcon={<Send />}
								>
									Enviar dúvida
								</Button>
							</Hidden>

						</Box>

						{/* <Typography 
							variant="h5"
							component="h5"
							className='salutation'>
							Olá{" "}
							<span style={{ color: colorsTheme.palette.primary.main }}>
								{user.nome.toLowerCase()}
							</span>{" "}
							!
						</Typography>
						<Typography
							variant="h5"
							component="h5" 
							className='what'>O que você precisa saber?</Typography>
						<Button
							id='btnSendQuestion'
							variant='contained'
							disableElevation
							style={{
								backgroundColor: colorsTheme.palette.primary.main,
								color: "white",
							}}
							onClick={() => {
								setOpenNewQuestion(true);
							}}
						>
							Enviar dúvida de conteúdo
						</Button> */}
					</Box>
				}

				<Box className='body_questions'>
					<Box className='body_questions_heading'>

						<Box mb={2} mt={2}>
							{/* <Typography variant='h5' component="h5" style={{ textTransform: "uppercase"}}>Perguntas com melhor avaliação</Typography> */}
							<Typography style={{ fontWeight: "bold" }} variant='h5' component="h5">Perguntas com melhor avaliação</Typography>
						</Box>
						<Box className='filters'>
							<FormControl variant='outlined'>
								<InputLabel>Curso:</InputLabel>
								<Select
									id='selectCourseQuestions'
									value={filterCourse}
									onChange={(e) => {
										setCurrentPage(0);
										setFilterCourse(e.target.value as string);
										handleFilterListQuestions("curso_id", e.target.value as string, user, 1);
									}}
									label='Curso:'
									disabled={loadingPage}
								>
									<MenuItem value='todos'>Todos</MenuItem>
									{listCourses.map((course: any) => (
										<MenuItem value={course.id} key={course.id}>
											{(user.typeUser === "tutor") ? course.nome : course.name}&nbsp;
											{(user.typeUser === "tutor") ? <>- &nbsp;{course.id}</> : ""}
										</MenuItem>
									))}
								</Select>
							</FormControl>

							<FormControl variant='outlined'>
								<InputLabel>Disciplina:</InputLabel>
								<Select
									id='selectSubjectQuestions'
									value={filterClass}
									onChange={(e) => {
										setCurrentPage(0);
										setFilterClass(e.target.value as string);
										handleFilterListQuestions(
											"disciplina_id",
											e.target.value as string,
											user
										);
									}}
									label='Disciplina:'
									disabled={loadingPage}
								>
									<MenuItem value='todos'>Todos</MenuItem>
									{listSubjects.map((subject: any) => (
										subject.id !== "0" ?
											<MenuItem value={subject.id} key={subject.id}>
												{subject.nome}
											</MenuItem>
											: <></>
									))}
								</Select>
							</FormControl>
						</Box>
					</Box>

					<Box className='views_questions'>
						{loadingPage ? (
							<Box
								display='flex'
								justifyContent='center'
								alignItems='center'
								mb={2}
								mt={2}>
								<CircularProgress />
							</Box>
						) : (
							<>
								{listQuestions?.length > 0 ? (
									<>
										{listQuestions?.map((question: any, index) => (
											<Box
												className='view_question'
												key={index}
												id='boxQuestionItem'
												onClick={() => {
													setOpenViewQuestion(true);
													setIdQuestion(question.id);
												}}>
												<Box
													p={2}
													style={{
														display: 'flex',
														flexDirection: 'row',
														justifyContent: 'flex-start',
														alignItems: 'center'
													}}>
													<Avatar
														className={"img_perfil_questions"}
														src={question.profile.name ? `https://dirin.s3.us-east-1.amazonaws.com/${question.profile.names}` : ""}
														alt='Perfil'
													>
														{getNameInitials(question.profile.name)}
													</Avatar>
													<Box ml={3}>
														<Typography variant='body1' component='p' style={{ fontWeight: 'bold' }}>{question.profile.name}</Typography>
														<Typography variant='subtitle2' component='small'>
															{question.curso.nome} - {question.disciplina.nome}- {date(question?.created_at)} - {hora(question?.created_at)}
														</Typography>
													</Box>
												</Box>
												<Typography variant='subtitle1' style={{ overflowWrap: "break-word" }}>
													{/* <pre>
													{JSON.stringify(question, null, 4)}
												</pre> */}
													{parse(question.pergunta)}
												</Typography>

												<Box className='footer_question_view'>
													<Box
														display={"flex"}
														flexDirection="row"
														style={{
															marginLeft: '12px'
														}}>

														<Badge
															badgeContent={question.respostas_count !== 0 ? question.respostas_count : 0}
															color="primary"
															overlap="circular">
															<Button
																id='btnViewResponses'
																variant='text'
																endIcon={<Message />}
																style={{
																	color: theme.palette.primary.light,
																	marginLeft: '12px'
																}}
																onClick={() => {
																	setOpenViewQuestion(true);
																	setIdQuestion(question.id);
																}}>
																RESPOSTAS
															</Button>
														</Badge>
													</Box>

													<Box className='avaliations'>
														<Typography variant='subtitle1'>
															<Star className='iconStar' />{" "}
															{parseFloat(question.avaliacoes_avg ?? 0)
																.toFixed(1)
																.replace(".", ",")}
														</Typography>
														<Typography variant='subtitle1'>
															<Favorite className='iconHeart' />{" "}
															{question.favoritos_count}
														</Typography>
													</Box>
												</Box>
											</Box>
										))}

										<Paginator
											rows={10}
											totalRecords={totalQuestions}
											first={currentPage}
											onPageChange={(e) => {
												setCurrentPage(e.first);

												if (
													filterClass === "todos" &&
													filterCourse !== "todos"
												) {
													handleFilterListQuestions(
														"curso_id",
														filterCourse as string,
														user,
														e.page + 1,
													);
												} else if (filterClass !== "todos") {
													handleFilterListQuestions(
														"disciplina_id",
														filterClass as string,
														user,
														e.page + 1,
													);
												} else {
													getQuestionsPaginator(e.page + 1);
												}
											}}
											style={{
												background: "#f7f7f7",
												marginTop: 10,
											}}
										></Paginator>
									</>
								) : (
									<Box className='nothing'>
										<Typography className='text_no_find'>
											Nenhum resultado encontrado
										</Typography>
										<Typography className='subtext_no_find'>
											Tente filtrar por outro curso ou disciplina
										</Typography>
									</Box>
								)}
							</>
						)}
					</Box>
				</Box>

				<DialogQuestionsAnswers open={openNewQuestion} setOpen={setOpenNewQuestion} />

				<DialogViewQuestion
					open={openViewQuestion}
					id={idQuestion}
					setOpen={setOpenViewQuestion}
					setId={setIdQuestion}
					question={
						listQuestions.find(question => question.id === idQuestion)
					}
				/>
			</Container>
		</>
	);
}
