import React from "react";
import parse from "html-react-parser";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import MoreVert from "@material-ui/icons/MoreVert";
import Report from "@material-ui/icons/Report";

import DialogReport from "../DialogReport";
// import AvatarProfile from "components/AvatarProfile";

import { BoxQuestionProps } from "./boxquestion.model";

// import { useAuth } from "context/Auth/hook";

import "./styles.scss";
import { Avatar } from "@material-ui/core";
import { getNameInitials } from "utils/getNameInitials";
// import { usePortfolio } from "context/Portfolio";

export default function BoxQuestion(props: BoxQuestionProps): React.ReactElement {
	// const { user } = useAuth();
	// const { state } = usePortfolio();
	const [openOptions, setOpenOptions] = React.useState<null | HTMLElement>(null);
	const [openReport, setOpenReport] = React.useState(false);
	const [idForReport, setIdForReport] = React.useState("");

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setOpenOptions(event.currentTarget);
	};

	const handleClose = () => {
		setOpenOptions(null);
	};

	function date(data: any) {
		let arrayData = data.split(" ");
		arrayData.splice(1, 1);
		arrayData = arrayData[0].split("-");
		return arrayData.reverse().join("/");
	}

	function hora(hora: any) {
		let arrayHora: string[] = hora.split(" ");
		return arrayHora[1];
	}

	return (
		<Grid item xs={12}>
			<Box className='area_question' mt={3} mb={3}>
				<Box className='header'>
					<Box className='locale_question'>
						<Typography variant="body1" component="p">Pergunta:</Typography>
						<Box mb={2} mt={3}>
							<Typography style={{ overflowWrap: "break-word" }}>{parse(props.pergunta)}</Typography>
						</Box>
					</Box>

					<IconButton
						title='Denunciar'
						aria-controls='simple-menu'
						aria-haspopup='true'
						id='btnOptions'
						onClick={handleClick}
					>
						<MoreVert />
					</IconButton>

					<Menu
						id='simple-menu'
						anchorEl={openOptions}
						keepMounted
						open={Boolean(openOptions)}
						onClose={handleClose}
					>
						<MenuItem
							style={{ color: "#BD0E0E" }}
							button
							id='btnReport'
							onClick={() => {
								setIdForReport(props.idQuestion);
								setOpenReport(true);
								handleClose();
							}}
						>
							<Report />
							&nbsp;Denunciar
						</MenuItem>
					</Menu>
				</Box>

				<Box className='body'>
					{/* <AvatarProfile
						// isAuthor={props.authorId === user.ra}
						urlImage={props.authorAvatar}
						authorName={props.author ?? ""}
					/> */}
					<Avatar
						className={"img_perfil_questions"}
						src={props.authorAvatar ? `https://dirin.s3.us-east-1.amazonaws.com/${props.authorAvatar}` : ""}
						alt='Perfil'
					>
						{getNameInitials(props.author)}
					</Avatar>
					<Box className='author_name_course'>
						<Typography variant="body1" component="p" className="answerAuthorName">
							{props.author} - {props.course} - {props.subjects}
						</Typography>
						<Typography className='author_course'>
							{date(props.created)} - {hora(props.created)}
						</Typography>
					</Box>
				</Box>
			</Box>

			<Box className='divider_custom'>
				<Typography>respostas e comentários</Typography>
			</Box>

			<DialogReport
				typeReport='perguntas'
				idForReport={idForReport}
				open={openReport}
				setOpen={setOpenReport}
				setReload={props.setOnReload}
			/>
		</Grid>
	);
}
