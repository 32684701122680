/* eslint-disable @typescript-eslint/no-unused-vars */
import { FormEvent, useState } from 'react';
import { useCookies } from 'react-cookie';
import Notify from "utils/Notification";

import './styles.scss';
import axios from 'axios';
import { Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Typography, useTheme } from '@material-ui/core';
import { ErrorOutline, Visibility, VisibilityOff } from '@material-ui/icons';
import { useAuth } from 'context/Auth/hook';
interface IDialogAuthMyLibraryProps {
    open: boolean;
    linkAccess: string;
    setOpen: (e: boolean) => void;
    access: number;
}

function authenticationMyLibrary1(props: any) {
    return axios.post(
        `https://minhabiblioteca.aiamis.com.br/api/companies/1/access`,
        { ...props }
    )
}

function authenticationMyLibrary2(props: any) {
    return axios.post(
        `https://minhabiblioteca.aiamis.com.br/api/companies/2/access`,
        { ...props }
    )
}

export default function DialogAuthMyLibrary({ open, linkAccess, setOpen, access }: IDialogAuthMyLibraryProps) {
    const setCookie = useCookies()[1];
    const [password, setPassword] = useState('');
    const [isPassword, setIsPassword] = useState(true);
    const [isLoadingAuthLibrary, setIsLoadingAuthLibrary] = useState(false);
    const { user } = useAuth();
    const theme = useTheme();

    const onClosed = () => {
        setOpen(false);
        setPassword('');
        setIsPassword(true);
    }

    const onConfirm = (e: FormEvent) => {
        e.preventDefault();
        setIsLoadingAuthLibrary(true);

        if (user.ra && password) {
            if (access === 1) {
                authenticationMyLibrary1({ username: user.ra, password }).then((data: any) => {
                    if (data.data.success) {
                        const dateWithPlusFourHours = new Date();
                        dateWithPlusFourHours.setHours(dateWithPlusFourHours.getHours() + 1);

                        const mywindow = window.open(data.data.url, "AutenticacaoMinhaBiblioteca", "width=1,height=1,top=10000, left=10000");

                        setTimeout(() => {
                            mywindow?.close();
                            window.open(linkAccess, '_blank');
                            const save = window.btoa(JSON.stringify({ myLibrary_auth: true }));
                            setCookie("a_mb", save, {
                                expires: dateWithPlusFourHours,
                            });
                            onClosed();
                            setIsLoadingAuthLibrary(false);
                        }, 2000);
                    }
                }).catch((e) => {
                    Notify("Verifique a senha e tente novamente", "error");
                    onClosed();
                    setIsLoadingAuthLibrary(false);
                })
            } else if (access === 2) {
                authenticationMyLibrary2({ username: user.ra, password }).then((data: any) => {
                    if (data.data.success) {
                        const dateWithPlusFourHours = new Date();
                        dateWithPlusFourHours.setHours(dateWithPlusFourHours.getHours() + 1);

                        const mywindow = window.open(data.data.url, "AutenticacaoMinhaBiblioteca", "width=1,height=1,top=10000, left=10000");

                        setTimeout(() => {
                            mywindow?.close();
                            window.open(linkAccess, '_blank');
                            const save = window.btoa(JSON.stringify({ myLibrary_auth: true }));
                            setCookie("a_mb", save, {
                                expires: dateWithPlusFourHours,
                            });
                            onClosed();
                            setIsLoadingAuthLibrary(false);
                        }, 2000);
                    }
                }).catch((e) => {
                    Notify("Verifique a senha e tente novamente", "error");
                    onClosed();
                    setIsLoadingAuthLibrary(false);
                })
            }
        } else { Notify("Por favor preencha todos campos.", "error",); }
    }

    return (
        <Dialog
            open={open}
            onClose={onClosed}
            fullWidth
            maxWidth="md"
        >
            <form onSubmit={onConfirm}>
                <DialogTitle style={{ background: theme.palette.primary.main, color: "white", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Typography>Acessar Minha Biblioteca</Typography>
                </DialogTitle>

                <DialogContent>
                    <Typography className="info_confirmation">Para confirmar o acesso a Minha Biblioteca, por favor confirme a sua senha.</Typography>

                    <FormControl variant="outlined" fullWidth>
                        <InputLabel>Confirme sua senha:</InputLabel>
                        <OutlinedInput
                            fullWidth
                            label="Confirme sua senha:"
                            required
                            placeholder="Digite sua senha aqui..."
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => setIsPassword(!isPassword)}
                                        edge="end"
                                    >
                                        {isPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            type={isPassword ? 'password' : 'text'}
                            disabled={isLoadingAuthLibrary}
                        />
                    </FormControl>

                    <Box className="area_btn_confirm">
                        <Button
                            variant="contained"
                            // color="success"
                            type="submit"
                            disabled={isLoadingAuthLibrary}
                        >
                            {isLoadingAuthLibrary ? <CircularProgress style={{ width: 20, height: 20 }} /> : 'Confirmar'}
                        </Button>
                    </Box>

                    <Box className="alert_for_popup">
                        <Typography className="info_confirmation">
                            <ErrorOutline className="icon_error" />
                            Caso a Minha Biblioteca não abra
                        </Typography>

                        <Typography className="info_confirmation sub_info">
                            Favor, verifique se o seu popup está habilitado para esta plataforma.
                            Veja o exemplo a seguir:
                        </Typography>

                        <div
                            style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}
                        >
                            <div
                                style={{ width: "60%" }}>
                                <img
                                    src="https://dirin.s3.amazonaws.com/ecossistema_v5/assets/popuprecord.gif"
                                    className="gif_hability_popup"
                                    alt="Gif que mostra como o usuário habilita o popup no navegador para esta plataforma."
                                />
                            </div>
                        </div>
                    </Box>
                </DialogContent>
            </form>
        </Dialog>
    )
}
