import React from "react";

import "./index.scss";
import Dialog from "@material-ui/core/Dialog";
import HeaderMobile from "./HeaderMobile";
import { Hidden, useTheme } from "@material-ui/core";
import { usePortfolio } from "context/Portfolio";
import { useAuth } from "context/Auth/hook";
import Pontuacoes from "./components/Pontuacoes";
import Desafios from "./components/Desafios";
import Ranking from "./components/Ranking";
import Header from "./Header";
import AvatarProfile from "components/AvatarProfile";
import { useGamificacao } from "context/Gamificacao";

export default function DialogGamificacaoView(): React.ReactElement {
	const { user } = useAuth();
	const { state } = usePortfolio();
	const theme = useTheme();
	const { openDialogGamificacao, switchContent, coresLinks, setSwitchContent, setCoresLinks, setOpenDialogGamificacao } = useGamificacao();

	function selecionarConteudoGamificacao() {
		if (switchContent === 0) {
			return <Pontuacoes />
		} else if (switchContent === 1) {
			return <Desafios />
		} else if (switchContent === 2) {
			return <Ranking />
		}
	}

	return (
		<Dialog
			open={openDialogGamificacao}
			onClose={() => {
				setOpenDialogGamificacao(!openDialogGamificacao);
			}}
			fullScreen
		>

			<Hidden mdDown>
				<Header />
			</Hidden>

			<Hidden lgUp>
				<HeaderMobile />
			</Hidden>

			<div className="container-gamificacao">
				<div className="gamificacao-content">

					<div className="card-gamificacao">
						<div className="dados-perfil">
							<AvatarProfile classname="foto-perfil-gamificacao" />

							<div>
								<span>{state.name}</span>
								<span>{user.ra}</span>
							</div>
						</div>

						<div className="links-gamificacao">
							<ul>
								<li style={{ color: coresLinks[0] }} onClick={() => { setCoresLinks([`${theme.palette.primary.main}`, "#000000", "#000000"]); setSwitchContent(0); }}>
									<span className="material-symbols-outlined">
										diamond
									</span> <strong>Pontuação</strong>
								</li>

								<li style={{ color: coresLinks[1] }} onClick={() => { setCoresLinks(["#000000", `${theme.palette.primary.main}`, "#000000"]); setSwitchContent(1); }}>
									<span className="material-symbols-outlined">
										military_tech
									</span> <strong>Desafios</strong>
								</li>

								<li style={{ color: coresLinks[2] }} onClick={() => { setCoresLinks(["#000000", "#000000", `${theme.palette.primary.main}`]); setSwitchContent(2); }}>
									<span className="material-symbols-outlined">
										trending_up
									</span> <strong>Ranking</strong>
								</li>
							</ul>
						</div>
					</div>

					{selecionarConteudoGamificacao()}

				</div>
			</div>
		</Dialog >
	);
}
