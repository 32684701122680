import { Depoimento, DepoimentosContextType } from "models/depoimento";
import { useContext, createContext, useState, useEffect } from "react";
import { getDepoimentosAluno, getDepoimentosTutor } from "services/Oportunidades";
import { useAuth } from "./Auth/hook";

const INI_VALUES: DepoimentosContextType = {
	depoimentos: [
		{
			id: 0,
			curso_id: "",
			curso_nome: "",
			depoimento: "",
			situacao: "",
			created_at: "",
			profile: {
				ra: "",
				name: "",
				avatar_path: "",
				city_address: "",
				uf_address: "",
			}
		},
	],
	cursos: [{
		codigo_nivel_ensino: 0,
		nome_nivel_ensino: "",
		codigo_curso: "",
		nome_curso: "",
		periodo: null,
		situacao: "",
		idhabilitacaofilial: "",
		idperlet: "",
	}],
	setDepoimentos: () => { },
	setCursos: () => { },
	refreshSeccaoDepoimentos: false,
	excecaoNaRequisicao: false,
	setExcecaoNaRequisicao: () => { },
	setRefreshSeccaoDepoimentos: () => { },
	enableDialogCadastroDepoimento: false,
	setEnableDialogCadastroDepoimento: () => { },
};

const DepoimentosContext = createContext<DepoimentosContextType>(INI_VALUES);

export const DepoimentosProvider: React.FC = ({ children }) => {
	const { user } = useAuth();
	const [excecaoNaRequisicao, setExcecaoNaRequisicao] = useState<boolean>(false);
	const [depoimentos, setDepoimentos] = useState<Depoimento[]>([]);
	const [cursos, setCursos] = useState([] as any);
	const [refreshSeccaoDepoimentos, setRefreshSeccaoDepoimentos] = useState(
		INI_VALUES.refreshSeccaoDepoimentos,
	);
	const [enableDialogCadastroDepoimento, setEnableDialogCadastroDepoimento] = useState(
		INI_VALUES.enableDialogCadastroDepoimento,
	);
	const tipoDoUsuarioLogado = JSON.parse(
		localStorage.getItem("@Application:User") as string,
	).typeUser;

	useEffect(() => {
		if (tipoDoUsuarioLogado === "student") {
			getDepoimentosAluno()
				.then((res) => {
					if (res.data.data.length > 0) {
						setDepoimentos(res.data.data);
						setExcecaoNaRequisicao(false);
					} else {
						setExcecaoNaRequisicao(true);
					}
				})
				.catch(() => {
					setExcecaoNaRequisicao(true);
				});
		} else {
			getDepoimentosTutor()
				.then((res) => {
					if (res.data.data.length > 0) {
						setDepoimentos(res.data.data);
						setExcecaoNaRequisicao(false);
					} else {
						setExcecaoNaRequisicao(true);
					}
				})
				.catch(() => {
					setExcecaoNaRequisicao(true);
				});
		}
	}, [user.ra, setDepoimentos, refreshSeccaoDepoimentos, tipoDoUsuarioLogado, setExcecaoNaRequisicao]);

	return (
		<DepoimentosContext.Provider
			value={{
				cursos,
				setCursos,
				depoimentos,
				setDepoimentos,
				excecaoNaRequisicao,
				setExcecaoNaRequisicao,
				refreshSeccaoDepoimentos,
				setRefreshSeccaoDepoimentos,
				enableDialogCadastroDepoimento,
				setEnableDialogCadastroDepoimento,
			}}
		>
			{children}
		</DepoimentosContext.Provider>
	);
};

export function useDepoimentos(): DepoimentosContextType {
	const context = useContext(DepoimentosContext);
	return context;
}
