import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Theme } from "@material-ui/core/styles";

import { useAuth } from "context/Auth/hook";

import "./styles.scss";
import { IconButton, useTheme } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { useGamificacao } from "context/Gamificacao";

const useStyles = makeStyles((theme: Theme) => ({
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		backgroundColor: "#FFFFFF",
	},
}));

export default function Header(): React.ReactElement {
	const { nivelDeEnsino } = useAuth();
	const theme = useTheme();
	const classes = useStyles();
	const { rankingUsuarioLogado, scoreTotalPontuacao, scoreTotalDesafios, setOpenDialogGamificacao } = useGamificacao();

	return (
		<>
			<AppBar position='static' className={classes.appBar}>
				<Toolbar className='navbar_question_answers'>
					<Box
						display="flex"
						style={{ width: "100%", position: "relative" }}
					>
						<div className="box-arrow">
							<IconButton
								edge='start'
								aria-label='menu'
								style={{ color: theme.palette.primary.main }}
								onClick={() => {
									setOpenDialogGamificacao(false);
								}}
							>
								<ArrowBack />
							</IconButton>
						</div>

						<div className="box-logo">
							<img
								onClick={() => {
									setOpenDialogGamificacao(false);
								}}
								src={`${(nivelDeEnsino.codigo_nivel_ensino !== 351) &&
									(nivelDeEnsino.nome_nivel_ensino !== "FACULDADE ALENCARINA") &&
									(nivelDeEnsino.nome_nivel_ensino !== "FACULDADE 5 DE JULHO - EAD") &&
									(nivelDeEnsino.nome_nivel_ensino !== "FACULDADE 5 DE JULHO")
									? nivelDeEnsino.codigo_nivel_ensino === 345 ? "https://dirin.s3.amazonaws.com/ecossistema_v5/assets/fortaleza_horizontal_vermelha.png" : "https://dirin.s3.amazonaws.com/ecossistema_v5/assets/uninta-01.svg"
									: "https://dirin.s3.amazonaws.com/ecossistema_v5/assets/f5+-+logos+2-01.svg"
									}`}
								style={{ cursor: "pointer", width: "auto", padding: "12px" }}
								className='logo'
								alt='Logo UNINTA'
							/>

							<h1 style={{ color: theme.palette.primary.main }}>&nbsp;| Gamificação Learning Data</h1>
						</div>

						<div className="box-pontuacoes">
							<div className="pontuacao" style={{ backgroundColor: theme.palette.primary.main }}>
								<span className="material-symbols-outlined">
									diamond
								</span>
								<span className="valor-pontuacao">{scoreTotalPontuacao}</span>
							</div>

							<div className="pontuacao" style={{ backgroundColor: theme.palette.primary.main }}>
								<span className="material-symbols-outlined">
									military_tech
								</span>
								<span className="valor-pontuacao">{scoreTotalDesafios}</span>
							</div>

							<div className="pontuacao" style={{ backgroundColor: theme.palette.primary.main }}>
								<span className="material-symbols-outlined">
									trending_up
								</span>
								<span className="valor-pontuacao">{rankingUsuarioLogado.rank_geral}</span>
							</div>
						</div>
					</Box>
				</Toolbar>
			</AppBar>
		</>
	);
}
