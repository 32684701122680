import React from "react";
import ItemListaDesafiosView from "./ItemListaDesafios.view";

export default function ItemListaDesafios({ numeroImagem, labelScore, numeroLabelData, pontuacaoMaximaLabel }) {
	return <ItemListaDesafiosView
		numeroImagem={numeroImagem}
		labelScore={labelScore}
		numeroLabelData={numeroLabelData}
		pontuacaoMaximaLabel={pontuacaoMaximaLabel}
	/>;
}
