import React from "react";
import Drawer from "@material-ui/core/Drawer";
import "./styles.scss";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import { ContactSupport } from "@material-ui/icons";
import parse from "html-react-parser";

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Close from "@material-ui/icons/Close";
import { useTheme } from "@material-ui/core/styles";

import "./styles.scss";
import { Avatar, Badge, Button, CircularProgress } from "@material-ui/core";
import { getNameInitials } from "utils/getNameInitials";
import DialogQuestionsAnswers from "pages/PedagogicalCommunication/components/DialogQuestionsAnswersTutor";
import { getMessagesCentral, getMessagesToUserCentralSeen } from "../PopupNotificationsMessage/notificationsmessage.services";
import { getNotificationsCentral } from "core/components/Navbar/navbar.services";
import ChatIcon from '@material-ui/icons/Chat';
import ArrowBackwardIosIcon from '@material-ui/icons/ArrowBackIos';
import DialogQuestionsAnswersNewQuestion from "pages/PedagogicalCommunication/components/DialogQuestionsAnswersTutor/Questions/DialogNewQuestion";


// eslint-disable-next-line @typescript-eslint/no-unused-vars
function hora(hora: any) {
	let arrayHora: string[] = hora.split(" ");
	return arrayHora[1];
}

function date(data: string) {
	let arrayData = data.split(" ");
	arrayData.splice(1, 1);
	arrayData = arrayData[0].split("-");
	return arrayData.reverse().join("/");
}

export default function DialogPopupNotificationsMessage() {

	// Drawer
	const [state, setState] = React.useState(false);
	const toggleDrawer = () => (event: any) => {
		if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) { return; }

		setState(!state);
	};

	// Loading no Drawer
	const [loading, setLoading] = React.useState(true);
	// Ternario para ver qual componente fica no Drawer
	const [mensagens, setMensagens] = React.useState(true);
	// DialogQuestionsAnswers
	const [openNewQuestion, setOpenNewQuestion] = React.useState(false);
	const [openNewQuestionDialog, setOpenNewQuestionDialog] = React.useState(false);
	// Dados com todas as Mensagens recebidas por tutor 
	const [dados, setDados] = React.useState<any>([]);
	// Mensagens específicas de um tutor 
	const [tutor, setTutor] = React.useState<any>("");
	// Total de mensagens não lidas da central pedagogica
	const [totalCentral, setTotalCentral] = React.useState(0);

	const colorsTheme = useTheme();
	function hanlerClose() {
		setLoading(true)
		setMensagens(true);
		getMessagesCentral().then(({ data }) => {
			if (data !== undefined) { setDados(data); }
		}).catch(() => {
			setDados([]);
		}).finally(() => {
			setLoading(false)
		});
	};

	async function hanlerSeen(ra) {
		await getMessagesToUserCentralSeen({ "de": ra });
		getNotificationsCentral().then((data) => {
			setTotalCentral(data.total)
		})
	};

	React.useEffect(() => {
		if (state) {
			getMessagesCentral().then(({ data }) => {
				setDados(data);
			}).catch(function (error) {
				setDados([]);
			}).finally(() => {
				setLoading(false)
			});
		}
	}, [state])

	React.useEffect(() => {
		setTimeout(() => {
			getNotificationsCentral().then((data) => {
				setTotalCentral(data.total)
			}).catch(() => {
				setTotalCentral(0)
			})
		}, 2000);
	}, [])

	const DadosAll = () => (
		<>
			<Box>
				<Box style={{ padding: "0px 15px 0px 15px" }} display='flex' justifyContent='space-between' alignItems='center'>
					<Typography className='author_name typographyText' style={{ fontWeight: "bold", fontSize: "20px" }}>Mensagens</Typography>

					<Box display='flex' alignItems='center' justifyContent='flex-end'>
						<IconButton
							onClick={toggleDrawer()}
							id='btnClosePopup'>
							<Close />
						</IconButton>
					</Box>
				</Box>
				<Divider />

				<Box style={{ maxHeight: "80vh" }} className='area_notify'>
					{(dados.length <= 0 || dados === undefined) ? (
						<Typography className='nullValue'>( Vazio )</Typography>
					) : (
						<List style={{ width: "100%" }} >
							{
								Object.keys(dados).map((data) => (
									<>
										<ListItem style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
											<Box className='boxListItem' onClick={() => {
												setDados([])
												hanlerSeen(dados[data]?.ra ?? " ");
												setMensagens(false); setTutor(dados[data] ?? " ")
											}}>
												<Badge overlap='rectangular' badgeContent={dados[data].nao_lido ?? 0} color='secondary'>
													<Avatar
														src={dados[data]?.avatar_path ?? ""}
														alt='Perfil'
														className={""}
													>
														{getNameInitials(dados[data]?.name ?? " ")}
													</Avatar>
												</Badge>
												<div
													style={{
														width: "100%",
														flexDirection: "row",
														justifyContent: "space-between",
														display: "flex",
														alignItems: "center",
													}}>
													<div
														style={{
															display: "flex",
															flexDirection: "column",
															width: "100%",
														}} >
														<Box
															style={{
																display: "flex",
																flexDirection: "column",
																fontWeight: "bold",
																width: "100%",
																marginBottom: "15px"
															}}>
															<div style={{ display: "flex", flexDirection: "row", fontWeight: "bold" }}>
																<Typography className='author_name_typography' style={{ fontSize: "14px" }}>
																	Tutor(a) &nbsp;{(dados[data].name ?? " ")}
																</Typography>
															</div>
														</Box>
														<Box className='question_author'>
															<Typography className='author_name typographyText' style={{ fontWeight: "bold" }}>
																{parse(dados[data].mensagens[0].mensagem ?? " ")}
															</Typography>
														</Box>
														<div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
															<Typography style={{ fontSize: "14px" }} className='author_name'>
																{date(dados[data].mensagens[0].created_at ?? " ")}
															</Typography>
														</div>
													</div>

													<div>
														<IconButton onClick={() => { setMensagens(true); }}>
															<ArrowForwardIosIcon />
														</IconButton>
													</div>
												</div>
											</Box>
										</ListItem>
									</>
								))
							}
						</List>
					)
					}
				</Box >
			</Box >
		</ >
	)

	const TutorMensagens = () => (
		<Box >
			<Box display='flex' justifyContent='space-between' alignItems='center'>
				<Box display='flex' justifyContent='center' flexDirection='row' alignItems='center' margin={"15px"}>
					<IconButton onClick={() => { hanlerClose(); }}>
						<ArrowBackwardIosIcon />
					</IconButton>
					<Avatar
						src={tutor.avatar_path ?? ""}
						alt='Perfil'
						className={""}
					>
						{getNameInitials(tutor.name ?? " ")}
					</Avatar>
					<Typography variant='h5'
						style={{
							fontSize: "15px",
							fontWeight: "bold",
						}}
					> &nbsp; Tutor(a) &nbsp; {tutor.name ?? " "}</Typography>
				</Box>

				<Box display='flex' alignItems='center' justifyContent='flex-end'>
					<IconButton onClick={() => { hanlerClose() }} id='btnClosePopup'>
						<Close />
					</IconButton>
				</Box>
			</Box>
			<Divider />

			<Box className="ListTutorMensagens">
				<List >
					{
						tutor?.mensagens?.map((data) => (
							<>
								<ListItem style={{ width: "100%", display: "flex", flexDirection: "row", gap: "15px", alignItems: "center" }}>
									<Box className='boxListItem'>
										<div
											style={{
												width: "100%",
												flexDirection: "row",
												display: "flex",
												alignItems: "center",
											}}>
											<div style={{ width: "100%" }}>
												<Box
													style={{
														display: "flex",
														flexDirection: "row",
														fontWeight: "bold",
														justifyContent: "space-between",
														alignItems: "center",
														width: "100%",
													}}>
												</Box>

												<Box className='question_author'>
													<Typography className='author_name'
														style={{
															minWidth: "100%",
															maxWidth: "100%",
															color: "black",
															wordBreak: "break-all"
														}}>
														{parse(data.mensagem ?? " ")}
													</Typography>
												</Box>
												<Box
													style={{
														minWidth: "100%",
														display: "flex",
														justifyContent: "flex-end",
													}}>
													<Typography style={{ fontSize: "14px", alignSelf: "flex-end" }} className='author_name'>
														{date(data.created_at ?? " ")}
														- {hora(data.created_at ?? " ")}
													</Typography>
												</Box>
											</div>
										</div>
									</Box>
								</ListItem>
							</>
						))
					}
				</List>
			</Box>
			<Divider />

			<Box display='flex' justifyContent='flex-start' alignItems='center' margin='10px'>
				<Button
					id='btnSendQuestion'
					variant='contained'
					disableElevation
					style={{
						backgroundColor: colorsTheme.palette.primary.main,
						color: "white",
						padding: '10px 20px',
						borderRadius: '24px',
						margin: '4px',
						marginLeft: '12px',
					}}
					onClick={() => {
						setOpenNewQuestion(true);
						setTimeout(() => {
							setOpenNewQuestionDialog(true);
						}, 1000);
					}}
					endIcon={<ContactSupport />}
				>
					Falar com a Tutoria
				</Button>
			</Box>
		</Box>
	)

	const FadeSpinner = () => (
		<>
			<Box
				style={{
					width: "100%",
					height: "calc(100vh - 64px)",
					display: "flex",
					justifyContent: "center",
					alignItems: "center"
				}}>
				<Box style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
					<Typography variant='subtitle2' component='p'>Por favor, aguarde...</Typography>
					<CircularProgress />
				</Box>
			</Box>
		</>
	);

	const list = () => (
		<div className="listDialogPopupNotificationsMessage" role="presentation" >
			<>
				{loading ? <FadeSpinner /> : <>{mensagens ?
					<>
						<DadosAll />
					</>
					:
					<>
						<TutorMensagens />
					</>
				}</>}
				<DialogQuestionsAnswers open={openNewQuestion} setOpen={setOpenNewQuestion} />
				<DialogQuestionsAnswersNewQuestion open={openNewQuestionDialog} setOpen={setOpenNewQuestionDialog} />
			</>
		</div>
	);

	return (
		<div>
			<React.Fragment >
				<>
					<IconButton onClick={toggleDrawer()} className='notificacoes-navbar'>
						<Badge badgeContent={totalCentral} color='secondary' overlap='rectangular'>
							<ChatIcon style={{ color: "white" }} />
						</Badge>
					</IconButton>
				</>
				<Drawer anchor={"right"} open={state} onClose={toggleDrawer()} variant="persistent" className={"Drawer-MuiBackdrop-root"} >
					{list()}
				</Drawer>
			</React.Fragment>
		</div>
	);
}
