import React from "react";

import PtBr from "date-fns/locale/pt-BR";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { makeStyles, Theme } from "@material-ui/core";

import { AuthProvider } from "context/Auth/provider";
import { ProjectsProvider } from "context/project";
import { SearchProvider } from "context/search";
import { SelectedProvider } from "context/selected";

import Routes from "routes";
import Footer from "components/Footer";
import Suspense from "components/Suspense";

import { Toaster } from "react-hot-toast";
import { BrowserRouter } from "react-router-dom";
import { createStyles } from "@material-ui/styles";
import Navbar from "core/components/Navbar";
import { ReactJoyride } from "components/ReactJoyride";
// import { ReactJoyrideMobile } from "components/ReactJoyrideMobile";
import DisciplinasProvider from "context/Disciplinas/provider";
import { GamificacaoProvider } from "context/Gamificacao";
import { PedagogicalCommunicationProvider } from "context/PedagogicalCommunication/pedagogicalcommunication.context";

//  const NavBar = React.lazy(() => import("modules/Appbar"));

/**
 * A linha abaixo busca a implementação atual da Navbar do Ecossistema
 * const NavBar = React.lazy(() => import("components/Navbar"));
 * A Navbar importada atualmente faz parte da ideia de modulos que tenho desenvolvido.
 */

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: "flex",
			"& > * + *": {
				marginLeft: theme.spacing(2),
			},
		},
	}),
);

/**
 *
 * @returns {JSX.Element} Retorna um container contendo o app completo, com
 * a Appbar, a estrutura de rotas e com o Footer.
 * No momento cada componente possui um ciclo de vida altamente acoplado:
 * 	- Navbar()
 *  - Routes()
 *  - Footer()
 */

export default function App(): React.ReactElement {
	const classes = useStyles();

	return (
		<React.Fragment>
			<BrowserRouter>
				<React.Suspense
					fallback={
						<div className={classes.root}>
							<Suspense />
						</div>
					}
				>
					<MuiPickersUtilsProvider utils={DateFnsUtils} locale={PtBr}>
						<AuthProvider>
							<ProjectsProvider>
								<SearchProvider>
									<SelectedProvider>
										<DisciplinasProvider>
											<GamificacaoProvider>
												<Toaster position='top-right' reverseOrder={false} />
												{/* <div style={{ position: "relative", zIndex: 6 }}> <NavBar /> </div> */}
												{/* { window.innerWidth <= 1275 ? <ReactJoyrideMobile/> : <ReactJoyride /> } */}
												<ReactJoyride />
												<PedagogicalCommunicationProvider>
													<Navbar />
												</PedagogicalCommunicationProvider >
												<div
													style={{
														minHeight: "60vh",
														marginTop: "72px",
														paddingTop: "72px",
													}}
												>
													<Routes />
												</div>
												<Footer />
											</GamificacaoProvider>
										</DisciplinasProvider>
									</SelectedProvider>
								</SearchProvider>
							</ProjectsProvider>
						</AuthProvider>
					</MuiPickersUtilsProvider>
				</React.Suspense>
			</BrowserRouter>
		</React.Fragment>
	);
}
