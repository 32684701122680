import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { getCursos, getGraphData, getHistorico } from "./historicoApi";

interface HistoricoInterface {
	score: any;
	cursos: any;
	disciplinas: any;
	areaDoConhecimento: any;
	competencias: any;
	dominioCognitivo: any;
	status: "idle" | "loading" | "done";
}

const initialState: HistoricoInterface = {
	score: null,
	cursos: [],
	disciplinas: [],
	areaDoConhecimento: null,
	competencias: null,
	dominioCognitivo: null,
	status: "idle",
};

export const getCursosAsync = createAsyncThunk(
	"historico/fetchCursos",
	async (ra: string | any) => {
		// The value we return becomes the `fulfilled` action payload
		const response = await getCursos(ra);
		return response;
	},
);

export const getHistoricAsync = createAsyncThunk(
	"historico/fetchHistorico",
	async (data: { ra: any; curso: any }) => {
		// The value we return becomes the `fulfilled` action payload
		const response = await getHistorico(data);
		return response;
	},
);

export const getSubjectsGraphData = createAsyncThunk(
	"historico/getDadosGraph",
	async (url: any | any[]) => {
		const response = await getGraphData(url);
		return response;
	},
);

export const historicoSlice = createSlice({
	name: "historico",
	initialState: initialState,
	reducers: {
		limpaHistorico: (state) => {
			state.cursos = [];
			state.disciplinas = [];
		},
		montarUrl: (state) => {
			if(Array.isArray(state.disciplinas)) {
				const lista = state.disciplinas.map((disciplina) => disciplina.id_curso_moodle);
				state.score = lista.map((item) => {
					return `idsAva[]=${item}`;
				});
				sessionStorage.setItem("@store:score", JSON.stringify(state.score));
			}
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getCursosAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(getCursosAsync.fulfilled, (state, action: PayloadAction<any>) => {
				state.cursos = action.payload.cursos;
			})
			.addCase(getHistoricAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(getHistoricAsync.fulfilled, (state, action: PayloadAction<any>) => {
				state.status = "done";
				state.disciplinas = action.payload;
			})
			.addCase(getSubjectsGraphData.pending, (state) => {
				state.status = "loading";
				state.score = JSON.parse(sessionStorage.getItem("@store:score") as string);
				state.areaDoConhecimento = JSON.parse(
					sessionStorage.getItem("@areaDoConhecimento") as string,
				);
				state.competencias = JSON.parse(sessionStorage.getItem("@competencias") as string);
				state.dominioCognitivo = JSON.parse(
					sessionStorage.getItem("@dominioCognitivo") as string,
				);
			})
			.addCase(getSubjectsGraphData.fulfilled, (state, action: PayloadAction<any>) => {
				state.status = "done";
				state.areaDoConhecimento = action.payload.areaDoConhecimento;
				state.competencias = action.payload.competencias;
				state.dominioCognitivo = action.payload.dominioCognitivo;

				sessionStorage.setItem(
					"@areaDoConhecimento",
					JSON.stringify(action.payload.areaDoConhecimento),
				);
				sessionStorage.setItem(
					"@competencias",
					JSON.stringify(action.payload.competencias),
				);
				sessionStorage.setItem(
					"@dominioCognitivo",
					JSON.stringify(action.payload.dominioCognitivo),
				);
			});
	},
});

export const { limpaHistorico, montarUrl } = historicoSlice.actions;

export const selectHistorico = (state: RootState) => state.historico;

export default historicoSlice.reducer;
