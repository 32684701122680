import React from "react";
import parse from "html-react-parser";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
// import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import { Paginator } from "primereact/paginator";

import DialogAnswer from "./DialogAnswer";

import {
	getCoursesStudent,
	getListQuestions,
	getListQuestionsCourseAndSubject,
	getSubjectsStudent,
} from "../dialogquestionsanswers.services";

// import { getListCourses, getListSubjects, } from "../../ListSolicitationsReviewQuestion/listsolicitationreview.services";

import { DialogAnswerProps } from "./DialogAnswer/dialoganswer.model";

import { useAuth } from "context/Auth/hook";

import { usePedagogicalCommunication } from "context/PedagogicalCommunication/hooks/usePedagogicalCommunication";

import "./styles.scss";
// import AvatarProfile from "../../../../../components/AvatarProfile";
import { Avatar, Badge, useTheme } from "@material-ui/core";
import { getCourses, getSubjects } from "../../DialogQuestionsAnswersTutor/dialogquestionsanswers.services";
import { getNameInitials } from "utils/getNameInitials";
import { Message, Send } from "@material-ui/icons";
import DialogViewQuestion from "../Questions/DialogViewQuestion";

export default function Response() {
	const theme = useTheme()
	const { user } = useAuth();
	const { reloadListQuestions } = usePedagogicalCommunication();
	const [loadingPage, setLoadingPage] = React.useState(true);

	// Paginação
	const [currentPage, setCurrentPage] = React.useState(0);
	const [totalQuestions, setTotalQuestions] = React.useState(0);

	const [filterCourseSelected, setFilterCourseSelected] = React.useState("all");
	const [filterSubjectSelected, setFilterSubjectSelected] = React.useState("all");

	const [idCourse, setidCourse] = React.useState<any>();

	const [listCourses, setListCourses] = React.useState<{ id: string; name: string }[]>([]);
	const [listSubjects, setListSubjects] = React.useState([]);

	const [listQuestions, setListQuestions] = React.useState<any>([]);

	const [idQuestion, setIdQuestion] = React.useState(""); // Id da questão atual selecionada
	const [openViewQuestion, setOpenViewQuestion] = React.useState(false);

	const [openAnswer, setOpenAnswer] = React.useState(false);
	const [detailsAnswer, setDetailsAnswer] =
		React.useState<Omit<DialogAnswerProps, "open" | "setOpen" | "course">>();

	const onOpenAnswer = (
		authorId = "",
		authorAvatar = "",
		authorName: string,
		authorCourse: string,
		authorSubject: string,
		answer: string,
		answerId: string,
	) => {
		setOpenAnswer(true);
		setDetailsAnswer({
			authorId,
			authorAvatar,
			authorName,
			authorCourse,
			authorSubject,
			answer,
			answerId,
		});
	};

	// TODO: Busca a lista de perguntas filtradas por tipo, valor e página
	const getListFilteredQuestion = (user, type?: string, value?: string, page = 1) => {
		value = value === "all" ? "" : value
		setLoadingPage(true);
		try {
			getListQuestions(user, type, value, page)
				.then((res: any) => {
					setListQuestions(res.data);
					setTotalQuestions(res.total);
					setLoadingPage(false);
					if (type === "disciplina_id") { setFilterCourseSelected("all"); } else { setFilterSubjectSelected("all"); }
				})
				.catch((e) => {
					setListQuestions([]);
					setTotalQuestions(0);
					setLoadingPage(false);
					console.log(e);
				});
		} catch (err) {
			setListQuestions([]);
			setTotalQuestions(0);
			setLoadingPage(false);
			console.log(err)
		}
	};

	// Busca questões
	const getQuestionsPaginator = (page = 1) => {
		setLoadingPage(true);
		getListQuestions(user, "", "", page)
			.then((response: any) => {
				setListQuestions(response.data);
				setTotalQuestions(response.total);
				setLoadingPage(false);
				setFilterCourseSelected("all");
				setFilterSubjectSelected("all");
			})
			.catch((e) => {
				setListQuestions([]);
				setTotalQuestions(0);
				setLoadingPage(false);
				console.log(e);
			});
	};

	const handleFilterListQuestions = (
		key: "curso_id" | "disciplina_id",
		value: string,
		user: any,
		page?: number,
	) => {
		setLoadingPage(true);

		let course = filterCourseSelected;
		let subject = filterSubjectSelected;

		if (key === "curso_id") {
			course = value;
		}

		if (key === "disciplina_id") {
			subject = value;
		}

		if (course !== "all" && subject !== "all") {
			getListQuestionsCourseAndSubject(user, course.split(":")[0], subject, page)
				.then((res: any) => {
					setListQuestions(res.data);
					setTotalQuestions(res.total);
					setLoadingPage(false);
				});
		} else {
			let newKey: string = key;
			let newValue: string = value;

			if (key === "curso_id" && value === "all") {
				if (filterSubjectSelected === "all") {
					newKey = "";
					newValue = "";
				} else {
					newKey = "disciplina_id";
					newValue = filterSubjectSelected;
				}
			}

			if (key === "disciplina_id" && value === "all") {
				if (filterCourseSelected === "all") {
					newKey = "";
					newValue = "";
				} else {
					newKey = "curso_id";
					newValue = filterCourseSelected.split(":")[0];
				}
			}

			getListQuestions(user, newKey, newValue, page)
				.then((res: any) => {
					setListQuestions(res.data);
					setTotalQuestions(res.total);
					setLoadingPage(false);
				})
				.catch((e) => {
					console.log(e);
				});
		}
	};


	React.useEffect(() => {
		if (user.typeUser === "tutor") {
			getCourses()
				.then(({ data }) => {
					setFilterCourseSelected("all");
					setListCourses(data);
					setCurrentPage(0);
					getQuestionsPaginator();
				})
				.catch((e) => {
					console.log(e);
				});

			getSubjects()
				.then(({ data }) => {
					setListSubjects(data);
				})
				.catch((e) => {
					console.log(e);
				});

			return;
		}

		getCoursesStudent(user?.ra).then((data: any) => {
			// setFilterCourseSelected(`${data[data.length - 1].id}:${data[data.length - 1].name}`);
			setListCourses(data);
			getListFilteredQuestion(user, "curso_id", data[data.length - 1].id);
		}).catch((e) => {
			console.log(e);
		});

		getSubjectsStudent(user?.ra).then((data: any) => {
			setListSubjects(data);
		}).catch((e) => {
			console.log(e);
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user, user?.ra, user.typeUser, reloadListQuestions]);

	React.useEffect(() => {
		if (currentPage) {
			setCurrentPage(0);
			getQuestionsPaginator();

			// getListFilteredQuestion(
			// 	user,
			// 	filterSubjectSelected !== "all" ? "disciplina_id" : "curso_id",
			// 	filterSubjectSelected !== "all"
			// 		? filterSubjectSelected.split(":")[0]
			// 		: filterCourseSelected.split(":")[0],
			// 	page,
			// );
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reloadListQuestions]);

	function date(data: string) {
		let arrayData = data.split(" ");
		arrayData.splice(1, 1);
		arrayData = arrayData[0].split("-");
		return arrayData.reverse().join("/");
	}

	function hora(hora: string) {
		let arrayHora = hora.split(" ");
		return arrayHora[1];
	}

	return (
		<Container>
			<Grid container>
				{loadingPage ? (
					<Grid item xs={12}>
						<Box className='awaiting'>
							<Typography variant='subtitle2'>Por favor, aguarde...</Typography>
							<CircularProgress />
						</Box>
					</Grid>
				) : (
					<>
						<Grid item xs={12} className='head_response_filter'>
							<Box className="billboard">
								<Typography variant="h5" component="h5">
									Dúvidas pedagógicas
								</Typography>
								<Typography
									variant="h3"
									component="h3"
									className="billboard_header"
									style={{
										marginBottom: '12px',
										fontWeight: 'bolder'
									}}>
									Listagem de Perguntas
								</Typography>
								<Typography
									variant="body1"
									component="p"
									className="caption"
									style={{
										fontSize: "1.2em",
										padding: "12px",
										marginBottom: "12px"
									}}>
									Utilize os filtros para uma busca mais rápida
								</Typography>
							</Box>

							<Box className='filters_questions_in_respnse'>
								<FormControl variant='outlined'>
									<InputLabel>Cursos:</InputLabel>
									<Select
										label='Cursos:'
										id='selectCourseAnswer'
										value={filterCourseSelected}
										onChange={(e) => {
											const val = e.target.value as string;
											setCurrentPage(0);
											setFilterCourseSelected(val);
											handleFilterListQuestions("curso_id", val.split(":")[0], user, 1);
											// getListFilteredQuestion(user, "curso_id", val.split(":")[0]);
										}}
										style={{ minWidth: "100px" }}
									>
										<MenuItem value='all'>Todos</MenuItem>
										{listCourses.map((course: any) => (
											<MenuItem
												key={
													user.typeUser === "tutor"
														? course.id
														: course.id
												}
												value={
													user.typeUser === "tutor"
														? course.id
														: `${course.id}:${course.name}`
												}
											>
												{(user.typeUser === "tutor") ? course.nome : course.name}&nbsp;
												{(user.typeUser === "tutor") ? <>- &nbsp;{course.id}</> : ""}
											</MenuItem>
										))}
									</Select>
								</FormControl>

								<FormControl variant='outlined'>
									<InputLabel>Disciplinas:</InputLabel>
									<Select
										id='selectSubjectAnswer'
										label='Disciplinas:'
										value={filterSubjectSelected}
										onChange={(e) => {
											const val = e.target.value as string;
											setCurrentPage(0);
											setFilterSubjectSelected(val);
											handleFilterListQuestions("disciplina_id", val, user);
											// if (e.target.value === "all") {
											// 	getListFilteredQuestion(
											// 		user,
											// 		"curso_id",
											// 		filterCourseSelected.split(":")[0],
											// 	);
											// } else {
											// 	getListFilteredQuestion(
											// 		user,
											// 		"disciplina_id",
											// 		e.target.value as string,
											// 	);
											// }
										}}
										style={{ minWidth: "100px" }}
									>
										<MenuItem value='all'>Todos</MenuItem>
										{listSubjects.map((course: any) => (
											course.id !== "0" ?
												<MenuItem key={course.id} value={course.id}>
													{course.nome}
												</MenuItem>
												: <></>
										))}
									</Select>
								</FormControl>
							</Box>
						</Grid>

						{listQuestions &&
							listQuestions.length <= 0 ? (
							<Box className='nothing'>
								<Typography className='text_no_find'>
									Nenhum resultado encontrado
								</Typography>
								<Typography className='subtext_no_find'>
									Tente filtrar por outro curso ou disciplina
								</Typography>
							</Box>
						) : (
							<>
								{Array.isArray(listQuestions) && listQuestions.map((qt: any) => (
									<Grid
										item
										xs={12}
										className='questions_area_response'
										key={qt.id}
									>
										<Box className='question_author'>
											<Avatar
												src={qt.profile?.avatar_path ? `https://dirin.s3.us-east-1.amazonaws.com/${qt.profile.avatar_path}` : ""}
												alt='Perfil'
												className={""}
											>
												{getNameInitials(qt.profile?.name ?? " ")}
											</Avatar>
											<Box className='author_name_course'>
												<Typography className='author_name'>
													{qt.profile.name.toLocaleLowerCase()}
												</Typography>
												<Typography className='author_course'>
													{qt?.curso.nome} - {qt?.disciplina.nome} - {date(qt?.created_at)} - {hora(qt?.created_at)}
												</Typography>
											</Box>
										</Box>

										<Box className='question_view'>
											<Typography variant='subtitle2' style={{ overflowWrap: "break-word" }}>
												{parse(qt.pergunta)}
											</Typography>
										</Box>

										<Box className='question_view_actions'>
											<Badge
												badgeContent={qt.respostas_count !== 0 ? qt.respostas_count : 0}
												color="primary"
												overlap="circular">
												<Button
													id='btnResponses'
													variant='text'
													endIcon={<Message />}
													style={{
														color: theme.palette.primary.light,
														marginLeft: '12px', marginRight: '12px'
													}}
													onClick={() => {
														setOpenViewQuestion(true);
														setIdQuestion(qt.id);
													}}
												>
													RESPOSTAS
												</Button>
											</Badge>
											<Button
												id='btdToResponse'
												variant='contained'
												disableElevation
												onClick={() => {
													setidCourse(qt?.curso.id)
													setOpenAnswer(true);
													onOpenAnswer(
														qt.profile.ra,
														qt.profile.avatar_path,
														qt.profile.name,
														qt?.curso.nome,
														qt?.disciplina.nome,
														qt.pergunta,
														qt.id,
													);
												}}
												style={{
													backgroundColor: theme.palette.primary.main,
													color: "white"
												}}
												endIcon={<Send />}
											>
												Responder
											</Button>
										</Box>
									</Grid>
								))}

								<Paginator
									rows={10}
									totalRecords={totalQuestions}
									first={currentPage}
									onPageChange={(e) => {
										setCurrentPage(e.first);
										if (
											filterSubjectSelected === "todos" &&
											filterCourseSelected !== "todos"
										) {
											handleFilterListQuestions(
												"curso_id",
												filterCourseSelected.split(":")[0] as string,
												user,
												e.page + 1,
											);
										} else if (filterSubjectSelected !== "todos") {
											handleFilterListQuestions(
												"disciplina_id",
												filterSubjectSelected.split(":")[0] as string,
												user,
												e.page + 1,
											);
										} else {
											getQuestionsPaginator(e.page + 1);
										}
										// getListFilteredQuestion(
										// 	user,
										// 	filterSubjectSelected !== "all"
										// 		? "disciplina_id"
										// 		: "curso_id",
										// 	filterSubjectSelected !== "all"
										// 		? filterSubjectSelected.split(":")[0]
										// 		: filterCourseSelected.split(":")[0],
										// 	e.page + 1,
										// );
									}}
									style={{
										background: "#f7f7f7",
										marginTop: 10,
										width: "100%",
									}}
								></Paginator>
							</>
						)}
					</>
				)}
			</Grid>

			<DialogAnswer
				open={openAnswer}
				course={idCourse}
				authorId={detailsAnswer?.authorId ?? ""}
				authorName={detailsAnswer?.authorName}
				authorCourse={detailsAnswer?.authorCourse}
				authorSubject={detailsAnswer?.authorSubject}
				authorAvatar={detailsAnswer?.authorAvatar ?? ""}
				answer={detailsAnswer?.answer}
				answerId={detailsAnswer?.answerId}
				setOpen={setOpenAnswer}
			/>

			<DialogViewQuestion
				open={openViewQuestion}
				id={idQuestion}
				setOpen={setOpenViewQuestion}
				setId={setIdQuestion}
				question={
					listQuestions.find(question => question?.id === idQuestion)
				}
			/>
		</Container >
	);
}
