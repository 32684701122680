import React, { useEffect, useState } from "react";
import { Chart } from "primereact/chart";
import { calcularScoreComunicacaoPedagogica, calcularScoreEcossistema, calcularScoreMaterialDidatico } from "utils/Gamificacao/calculoScoresPorModulo";
import { useGamificacao } from "context/Gamificacao";
import { CircularProgress } from "@material-ui/core";

export default function PontuacoesView() {
    const { score, scoreTotalPontuacao, loadingPontuacao } = useGamificacao();
    const [chartData, setChartData] = useState({
        labels: ['Comunicação Pedagógica', 'Ecossistema', 'Material Didático'],
        datasets: [
            {
                data: [1, 1, 1],
                backgroundColor: [
                    "#ff6361",
                    "#ffa600",
                    "#668c9d"
                ],
                hoverBackgroundColor: [
                    "#FF6384",
                    "#FFCE56",
                    "#36A2EB"
                ]
            }]
    });

    const [lightOptions] = useState({
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                }
            }
        }
    });

    useEffect(() => {
        const scoreComunicacaoPedagogica = calcularScoreComunicacaoPedagogica(score);
        const scoreEcossistema = calcularScoreEcossistema(score);
        const scoreMaterialDidatico = calcularScoreMaterialDidatico(score);

        setChartData({
            labels: ['Comunicação Pedagógica', 'Ecossistema', 'Material Didático'],
            datasets: [
                {
                    data: [scoreComunicacaoPedagogica !== 0 ? scoreComunicacaoPedagogica : 0, scoreEcossistema !== 0 ? scoreEcossistema : 0, scoreMaterialDidatico !== 0 ? scoreMaterialDidatico : 1],
                    backgroundColor: [
                        "#ff6361",
                        "#ffa600",
                        "#668c9d"
                    ],
                    hoverBackgroundColor: [
                        "#FF6384",
                        "#FFCE56",
                        "#36A2EB"
                    ]
                }]
        });
    }, [score]);

    return (
        <>
            <div className="grafico-gamificacao">
                <div className="imagem-gamificacao">
                    <img src="https://dirin.s3.amazonaws.com/ecossistema_v5/assets/trofeu.png" alt="Troféu da Gamificação" />
                </div>

                <div className="grafico">
                    <Chart type="doughnut" data={chartData} options={lightOptions} style={{ position: 'relative' }} />

                    <div className="pontuacao-total-gamificacao">
                        {
                            loadingPontuacao ?
                                <CircularProgress />
                            :
                            <>
                                <span>{scoreTotalPontuacao}</span>
                                Total
                            </>
                                
                        }
                    </div>
                </div>
            </div>

            <div className="modulos-gamificacao">
                <div className="gamificacao-modulo">
                    <h3 style={{ color: "#ff6361" }}>
                        <strong>COMUNICAÇÃO PEDAGÓGICA</strong>
                    </h3>

                    <div className="gamificacao-lista">
                        <ul>
                            <li>
                                <div className="cor-modulo" style={{ backgroundColor: "#ff6361" }}></div>

                                <div className="pontuacao-label">
                                    <span className="pontuacao">{score.scores?.ALUNO_ENVIA_DUVIDA_CONTEUDO ?? 0}</span>
                                    <span className="label-score">Enviar Dúvida de Conteúdo</span>
                                </div>
                            </li>

                            <li>
                                <div className="cor-modulo" style={{ backgroundColor: "#ff6361" }}></div>

                                <div className="pontuacao-label">
                                    <span className="pontuacao">{score.scores?.ALUNO_RESPONDE_DUVIDA_CONTEUDO ?? 0}</span>
                                    <span className="label-score">Responder Dúvidas de Conteúdo</span>
                                </div>
                            </li>

                            <li>
                                <div className="cor-modulo" style={{ backgroundColor: "#ff6361" }}></div>

                                <div className="pontuacao-label">
                                    <span className="pontuacao">{score.scores?.ALUNO_COMENTA_RESPOSTA_DUVIDA_CONTEUDO ?? 0}</span>
                                    <span className="label-score">Comentar Resposta de Dúvidas</span>
                                </div>
                            </li>

                            <li>
                                <div className="cor-modulo" style={{ backgroundColor: "#ff6361" }}></div>

                                <div className="pontuacao-label">
                                    <span className="pontuacao">{score.scores?.ALUNO_AVALIA_RESPOSTA_DUVIDA_CONTEUDO ?? 0}</span>
                                    <span className="label-score">Avaliar Respostas</span>
                                </div>
                            </li>

                            <li>
                                <div className="cor-modulo" style={{ backgroundColor: "#ff6361" }}></div>

                                <div className="pontuacao-label">
                                    <span className="pontuacao">{score.scores?.ALUNO_SOLICITA_AGENDAMENTO_DEFER_INDEFER ?? 0}</span>
                                    <span className="label-score">Solicitações de Agendamento Deferido</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="gamificacao-modulo">
                    <h3 style={{ color: "#ffa600" }}>
                        <strong>ECOSSISTEMA</strong>
                    </h3>

                    <div className="gamificacao-lista">
                        <ul>
                            <li>
                                <div className="cor-modulo" style={{ backgroundColor: "#d3d3d3" }}></div>

                                <div className="pontuacao-label">
                                    <span className="pontuacao">0</span>
                                    <span className="label-score">Eventos Virtuais</span>
                                </div>
                            </li>
                            <li>
                                <div className="cor-modulo" style={{ backgroundColor: "#d3d3d3" }}></div>

                                <div className="pontuacao-label">
                                    <span className="pontuacao">0</span>
                                    <span className="label-score">Oficinas Virtuais</span>
                                </div>
                            </li>
                            <li>
                                <div className="cor-modulo" style={{ backgroundColor: "#d3d3d3" }}></div>

                                <div className="pontuacao-label">
                                    <span className="pontuacao">0</span>
                                    <span className="label-score">Cursos Livres</span>
                                </div>
                            </li>
                            <li>
                                <div className="cor-modulo" style={{ backgroundColor: "#d3d3d3" }}></div>

                                <div className="pontuacao-label">
                                    <span className="pontuacao">0</span>
                                    <span className="label-score">Laboratórios Virtuais</span>
                                </div>
                            </li>
                            <li>
                                <div className="cor-modulo" style={{ backgroundColor: "#d3d3d3" }}></div>

                                <div className="pontuacao-label">
                                    <span className="pontuacao">0</span>
                                    <span className="label-score">Construir Trilhas de Aprendizagem</span>
                                </div>
                            </li>
                            <li>
                                <div className="cor-modulo" style={{ backgroundColor: "#d3d3d3" }}></div>

                                <div className="pontuacao-label">
                                    <span className="pontuacao">0</span>
                                    <span className="label-score">Concluir Trilha de Aprendizagem</span>
                                </div>
                            </li>
                            <li>
                                <div className="cor-modulo" style={{ backgroundColor: "#d3d3d3" }}></div>

                                <div className="pontuacao-label">
                                    <span className="pontuacao">0</span>
                                    <span className="label-score">Colaborar UNINTA Inspire</span>
                                </div>
                            </li>
                            <li>
                                <div className="cor-modulo" style={{ backgroundColor: "#ffa600" }}></div>

                                <div className="pontuacao-label">
                                    <span className="pontuacao">{score.scores?.USUARIO_CONCLUIU_TUTORIAL ?? 0}</span>
                                    <span className="label-score">Concluir Tutorial</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="gamificacao-modulo">
                    <h3 style={{ color: "#668c9d" }}>
                        <strong>MATERIAL DIDÁTICO</strong>
                    </h3>

                    <div className="gamificacao-lista">
                        <ul>
                            <li>
                                <div className="cor-modulo" style={{ backgroundColor: "#668c9d" }}></div>

                                <div className="pontuacao-label">
                                    <span className="pontuacao">{score.scores?.USUARIO_CONTRIBUA_COM_DOCUMENTO ?? 0}</span>
                                    <span className="label-score">Contribuição de Conteúdo</span>
                                </div>
                            </li>
                            <li>
                                <div className="cor-modulo" style={{ backgroundColor: "#668c9d" }}></div>

                                <div className="pontuacao-label">
                                    <span className="pontuacao">{score.scores?.USUARIO_REALIZA_COMENTARIO ?? 0}</span>
                                    <span className="label-score">Comentar Contribuições</span>
                                </div>
                            </li>
                            <li>
                                <div className="cor-modulo" style={{ backgroundColor: "#668c9d" }}></div>

                                <div className="pontuacao-label">
                                    <span className="pontuacao">{score.scores?.USUARIO_AVALIA_CONTRIB_COM_DOCUMENTO ?? 0}</span>
                                    <span className="label-score">Avaliar Contribuições</span>
                                </div>
                            </li>
                            <li>
                                <div className="cor-modulo" style={{ backgroundColor: "#668c9d" }}></div>

                                <div className="pontuacao-label">
                                    <span className="pontuacao">{score.scores?.USUARIO_ACESSA_RECURSO_APRENDZ ?? 0}</span>
                                    <span className="label-score">Acessar Recursos</span>
                                </div>
                            </li>
                            <li>
                                <div className="cor-modulo" style={{ backgroundColor: "#668c9d" }}></div>

                                <div className="pontuacao-label">
                                    <span className="pontuacao">{score.scores?.USUARIO_ACESSA_RECURSO_APROF_CONHECIMENTO ?? 0}</span>
                                    <span className="label-score">Acessar Aprofundamento Conhecimento</span>
                                </div>
                            </li>
                            <li>
                                <div className="cor-modulo" style={{ backgroundColor: "#668c9d" }}></div>

                                <div className="pontuacao-label">
                                    <span className="pontuacao">{score.scores?.USUARIO_EDIT_SAVE_BLOCO_DE_NOTAS ?? 0}</span>
                                    <span className="label-score">Bloco de Notas</span>
                                </div>
                            </li>
                            <li>
                                <div className="cor-modulo" style={{ backgroundColor: "#668c9d" }}></div>

                                <div className="pontuacao-label">
                                    <span className="pontuacao">{score.scores?.USUARIO_RESPONDE_PESQUISA_AVA_QUALIDADE_MAT_DIDAT ?? 0}</span>
                                    <span className="label-score">Avaliar Qualidade da Disciplina</span>
                                </div>
                            </li>
                            <li>
                                <div className="cor-modulo" style={{ backgroundColor: "#668c9d" }}></div>

                                <div className="pontuacao-label">
                                    <span className="pontuacao">{score.scores?.USUARIO_AVALIA_UNIDADES_DE_ESTUDO ?? 0}</span>
                                    <span className="label-score">Avaliar Unidades</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}