import React from "react";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";

import { CardNavegationProps } from "./cardnavegate.model";

import "./styles.scss";
import { useTheme } from "@material-ui/core";
import Imagem from "components/Imagem";

const useStyles = makeStyles({
	root: {
		maxWidth: 300,
		minWidth: 300,
		minHeight: 300,
		background: "#fff",
		boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
	},
	media: {
		height: "8rem",
		backgroundPosition: "center center",
		backgroundSize: "100% 100%",
		borderBottom: "1px solid #d0d0d0",
	},
});

export default function CardNavegate(props: CardNavegationProps): JSX.Element {
	const classes = useStyles();
	const theme = useTheme();
	return (
		<Grid
			item
			xs={12}
			sm={6}
			md={4}
			lg={3}
			style={{
				padding: 4,
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				marginBottom: "6vh",
			}}
		>
			<Card className={classes.root} elevation={3}>
				<Imagem
					src={props.imageCard}
					className={classes.media}
					alt="Módulo da Comunicação Pedagógica"
					heightPlaceholder="128px"
				/>
				<CardContent style={{ background: "#fff" }}>
					<Typography className='title_card'>{props.titleCard}</Typography>
				</CardContent>
				<CardActions
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						background: "#fff",
					}}
				>
					<Button
						variant='contained'
						disableElevation
						startIcon={props.iconStartButton}
						className='btnAcesss'
						onClick={props.actionButton}
						style={{
							backgroundColor: theme.palette.primary.main,
						}}
						id={props.id}
					>
						{props.titleButton}
					</Button>
				</CardActions>
			</Card>
		</Grid>
	);
}
