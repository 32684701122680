import { Box, Typography } from "@material-ui/core";
import "./styles.scss";
import { Carousel } from "primereact/carousel";
import CardTrilhaAprendizagem from "components/Oportunidades/CardsOportunidades/CardTrilhaAprendizagem";
import React, { useEffect, useState } from "react";
import { TrilhaAprendizagem } from "models/trilhaAprendizagem";
import { getTrilhasAprendizagem } from "services/Oportunidades";
import NoContentAvailable from "components/NoContentAvailableTeste";

export default function SeccaoTrilhasAprendizagemView() {
	const [refreshSeccaoTrilhasAprendizagem, setRefreshSeccaoTrilhasAprendizagem] =
		useState<boolean>(false);
	const [trilhasAprendizagem, setTrilhasAprendizagem] = useState<TrilhaAprendizagem[]>([]);
	const [excecaoNaRequisicao, setExcecaoNaRequisicao] = useState<boolean>(false);

	useEffect(() => {
		getTrilhasAprendizagem()
			.then((res) => {
				setTrilhasAprendizagem(res.data);
				setExcecaoNaRequisicao(false);
			})
			.catch(() => {
				setExcecaoNaRequisicao(true);
			});
	}, [refreshSeccaoTrilhasAprendizagem]);

	const responsiveOptions = [
		{
			breakpoint: "1024px",
			numVisible: 2,
			numScroll: 2,
		},
		{
			breakpoint: "768px",
			numVisible: 1,
			numScroll: 1,
		},
		{
			breakpoint: "560px",
			numVisible: 1,
			numScroll: 1,
		},
	];

	function itemTemplate(trilhaAprendizagem: TrilhaAprendizagem) {
		return <CardTrilhaAprendizagem dados={trilhaAprendizagem} />;
	}

	return (
		<Box className='seccao-trilhas-aprendizagem'>
			<Box className='box-header'>
				<Typography component='h2'>Trilhas de Aprendizagem</Typography>
			</Box>

			{excecaoNaRequisicao ? (
				<NoContentAvailable
					refresh={refreshSeccaoTrilhasAprendizagem}
					setRefresh={setRefreshSeccaoTrilhasAprendizagem}
				/>
			) : (
				<React.Fragment>
					<Box className='box-text-content'>
						<Typography color='textSecondary' component='p'>
							Sabemos que você assim como o UNINTA pensa e pratica a aprendizagem de
							forma plena para a vida toda, preparamos as melhores trilhas de
							aprendizagem com um conjunto de cursos, palestras e oficinas organizadas
							por professores para lhe ajudar no ingresso ao mercado de trabalho.
						</Typography>
					</Box>

					<Carousel
						value={trilhasAprendizagem}
						itemTemplate={itemTemplate}
						numVisible={3}
						numScroll={1}
						responsiveOptions={responsiveOptions}
					></Carousel>

					<hr />
				</React.Fragment>
			)}
		</Box>
	);
}
