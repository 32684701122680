import {
	Button,
	Checkbox,
	CircularProgress,
	CssBaseline,
	Dialog,
	FormControl,
	FormControlLabel,
	IconButton,
	InputAdornment,
	makeStyles,
	TextField,
	Typography,
	useTheme,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useAuth } from "context/Auth/hook";
import { useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import Notify, { CustomNotification } from "utils/Notification";
import React, { useState } from "react";
import "./styles.scss";

// -- Lottie
import animationData from "../../assets/Lottie/animacao.json";
import Lottie from "lottie-react";

// -- Inserindo Array com diferentes níveis de ensino
export const niveisDeEnsino = [
	"faculdade-alencarina",
	"alencarina",
	"5-de-julho",
	"julho",
	"faculdade-5-de-julho",
	"f5",
	"fal",
	"inta",
	"uninta",
	"bonus",
	"uninta-fortaleza",
	"fortaleza",
];

export function SignInView(): JSX.Element {

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [easter, setEaster] = useState(0);

	const theme = useTheme();
	const [values] = useSearchParams();
	const { Login, setNivelDeEnsino, nivelDeEnsino } = useAuth();
	const useStyles = makeStyles((theme) => ({
		root: {
			color: theme?.palette.secondary.main,
		},
		uninta: {
			color: "#bd0e0e",
		},
		textField: {
			"& label.Mui-focused": {
				color: "black",
			},
			"& .MuiInput-underline:after": {
				borderBottomColor: "Grey",
			},
			"& .MuiOutlinedInput-root": {
				"& fieldset": {
					borderColor: "Grey",
				},
				"&.Mui-focused fieldset": {
					borderColor: "Grey",
				},
			},
		},
	}));
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({ mode: "onSubmit" });

	const classes = useStyles();


	React.useEffect(() => {

		if (values && values.get("redirect") != null) {
			localStorage.setItem("@redirect", values.get("redirect") as string)
		}

		if (values) {
			if (
				values.get("nivelDeEnsino") &&
				niveisDeEnsino.includes(values.get("nivelDeEnsino") || "uninta")
			) {
				switch (values.get("nivelDeEnsino")) {
					case "faculdade-alencarina":
						setNivelDeEnsino(
							JSON.parse(
								"{\"codigo_nivel_ensino\":0,\"nome_nivel_ensino\":\"FACULDADE ALENCARINA\",\"codigo_curso\":\"\",\"nome_curso\":\"\",\"periodo\":0,\"situacao\":\"\",\"idhabilitacaofilial\":\"\",\"idperlet\":\"\"}",
							),
						);
						break;
					case "faculdade-5-de-julho":
						setNivelDeEnsino(
							JSON.parse(
								"{\"codigo_nivel_ensino\":0,\"nome_nivel_ensino\":\"FACULDADE ALENCARINA\",\"codigo_curso\":\"\",\"nome_curso\":\"\",\"periodo\":0,\"situacao\":\"\",\"idhabilitacaofilial\":\"\",\"idperlet\":\"\"}",
							),
						);
						break;
					case "uninta-fortaleza":
						setNivelDeEnsino(
							JSON.parse(
								"{\"codigo_nivel_ensino\":345,\"nome_nivel_ensino\":\"FACULDADE UNINTA FORTALEZA\",\"codigo_curso\":\"\",\"nome_curso\":\"\",\"periodo\":0,\"situacao\":\"\",\"idhabilitacaofilial\":\"\",\"idperlet\":\"\"}",
							),
						);
						break;
					default:
						break;
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [loadingLogin, setLoadingLogin] = React.useState(false);
	const [showPassword, setShowPassword] = React.useState(false);

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};

	interface LoginData {
		ra: string;
		password: string;
	}

	const onSubmit = (data: LoginData) => {
		if (!data.ra) {
			Notify("Campo de RA obrigatório.", "warning");
			return;
		}

		if (!data.password) {
			Notify("Campo de Senha obrigatório.", "warning");
			return;
		}

		setLoadingLogin(true);

		Login({ ...data })
			.then((data) => {
				if (!data.data) {
					setLoadingLogin(false);
				}
			})
			.catch(() => {
				setLoadingLogin(false);
			});
	};

	React.useEffect(() => {

		if (easter === 5)
			CustomNotification("Olá! Este é o novo Ecossistema de Aprendizagem. Faça login para prosseguir.")

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [easter >= 5])

	return (
        <>
		<Dialog
			open={true}
			fullScreen
			className='container-fullscreen'
		>
			<div className='container-login'>
				<CssBaseline />

				<div className="secao">
					<div className='seccao-banner-login'>
						<div className='banner-login' id='logo'>
							<Lottie
								animationData={animationData}
								loop={true}
								id='lottie'
							/>
						</div>

						<div className='textos-banner-login'>
							<div style={{ marginTop: "40px" }}>
								<Typography variant='h3'>
									ECOSSISTEMA DE{" "}
									<span
										className={
											nivelDeEnsino.nome_nivel_ensino !==
												"FACULDADE ALENCARINA" &&
												nivelDeEnsino.nome_nivel_ensino !== "FACULDADE 5 DE JULHO"
												? classes.uninta
												: classes.root
										}
									>
										APRENDIZAGEM
									</span>
								</Typography>
								<Typography component='p'>
									Para conectar pessoas e formar comunidades de práticas.
								</Typography>
								<div style={{
									width: "100%",
									display: "flex",
									alignItems: "center",
									justifyContent: "space-between",
									gap: "40px",
									marginTop: "40px"
								}}>
									<img
										src={"https://dirin.s3.amazonaws.com/ecossistema_v5/assets/FIED.png"}
										alt='Login do Ecossitema'
										style={{
											height: "auto", width: "20%"
										}}
									/>
									<img
										src={"https://dirin.s3.amazonaws.com/ecossistema_v5/assets/f5-+logos_Prancheta_1.svg"}
										alt='Login do Ecossitema'
										style={{
											height: "80px", width: "20%"
										}}
									/>
									<img
										src={"https://dirin.s3.amazonaws.com/ecossistema_v5/assets/fortaleza_horizontal_vermelha.svg"}
										alt='Login do Ecossitema'
										style={{
											height: "80px", width: "20%"
										}}
									/>
									<img
										src={"https://dirin.s3.amazonaws.com/ecossistema_v5/assets/uninta-01.svg"}
										alt='Login do Ecossitema'
										style={{
											height: "55px", width: "20%"
										}}
									/>
								</div>
							</div>
						</div>
					</div>

					<div className='seccao-formulario-login'>
						<div className='formulario-login'>
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "center",
									marginTop: "12px",
									marginBottom: "12px",
								}}
							>
								{/* <img
									src='https://dirin.s3.amazonaws.com/ecossistema_v5/assets/uninta-01.png'
									alt='Logo Uninta'
									width='200'
								/> */}

								{/* 
								<img
									src={
										(nivelDeEnsino.codigo_nivel_ensino !== 351) &&
											(nivelDeEnsino.nome_nivel_ensino !== "FACULDADE ALENCARINA") &&
											(nivelDeEnsino.nome_nivel_ensino !== "FACULDADE 5 DE JULHO - EAD") &&
											(nivelDeEnsino.nome_nivel_ensino !== "FACULDADE 5 DE JULHO")
											? (nivelDeEnsino.codigo_nivel_ensino === 345) ? "https://dirin.s3.amazonaws.com/ecossistema_v5/assets/fortaleza_horizontal_vermelha.png" : "https://dirin.s3.amazonaws.com/ecossistema_v5/assets/uninta-01.png"
											: "https://dirin.s3.amazonaws.com/ecossistema_v5/assets/f5-+logos_Prancheta_1.svg"
									}
									alt="Logo da Instituição de Ensino"
									width='200'
								/> */}

								<div className='texto-header-formulario-login'>
									<Typography variant='h3'>
										ECOSSISTEMA DE{" "}
										<span
											className={
												nivelDeEnsino.nome_nivel_ensino !==
													"FACULDADE ALENCARINA" &&
													nivelDeEnsino.nome_nivel_ensino !== "FACULDADE 5 DE JULHO"
													? classes.uninta
													: classes.root
											}
										>
											APRENDIZAGEM
										</span>
									</Typography>
									<Typography component='p'>
										Para conectar pessoas e formar comunidades de práticas.
									</Typography>
								</div>

								<Typography
									component='h5'
									style={{
										// color: theme.palette.primary.main,
										fontSize: "1.6rem",
										fontWeight: "normal",
										marginTop: "12px",
									}}
								>
									Entrar no Ecossistema
								</Typography>
							</div>

							<form
								onSubmit={handleSubmit((data) => {
									onSubmit({ ra: data.ra, password: data.password });
								})}
							>
								<FormControl variant='outlined' fullWidth>
									<label htmlFor=''>Digite seu usuário*</label>
									<TextField
										variant='outlined'
										className={classes.textField}
										type='text'
										fullWidth
										placeholder='Identificação de usuário'
										autoFocus
										disabled={loadingLogin}
										{...register("ra", { required: true })}
										error={errors.ra?.type === "required"}
										helperText={
											errors.ra?.type === "required" && (
												<span>
													O campo "Digite seu usuário" é obrigatório!
												</span>
											)
										}
									/>
								</FormControl>

								<FormControl variant='outlined' fullWidth>
									<label htmlFor=''>Senha*</label>
									<TextField
										className={classes.textField}
										type={showPassword ? "text" : "password"}
										variant='outlined'
										fullWidth
										autoFocus
										placeholder='Insira aqui a sua senha'
										{...register("password", { required: true })}
										error={errors.password?.type === "required"}
										helperText={
											errors.password?.type === "required" && (
												<span>O campo "Senha" é obrigatório!</span>
											)
										}
										InputProps={{
											endAdornment: (
												<InputAdornment position='end'>
													<IconButton
														aria-label='toggle password visibility'
														onClick={handleClickShowPassword}
														onMouseDown={(e) => {
															e.preventDefault();
														}}
														edge='end'
													>
														{showPassword ? (
															<VisibilityOff />
														) : (
															<Visibility />
														)}
													</IconButton>
												</InputAdornment>
											),
										}}
									/>
								</FormControl>

								<div className='footer-formulario-login'>
									<FormControlLabel
										style={{ color: "#0000008A", alignSelf: "flex-start" }}
										control={<Checkbox value='remember' color='primary' />}
										label='Mantenha-me conectado'
										disabled={loadingLogin}
									/>
								</div>

								<Button
									type='submit'
									size='large'
									fullWidth
									variant='contained'
									color='primary'
									disableElevation
									disabled={loadingLogin}
									id='btnLogin'
									style={{
										backgroundColor: theme.palette.primary.main,
										textTransform: "capitalize",
										fontSize: "1.1rem",
									}}
								>
									{loadingLogin ? (
										<CircularProgress
											style={{
												color: "#fff",
												width: "1.5rem",
												height: "1.5rem",
											}}
											thickness={4.4}
										/>
									) : (
										"Entrar"
									)}
								</Button>

								<div className='footer-formulario-login'>
									<Typography
										variant='body2'
										component='a'
										style={{ color: "grey", cursor: "pointer" }}
										onClick={() =>
											window.open(
												"https://uninta.vc/ava-recuperar-senha",
												"ForgetPass",
												"STATUS=NO, TOOLBAR=NO, LOCATION=NO, DIRECTORIES=NO, RESISABLE=NO, SCROLLBARS=YES, TOP=10, LEFT=10, WIDTH=400, HEIGHT=260",
											)
										}
									>
										Esqueceu o seu usuário ou senha?{" "}
									</Typography>
									<Typography variant='body2' component='a'>
										<a
											href='https://ead.uninta.edu.br/suporte-ao-ambiente-virtual/?sis=QW1iaWVudGUgVmlydHVhbA=='
											target='_blank'
											style={{ color: "grey" }}
											rel='noreferrer'
										>
											Problemas com acesso? Clique aqui?
										</a>
									</Typography>
								</div>
							</form>
						</div>
					</div>
				</div>

				<div className="footer-login">
					<Typography variant='body1'>
						{" "}
						2022. Todos os direitos reservados.
						{
							nivelDeEnsino.nome_nivel_ensino !== "FACULDADE ALENCARINA" &&
								nivelDeEnsino.nome_nivel_ensino !== "FACULDADE 5 DE JULHO"
								? <a href="https://uninta.edu.br" target="_blank" rel="noreferrer" style={{ color: theme.palette.primary.main }}> Associação Igreja Adventista Missionária - Aiamis.</a>
								: <a href="https://faculdadef5.com.br/" target="_blank" rel="noreferrer" style={{ color: theme.palette.primary.main }}> Faculdade 5 de Julho.</a>
						}
						{" "}
						{/* Versão {`${new Date().getFullYear()}-${new Date().getMonth()}-${new Date().getDay()}` } {process.env.REACT_APP_API_JOB_ID ? `(${process.env.REACT_APP_API_JOB_ID})` :  ""} */}
					</Typography>
				</div>
			</div>
		</Dialog>
        </>
	);
}
