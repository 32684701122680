import React, { useEffect } from "react";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import { useTheme } from "@material-ui/core/styles";

import ArrowBack from "@material-ui/icons/ArrowBack";
import RateReview from "@material-ui/icons/RateReview";

import ButtonInfo from "pages/PedagogicalCommunication/components/DialogQuestionsAnswers/ButtonsInfo";
import PopupList from "../../PopupList";

import { getSolicitations } from "../FormReviewQuestion/formreviewquestion.services";
import { HeaderProps } from "./header.model";

import { useAuth } from "context/Auth/hook";

import { usePedagogicalCommunication } from "context/PedagogicalCommunication/hooks/usePedagogicalCommunication";

import "./styles.scss";
import useNivelEnsino from "hooks/useNivelEnsino";

export default function Header(props: HeaderProps): React.ReactElement {
	const { nivelDeEnsino } = useAuth();
	const { updateNumReviews } = usePedagogicalCommunication();
	const colorsTheme = useTheme();

	const [openPopup, setOpenPopup] = React.useState(false);
	const [numSolicitations, setNumSolicitations] = React.useState(0);

	useEffect(() => {
		getSolicitations().then((data) => {
			setNumSolicitations(data.total);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateNumReviews]);

	return (
		<AppBar position='static' style={{ backgroundColor: colorsTheme.palette.primary.main }}>
			<Toolbar className='navbar_header'>
				<Box display='flex' justifyContent='flex-start' alignItems='center'>
					<IconButton
					    id='btnClose'
						edge='start'
						color='inherit'
						aria-label='menu'
						onClick={() => {
							props.onClosed(false);
						}}
					>
						<ArrowBack />
					</IconButton>

					<img
						src={useNivelEnsino(nivelDeEnsino)}
						className='logo'
						alt='Logo branca'
					/>
				</Box>

				<Box display='flex' justifyContent='flex-start' alignItems='center'>
					<ButtonInfo
						id='btnQtdRevisoes'
						startIconButton={
							<RateReview
								className='iconButtonAppBar'
								style={{
									color: colorsTheme.palette.secondary.main,
									background: colorsTheme.palette.primary.main,
								}}
							/>
						}
						titleButton='Respostas Dada'
						value={numSolicitations.toString()}
						actionButton={() => setOpenPopup(!openPopup)}
					/>
				</Box>
			</Toolbar>

			<PopupList
				open={openPopup}
				setOpen={setOpenPopup}
				title='Minhas Solicitações'
				type='review'
			/>
		</AppBar>
	);
}
