import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Theme } from "@material-ui/core/styles";


import "./styles.scss";
import { useAuth } from "context/Auth/hook";
import { ArrowBack } from "@material-ui/icons";
import { IconButton, useTheme } from "@material-ui/core";
import Menu from "@material-ui/icons/Menu";
import DrawerMenuGamificacao from "./DrawerMenuGamificacao";
import { useGamificacao } from "context/Gamificacao";

const useStyles = makeStyles((theme: Theme) => ({
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		backgroundColor: "#FFFFFF",
		width: "100%",
	},
}));

export default function HeaderMobile(): React.ReactElement {
	const { nivelDeEnsino } = useAuth();
	const { setOpenDialogGamificacao } = useGamificacao();
	const classes = useStyles();
	const theme = useTheme();
	const [openDrawer, setOpenDrawer] = React.useState(false);

	return (
		<>
			<AppBar position='static' className={classes.appBar}>
				<Toolbar className='navbar_question_answers'>
					<Box
						display="flex"
						flexDirection="row"
						justifyContent="space-between"
						alignItems="center"
						style={{ width: "100%" }}
					>
						<div className="box-arrow-logo
						">
							<IconButton
								edge='start'
								aria-label='menu'
								style={{ color: theme.palette.primary.main }}
								onClick={() => {
									setOpenDialogGamificacao(false);
								}}
							>
								<ArrowBack />
							</IconButton>

							<img
								onClick={() => {
									setOpenDialogGamificacao(false);
								}}
								src={`${(nivelDeEnsino.codigo_nivel_ensino !== 351) &&
									(nivelDeEnsino.nome_nivel_ensino !== "FACULDADE ALENCARINA") &&
									(nivelDeEnsino.nome_nivel_ensino !== "FACULDADE 5 DE JULHO - EAD") &&
									(nivelDeEnsino.nome_nivel_ensino !== "FACULDADE 5 DE JULHO")
									? nivelDeEnsino.codigo_nivel_ensino === 345 ? "https://dirin.s3.amazonaws.com/ecossistema_v5/assets/fortaleza_horizontal_vermelha.png" : "https://dirin.s3.amazonaws.com/ecossistema_v5/assets/uninta-01.svg"
									: "https://dirin.s3.amazonaws.com/ecossistema_v5/assets/f5+-+logos+2-01.svg"
									}`}
								className='logo'
								style={{ cursor: "pointer", width: "auto", padding: "12px" }}
								alt='Logo branca'
							/>
						</div>

						<div className="box-menu">
							<IconButton onClick={() => setOpenDrawer(true)}>
								<Menu className='icon-menu' style={{ color: theme.palette.primary.main }} />
							</IconButton>

							<DrawerMenuGamificacao
								open={openDrawer}
								setOpen={setOpenDrawer}
							/>

						</div>
					</Box>
				</Toolbar>
			</AppBar>
		</>
	);
}
