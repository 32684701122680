import { BaseSyntheticEvent } from "react";
import { Box, Grid, Typography, TextField, Button, FormControl, useTheme } from "@material-ui/core";
import Notify from "../../../../utils/Notification/index";
import { useForm } from "react-hook-form";

export default function FormularioCadastroDepoimentoView({
	depoimento,
	disableButton,
	setDepoimento,
	submitDepoimento,
}): any {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();
	const theme = useTheme();
	const limiteCaracteresDepoimento = 250;

	return (
		<form
			onSubmit={handleSubmit(
				(data, event: BaseSyntheticEvent<object, any, any> | undefined) => {
					submitDepoimento(
						{
							depoimento: data.depoimento,
						},
						event, 
					);
				},
			)}
		>
			<Box mt={3} mb={3}>
				<Typography variant='body1'>DADOS CADASTRAIS</Typography>
			</Box>
			<Box mt={3}>
				<Grid container spacing={2}>
					<Grid item xs={12} md={12}>
						<FormControl size='small' fullWidth>
							<TextField
								id='depoimento'
								multiline
								label='Depoimento'
								variant='outlined'
								rows={4}
								style={{
									width: "100%",
								}}
								inputProps={{ maxLength: limiteCaracteresDepoimento }}
								value={depoimento}
								{...register("depoimento", {
									required: true,
									onChange: (event) => event.target.value.length > limiteCaracteresDepoimento ? Notify(`O depoimento precisa ter no máximo ${limiteCaracteresDepoimento} caracteres`) : setDepoimento(event.target.value),
								})}
								error={errors.depoimento?.type === "required"}
								helperText={
									errors.depoimento?.type === "required" &&
									"O campo 'Depoimento' é obrigatório!"
								}
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<Box>
							<Button
								disableElevation
								disabled={disableButton}
								variant='contained'
								type={"submit"}
								style={{
									backgroundColor: disableButton ? `${theme.palette.primary.main}60` : theme.palette.primary.main,
									width: "100%",
									color: "#FFFFFF",
								}}
							>
								{" "}
								Cadastrar{" "}
							</Button>
						</Box>
					</Grid>
				</Grid>
			</Box>
		</form>
	);
}
