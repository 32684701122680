import React from "react";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Theme } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import SignalCellularAltIcon from '@material-ui/icons/SignalCellularAlt';
import SendIcon from '@material-ui/icons/Send';
import ArrowBack from "@material-ui/icons/ArrowBack";
import Message from "@material-ui/icons/Message";
// import HelpOutline from "@material-ui/icons/HelpOutline";
import Report from "@material-ui/icons/Report";

import ButtonInfo from "../ButtonsInfo";
import PopupNotifications from "../PopupNotifications";
import DialogViewQuestion from "../Questions/DialogViewQuestion";
// import AvatarProfile from "components/AvatarProfile";

import { usePedagogicalCommunication } from "context/PedagogicalCommunication/hooks/usePedagogicalCommunication";
import { useAuth } from "context/Auth/hook";

import { HeaderProps } from "./header.model";

import {
	getCountQuestion,
	getListAnswersByAuthor,
	getListQuestionsByAuthor,
} from "../dialogquestionsanswers.services";

import "./styles.scss";
import { LiveHelp, QuestionAnswer } from "@material-ui/icons";

import useNivelEnsino from "hooks/useNivelEnsino";
import { Avatar } from "@material-ui/core";
import { getNameInitials } from "utils/getNameInitials";
import { usePortfolio } from "context/Portfolio";

const useStyles = makeStyles((theme: Theme) => ({
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
	},
}));

export default function Header(props: HeaderProps): React.ReactElement {
	const { user, nivelDeEnsino } = useAuth();
	const { updateNumQuestions, updateNumAnswers } = usePedagogicalCommunication();
	const classes = useStyles();
	const colorsTheme = useTheme();
	const { state } = usePortfolio();

	const [numQuestions, setNumQuestions] = React.useState<number | null>(null);
	const [numAnswers, setNumAnswers] = React.useState<number | null>(null);

	const [openViewQuestion, setOpenViewQuestion] = React.useState(false);
	const [openListQuestions, setOpenListQuestions] = React.useState(false);
	const [openListResponse, setOpenListResponse] = React.useState(false);
	const [listQuestions, setListQuestions] = React.useState([]);
	const [listAnswer, setListAnswer] = React.useState([]);
	const [idQuestionSelected, setIdQuestionSelected] = React.useState("");

	const [titlePopup, setTitlePopup] = React.useState("");

	const handleActiveNavigate = (value: "question" | "response" | "report" | "indicador" | "message") => {
		props.setComponentOpen(value);
	};

	// Busca quantidade de perguntas e perguntas do usuário
	React.useEffect(() => {
		getCountQuestion(user.ra ?? "", user)
			.then((data: any) => {
				setNumQuestions(data.total);
			})
			.catch((e) => {
				console.log(e);
			});

		getListQuestionsByAuthor(user)
			.then((data) => {
				if (Array.isArray(data.data)) {
					const results = data.data.map((dt: any) => {
						return {
							title: dt.pergunta.replace(/(&nbsp;|<([^>]+)>)/gi, ""),
							actionClick: () => {
								setOpenViewQuestion(true);
								setIdQuestionSelected(dt.id);
								setOpenListQuestions(false);
							},
						};
					});

					setListQuestions(results);
				}
			})
			.catch((e) => {
				console.log(e);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user.ra, updateNumQuestions]);

	// Busca respostas e quantidade de respostas do usuário

	React.useEffect(() => {

		getListAnswersByAuthor(user!.ra!, user)
			.then((res: any) => {
				// console.log("Resultados: " + res.data.length)
				if (Array.isArray(res.data)) {
					setNumAnswers(res.data.length);

					const results = res.data.map((dt) => {
						return {
							title: dt.resposta,
							actionClick: () => {
								setOpenViewQuestion(true);
								setIdQuestionSelected(dt.pergunta_id);
								setOpenListQuestions(false);
							},
						};
					});

					setListAnswer(results);
				}
			})
			.catch((e) => {
				console.log(e);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user.ra, updateNumAnswers]);

	return (
		<>
			<AppBar position='static' className={classes.appBar} elevation={1}>
				<Toolbar
					className='navbar_question_answers'
					style={{ background: colorsTheme.palette.primary.main }}
				>
					<Box display='flex' justifyContent='flex-start' alignItems='center'>
						<IconButton
							id='btnArrowBack'
							edge='start'
							color='inherit'
							aria-label='menu'
							onClick={() => props.setOpen(false)}
						>
							<ArrowBack />
						</IconButton>

						<img
							src={useNivelEnsino(nivelDeEnsino)}
							className='logo'
							alt='Logo UNINTA'
							style={{
								padding: "12px"
							}}
						/>
					</Box>

					<Box display='flex' justifyContent='flex-start' alignItems='center'>
						{user.typeUser === "student" && (
							<ButtonInfo
								startIconButton={
									<LiveHelp
										className='iconButtonAppBar'
										style={{
											color: colorsTheme.palette.secondary.main,
											background: colorsTheme.palette.primary.main,
										}}
									/>
								}
								titleButton='Perguntas Feitas'
								value={numQuestions ? numQuestions : 0}
								actionButton={() => {
									if (openListResponse) {
										setOpenListResponse(false);
									}

									setOpenListQuestions(!openListQuestions);
									setTitlePopup("Suas Perguntas");
								}}
								id='btnQtdQuestions'
							>
								<PopupNotifications
									title={titlePopup}
									list={listQuestions}
									open={openListQuestions}
									setOpen={setOpenListQuestions}
								/>
							</ButtonInfo>
						)}

						<ButtonInfo
							startIconButton={
								<Message
									className='iconButtonAppBar'
									style={{
										color: colorsTheme.palette.secondary.main,
										background: colorsTheme.palette.primary.main,
									}}
								/>
							}
							titleButton='Respostas Dada'
							value={numAnswers ? numAnswers : 0}
							actionButton={() => {
								if (openListQuestions) {
									setOpenListQuestions(false);
								}

								setOpenListResponse(!openListResponse);
								setTitlePopup("Suas Respostas");
							}}
							id='btnQtdResponses'
						>
							<PopupNotifications
								title={titlePopup}
								list={listAnswer}
								open={openListResponse}
								setOpen={setOpenListResponse}
							/>
						</ButtonInfo>
					</Box>
				</Toolbar>
			</AppBar>

			<Drawer variant='permanent'>
				<Toolbar style={{ minWidth: "15vw", maxWidth: "15vw" }} />
				<List style={{ minWidth: "15vw", maxWidth: "15vw" }}>
					<ListItem>
						<Box className='infoPerfil' style={{ paddingTop: "18px", paddingBottom: "12px" }}>
							{/* <AvatarProfile
								classname='img_perfil_questions'
								isTutor={user.typeUser === "tutor"}
							/> */}
							<Avatar
								className={"img_perfil_questions"}
								src={state.avatar_path}
								alt='Perfil'
							>
								{getNameInitials(user.nome)}
							</Avatar>
							<Typography className='name_studenty' style={{ marginTop: "6px", textAlign: "center", margin: "6px" }}>{user.nome}</Typography>
							<Typography className='ra_studenty'>RA: {user.ra}</Typography>
						</Box>
					</ListItem>
				</List>
				<Divider />
				<List style={{ minWidth: "15vw", maxWidth: "15vw" }}>
					<ListItem
						id='itemNavQuestion'
						button
						onClick={() => handleActiveNavigate("question")}
						style={{
							backgroundColor: props.componentOpen === "question" ? '#f8f8f8' : ''
						}}
					>
						<ListItemIcon>
							<LiveHelp
								className='iconNavigate'
								style={{
									color:
										props.componentOpen === "question"
											? colorsTheme.palette.primary.main
											: "#707070",
								}}
							/>
						</ListItemIcon>
						<Typography
							className='itemNavigate'
							style={{
								color:
									props.componentOpen === "question"
										? colorsTheme.palette.primary.main
										: "#707070",
							}}
						>
							{user.typeUser === "tutor" ? "Perguntas" : "Perguntar"}
						</Typography>
					</ListItem>

					<ListItem
						id='itemNavAnswer'
						button
						onClick={() => handleActiveNavigate("response")}
						style={{
							backgroundColor: props.componentOpen === "response" ? '#f8f8f8' : ''
						}}
					>
						<ListItemIcon>
							<QuestionAnswer
								className='iconNavigate'
								style={{
									color:
										props.componentOpen === "response"
											? colorsTheme.palette.primary.main
											: "#707070",
								}}
							/>
						</ListItemIcon>
						<Typography
							className='itemNavigate'
							style={{
								color:
									props.componentOpen === "response"
										? colorsTheme.palette.primary.main
										: "#707070",
							}}
						>
							Responder
						</Typography>
					</ListItem>

					{user.typeUser === "tutor" && (
						<>
							<ListItem id='itemNavIndicator' button onClick={() => handleActiveNavigate("indicador")}>
								<ListItemIcon>
									<SignalCellularAltIcon
										className={`iconNavigate ${props.componentOpen === "indicador" ? "active" : ""
											}`}
									/>
								</ListItemIcon>
								<Typography
									className={`itemNavigate ${props.componentOpen === "indicador" ? "active" : ""
										}`}
								>
									Indicador
								</Typography>
							</ListItem>
							<ListItem id='itemNavSendMessage' button onClick={() => handleActiveNavigate("message")}>
								<ListItemIcon>
									<SendIcon
										className={`iconNavigate ${props.componentOpen === "message" ? "active" : ""
											}`}
									/>
								</ListItemIcon>
								<Typography
									className={`itemNavigate ${props.componentOpen === "message" ? "active" : ""
										}`}
								>
									Enviar Mensagem
								</Typography>
							</ListItem>
							<ListItem id='itemNavReport' button onClick={() => handleActiveNavigate("report")}>
								<ListItemIcon>
									<Report
										className={`iconNavigate ${props.componentOpen === "report" ? "active" : ""
											}`}
									/>
								</ListItemIcon>
								<Typography
									className={`itemNavigate ${props.componentOpen === "report" ? "active" : ""
										}`}
								>
									Denúncias
								</Typography>
							</ListItem>
						</>
					)}
				</List>
			</Drawer>

			<DialogViewQuestion
				id={idQuestionSelected}
				setId={setIdQuestionSelected}
				open={openViewQuestion}
				setOpen={setOpenViewQuestion}
			/>
		</>
	);
}
