import { TabelaDisciplinaProvider } from "context/TabelaDisciplinas";
import React from "react";
import BlocoNotasArquivosView from "./BlocoNotasArquivos.view";

export default function BlocoNotasArquivos(): React.ReactElement {
	return (
		<TabelaDisciplinaProvider>
			<BlocoNotasArquivosView />
		</TabelaDisciplinaProvider>
	);
}
