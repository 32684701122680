import { Box, Button, Typography, useTheme } from "@material-ui/core";

import ListaDepoimentos from "../../ListasOportunidades/ListaDepoimentos";
import DialogCadastroDepoimento from "components/Dialogs/Cadastro/DialogCadastroDepoimento";
import { useDepoimentos } from "context/depoimentos";
import CardLateralAluno from "components/Oportunidades/CardsOportunidades/CardLateralAluno";
import NoContentAvailable from "components/NoContentAvailableTeste";
import React from "react";
import "./styles.scss";

export default function SeccaoDepoimentosView(): JSX.Element {
	const theme = useTheme();
	const {
		refreshSeccaoDepoimentos,
		setRefreshSeccaoDepoimentos,
		excecaoNaRequisicao,
		enableDialogCadastroDepoimento,
		setEnableDialogCadastroDepoimento,
	} = useDepoimentos();

	function exibirBotaoDeEscreverDepoimento(): JSX.Element {
		const tipoDoUsuarioLogado = JSON.parse(
			localStorage.getItem("@Application:User") as string,
		).typeUser;
		if (tipoDoUsuarioLogado === "student") {
			return (
				<Button
					className='botao-escrever-depoimento'
					onClick={() => {
						setEnableDialogCadastroDepoimento(!enableDialogCadastroDepoimento);
					}}
					style={{ backgroundColor: theme.palette.primary.main }}
				>
					Escrever Depoimento
				</Button>
			);
		} else {
			return <></>;
		}
	}

	return (
		<>
			<Box className='seccao-depoimentos'>
				<Box className='box-card-aluno'>
					<CardLateralAluno />
				</Box>

				<Box className='box-depoimentos'>
					<Box className='box-header'>
						<Typography component='h2'>Depoimentos</Typography>
					</Box>
					{excecaoNaRequisicao ? (
						<React.Fragment>
							<Box className='box-text-content'>
								<Typography component='p'>
									A graduação é um importante retrato da sua história e temos
									orgulho de fazer parte dela. Nos conte como foi a sua
									experiência, nos deixe o seu depoimento.
								</Typography>
								{exibirBotaoDeEscreverDepoimento()}
							</Box>

							<NoContentAvailable
								refresh={refreshSeccaoDepoimentos}
								setRefresh={setRefreshSeccaoDepoimentos}
							/>
						</React.Fragment>
					) : (
						<React.Fragment>
							<Box className='box-text-content'>
								<Typography component='p'>
									A graduação é um importante retrato da sua história e temos
									orgulho de fazer parte dela. Nos conte como foi a sua
									experiência, nos deixe o seu depoimento.
								</Typography>
								{exibirBotaoDeEscreverDepoimento()}
							</Box>

							<ListaDepoimentos />
						</React.Fragment>
					)}
				</Box>
			</Box>

			<DialogCadastroDepoimento />
		</>
	);
}
