import React from "react";
import {
	AppBar,
	Badge,
	Box,
	Button,
	Container,
	Hidden,
	IconButton,
	ListItem,
	Menu,
	Toolbar,
	Typography,
	useMediaQuery,
	useTheme,
} from "@material-ui/core";
import {
	Description,
	LibraryAddCheck,
	NightsStay,
	Notifications,
	PaymentOutlined,
	PermContactCalendar,
	QuestionAnswer,
	School,
	ViewComfy,
	WbSunny, PlaylistAddCheck
} from "@material-ui/icons";
import AvatarProfile from "components/AvatarProfile";
import { useAuth } from "context/Auth/hook";
import { Link, useNavigate } from "react-router-dom";

import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';

import "./styles.scss";

import {
	getNotifications,
	readedNotification,
	clearAllNotifications,
	getNumberPendingReviewsTutor,
} from "./navbar.services";
import { usePedagogicalCommunication } from "context/PedagogicalCommunication/hooks/usePedagogicalCommunication";


import { Menu as MenuIcon } from "@material-ui/icons";
import DialogOportunidades from "components/Dialogs/DialogOportunidades";
import DialogTutorialMobile from "components/Dialogs/DialogTutorialMobile";
import useNivelEnsino from "hooks/useNivelEnsino";
import DialogGamificacao from "components/Dialogs/DialogGamificacao";
import DialogPopupNotificationsMessage from "components/DialogPopupNotificationsMessage";
import { useGamificacao } from "context/Gamificacao";

import DialogCorfirmPerfil from "components/Dialogs/DialogConfirmPerfil";
import { useCookies } from 'react-cookie';
import DialogAuthMyLibrary from "components/Dialogs/DialogAuthMyLibrary";
import DialogTutorGerenciaDisciplinas from "../../../../src/components/Dialogs/DialogTutorGerenciaDisciplinas";
import DialogTutorGerenciaPerfis from "components/Dialogs/DialogTutorGerenciaPerfis";
// import DialogAuthAcademico from "components/Dialogs/DialogAuthAcademico";

const PopupNotifications = React.lazy(
	() =>
		import(
			"../../../components/PopupNotifications"
		),
);
const DialogViewReview = React.lazy(
	() =>
		import(
			"pages/PedagogicalCommunication/components/DialogReviewQuestion/FormReviewQuestion/DialogRequestConfirmation"
		),
);
const DialogViewSchedule = React.lazy(
	() =>
		import(
			"pages/PedagogicalCommunication/components/DialogScheduleTime/DialogScheduleConfirmation"
		),
);
const DialogViewQuestion = React.lazy(
	() =>
		import(
			"pages/PedagogicalCommunication/components/DialogQuestionsAnswers/Questions/DialogViewQuestion"
		),
);

const DrawerMenu = React.lazy(() => import("./Components/DrawerMenu"));

/**
 * 
 * @returns Renderiza a NAVBAR com os níveis de Ensino e acessos do estudante, com elementos para interação com o tutor e com DRAWER
 */
function Navbar() {
	const theme = useTheme();
	const { user, nivelDeEnsino, Logout, setNivelDeEnsino, setUnintaTheme, openDrawer, setOpenDrawer, setRunReactJoyride } = useAuth();
	const { setOpenDialogGamificacao } = useGamificacao();
	const history = useNavigate();
	const { updateNumReviews } = usePedagogicalCommunication();
	// const [openProfile, setOpenProfile] = React.useState(false);
	const [openNotifications, setOpenNotifications] = React.useState(false);
	const [listNotifications, setListNotifications] = React.useState<any[]>([]);
	const [reloadNotifications, setReloadNotifications] = React.useState(false);
	const [countNotifications, setCountNotifications] = React.useState(0);
	const [openViewReview, setOpenViewReview] = React.useState(false);
	const [idModule, setIdModule] = React.useState("");
	const [openViewSchedule, setOpenViewSchedule] = React.useState(false);
	const [openViewQuestion, setOpenViewQuestion] = React.useState(false);
	const [openDialogOportunidades, setOpenDialogOportunidades] = React.useState(false);
	const [openDialogTutorialMobile, setOpenDialogTutorialMobile] = React.useState<boolean>(false);
	const [openDialogConfirm, setOpenDialogConfirm] = React.useState<boolean>(false);

	const fullScreen = useMediaQuery(theme.breakpoints.down(1600));
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const onReadedNotification = (idNotification: string) => {
		if (idNotification) {
			readedNotification(idNotification)
				.then((data) => {
					setReloadNotifications(!reloadNotifications);
				})
				.catch((e) => {
					console.log(e);
				});
		}
	};

	const onClearNotifications = () => {
		if (user.ra && listNotifications.length > 0) {
			clearAllNotifications()
				.then((data) => {
					if (data.success) {
						setReloadNotifications(!reloadNotifications);
					}
				})
				.catch((e) => {
					console.log(e);
				});
		}
	};

	React.useEffect(() => {
		if (user.ra && user.typeUser === "student") {
			getNotifications(user.ra)
				.then(({ data }) => {
					console.log("Notifications")
					setListNotifications(
						data.map((notify) => {
							return {
								title: notify.mesage,
								date: notify.created_at,
								readed: notify.status,
								actionClick: async () => {
									console.log("Ações ainda não permitidas")									
								},
							};
						}),
					);
					setCountNotifications(data.length);
				})
				.catch((e) => {
					setCountNotifications(0);
					setListNotifications([]);
					console.log(e);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user.ra, reloadNotifications]);

	React.useEffect(() => {
		if (user.typeUser === "tutor") {
			getNumberPendingReviewsTutor()
				.then((data) => {

					setCountNotifications(data.total);

					if (data.total > 0) {
						setListNotifications([
							{
								title: `Existem ${data.total} revisões a serem feitas.`,
								date: new Date(),
								readed: 1,
								actionClick: () => {
									window.location.href = "/comunicacao-pedagogica/reviews";
								},
							},
						]);
					}

				})
				.catch((e) => {
					console.log(e);
				});
		}
	}, [user.typeUser, updateNumReviews]);

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [anchorElDigital, setAnchorElDigital] = React.useState<null | HTMLElement>(null);
	// const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
        // setOpenAuthAcademico(true);
        // const AuthAcademico = localStorage.getItem("@AuthAcademico") as string;
        // if (AuthAcademico === null) {setOpenAuthAcademico(true);}
	};

	const handleClickDigital = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorElDigital(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};
    
	const handleCloseDigital = () => {
		setAnchorElDigital(null);
	};

	const [toggleAddDisciplina, setToggleAddDisciplina] = React.useState<boolean>(false);
    let [openProfileManagementDialog, setOpenProfileManagementDialog] = React.useState<boolean>(false); // Abre e fecha o Dialog

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [access, setAccess] = React.useState(1);
	const [openAuthMyLibrary, setOpenAuthMyLibrary] = React.useState(false);

	const link = "https://integrada.minhabiblioteca.com.br/"
	const getCookie = useCookies(['a_mb'])[0];

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const accessDoc = () => {
		if (link.includes('minhabiblioteca')) {
			if ((getCookie?.a_mb && link.includes('minhabiblioteca'))) {
				window.open(link, '_blank');
			} else {
				setOpenAuthMyLibrary(true);
			}
		} else {
			window.open(link, '_blank');
		}
	}

	// const [openAuthAcademico, setOpenAuthAcademico] = React.useState(false);
    /**
     * Retorna a 
     */
	return (
		<div>
			<AppBar
				position='fixed'				
				className={"appBar-animated"}
				style={{
					backgroundColor: theme.palette.primary.main,
				}}
			>				
				<Toolbar>
					<Container
						maxWidth='xl'
						className='navbar'
						style={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
						}}
					>
                        {/* LOGO das instituições */}
						<Box
							style={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
							}}
						>							
							<Box>
								{<Link to={"/"} className="link-logo">
									<img
										src={useNivelEnsino(nivelDeEnsino)}
										className='logo'
										alt='Logo branca'
										style={{ padding: nivelDeEnsino.codigo_nivel_ensino === 345 ? "12px" : "" }}
									/>
								</Link>}
							</Box>
						</Box>
                        {/* LINKS e atalhos para os usuários */}
                        {
                            user.signed && (
                                <Hidden mdDown>
                                    <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <React.Fragment>
                                            {
                                                (user.typeUser === "student") && 
                                            <>
                                                <Button
                                                    className='academico-navbar'
                                                    aria-controls='simple-menu'
                                                    aria-haspopup='true'
                                                    onClick={handleClick}
                                                    style={{
                                                        textTransform: "capitalize",
                                                        color: "white",
                                                        margin: "0 6px",
                                                    }}
                                                    >
                                                        Acadêmico
                                                </Button>
                                            </>
                                            }
                                            <Menu
                                                id='simple-menu'
                                                anchorEl={anchorEl}
                                                keepMounted
                                                open={Boolean(anchorEl)}
                                                onClose={handleClose}
                                            >
                                                <ListItem
                                                    button
                                                    className='item_menu'
                                                    onClick={() => {
                                                        window.open(
                                                            "https://academico.aiamis.com.br/FrameHTML//web/app/edu/PortalEducacional/#/requerimentos",
                                                            "_blank",
                                                        );
                                                    }}
                                                >
                                                    <PermContactCalendar />

                                                    <Typography variant='subtitle2'>
                                                        Requerimento Online
                                                    </Typography>
                                                </ListItem>

                                                <ListItem
                                                    button
                                                    className='item_menu'
                                                    onClick={() => {
                                                        if (nivelDeEnsino.nome_nivel_ensino.indexOf("DIGITAL") < 0) {
                                                            window.open(
                                                            "https://academico.aiamis.com.br/FrameHTML//web/app/edu/PortalEducacional/#/financeiro.new",
                                                            "_blank"
                                                            );
                                                        } else {
                                                            window.open(
                                                            "https://academico.aiamis.com.br/FrameHTML//web/app/edu/PortalEducacional/#/financeiro.new",
                                                            "_blank"
                                                            );
                                                        }
                                                    }}
                                                >
                                                    <PaymentOutlined />

                                                    <Typography variant='subtitle2'>Boletos</Typography>
                                                </ListItem>

                                                <ListItem
                                                    button
                                                    className='item_menu'
                                                    onClick={() => {
                                                        window.open(
                                                            "https://academico.aiamis.com.br/FrameHTML/web/app/edu/PortalEducacional/login/?redirect=es%2Fmatricula%2Fapresentacao",
                                                            "_blank",
                                                        );
                                                    }}
                                                >
                                                    <School />

                                                    <Typography variant='subtitle2'>
                                                        Rematrícula
                                                    </Typography>
                                                </ListItem>

                                                <ListItem
                                                    button
                                                    className='item_menu'
                                                    onClick={() => {
                                                        window.open(
                                                            "https://academico.aiamis.com.br/FrameHTML//web/app/edu/PortalEducacional/#/negociacao/introducao",
                                                            "_blank",
                                                        );
                                                    }}
                                                >
                                                    <QuestionAnswer />

                                                    <Typography variant='subtitle2'>
                                                        Negociação
                                                    </Typography>
                                                </ListItem>

                                                <ListItem
                                                    button
                                                    className='item_menu'
                                                    onClick={() => {
                                                        window.open(
                                                            "https://academico.aiamis.com.br/FrameHTML//web/app/edu/PortalEducacional/#/grade-curricular",
                                                            "_blank",
                                                        );
                                                    }}
                                                >
                                                    <ViewComfy />

                                                    <Typography variant='subtitle2'>
                                                        Matriz Curricular
                                                    </Typography>
                                                </ListItem>

                                                <ListItem
                                                    button
                                                    className='item_menu'
                                                    onClick={() => {
                                                        window.open(
                                                            "https://academico.aiamis.com.br/FrameHTML//web/app/edu/PortalEducacional/#/relatorios",
                                                            "_blank",
                                                        );
                                                    }}
                                                >
                                                    <Description />

                                                    <Typography variant='subtitle2'>
                                                        Relatórios
                                                    </Typography>
                                                </ListItem>

                                                <ListItem
                                                    button
                                                    className='item_menu'
                                                    onClick={() => {
                                                        window.open(
                                                            "https://academico.aiamis.com.br/FrameHTML//web/app/edu/PortalEducacional/#/faltas",
                                                            "_blank",
                                                        );
                                                    }}
                                                >
                                                    <LibraryAddCheck />

                                                    <Typography variant='subtitle2'>Faltas</Typography>
                                                </ListItem>
                                                
                                                <ListItem
                                                    button
                                                    className='item_menu'
                                                    onClick={() => {
                                                        window.open(
                                                            "https://academico.aiamis.com.br/FrameHTML//web/app/edu/PortalEducacional/#/notas",
                                                            "_blank",
                                                        );
                                                    }}
                                                >
                                                    <PlaylistAddCheck />

                                                    <Typography variant='subtitle2'>Notas</Typography>
                                                </ListItem>
                                            </Menu>
                                        </React.Fragment>
                                         
                                         
                                        {
                                            (user.typeUser === "student" || user.typeUser === "tutor") && 
                                            <>
                                             <React.Fragment>
                                                <Button
                                                    className='academico-navbar'
                                                    aria-controls='simple-menu'
                                                    aria-haspopup='true'
                                                    onClick={handleClickDigital}
                                                    style={{
                                                        textTransform: "capitalize",
                                                        color: "white",
                                                        margin: "0 6px",
                                                    }}
                                                    >
                                                        {fullScreen === false ?
                                                        <>
                                                            Biblioteca &nbsp;Digital
                                                        </>
                                                        : <>Biblioteca</>}
                                                </Button>
                                                <Menu
                                                    id='simple-menu'
                                                    anchorEl={anchorElDigital}
                                                    keepMounted
                                                    open={Boolean(anchorElDigital)}
                                                    onClose={handleCloseDigital}
                                                >
                                                    <ListItem
                                                        button
                                                        className='item_menu'
                                                        onClick={() => {
                                                            nivelDeEnsino.nome_nivel_ensino === "FACULDADE 5 DE JULHO - EAD" ||
                                                                nivelDeEnsino.nome_nivel_ensino === "FACULDADE ALENCARINA"
                                                                ?
                                                                setAccess(2)
                                                                :
                                                                setAccess(1);
                                                            accessDoc();
                                                        }}
                                                    >
                                                        <LocalLibraryIcon />
                                                        <Typography variant='subtitle2'>
                                                            Minha Biblioteca
                                                        </Typography>
                                                    </ListItem>

                                                    <ListItem
                                                        button
                                                        className='item_menu'
                                                        onClick={() => {
                                                            window.open("https://bibliotecadigitalsenac.com.br/#/login", '_blank');
                                                        }}
                                                    >
                                                        <LocalLibraryIcon />
                                                        <Typography variant='subtitle2'>
                                                            Biblioteca Senac
                                                        </Typography>
                                                    </ListItem>
                                                </Menu>

                                            </React.Fragment>
                                            </>
                                        }
                                        {
                                            (user.typeUser === "tutor" || user.typeUser === "student") && 
                                                <Button
                                                    color='inherit'
                                                    className='button-navbar comunicacao-navbar'
                                                    onClick={() => {
                                                        history("/comunicacao-pedagogica");
                                                    }}
                                                    style={{ textTransform: "capitalize", margin: "0 6px" }}
                                                >
                                                    {fullScreen === false ?
                                                        <>
                                                            Comunicação&nbsp; Pedagógica
                                                        </> : <>
                                                            Comunicação
                                                        </>}
                                                </Button>
                                        }
                                        {
                                            (user.typeUser === "student" ) && 
                                            <React.Fragment>
                                                <Button
                                                    className='button-navbar calendario-navbar'
                                                    color='inherit'
                                                    onClick={() => history("/calendario")}
                                                    style={{ textTransform: "capitalize", margin: "0 6px" }}
                                                >
                                                    Calendário
                                                </Button>
                                        
                                                <Button
                                                    className='button-navbar gamificacao-navbar'
                                                    color='inherit'
                                                    onClick={() => {

                                                        setOpenDialogGamificacao(true);
                                                    }}
                                                    style={{ textTransform: "capitalize", margin: "0 6px" }}
                                                >
                                                    Gamificação
                                                </Button>

                                                <Button
                                                    className='button-navbar oportunidades-navbar'
                                                    color='inherit'
                                                    onClick={() => {
                                                        setOpenDialogOportunidades(true);
                                                    }}
                                                    style={{ textTransform: "capitalize", margin: "0 6px" }}
                                                >
                                                    Oportunidades
                                                </Button>
                                            </React.Fragment>
                                        }
                                        <Button
                                            className='button-navbar telegram-navbar'
                                            color='inherit'
                                            onClick={() => {
                                                // setOpenDialogOportunidades(true);
                                                window.open("https://t.me/+teEctz3ihTM2OTA5");
                                            }}
                                            style={{ textTransform: "capitalize", margin: "0 6px" }}
                                        >
                                            Comunidade
                                        </Button>
                                        {
                                            (user.typeUser === "tutor" || user.typeUser === "administrador") &&
                                            <>
                                                <Button
                                                    className='button-navbar'
                                                    color='inherit'
                                                    onClick={() => { setOpenDialogConfirm(true) }}
                                                    style={{ textTransform: "capitalize", margin: "0 6px" }}
                                                >
                                                    Buscar Perfil
                                                </Button>
                                                <Button
                                                    className='button-navbar'
                                                    color='inherit'
                                                    onClick={() => { setToggleAddDisciplina(true); }}
                                                    style={{ textTransform: "capitalize", margin: "0 6px" }}
                                                >
                                                    Adicionar disciplinas
                                                </Button>
                                            </>
                                        }
                                        {
                                            user.typeUser === "administrador" &&
                                            <>
                                                <Button
                                                    className='button-navbar'
                                                    color='inherit'
                                                    onClick={() => { setOpenProfileManagementDialog(true); }}
                                                    style={{ textTransform: "capitalize", margin: "0 6px" }}
                                                >
                                                    Gerenciar perfis
                                                </Button>
                                            </>
                                        }
                                    </Box>
                                <Box
                                    style={{
                                        minWidth: "250px",
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-end",
                                        alignItems: "center",
                                    }}
                                >
                                    {
                                        user.signed && (
                                            <React.Fragment>
                                                <IconButton
                                                    style={{
                                                        display: user.typeUser === 'tutor' ? 'flex' : 'none'
                                                    }}
                                                    onClick={() => {
                                                        if (
                                                            nivelDeEnsino.nome_nivel_ensino !==
                                                            "FACULDADE ALENCARINA" &&
                                                            nivelDeEnsino.nome_nivel_ensino !==
                                                            "FACULDADE 5 DE JULHO"
                                                        ) {
                                                            setNivelDeEnsino({
                                                                codigo_nivel_ensino: 2,
                                                                nome_nivel_ensino: "FACULDADE ALENCARINA",
                                                                codigo_curso: "DID_ENSUP",
                                                                nome_curso: "DIDÁTICA DO ENSINO SUPERIOR",
                                                                periodo: 1,
                                                                situacao: "ATIVO",
                                                                idhabilitacaofilial: "2411",
                                                                idperlet: "354",
                                                            });
                                                            setUnintaTheme(false);
                                                            sessionStorage.setItem(
                                                                "nivelDeEnsino",
                                                                JSON.stringify({
                                                                    codigo_nivel_ensino: 2,
                                                                    nome_nivel_ensino:
                                                                        "FACULDADE ALENCARINA",
                                                                    codigo_curso: "DID_ENSUP",
                                                                    nome_curso:
                                                                        "DIDÁTICA DO ENSINO SUPERIOR",
                                                                    periodo: 1,
                                                                    situacao: "ATIVO",
                                                                    idhabilitacaofilial: "2411",
                                                                    idperlet: "354",
                                                                }),
                                                            );
                                                        } else {
                                                            setNivelDeEnsino({
                                                                codigo_nivel_ensino: 2,
                                                                nome_nivel_ensino: "POLO DIGITAL - BRASIL",
                                                                codigo_curso: "DID_ENSUP",
                                                                nome_curso: "DIDÁTICA DO ENSINO SUPERIOR",
                                                                periodo: 1,
                                                                situacao: "ATIVO",
                                                                idhabilitacaofilial: "2411",
                                                                idperlet: "354",
                                                            });
                                                            setUnintaTheme(true);
                                                            sessionStorage.setItem(
                                                                "nivelDeEnsino",
                                                                JSON.stringify({
                                                                    codigo_nivel_ensino: 2,
                                                                    nome_nivel_ensino:
                                                                        "POLO DIGITAL - BRASIL",
                                                                    codigo_curso: "DID_ENSUP",
                                                                    nome_curso:
                                                                        "DIDÁTICA DO ENSINO SUPERIOR",
                                                                    periodo: 1,
                                                                    situacao: "ATIVO",
                                                                    idhabilitacaofilial: "2411",
                                                                    idperlet: "354",
                                                                }),
                                                            );
                                                        }
                                                    }}
                                                >
                                                    {
                                                        nivelDeEnsino.nome_nivel_ensino !== "FACULDADE ALENCARINA" &&
                                                            nivelDeEnsino.nome_nivel_ensino !== "FACULDADE 5 DE JULHO"
                                                            ? <WbSunny style={{ color: "white" }} />
                                                            : <NightsStay style={{ color: "white" }} />
                                                    }

                                                </IconButton>
                                                <IconButton
                                                    style={{ display: "none" }}
                                                    className="botao-tutorial"
                                                    onClick={() => {
                                                        setRunReactJoyride(true);
                                                    }}
                                                />
                                                
                                                <IconButton
                                                        onClick={() => {
                                                            setOpenNotifications(!openNotifications);
                                                        }}
                                                        className='notificacoes-navbar'
                                                    >
                                                    <Badge
                                                        overlap='rectangular'
                                                        badgeContent={countNotifications}
                                                        color='secondary'
                                                    >
                                                        <Notifications style={{ color: "white" }} />
                                                    </Badge>
                                                    <PopupNotifications
                                                        title='Notificações'
                                                        list={listNotifications}
                                                        open={openNotifications}
                                                        setOpen={setOpenNotifications}
                                                        fullWidthText
                                                        withDate
                                                        clearNotification
                                                        onClearNotification={() => {
                                                            onClearNotifications();
                                                        }}
                                                    />
                                                </IconButton>

                                                {
                                                    (user.typeUser === 'student') && <DialogPopupNotificationsMessage />
                                                }
                                                <Button
                                                    style={{
                                                        display: user.typeUser === "student" ? "" : "none"
                                                    }}
                                                    className='button_portfolio_avatar'
                                                    onClick={() => {
                                                        history("/portfolio");
                                                    }}
                                                >
                                                    <AvatarProfile classname='imgPerfil' />
                                                    <Typography className='title_button_portifolio'>
                                                        Meu Portfólio
                                                    </Typography>
                                                </Button>
                                                {/* <Button
                                                    variant='text'
                                                    style={{ color: "white" }}
                                                    onClick={() => Logout()}
                                                >
                                                    Sair
                                                </Button> */}
                                                <form method="post" action="https://virtual.uninta.edu.br/login/logout.php" >
                                                    <input type="hidden" name="sesskey" value="JRZyBMcGqh" />
                                                    <button
                                                        type="submit"
                                                        className='navButton'
                                                        style={{
                                                            marginLeft: 8,
                                                            backgroundColor: "transparent",
                                                            color: "white", cursor: "pointer"
                                                        }}
                                                        onClick={Logout}
                                                    >
                                                        SAIR
                                                    </button>
                                                </form>
                                            </React.Fragment>
                                        )					
                                    }
                                </Box>
                            </Hidden>
                            )
                        }
                        {/* CTA Portfólio e SAIR */}
                        {
                            user.signed  && (
							<Hidden lgUp>
								<Box style={{ 
                                    display: "flex", 
                                    flexDirection: "row", 
                                    alignItems: "center", 
                                    justifyContent: "flex-end",
                                }}>

									<IconButton
										style={{
											display: user.typeUser === 'tutor' ? 'inline' : 'none'
										}}
										onClick={() => {
											if (
												nivelDeEnsino.nome_nivel_ensino !==
												"FACULDADE ALENCARINA" &&
												nivelDeEnsino.nome_nivel_ensino !==
												"FACULDADE 5 DE JULHO"
											) {
												setNivelDeEnsino({
													codigo_nivel_ensino: 2,
													nome_nivel_ensino: "FACULDADE ALENCARINA",
													codigo_curso: "DID_ENSUP",
													nome_curso: "DIDÁTICA DO ENSINO SUPERIOR",
													periodo: 1,
													situacao: "ATIVO",
													idhabilitacaofilial: "2411",
													idperlet: "354",
												});
												setUnintaTheme(false);
												sessionStorage.setItem(
													"nivelDeEnsino",
													JSON.stringify({
														codigo_nivel_ensino: 2,
														nome_nivel_ensino:
															"FACULDADE ALENCARINA",
														codigo_curso: "DID_ENSUP",
														nome_curso:
															"DIDÁTICA DO ENSINO SUPERIOR",
														periodo: 1,
														situacao: "ATIVO",
														idhabilitacaofilial: "2411",
														idperlet: "354",
													}),
												);
											} else {
												setNivelDeEnsino({
													codigo_nivel_ensino: 2,
													nome_nivel_ensino: "POLO DIGITAL - BRASIL",
													codigo_curso: "DID_ENSUP",
													nome_curso: "DIDÁTICA DO ENSINO SUPERIOR",
													periodo: 1,
													situacao: "ATIVO",
													idhabilitacaofilial: "2411",
													idperlet: "354",
												});
												setUnintaTheme(true);
												sessionStorage.setItem(
													"nivelDeEnsino",
													JSON.stringify({
														codigo_nivel_ensino: 2,
														nome_nivel_ensino:
															"POLO DIGITAL - BRASIL",
														codigo_curso: "DID_ENSUP",
														nome_curso:
															"DIDÁTICA DO ENSINO SUPERIOR",
														periodo: 1,
														situacao: "ATIVO",
														idhabilitacaofilial: "2411",
														idperlet: "354",
													}),
												);
											}
										}}
									>
										{
											nivelDeEnsino.nome_nivel_ensino !== "FACULDADE ALENCARINA" &&
												nivelDeEnsino.nome_nivel_ensino !== "FACULDADE 5 DE JULHO"
												? <WbSunny style={{ color: "white" }} />
												: <NightsStay style={{ color: "white" }} />
										}

									</IconButton>
									<IconButton
										style={{ display: "none" }}
										className="botao-tutorial"
										onClick={() => {
											setOpenDialogTutorialMobile(true);
										}}
									/>
									
                                    <IconButton
										className="container-icon-notification"
                                        onClick={() => {
                                            setOpenNotifications(!openNotifications);
                                        }}>
                                            <Badge
                                                badgeContent={countNotifications}
                                                color='secondary'
                                                overlap='rectangular'
                                            >
                                                <Notifications style={{ color: "white" }} />
                                            </Badge>
                                            <PopupNotifications
                                                title='Notificações'
                                                list={listNotifications}
                                                open={openNotifications}
                                                setOpen={setOpenNotifications}
                                                fullWidthText
                                                withDate
                                                clearNotification
                                                onClearNotification={() => {
                                                    onClearNotifications();
                                                }}
                                            />
                                    </IconButton>

									{
                                        (user.typeUser === 'student') && <DialogPopupNotificationsMessage />
                                    }

									<IconButton className="container-icon-menu" onClick={() => setOpenDrawer(true)}>
										<MenuIcon className='iconMenu' />
									</IconButton>
								</Box>
								<DrawerMenu
									open={openDrawer}
									setOpen={setOpenDrawer}
									setOpenProfile={() => { return; }}
								/>
							</Hidden>
						)}
					</Container>
				</Toolbar>
                {/* DIALOGS vinculados a NAVBAR */}
                <React.Fragment>
                    <DialogViewReview
                        open={openViewReview}
                        idSolicitation={idModule}
                        setIdSolicitation={setIdModule}
                        setOpen={setOpenViewReview}
                    />

                    <DialogViewSchedule
                        open={openViewSchedule}
                        idSchedule={idModule}
                        setOpen={setOpenViewSchedule}
                    />

                    <DialogViewQuestion
                        open={openViewQuestion}
                        id={idModule.toString()}
                        setId={setIdModule}
                        setOpen={setOpenViewQuestion}
                    />

                    <DialogOportunidades
                        open={openDialogOportunidades}
                        setOpen={setOpenDialogOportunidades}
                    />

				    <DialogGamificacao />

                    <DialogTutorialMobile
                        open={openDialogTutorialMobile}
                        setOpen={setOpenDialogTutorialMobile}
                    />

                    <DialogCorfirmPerfil
                        open={openDialogConfirm}
                        setOpen={setOpenDialogConfirm}
                    />

                    <DialogAuthMyLibrary
					    open={openAuthMyLibrary}
                        linkAccess={link}
                        setOpen={setOpenAuthMyLibrary}
                        access={access}
				    />
                    
                    {/* <DialogAuthAcademico
					    open={openAuthAcademico}
                        setOpen={setOpenAuthAcademico}
				    /> */}

                    <DialogTutorGerenciaDisciplinas 
                        open={toggleAddDisciplina}
                        setOpen={setToggleAddDisciplina}
                    />

                    <DialogTutorGerenciaPerfis 
                        open={openProfileManagementDialog} 
                        setOpen={setOpenProfileManagementDialog} 
                    />

                </React.Fragment>
			</AppBar>
		</div>
	);
}

export default Navbar;