/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { 
	useEffect, 
	useState 
} from "react";
import { getHistoricAsync, getSubjectsGraphData, montarUrl, selectHistorico } from "features/Historico/historicoSlice";
import { centralPedagogica, ecossistema } from "services/api";
import { getCursos, getMoodleUrl, getProfile, loginAluno } from "./services";
import { NivelDeEnsinoContext, UserContext } from "models/auth";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { PortfolioProvider } from "context/Portfolio";
import { ProfileProvider } from "context/Profile/provider";
import { azul, vermelho } from "theme";
import { ThemeProvider } from "@material-ui/core";
import axios, { AxiosResponse } from "axios";
import { AuthContext } from "context/Auth/context";
import { INI_VALUES } from "context/Auth/defaults";
import Notify from "utils/Notification";
import { apiLogsDoEcossistema } from "services/Logs";
import { ACESSO } from "defaults/modules";
import { USUARIO_LOGIN, USUARIO_LOGOUT } from "defaults/events";
import { useNavigate } from "react-router-dom";
import { getNotificationsCentral } from "core/components/Navbar/navbar.services";


export const AuthProvider: React.FC = ({ children }) => {
	/**
	 * importa o useAppDispatch para fazer uso do Redux em conjunto com a Context
	 */
    // 
    const history = useNavigate();
	const dispatch 								= useAppDispatch(); 												// Permite disparar actions do Redux
	const historico 							= useAppSelector(selectHistorico); 									// Permite consumir o histórico do aluno para buscar a url do score	
    const [signedInVirtual, setSignedInVirtual] = useState<boolean>(INI_VALUES.signedInVirtual);
	const [user, setUser] 						= useState<UserContext>({ ...INI_VALUES.user }); 					// Dados básicos do usuário
	const [cursos, setCursos] 					= useState<any>({ ...INI_VALUES.cursos }); 							// Cursos do usuário
	const [openDrawer, setOpenDrawer] 			= React.useState<boolean>(false);									// Variável para controlar o Drawer durante o passeio	
	const [unintaTheme, setUnintaTheme] 		= useState<boolean>(true); 											// Define o tema de cores do App
	const [nivelDeEnsino, setNivelDeEnsino] 	= useState<NivelDeEnsinoContext>({...INI_VALUES.nivelDeEnsino}); 	// Define o nível de ensino do aluno
	const [runReactJoyride, setRunReactJoyride] = useState<boolean>(false); 										// Define a execução do ReactJoyride
	const [totalCentral, setTotalCentral] 		= React.useState(0); 												// Total de mensagens não lidas da central pedagogica

	/**
	 * @param { ra } string
	 * @param { password } string
	 * @returns {void}
	 * @memberof Login
	 * @description Realiza o login do usuário tutor o aluno e retorna o token para o cache local
	 */
	const Login = async (props: { ra?: string; password?: string }) => {	
            
        var authUser: UserContext;

        function defineTipoUser (data) {
            if(data.tipo_auth === "moodle" && data.moodle_admin === true) {
                return "administrador"
            } else if(data.tipo_auth === "moodle" && data.moodle_admin === false) {
                return "comunidade"
            } else if(data.tipo_auth === "totvs" && data.professor === true) {
                return "tutor"
            } else if(data.tipo_auth === "totvs" && data.professor === false && data.aluno === true) {
                return "student"
            }
        }

        return await loginAluno({ra: props.ra, password: props.password},ecossistema,)
            .then(async(response: AxiosResponse<any>) => {				                                                                                
                    const { data } = response;

					if (data.AccessToken) {						
						Notify("Seja bem vindo ao Ecossistema", "success");

						ecossistema.defaults.headers!.common["Authorization"] = "Bearer " + response.data.AccessToken;                       
                        
						/**
						 * Adiciona o token ao cache local
						 */                                                                              
						authUser =  {
							signed: true,
							typeUser: defineTipoUser(data),
							ra: props.ra,
							nome: "",
							image: "",
						};

						setUser(authUser);

                        localStorage.setItem("@Application:User", JSON.stringify({...authUser}));
						localStorage.setItem("data_time",JSON.stringify(new Date().getTime()),);
						localStorage.setItem("token", JSON.stringify(data.AccessToken), );

                        if(authUser.typeUser === "student" || authUser.typeUser === "tutor") 
                        {
                            await apiLogsDoEcossistema.get(`ra/${props.ra}?label=USUARIO_CONCLUIU_TUTORIAL`)
                                .then((response: AxiosResponse<any>) => {
                                    if(response.data.length === 0) {
                                        setRunReactJoyride(true);
                                        Notify("Validando seu registro de atividade", "success");
                                    }
                                })                            
                        }
					}					

                    if(authUser.typeUser === "student" || authUser.typeUser === "tutor") {                    
                        await getProfile(props.ra, ecossistema)
                            .then(({ data }: any) => {
                                // const authUser: UserContext = {...user, nome: data.nome, image: data.image};
                                authUser = {
                                    signed: true,
                                    typeUser: authUser.typeUser,
                                    ra: props.ra,
                                    nome: data.nome,
                                    image: "",
                                    data: data,
                                };
                                setUser(authUser);
                                localStorage.setItem("@Application:User", JSON.stringify(authUser));
                                console.log(authUser);
                                centralPedagogica.post('register',{
                                    profile: {
                                        ra: props.ra,
                                        nome: data.nome,
                                    },
                                    cursos: [nivelDeEnsino],
                                    disciplinas: JSON.parse(
                                        sessionStorage.getItem("@user:disc:central") as string
                                    )
                                })
                                return data;
                            })
                            .then(async (perfil) => {
                                await buscarCursosNivelEnsinoAluno({ra: props!.ra!}, perfil);
                            })
                            .then(async () => {
                                await buscarCursosDoAluno(props!.ra!);
                            })              
						return response;
                    } 
				})
                .then(()=>{
                    if(localStorage.getItem("@redirect")) {
                        history(localStorage.getItem("@redirect") as string)
                        localStorage.removeItem("@redirect")
                    }
                })
				.catch((e) => {
					console.log(e);
					Notify("E-mail e/ou senha incorreto.", "warning");
				});		
	};

	/**
	 *
	 * @returns {void}
	 * @memberof Login
	 * @description limpa a sessão e o cache local e redireciona para a página de login
	 */
	const Logout = async() => {
		if (
			nivelDeEnsino.nome_nivel_ensino === "FACULDADE ALENCARINA" ||
			nivelDeEnsino.nome_nivel_ensino === "FACULDADE 5 DE JULHO" ||
			nivelDeEnsino.nome_nivel_ensino === "FACULDADE 5 DE JULHO - EAD" ||
			nivelDeEnsino.codigo_nivel_ensino === 351
		) {
			sessionStorage.clear();
			localStorage.clear();
			window.location.href = "/?nivelDeEnsino=faculdade-5-de-julho";
			await apiLogsDoEcossistema.post('/',
			{
				ra: user.ra as string,
				module : "ECO_LOGIN",
				evento: {		
					label : "LOGOUT",
					log_info: {
						user_type: user.typeUser,						
					}	
				},
				metadata: {
					curso: nivelDeEnsino.nome_curso,
					polo: nivelDeEnsino.nome_nivel_ensino,
					periodo: nivelDeEnsino.periodo,
				}
			}
		)			
			return;
		}
		sessionStorage.clear();
		localStorage.clear();
		window.location.href = "/";
		await apiLogsDoEcossistema.post('/',
				{
					ra: user.ra as string,
					module : ACESSO,
					evento: {		
						label : USUARIO_LOGOUT,
						log_info: {
							user_type: user.typeUser,	
						}		
					},
					metadata: {
						curso: nivelDeEnsino.nome_curso,
						polo: nivelDeEnsino.nome_nivel_ensino,
						periodo: nivelDeEnsino.periodo,
					}
				}
			)
		return;
	};

	async function buscarCursosNivelEnsinoAluno(props: { ra: string}, perfil: any) {
		/**
		 * Se o AccessToken estiver disponível no header da requisição,
		 * o usuário autenticado já teve seu perfil carregado então pode-se requisitar seus cursos.
		 */
		if(										
			perfil.funcao !== "ALUNO"
		) {
			return sessionStorage.setItem(
				"unintaTheme",
				JSON.stringify(true),
			);
		}
		await getCursos(props.ra, ecossistema).then(
			async (response: AxiosResponse<any>) => {
				const { data } = response;
				// -- Se o usuário estiver matriculado em um ou mais cursos
				if (data.length > 0) {
					setCursos(data);
					setNivelDeEnsino(data[data.length > 1 ? data.length - 1 : 0]);
					sessionStorage.setItem(
						"nivelDeEnsino",
						JSON.stringify(data[data.length > 1 ? data.length - 1 : 0]),
					);
					// eslint-disable-next-line
					data[data.length > 1 ? data.length - 1 : 0].nome_nivel_ensino ===
						"FACULDADE ALENCARINA" ||
						data[data.length > 1 ? data.length - 1 : 0].nome_nivel_ensino ===
						"FACULDADE 5 DE JULHO - EAD"
						// eslint-disable-next-line
							data[data.length > 1 ? data.length - 1 : 0].nome_nivel_ensino ===
							"FACULDADE 5 DE JULHO"
								data[data.length > 1 ? data.length - 1 : 0].codigo_nivel_ensino ===
								351
						? setUnintaTheme(false)
						: setUnintaTheme(true);
					sessionStorage.setItem(
						"unintaTheme",
						JSON.stringify(
							data[data.length > 1 ? data.length - 1 : 0].nome_nivel_ensino   ===
							"FACULDADE ALENCARINA" ||
							data[data.length > 1 ? data.length - 1 : 0].nome_nivel_ensino   ===
							"FACULDADE ALENCARINA - EAD" ||
							data[data.length > 1 ? data.length - 1 : 0].codigo_nivel_ensino ===
							351 ||
							data[data.length > 1 ? data.length - 1 : 0].nome_nivel_ensino   ===
							"FACULDADE 5 DE JULHO",
						),
					);
					await apiLogsDoEcossistema.post('/',
					{
						ra: props.ra as string,
						module : ACESSO,
						evento: {		
							label : USUARIO_LOGIN,
							log_info: {
								user_type: user.typeUser,
								hora: new Date().toLocaleDateString()
							}		
						},
						metadata: {
							curso: data[data.length > 1 ? data.length - 1 : 0].nome_curso,
							polo: data[data.length > 1 ? data.length - 1 : 0].nome_nivel_ensino,
							periodo: data[data.length > 1 ? data.length - 1 : 0].periodo
						}
					}
				)
					/**
					 * Atualizando store com dados do histórico do Aluno
					 */
					await dispatch(
						getHistoricAsync({
							ra: props.ra,
							curso: data[data.length > 1 ? data.length - 1 : 0].codigo_curso,
						}),
					).then(async() => {
						await dispatch(montarUrl());
					});
				} else {
					// -- Se o usuário não estiver matriculado em nenhum curso
					setCursos(data);
					setNivelDeEnsino({
						codigo_nivel_ensino: 2,
						nome_nivel_ensino: "FACULDADE ALENCARINA",
						codigo_curso: "DID_ENSUP",
						nome_curso: "DIDÁTICA DO ENSINO SUPERIOR",
						periodo: 1,
						situacao: "ATIVO",
						idhabilitacaofilial: "2411",
						idperlet: "354",
					});
					setUnintaTheme(false);
					sessionStorage.setItem(
						"nivelDeEnsino",
						JSON.stringify({
							codigo_nivel_ensino: 2,
							nome_nivel_ensino: "FACULDADE ALENCARINA",
							codigo_curso: "DID_ENSUP",
							nome_curso: "DIDÁTICA DO ENSINO SUPERIOR",
							periodo: 1,
							situacao: "ATIVO",
							idhabilitacaofilial: "2411",
							idperlet: "354",
						}),
					);
					setNivelDeEnsino({
						codigo_nivel_ensino: 2,
						nome_nivel_ensino: "FACULDADE ALENCARINA",
						codigo_curso: "DID_ENSUP",
						nome_curso: "DIDÁTICA DO ENSINO SUPERIOR",
						periodo: 1,
						situacao: "ATIVO",
						idhabilitacaofilial: "2411",
						idperlet: "354",
					});
					setUnintaTheme(false);
					sessionStorage.setItem(
						"nivelDeEnsino",
						JSON.stringify({
							codigo_nivel_ensino: 2,
							nome_nivel_ensino: "FACULDADE ALENCARINA",
							codigo_curso: "DID_ENSUP",
							nome_curso: "DIDÁTICA DO ENSINO SUPERIOR",
							periodo: 1,
							situacao: "ATIVO",
							idhabilitacaofilial: "2411",
							idperlet: "354",
						}),
					);

					sessionStorage.setItem(
						"unintaTheme",
						JSON.stringify(true),
					);
				}
			},
		);
	}
	// Busca cursos do aluno e monta histórico no Reducer
	async function buscarCursosDoAluno(ra: string) {		
		await getCursos(ra, ecossistema)
			.then(async (response: AxiosResponse<any>) => {
				const { data } = response;
				// -- Se o usuário estiver matriculado em um ou mais cursos
				if (data.length > 0) {
					setCursos(data);
					sessionStorage.setItem(
						"@user:cursos",
						JSON.stringify(data)
					);				

					/**
					 * Atualizando store com dados do histórico do Aluno
					 */
					await dispatch(
						getHistoricAsync({
							ra: user.ra,
							curso: data[data.length > 1 ? data.length - 1 : 0].codigo_curso,
						}),
					).then(async() => {
						await dispatch(montarUrl());
					}).then(async()=>{
						await getSubjectsGraphData(historico.score);
					})
				}
			})
			.catch((e)=>{
				Notify("Falhou ao carregar o histórico do aluno", "error");
			})
	}

	// Busca URL para autenticar usuário no AVA
	async function buscarUrlMoodle(ra: string) {
		/**
		 * Realiza a tentativa de abertura da sessão do AVA
		 */
        try {
			await getMoodleUrl(ra.toUpperCase(), ecossistema)
				.then(async ({ data }: any) => {
                    localStorage.setItem("@moodle:url", JSON.stringify(data))
					const pop = window.open(
						data,
						"Ambiente Virtual de Aprendizagem",
						"width=1,height=1,toolbar=0,menubar=0,location=0",
					);
                    pop?.focus();
                    if(pop?.open) {
                        setTimeout(() => {
                            pop?.close();
                        }, 500);
                    } else {
                        alert("Atenção, não foi possível conectar ao Ambiente Virtual! Desabilite bloqueadores de Pop-up para navegar no Ecossitema.")
                        throw new DOMException()
                    }
                    // window.open(data)?.focus();
				})
                .then(() => {
                    Notify("Autenticação no Ambiente Virtual concluída", "success");
                    setSignedInVirtual(true);
                });
		} catch (e) {
            setSignedInVirtual(false);
			Notify("Atenção, não foi possível conectar ao Ambiente Virtual! Desabilite bloqueadores de Pop-up para navegar no Ecossitema!.", "error");
		}
	}

    // Logado no AVA?
    useEffect(() => {

        const virtualAuthStatus = localStorage.getItem("@ava:signed:status");

        if(virtualAuthStatus && JSON.parse(virtualAuthStatus) !== "") {
            if(JSON.parse(virtualAuthStatus) === true) {
                setSignedInVirtual(true)
            } else {
                setSignedInVirtual(false)
            }
        } else {
            setSignedInVirtual(false)
        }
    }, []);

    // useEffect(() => {
    //     localStorage.setItem("@ava:signed:status", JSON.stringify(signedInVirtual))
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [setSignedInVirtual]);


	// Busca cursos do aluno e monta histórico
	useEffect(()=>{
		if(user!.ra!)
			buscarCursosDoAluno(user!.ra!)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[user.ra]);

	useEffect(() => {
		if (
			nivelDeEnsino.nome_nivel_ensino === "FACULDADE ALENCARINA" ||
			nivelDeEnsino.nome_nivel_ensino === "FACULDADE 5 DE JULHO" ||
			nivelDeEnsino.nome_nivel_ensino === "FACULDADE 5 DE JULHO - EAD" ||
			nivelDeEnsino.codigo_nivel_ensino === 351
		) {
			setUnintaTheme(false);
		} else {
			setUnintaTheme(true);
		}
	}, [nivelDeEnsino]);
	
	// Carrega os dados do aluno e o token JWT
	useEffect(() => {
		const sessionUser = localStorage.getItem("@Application:User");
		const token = localStorage.getItem("token");
		// Verifica e atribui token as instancias do axios
		if (token) {
			ecossistema.defaults.headers!.common["Authorization"] = "Bearer " + JSON.parse(token);

			if (sessionUser) {
				const getUser = JSON.parse(sessionUser);

				if (getUser.token && getUser.typeUser === "tutor") {
					centralPedagogica!.defaults!.headers!.common[
						"Authorization"
					] = `Bearer ${getUser.token}`;
				}

				setUser(getUser);
				const localUnintaTheme = sessionStorage.getItem("unintaTheme");
				const nivelDeEnsino = sessionStorage.getItem("nivelDeEnsino");

				// Verifica e atribui nível de ensino
				if (nivelDeEnsino) {
					setNivelDeEnsino(
						JSON.parse(nivelDeEnsino || JSON.stringify(INI_VALUES.nivelDeEnsino)),
					);
				}
				// Verifica e atribui tema
				if (localUnintaTheme) {
					const localUnintaThemeBool = JSON.parse(localUnintaTheme);

					if (localUnintaThemeBool === true) {
						return setUnintaTheme(true);
					} else {
						return setUnintaTheme(false);
					}
				}
			} else {
				setUser({ ...INI_VALUES.user });
			}
		}
	}, []);

	// Busca cursos localmente, se existe, adiciona para o contexto
	useEffect(()=>{
		const asyncCursos = sessionStorage.getItem("@user:cursos");
		if(asyncCursos) { 
			const asyncStoreCursos = JSON.parse(asyncCursos);
			setCursos(asyncStoreCursos);			
		}
	},[]);

    useEffect(()=>{
        const sessionUser = localStorage.getItem("@Application:User");
		const token = localStorage.getItem("token");
        if(token && sessionUser) {
            if(localStorage.getItem("@redirect")) {
                history(localStorage.getItem("@redirect") as string)
                localStorage.removeItem("@redirect")
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[localStorage]);

	useEffect(()=>{
		let dateLogin = localStorage.getItem("data_time");		
		if(dateLogin !== null){
			let dateLogout = new Date().getTime()
			if((Number(dateLogout) - Number(dateLogin)) > 7200000){Logout()}	
		}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[]);

	return (
		<AuthContext.Provider
			value={{
				openDrawer,
				setOpenDrawer,
				runReactJoyride,
				setRunReactJoyride,
                signedInVirtual,
                setSignedInVirtual,
				user,
				setUser,
				Login,
				Logout,
				nivelDeEnsino,
				setNivelDeEnsino,
				setUnintaTheme,
				unintaTheme,
				cursos,
				setCursos,
				totalCentral,
				setTotalCentral
			}}
		>
			<ProfileProvider>
				<PortfolioProvider>
					<ThemeProvider theme={unintaTheme === true ? vermelho : azul}>
						{children}
					</ThemeProvider>
				</PortfolioProvider>
			</ProfileProvider>
		</AuthContext.Provider>
	);
};
