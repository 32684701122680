import React from "react";
import { makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, withStyles } from "@material-ui/core";
import { useGamificacao } from "context/Gamificacao";
import { useAuth } from "context/Auth/hook";
import { faCrown, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
});

export default function TabelaRankingsView(): JSX.Element {
    const classes = useStyles();
    const { ranking, rankingUsuarioLogado, exibirLinhaExtra, switchRankingType } = useGamificacao();
    const { user } = useAuth();

    function exibirCoroa(colocacao: number | string | undefined): JSX.Element {

        if(typeof(colocacao) === "string") {
            colocacao = Number(colocacao);
        }

        switch (colocacao) {
            case 1:
                return <>&nbsp;<FontAwesomeIcon icon={faCrown} color="#D8B700" /></>
            case 2:
                return <>&nbsp;<FontAwesomeIcon icon={faCrown} color="#C0C0C0" /></>
            case 3:
                return <>&nbsp;<FontAwesomeIcon icon={faCrown} color="#CD7F32" /></>
        }

        return <></>

    }

    function selecionarRankUsuarioLogado(): string {

        switch (switchRankingType) {
            case 0:
                return rankingUsuarioLogado.rank_geral;
            case 1:
                return rankingUsuarioLogado.rank_polo;
            case 2:
                return rankingUsuarioLogado.rank_curso;
        }

        return "0";

    }

    function omitirParteRA(ra: string) {

        let substring01 = ra.slice(0, 5);
        let substring02 = ra.slice(5, ra.length);

        for (let i = 0; i < substring02.length; i++) {
            substring02 = substring02.replace(substring02[i], "*");
        }

        return (substring01 + substring02);
    }

    return (
        <TableContainer>
            <Table className={classes.table} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>RA</StyledTableCell>
                        <StyledTableCell align="left">Polo</StyledTableCell>
                        <StyledTableCell align="left">Curso</StyledTableCell>
                        <StyledTableCell align="left">Pontos</StyledTableCell>
                        <StyledTableCell align="center">Colocação</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {ranking.data.map((ranking, index) => (
                        <StyledTableRow key={index} style={{ border: user.ra && ranking.ra === user.ra ? "2px solid #31B528" : "none" }}>
                            <StyledTableCell component="th" scope="row">
                                {user.ra && ranking.ra === user.ra ? ranking.ra : omitirParteRA(ranking.ra)}
                            </StyledTableCell>
                            <StyledTableCell align="left">{ranking.polo}</StyledTableCell>
                            <StyledTableCell align="left">{ranking.curso}</StyledTableCell>
                            <StyledTableCell align="left">{ranking.total}</StyledTableCell>
                            <StyledTableCell align="center">
                                {(index + 1) + "º"}
                                {exibirCoroa(index + 1)}
                                {
                                    user.ra && ranking.ra === user.ra ?
                                        <>&nbsp;<FontAwesomeIcon icon={faUser} color="#31B528" /></>
                                        :
                                        <></>
                                }
                            </StyledTableCell>
                        </StyledTableRow>
                    ))}
                    {
                        exibirLinhaExtra ?

                            <StyledTableRow style={{ border: "2px solid #31B528" }}>
                                <StyledTableCell component="th" scope="row">
                                    {rankingUsuarioLogado.ra}
                                </StyledTableCell>
                                <StyledTableCell align="left">{rankingUsuarioLogado.polo}</StyledTableCell>
                                <StyledTableCell align="left">{rankingUsuarioLogado.curso}</StyledTableCell>
                                <StyledTableCell align="left">{rankingUsuarioLogado.total}</StyledTableCell>
                                <StyledTableCell align="center">
                                    {selecionarRankUsuarioLogado() + "º"}
                                    {exibirCoroa(selecionarRankUsuarioLogado())}
                                    <>&nbsp;<FontAwesomeIcon icon={faUser} color="#31B528" /></>
                                </StyledTableCell>
                            </StyledTableRow>
                            :
                            <></>
                    }
                </TableBody>
            </Table>
        </TableContainer >
    )

}