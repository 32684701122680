import React from "react";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { useTheme } from "@material-ui/core/styles";

import School from "@material-ui/icons/School";

import CardsMenuStudent from "./components/CardsMenuStudent";
import CardsMenuTutor from "./components/CardsMenuTutor";
import { PedagogicalCommunicationProvider } from "context/PedagogicalCommunication/pedagogicalcommunication.context";

import { useAuth } from "context/Auth/hook";

import "./styles.scss";
// import { centralPedagogica } from "services/api";

export default function PedagogicalCommunication(): JSX.Element {
	const { user } = useAuth();
	const colorsTheme = useTheme();

	// useEffect(() => {
	// 	centralPedagogica.post('register',{
	// 		profile: {
	// 			ra: user.ra,
	// 			nome: user.nome
	// 		},
	// 		curs/0/os: [nivelDeEnsino],
	// 		disciplinas: JSON.parse(
	// 			sessionStorage.getItem("@user:disc:central") as string
	// 		)
	// 	})
	// 	.then(()=>{})
	// 	.catch(err => {
	// 		return null;
	// 	});
	// // eslint-disable-next-line react-hooks/exhaustive-deps
	// },[]);

	return (
		<PedagogicalCommunicationProvider>
			<Container maxWidth='lg'>
				<Grid container>
					<Grid item xs={12}>
						<Box className='header_page'>
							<School
								className='iconSchool'
								style={{
									backgroundColor: colorsTheme.palette.primary.main,
								}}
							/>
							<Typography className='title_page' component="h1">Comunicação</Typography>
							<Typography className='title_page' component="h1">Pedagógica</Typography>

							{user.typeUser === "tutor" ? (
								<Typography className='description_page'>
									Este é o espaço destinado aos atendimentos pedagógicos como:
									Esclarecimentos de dúvidas de conteúdo; Acompanhamento de
									agendamentos de videoconferências de mentoria e Atendimento de
									solicitação de revisão de questões.
								</Typography>
							) : (
								<Typography className='description_page'>
									Este é o espaço destinado a realização de solicitações de
									atendimento pedagógico como: Esclarecimentos de dúvidas de conteúdo
									com professores e colegas; Chat com o tutor ; Agendamento de
									videoconferências de mentoria e solicitação de revisão de
									avaliações.
								</Typography>
							)}
						</Box>
					</Grid>

					<Grid item xs={12}>
						<Grid container>
							{user.typeUser === "tutor" ? <CardsMenuTutor /> : <CardsMenuStudent />}
						</Grid>
					</Grid>
				</Grid>
			</Container>
		</PedagogicalCommunicationProvider>
	);
}
