import { AxiosInstance } from "axios";
// import { centralPedagogica, ecossistema } from "services/api";

/**
 *
 * @param ra
 * @param password
 * @returns {
 *       AccessToken: string,
 *       ExpiresIn: number,
 *       TokenType: string,
 * }
 * @description realiza a autenticação do usuário do tipo Tutor
 */
export const loginTutor = async (
	props: {
		email: string | number | undefined;
		password: string | undefined;
	},
	axiosInstance: AxiosInstance,
): Promise<any> => {
	return await axiosInstance.post("tutorLogin", { email: props.email, password: props.password });
};

/**
 *
 * @param ra
 * @param password
 * @returns {
 *       AccessToken: string,
 *       ExpiresIn: number,
 *       TokenType: string,
 * }
 * @description realiza a autenticação do usuário do tipo Aluno
 */
export const loginAluno = async (
	props: {
		ra: string | number | undefined;
		password: string | undefined;
	},
	axiosInstance: AxiosInstance,
): Promise<any> => {
	return await axiosInstance.post("nti/login", {
		ra: props.ra,
		password: props.password,
	});
};

/**
 *
 * @param ra string|number
 * @returns {"nome":"José da Silva"}
 * @description Retorna o nome do usuário
 */
export const getProfile = async (
	ra: string | undefined,
	axiosInstance: AxiosInstance,
): Promise<any> => {
	return await axiosInstance.get(`nti/profile/${ra}`);
};

/**
 * 
 * @param ra string|number
 * @returns [{
        "codigo_nivel_ensino": 4,
        "nome_nivel_ensino": "POLO SOBRAL- CENTRO",
        "codigo_curso": "4_GETI",
        "nome_curso": "TECNOLOGIA EM GESTÃO DA TECNOLOGIA DA INFORMAÇÃO",
        "periodo": 2,
        "situacao": "ATIVO",
        "idhabilitacaofilial": "3374",
        "idperlet": "1968"
    }]
    * @description Retorna um array com os cursos daquele RA
 */
export const getCursos = async (
	ra: string | undefined,
	axiosInstance: AxiosInstance,
): Promise<any> => {
	return await axiosInstance.get(`nti/cursos/${ra}`);
};

/**
 *
 * @param ra string|number
 * @returns string
 * @description Retorna uma URL assinada para o usuário acessar o moodle
 */
export const getMoodleUrl = async (ra: string | undefined, axiosInstance: AxiosInstance) => {
	let raUpper = (typeof ra !== "number") ?  ra?.toLowerCase() : ra
	return await axiosInstance.get(`nti/link-acesso-moodle/${raUpper}`);
};
