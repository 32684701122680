import { centralPedagogica } from "services/api";

export async function getPoleAll() {
    return await centralPedagogica.get(`polos`, {
        params: {
            all: "true"
        },
    }).then(({ data }: any) => {
        return data
    }).catch((e) => { console.log(e); });
}



export async function getCouserAll(polos) {
    return await centralPedagogica.get(`cursos`, {
        params: {
            polo_id: polos
        },
    }).then(({ data }: any) => {
        return data
    }).catch((e) => { console.log(e); });
}

export async function getRasAll(cursos) {
    return await centralPedagogica.get(`profiles`,
        {
            params: {
                model: "cursos",
                model_id: cursos
            },
        }
    ).then(({ data }: any) => {
        return data
    }).catch((e) => { console.log(e); });
}
