import { useGamificacao } from "context/Gamificacao";
import React from "react";
import ItemListaDesafios from "./Components/ItemListaDesafios";

export default function ListaDesafiosView(): JSX.Element {

	const { pontuacaoMaximaPorLabel, labelsScore } = useGamificacao();

	return (
		<ul>
			{
				labelsScore.map((labelScore, index) => {
					return (
						<ItemListaDesafios
							numeroImagem={index + 1}
							labelScore={labelScore}
							numeroLabelData={index}
							pontuacaoMaximaLabel={pontuacaoMaximaPorLabel[index]}
						/>
					);
				})
			}
		</ul>
	);
}
