import FormularioCadastroDepoimentoView from "./FormularioCadastroDepoimento.view";

export default function FormularioCadastroDepoimento({
	depoimento,
	disableButton,
	setDepoimento,
	submitDepoimento,
}): JSX.Element {
	return (
		<FormularioCadastroDepoimentoView
			depoimento={depoimento}
			disableButton={disableButton}
			setDepoimento={setDepoimento}
			submitDepoimento={submitDepoimento}
		/>
	);
}
