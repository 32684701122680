import { useState } from "react";
import { Box, Button, Container, Grid, Typography } from "@material-ui/core";
import { apiLogsDoEcossistema } from "services/Logs";
import { useEffect } from "react";
import { useAuth } from "context/Auth/hook";
import { AxiosResponse } from "axios";
import { BugReport, Sort } from "@material-ui/icons";
import { Alert, AlertTitle } from "@material-ui/lab";
import AnchievementItem from "./AnchievementItem";

export default function AnchievementsView() {
    
    let { user } = useAuth();
    let [tutorial, setTutorial] = useState<number>(0);
    let [acessos, setAcessos] = useState<number>(0);
    let [recurso_aprendz, setRecursoAprendz] = useState<number>(0);

    useEffect(()=>{
        // apiLogsDoEcossistema.get(`ra/${user.ra}?label=USUARIO_CONCLUIU_TUTORIAL`)
        apiLogsDoEcossistema.get(`ra/${user.ra}/module/TUTORIAL?count=true`)
            .then((res: AxiosResponse<any>)=>{
                setTutorial(res.data.evento)
            })
        
        apiLogsDoEcossistema.get(`ra/${user.ra}/module/ACESSO?count=true`)
            .then((res: AxiosResponse<any>)=>{
                setAcessos(res.data.evento)
            })
        
        apiLogsDoEcossistema.get(`ra/${user.ra}/module/MATERIAL_DIDATICO?count=true`)
            .then((res: AxiosResponse<any>)=>{
                setRecursoAprendz(res.data.evento)
            })
    },[user.ra])
    
    return(
        <>
            <Container style={{ marginBottom: "10vh"}}>
                <Grid container spacing={2}>
                    <Grid 
                        item
                        md={12}
                    >
                        <Box mb={3}>
                            <Typography variant="h5">Selos e Competências</Typography>
                            <Alert severity='info' style={{ marginBottom: "12px", marginTop: "36px" }}>
                                <AlertTitle>Sobre esta página</AlertTitle>                                
                                <Typography variant="body1">
                                Olá, <span style={{ textTransform: "capitalize"}}>{user.nome.split(" ")[0].toLowerCase()}!</span>
                                Sua página de "Selos e Competências" está disponível somente para você. 
                                Nesta página você consegue visualizar suas conquistas e competências dentro da plataforma. 
                                Conquistas e competências refletem seu nível de interação com as ferramentas da plataforma, quanto mais você interagir mais selos você pode adquirir.
                            </Typography>
                            </Alert>
                        </Box>
                    </Grid>
                    <Grid 
                        item
                        md={12}
                        sm={12}
                        xs={12}
                        lg={12}
                        xl={12}
                    >
                        <Box 
                            mt={3}
                            mb={3}
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center"
                            }}>
                            <Typography variant="h5">Todos os selos</Typography>
                            <Button>
                                <Sort />
                                Classificar por
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item sm={4} xs={4} md={2} lg={2} xl={2}>
                        <AnchievementItem 
                            name={tutorial && 'Completou o tour pelo Novo Ecossistema'}
                            icon={'imagens-react-joyride/gamificacao.svg'}
                        />
                    </Grid>
                    <Grid item sm={4} xs={4} md={2} lg={2} xl={2}>
                        <AnchievementItem 
                            name={`Acessou o Ecossistema ${ acessos.toString() } vezes!`}
                            icon={'imagens-react-joyride/conclusao-tutorial.svg'}
                        />
                    </Grid>
                    <Grid item sm={4} xs={4} md={2} lg={2} xl={2}>
                        <AnchievementItem 
                            name={`Acessou os recursos de aprendizagem ${ recurso_aprendz.toString() } vezes!`}
                            icon={'imagens-react-joyride/ecossistema-aprendizagem.svg'}
                        />
                    </Grid>
                    <Grid item sm={4} xs={4} md={2} lg={2} xl={2}>
                        <AnchievementItem 
                            name={`Interaja para conquistar um novo selo`}
                        />
                    </Grid>
                    <Grid 
                        item
                        md={12}
                        lg={12}
                        xl={12}
                        xs={12}
                        sm={12}
                    >
                        <Box mt={3} mb={3}>
                            <Button>
                                <BugReport />
                                Reportar um problema
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}