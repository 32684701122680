import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Paper, Popper, Typography, useTheme } from "@material-ui/core";
import { AxiosResponse } from "axios";
import { useAuth } from "context/Auth/hook";

import LinearDeterminate from "components/Card/LinearProgress";
import DialogRecursos from "components/Dialogs/DialogRecursos";

import "./index.scss";

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { ecossistema } from "services/api";
// import { Link } from "react-router-dom";
import { Visibility, VisibilityOff } from "@material-ui/icons";
// import { getMoodleUrl } from "context/Auth/services";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		popover: {
			pointerEvents: "none",
		},
		paper: {
			padding: theme.spacing(1),
		},
	}),
);

const colors = makeStyles((theme: any) => ({
	root: {
		color: theme?.palette.secondary.main,
	},
	uninta: {
		color: "#bd0e0e",
	},
}));

function SubjectView(disciplina: any): React.ReactElement {
	const classes = useStyles();
	const classesColors = colors();
	const theme = useTheme();

	const { nivelDeEnsino } = useAuth();
	const [notas, setNotas] = useState<any | null>(null);
	const [media, setMedia] = useState<any | null>(null);
	const [image, setImage] = useState<string>("");

	const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

	const [enableDialogRecursos, setEnableDialogRecursos] = useState<boolean>(false);
	const [idCursoMoodle, setIdCursoMoodle] = useState<any>();

	// const [openMoodleSubject, setOpenMoodleSubject] = useState<boolean>(false);

	// const [remoteURL, setRemoteURL] = useState<string | null>(null);

	const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => { setAnchorEl(event.currentTarget); };

	const handlePopoverClose = () => { setAnchorEl(null); };

	const open = Boolean(anchorEl);

	const [viewGradeBook, setViewGradebook] = useState<boolean>(false);

	async function getNotas(idturmadisc_rm): Promise<any> {

		try {
			if (disciplina.ra && idturmadisc_rm) {
			await ecossistema
				.get(`nti/notas/${disciplina.ra}/disciplinas/${idturmadisc_rm}`)
				.then((res: AxiosResponse<any>) => {
					if (res.data.length > 0) {
						const mediaParcial = res.data.find(
							(nota) =>
								nota.nome_etapa === "MÉDIA PARCIAL" ||
								nota.nome_etapa === "MEDIA PARCIAL" ||
								nota.nome_etapa === "NOTAS",
						);
						const notaFinal = res.data.find(
							(nota) =>
								nota.nome_etapa === "NF" ||
								nota.nome_etapa === "MEDIA FINAL" ||
								nota.nome_etapa === "MÉDIA FINAL",
						);

						setNotas(res.data);

						if (mediaParcial) setMedia(mediaParcial.nota);
						if (notaFinal) {
							if(notaFinal.nota >= 7)
							setMedia(notaFinal.nota);
						} 
					}
				});
			}
		} catch (err) {
			console.log(err);
		}
	}

	function calcPercentagem(): number | undefined {
		if (notas) {
			if (notas.length > 0) {
				const nf = notas.filter(
					(nota) => nota.nome_etapa === "NF" || nota.nome_etapa === "NAF",
				);
				if (nf[0] && nf[0]?.nota) {
					return 100.0;
				}
				if (nf[1] && nf[1]?.nota && nf[1]?.nota >= 7) {
					return 100.0;
				}
			} else {
				return 0.0;
			}
		}
		if (notas) {
			return (
				(notas.filter(
					(nota) =>
						nota.nome_etapa !== "MÉDIA PARCIAL" &&
						nota.nome_etapa !== "MEDIA PARCIAL" &&
						nota.nome_etapa !== "NAF" &&
						nota.nota !== null,
				).length /
					notas.filter(
						(nota) =>
							nota.nome_etapa !== "MÉDIA PARCIAL" &&
							nota.nome_etapa !== "MEDIA PARCIAL" &&
							nota.nome_etapa !== "NAF"
					).length) *
				100
			);
		}
	}

	useEffect(() => {
		getNotas(disciplina.idturmadisc_rm);
		ecossistema.get(`avas/${disciplina.id}`).then((res: AxiosResponse<any>) => {
			setImage(res.data.disciplina.path_capa_imagem);
			// setImage(res.data[0]?.disciplina?.path_capa_imagem);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<Box m={1}>
				<Paper
					style={{
						width: "256px",
						minHeight: "350px",
						borderRadius: "12px",
						overflow: "hidden",
						border: "1px solid #e0e0e0",
					}}
				>
					{
						image ? (
							<img src={image} width='100%' alt='Capa do curso' />
						) : (
							<img src='disciplina_default.png' alt='banner' width='100%' />
						)
					}
					<Box p={1} m={1}>
						{disciplina?.idturmadisc_rm && (
							<Box
								display={"flex"}
								flexDirection={"row"}
								justifyContent={"center"}
								textAlign={"center"}
							>
								<Typography
									variant={"body1"}
									className={
										nivelDeEnsino.nome_nivel_ensino !== "FACULDADE ALENCARINA"
											? classesColors.uninta
											: classesColors.root
									}
									style={{
										// color: "#CB1E48",
										fontWeight: "bold",
										height: "56px",
									}}
								>
									<Button
										disableRipple
										disableFocusRipple
										onClick={async () => {
											// setRemoteURL(`//virtual.uninta.edu.br/course/view.php?id=${disciplina.id}`)
											setEnableDialogRecursos(true)
											setIdCursoMoodle(disciplina.id)
											// await getMoodleUrl(user.ra, ecossistema)
											// 	.then(async ({ data }: any) => {
											// 		window.open(`${data}&wantsurl=https://virtual.uninta.edu.br/course/view.php?id=${disciplina.id}`, "_blank",);
											// 	})
										}}
										variant="text"
										onMouseEnter={handlePopoverOpen}
										onMouseLeave={handlePopoverClose}
										style={{
											textDecoration: "none",
											color: theme.palette.primary.main,
										}}>
										<span className={"titleLimit"}> {disciplina.nome.toUpperCase()} </span>
									</Button>
									<Popper
										id='mouse-over-popover'
										className={classes.popover}
										open={open}
										anchorEl={anchorEl}
										placement='bottom-start'
									>
										<Paper elevation={6}>
											<Box p={1}>
												<Typography variant={"body1"}>
													{disciplina.nome.toUpperCase()}
												</Typography>
											</Box>
										</Paper>
									</Popper>
								</Typography>
							</Box>
						)}
						<Box
							style={{
								padding: "24px",
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							<Typography variant={"body1"}>
								{"Média parcial"}{" "}
								{parseFloat(media) >= 4 && parseFloat(media) < 7 ? (
									<span style={{ color: "rgb(203, 30, 72)" }}>(NAF)</span>
								) : null}
							</Typography>
							<Typography
								variant={"h5"}
								style={{
									color: parseFloat(media) >= 7.0 ? "green" : "#BD0E0E",
								}}
							>
								{media ? parseFloat(media).toFixed(1) : "--"}
							</Typography>
						</Box>

						<Typography>
							<small style={{ color: "green" }}>Progresso na disciplina</small>
						</Typography>
						<Box
							display='flex'
							flexDirection='row'
							justifyContent='space-between'
							alignItems='center'
							width='100%'
						>
							<div style={{ width: "60%" }}>
								<LinearDeterminate value={notas ? calcPercentagem() : null} />
							</div>
							<div style={{ width: "30%" }}>
								<Typography variant='body1'>
									<small>{calcPercentagem()?.toFixed(1)} %</small>
								</Typography>
							</div>
						</Box>

						<Box
							display={"flex"}
							flexDirection={"row"}
							alignItems={"center"}
							justifyContent={"center"}
						>
							<Button
								fullWidth
								// onMouseEnter={() => setViewGradebook(!viewGradeBook)}
								// onMouseLeave={() => setViewGradebook(!viewGradeBook)}
								onClick={() => setViewGradebook(!viewGradeBook)}
								style={{
									backgroundColor: "#fcfcfc",
									marginTop: "24px",
								}}
								startIcon={
									viewGradeBook
										? <VisibilityOff style={{ marginRight: "12px" }} />
										: <Visibility style={{ marginRight: "12px" }} />
								}
							>
								Ver notas
							</Button>
						</Box>

						<Grid
							container
							spacing={1}
							style={{
								marginTop: "12px",
								display: viewGradeBook ? "" : "none",
							}}
						>
							{notas &&
								notas.map((nota, index) => {
									return (
										<Grid item md={3} key={index}>
											<Box
												p={1}
												display={"flex"}
												flexDirection={"column"}
												justifyContent={"space-between"}
												alignItems={"center"}
												style={{
													backgroundColor: "#fcfcfc",
													borderRadius: "4px",
													minHeight: "86px",
													textAlign: "center",
												}}
											>
												<Typography variant={"body2"}>
													<small>{nota.nome_etapa}</small>
												</Typography>
												<Typography variant={"body1"}>
													<strong>
														{nota.nota
															? parseFloat(nota.nota).toFixed(1)
															: "--"}
													</strong>
												</Typography>
											</Box>
										</Grid>
									);
								})}
						</Grid>
					</Box>
				</Paper>
			</Box>

			<DialogRecursos
				dados={{
					idCursoMoodle: idCursoMoodle,
					enableDialogRecursos: enableDialogRecursos,
					setEnableDialogRecursos: setEnableDialogRecursos
				}} />
		</>
	);
}

export default SubjectView;
