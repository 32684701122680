import { centralPedagogica } from "services/api";

export async function getListCourses() {
	return await centralPedagogica
		.get("cursos?revisoes=true")
		.then(({ data }: any) => {
			return data?.data;
		})
		.catch((e) => {
			return [];
		});
}

export async function getListCoursesAsync() {
	return await centralPedagogica
		.get("cursos")
		.then(({ data }: any) => {
			return data;
		})
		.catch((e) => {
			return [];
		});
}

export async function getListSubjects() {
	return await centralPedagogica
		.get("disciplinas")
		.then(({ data }: any) => {
			return data?.data;
		})
		.catch((e) => {
			return [];
		});
}

// Corrige 
export async function getListSubjectsAsync() {
	return await centralPedagogica
		.get("disciplinas")
		.then(({ data }: any) => {
			return data;
		})
		.catch((e) => {
			return [];
		});
}