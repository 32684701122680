import { useState } from "react";
import { Box, Typography, Dialog, useTheme } from "@material-ui/core";
import Notify from "../../../../utils/Notification";
// import { DadosFormularioCadastroDepoimento } from "models/depoimento";
import "./styles.scss";
import { useDepoimentos } from "context/depoimentos";
import FormularioCadastroDepoimento from "components/Formularios/Cadastro/FormularioCadastroDepoimento";
import { useMediaQuery } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { postDepoimento } from "services/Oportunidades";

export default function DialogCadastroDepoimentoView(): any {
	const theme = useTheme();
	const {
		enableDialogCadastroDepoimento,
		setEnableDialogCadastroDepoimento,
		refreshSeccaoDepoimentos,
		setRefreshSeccaoDepoimentos,
	} = useDepoimentos();
	const [depoimento, setDepoimento] = useState<string>("");
	const [disableButton, setDisableButton] = useState<boolean>(false);

	const fullScreen = useMediaQuery(theme.breakpoints.down(800));

	/**
	 * @function submitDepoimento
	 * @param data - Contém os dados do formulário de cadastro de depoimento.
	 * @param {object} event - Contém informações do componente formulário de cadastro de depoimento, que sofreu o evento submit.
	 */

	async function submitDepoimento(data, event) {
		event.preventDefault();

		// let raDoUsuarioLogado = JSON.parse(localStorage.getItem("@Application:User") as string).ra;
		const dadosUsuarioLogado = JSON.parse(
			localStorage.getItem("@Application:User") as string,
		);

		const dados = {
			ra: dadosUsuarioLogado.ra,
			depoimento: data.depoimento,
		};

		setDisableButton(true);

		postDepoimento(dados)
			.then(() => {
				setDepoimento("");
				setEnableDialogCadastroDepoimento(false);
				setDisableButton(false);
				Notify(
					"Sucesso! Em breve será dispobilizado para que todos possam ver.",
					"success",
				);
				setRefreshSeccaoDepoimentos(!refreshSeccaoDepoimentos);
			})
			.catch(() => {
				setDepoimento("");
				setEnableDialogCadastroDepoimento(false);
				Notify(
					"Falha ao cadastrar o depoimento! Tente novamente dentro de alguns instantes.",
					"error",
				);
				setRefreshSeccaoDepoimentos(!refreshSeccaoDepoimentos);
			});
	}

	return (
		<Dialog
			open={enableDialogCadastroDepoimento}
			fullScreen={fullScreen}
			onClose={() => {
				setDepoimento("");
				setEnableDialogCadastroDepoimento(false);
			}}
		>
			<div>
				<div className='body'>
					<div className='head' style={{ backgroundColor: theme.palette.primary.main }}>
						<Typography variant='body1'>Cadastrar Depoimento</Typography>
						<CloseIcon
							style={{ marginLeft: "25px", cursor: "pointer", position: "absolute", right: "10px" }}
							onClick={() => {
								setEnableDialogCadastroDepoimento(false);
							}}
						/>
					</div>

					<Box p={3} style={{ border: "none" }}>
						<div>
							<Box mb={3} mt={2}>
								<Typography variant='body1'>
									Preencha o formulário para cadastrar seu depoimento no
									Ecossistema de Aprendizagem
								</Typography>
							</Box>
							<Box>
								<FormularioCadastroDepoimento
									depoimento={depoimento}
									disableButton={disableButton}
									setDepoimento={setDepoimento}
									submitDepoimento={submitDepoimento}
								/>
							</Box>
						</div>
					</Box>
				</div>
			</div>
		</Dialog>
	);
}
