import React from "react";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import DialogRequestConfirmation from "./DialogRequestConfirmation";
import SelectorArquivos from "./SelectorArquivos";
import FileList from "./FileList";

import { storeRequest } from "./formreviewquestion.services";
import {
	getCoursesStudent,
	getSubjectsStudent,
} from "../../DialogQuestionsAnswers/dialogquestionsanswers.services";
import { StoreRequestProps } from "./formreviewquestion.model";

import Notify from "utils/Notification";
import { useAuth } from "context/Auth/hook";

import { usePedagogicalCommunication } from "context/PedagogicalCommunication/hooks/usePedagogicalCommunication";

import "./styles.scss";
import { useTheme } from "@material-ui/core";

import { CENTRAL_PEDAGOGICA } from "defaults/modules";
import { USUARIO_SOLICITA_REVISAO_DEFER_INDEFER } from "defaults/events";
import { apiLogsDoEcossistema } from "services/Logs";

export default function FormReviewQuestion(): React.ReactElement {
	const theme = useTheme();
	const { user, nivelDeEnsino } = useAuth();
	const { updateNumReviews, setUpdateNumReviews } = usePedagogicalCommunication();

	const [open, setOpen] = React.useState(false);
	const [dataSolicitation, setDataSolicitation] = React.useState("");
	const [disabledOptionsQuestions, setDisabledOptionsQuestions] = React.useState(true);
	const [loadingForm, setLoadingForm] = React.useState(true);

	// Este state só vai pegar o nome do curso e mostrar em tela
	const [courses, setCourses] = React.useState([]);
	// Recebe a listagem de disciplinas
	const [subjects, setSubjects] = React.useState<{ id: number; nome: string }[]>([]);

	const defaultValuesData: StoreRequestProps = {
		polo_id: 0,
		curso_id: "",
		disciplina_id: "",
		avaliacao: "",
		questao: "",
		justificativa: "",
		anexo: undefined,
	};

	const [dataForm, setDataForm] = React.useState<StoreRequestProps>(defaultValuesData);

	const onSubmit = (e: React.FormEvent) => {
		e.preventDefault();

		if (!dataForm.anexo) {
			Notify("O campo de anexo é obrigatório!", "warning");
			return;
		}

		storeRequest({ ...dataForm, polo_id: nivelDeEnsino.codigo_nivel_ensino })
			.then((data) => {
				setOpen(true);
				setUpdateNumReviews(!updateNumReviews);
				setDataSolicitation(data.created.id);
				handleClearInputs();
				Notify("Solicitação Realizada.", "success");

				apiLogsDoEcossistema.post('/',
					{
						ra: user.ra as string,
						module: CENTRAL_PEDAGOGICA,
						evento: {
							label: USUARIO_SOLICITA_REVISAO_DEFER_INDEFER,
							log_info: {
								user_type: user.typeUser,
								codigo_nivel_ensino: nivelDeEnsino.codigo_nivel_ensino,
								nome_nivel_ensino: nivelDeEnsino.nome_nivel_ensino,
								nome_curso: nivelDeEnsino.nome_curso,
								periodo: nivelDeEnsino.periodo,
							}
						},
						metadata: {
							curso: nivelDeEnsino.nome_curso,
							polo: nivelDeEnsino.nome_nivel_ensino,
							periodo: nivelDeEnsino.periodo,
						}
					}
				)
			})
			.catch(() => {
				Notify("Sua solicitação não foi realizada. Por favor tente novamente", "error");
			});
	};

	const handleValuesData = (position: string, value: unknown) => {
		setDataForm({ ...dataForm, [position]: value });
	};

	const handleUpload = (file: File[]): void => {
		handleValuesData("anexo", file[0]);
	};

	const handleClearInputs = (): void => {
		setDataForm({ ...defaultValuesData, curso_id: dataForm.curso_id });
	};

	const disabledQuestion = (e: unknown): void => {
		setDisabledOptionsQuestions(e !== "NT3" && e !== "NAF");
	};

	React.useEffect(() => {
		getCoursesStudent(user.ra)
			.then((data: any) => {
				setCourses(data);
				handleValuesData("curso", `${data[0].id}:${data[0].name}`);
			})
			.catch((e) => {
				console.log(e);
			});

		getSubjectsStudent(user?.ra)
			.then((data: any) => {
				setSubjects(data);
				setLoadingForm(false);
			})
			.catch((e) => {
				console.log(e);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user?.ra]);

	if (loadingForm) {
		return (
			<Box display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
				<Typography style={{ fontWeight: "bold", marginBottom: 4 }}>
					Carregando Formulário...
				</Typography>
				<CircularProgress />
			</Box>
		);
	}

	return (
		<form onSubmit={onSubmit}>
			<Grid container className='form_review_question'>
				<Grid item xs={12} md={6}>
					<FormControl variant='outlined' fullWidth>
						<InputLabel id='demo-simple-select-outlined-label'>Curso:</InputLabel>
						<Select
							labelId='demo-simple-select-outlined-label'
							id='selectCurso'
							label='Curso:'
							value={dataForm?.curso_id}
							onChange={(e) => handleValuesData("curso_id", e.target.value)}
							required
						>
							{courses.map((course: any) => (
								<MenuItem key={course.id} value={`${course.id}:${course.name}`}>
									{course.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>

				<Grid item xs={12} md={6}>
					<FormControl fullWidth variant='outlined' required>
						<InputLabel>Disciplina:</InputLabel>

						<Select
							id='selectDisciplina'
							label='Disciplina: *'
							value={dataForm?.disciplina_id}
							onChange={(e) => handleValuesData("disciplina_id", e.target.value)}
						>
							{subjects.map((disciplina) => {
								return (
									<MenuItem
										key={disciplina.id}
										value={`${disciplina.id}:${disciplina.nome}`}
									>
										{disciplina.nome}
									</MenuItem>
								);
							})}
						</Select>
					</FormControl>
				</Grid>

				<Grid item xs={12} md={6}>
					<FormControl fullWidth variant='outlined' required>
						<InputLabel>Avaliação:</InputLabel>

						<Select
							id='selectAvaliacao'
							label='Avaliação: *'
							value={dataForm?.avaliacao}
							onChange={(e) => {
								handleValuesData("avaliacao", e.target.value);
								disabledQuestion(e.target.value);
							}}
						>
							<MenuItem value='NT1'>NT1</MenuItem>
							<MenuItem value='NT2'>NT2</MenuItem>
							<MenuItem value='NT3'>NT3</MenuItem>
							<MenuItem value='NAF'>NAF</MenuItem>
						</Select>
					</FormControl>
				</Grid>

				<Grid item xs={12} md={6}>
					<FormControl fullWidth variant='outlined' required>
						<InputLabel>Questão:</InputLabel>

						<Select
							id='selectQuestao'
							label='Questão: *'
							value={dataForm?.questao}
							onChange={(e) => handleValuesData("questao", e.target.value)}
						>
							<MenuItem value={1}>1</MenuItem>
							<MenuItem value={2}>2</MenuItem>
							<MenuItem value={3}>3</MenuItem>
							<MenuItem value={4}>4</MenuItem>
							<MenuItem value={5}>5</MenuItem>
							<MenuItem value={6}>6</MenuItem>
							<MenuItem value={7}>7</MenuItem>
							<MenuItem value={8}>8</MenuItem>
							<MenuItem value={9}>9</MenuItem>
							<MenuItem value={10}>10</MenuItem>
							<MenuItem value={11} disabled={disabledOptionsQuestions}>
								11
							</MenuItem>
							<MenuItem value={12} disabled={disabledOptionsQuestions}>
								12
							</MenuItem>
							<MenuItem value={13} disabled={disabledOptionsQuestions}>
								13
							</MenuItem>
							<MenuItem value={14} disabled={disabledOptionsQuestions}>
								14
							</MenuItem>
							<MenuItem value={15} disabled={disabledOptionsQuestions}>
								15
							</MenuItem>
							<MenuItem value={15} disabled={disabledOptionsQuestions}>
								15
							</MenuItem>
							<MenuItem value={16} disabled={disabledOptionsQuestions}>
								16
							</MenuItem>
							<MenuItem value={17} disabled={disabledOptionsQuestions}>
								17
							</MenuItem>
							<MenuItem value={18} disabled={disabledOptionsQuestions}>
								18
							</MenuItem>
							<MenuItem value={19} disabled={disabledOptionsQuestions}>
								19
							</MenuItem>
							<MenuItem value={20} disabled={disabledOptionsQuestions}>
								20
							</MenuItem>
						</Select>
					</FormControl>
				</Grid>

				<Grid item xs={12}>
					<TextField
						id='justificativa'
						required
						fullWidth
						variant='outlined'
						label='Descreva a justificativa:'
						multiline
						minRows={4}
						maxRows={4}
						style={{ wordWrap: "break-word" }}
						value={dataForm?.justificativa}
						onChange={(e) => handleValuesData("justificativa", e.target.value)}
					/>
				</Grid>

				<Grid item xs={12}>
					{dataForm?.anexo ? (
						<FileList
							fileSelected={dataForm?.anexo}
							onSetFile={() => handleValuesData("anexo", undefined)}
						/>
					) : (
						<SelectorArquivos onUpload={handleUpload} />
					)}
				</Grid>

				<Grid item xs={12} style={{ display: "flex", justifyContent: "flex-end" }}>
					<Button
						id='btnSolicitar'
						variant='contained'
						style={{
							backgroundColor: theme.palette.primary.main,
							color: "white",
						}}
						type='submit'
					>
						Solicitar
					</Button>
				</Grid>

				<DialogRequestConfirmation
					open={open}
					idSolicitation={dataSolicitation}
					setOpen={setOpen}
					setIdSolicitation={setDataSolicitation}
				/>
			</Grid>
		</form>
	);
}
