import React, { useState, useEffect } from "react";
import { LearningResourceInterface } from "components/LearningResources/resource.model";
import { selectLearningResource } from "components/SelectLearningResources";
import { ecossistema } from "services/api";
import { useSearchParams } from "react-router-dom";
import { AxiosResponse } from "axios";
import { useAuth } from "context/Auth/hook";
import {	
	Box,
	Button,
	useTheme,
} from "@material-ui/core";
import useDisciplinas from "context/Disciplinas/hooks";
import { useProfiles } from "context/Profile/hook";

/**
 *
 * @returns {React.ReactElement} com tela de disciplinas e suas informações e busca
 */

// TODO:  Implementar busca de AVAs para dentro do contexto
// TODO:  Remover variáveis de estado do componente
// TODO: Corrigir implementação de profile e profiles para o formato atual

export default function DisciplinasView(): React.ReactElement {		
	const theme 										= useTheme(); 										// Tema global
	const { user }  						= useAuth(); 										// Hook para obter dados do usuário
	const [searchParams] 								= useSearchParams(); 								// Hook para obter query params
	const params 										= {disciplinas: searchParams.get("disciplina"),}; 	// Query params 
	const [tab, setTab] 								= useState<number>(1); 								// Define tab 1: disciplinas, tab 2: cursos livres
	const [carregaDisciplinas, setCarregaDisciplinas] 	= useState<boolean>(false); 						// Habilita o carregamento de disciplinas	
	
	const [disciplinaAva, setDisciplinaAva]				= React.useState<LearningResourceInterface | null>(null); // Define a disciplina a ser passada para o componente
	const {minhasDisciplinas, setMinhasDisciplinas} 	= useDisciplinas(); 
	const {profile, setProfile, profiles, setProfiles} 	= useProfiles();
	

	// const [profile, setProfile] 						= useState<any>(undefined);							// Define o perfil atual
	// const [profiles, setProfiles] 						= useState<any[]>([]);								// Define a coleção de perfis atuais
	// const [minhasDisciplinas, setMinhasDisciplinas] 	= useState<any[] | any>([]);						// Define as disciplinas adicionadas pelo tutor
		
	
    /**
     * Busca AVAs 
     */

    const CarregarDisciplinasV3 = async () => {
			let disciplinas: any = []
			let cursosLivres: any = []
			let salasMoodle: any = []

            const moodle = await ecossistema.get(`nti/inscricoes-moodle/${user.ra}`)
                .then((res: AxiosResponse<any>) => {
					if (Array.isArray(res.data)) {
						salasMoodle = res.data.filter(item => {
							return item.role_shortname === "editingteacher" || item.role_shortname === "teacher"
						})
					}
                    return res.data;
                })

            if (moodle.length > 0) {
				disciplinas = moodle.filter((disciplina) => {
					return (disciplina.idturmadisc_rm !== null)
				});
	
				cursosLivres = moodle.filter((cursoLivre) => {
					return ((
						cursoLivre.role_shortname === "student" || cursoLivre.role_archetype === "student"
					) && (
							cursoLivre.idturmadisc_rm === null
						)
					)
				});

                cursosLivres = cursosLivres.reduce((r,a) => {
                    r[a.categoria_nome] = r[a.categoria_nome] || [];
                    r[a.categoria_nome].push(a);
                    return r;
                }, Object.create(null));


                setProfiles([
                    ...profiles,
                    {
                        ra: user.ra,
                        nome: user.nome,
                        disciplinas: disciplinas,
                        cursosLivres: cursosLivres,
						salasMoodle: salasMoodle,
                    },
                ]);
                setProfile({
                    ra: user.ra ? user.ra : "",
                    nome: user.nome,
                    disciplinas: disciplinas,
                    cursosLivres: cursosLivres,
					salasMoodle: salasMoodle,
                });
                // setDisciplinas(disciplinas);
                setCarregaDisciplinas(false);
                sessionStorage.setItem(
                    "@my-profile",
                    JSON.stringify({
                        ra: user.ra,
                        nome: user.nome,
                        disciplinas: disciplinas,
                        cursosLivres: cursosLivres,
						salasMoodle: salasMoodle,
                    }),
                );
				sessionStorage.setItem("@user:disc:central", JSON.stringify(disciplinas));
                sessionStorage.setItem("@user:disciplinas",JSON.stringify(disciplinas));
                sessionStorage.setItem("@filter:cursos:livres",JSON.stringify(cursosLivres));
                sessionStorage.setItem("@user:profiles:collection",JSON.stringify([
                        ...profiles,
                        {
                            ra: user.ra,
                            nome: user.nome,
                            disciplinas: disciplinas,
                            cursosLivres: cursosLivres,
							salasMoodle: salasMoodle,
                        },
                    ]),
                );
				sessionStorage.setItem("@salas:digitais",JSON.stringify(salasMoodle));
            } else {
				setProfiles([
                    ...profiles,
                    {
                        ra: user.ra,
                        nome: user.nome,
                        disciplinas: disciplinas,
                        cursosLivres: cursosLivres,
						salasMoodle: salasMoodle,
                    },
                ]);
				setProfile({
                    ra: user.ra ? user.ra : "",
                    nome: user.nome,
                    disciplinas: disciplinas,
                    cursosLivres: cursosLivres,
					salasMoodle: salasMoodle,
                });
				setCarregaDisciplinas(false);  
			}
    }   

    /**
     * -- TODO: Remover o método abaixo em vista da atualização mais recente
     * Busca disciplinas e cursos livres pertencentes a um determinado RA e em seguida retorna para a coleção	  
     * */ 
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	// const carregarDisciplinasV2 = () => {
	// 	setCarregaDisciplinas(true);
	// 	ecossistema.get(`/nti/disciplinas/${user.ra}`)
	// 		.then((res: AxiosResponse<any>) => {
	// 			// setDisciplinas(res.data);
	// 			sessionStorage.setItem("@user:disc:central", JSON.stringify(res.data));
	// 			return res.data;
	// 		})
	// 		.then((disciplinas) => {
	// 			ecossistema.get(`/nti/cursos-moodle/${user.ra}`).then((res: AxiosResponse<any>) => {
	// 				if (res.data.length > 0) {
	// 					setProfiles([
	// 						...profiles,
	// 						{
	// 							ra: user.ra,
	// 							nome: user.nome,
	// 							disciplinas: disciplinas,
	// 							cursosLivres: res.data,
	// 						},
	// 					]);
	// 					setProfile({
	// 						ra: user.ra ? user.ra : "",
	// 						nome: user.nome,
	// 						disciplinas: disciplinas,
	// 						cursosLivres: res.data,
	// 					});
	// 					// setDisciplinas(disciplinas);
	// 					setCarregaDisciplinas(false);
	// 					sessionStorage.setItem(
	// 						"@my-profile",
	// 						JSON.stringify({
	// 							ra: user.ra,
	// 							nome: user.nome,
	// 							disciplinas: disciplinas,
	// 							cursosLivres: res.data,
	// 						}),
	// 					);
	// 					sessionStorage.setItem(
	// 						"@user:disciplinas", 
	// 						JSON.stringify(res.data)
	// 					);
	// 					sessionStorage.setItem(
	// 						"@user:profiles:collection",
	// 						JSON.stringify([
	// 							...profiles,
	// 							{
	// 								ra: user.ra,
	// 								nome: user.nome,
	// 								disciplinas: disciplinas,
	// 								cursosLivres: res.data,
	// 							},
	// 						]),
	// 					);
	// 				} else {
	// 					// setDisciplinas(disciplinas);
	// 					setProfiles([
	// 						...profiles,
	// 						{
	// 							ra: user.ra,
	// 							nome: user.nome,
	// 							disciplinas: disciplinas,
	// 							cursosLivres: res.data,
	// 						},
	// 					]);
	// 					setCarregaDisciplinas(false);
	// 					sessionStorage.setItem("@user:disciplinas", JSON.stringify(res.data));
	// 					sessionStorage.setItem(
	// 						"@user:profiles:collection",
	// 						JSON.stringify([
	// 							...profiles,
	// 							{
	// 								ra: user.ra,
	// 								nome: user.nome,
	// 								disciplinas: disciplinas,
	// 								cursosLivres: res.data,
	// 							},
	// 						]),
	// 					);
	// 				}
	// 				return res;
	// 			});
	// 		})
	// 		.catch((res) => {
	// 			console.log(res);
	// 			setCarregaDisciplinas(false);
	// 			console.log("Erro ao carregar disciplinas");
	// 		});
	// };

	// Verifica se existe alguma disciplina localmente, caso não exista realiza a busca na API
	useEffect(() => {
		// Load the collection
		const userCollection = sessionStorage.getItem("@user:profiles:collection");
		if (userCollection && userCollection.length > 0) {
			setProfiles(JSON.parse(userCollection || "null"));
		}

		const cursorCollection = sessionStorage.getItem("@currentCollection");
		if (cursorCollection) {
			// setCurrentCollection(JSON.parse(cursorCollection || "0"));
		}

		// Get current profile
		const cursorProfile = sessionStorage.getItem("@my-profile");
		if (cursorProfile) {
			setProfile(JSON.parse(cursorProfile || ""));
		}

		// Load the subjects
		const userSalas = sessionStorage.getItem("@salas:digitais");
		if (userSalas && userSalas.length > 0 ) {
			// setSalasVirtuais(JSON.parse(userSalas || ""));
		} else {
			setCarregaDisciplinas(true);
			CarregarDisciplinasV3();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	
	/**
	 * Carrega minhasDisciplinas do Cache local
	 */	
	useEffect(() => {
		if (minhasDisciplinas.length > 0) {
			sessionStorage.setItem("@user:minhasDisciplinas", JSON.stringify(minhasDisciplinas));
		} else {
			try {
				const minhasDisciplinasLocais = sessionStorage.getItem("@user:minhasDisciplinas");
				if (minhasDisciplinasLocais !== null && minhasDisciplinasLocais.length > 0) {
					setMinhasDisciplinas(JSON.parse(minhasDisciplinasLocais));
				}
			} catch (e) {
				console.log(e);
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [minhasDisciplinas]);

	// Este useEffect busca a disciplina a partir do query param, caso exista disciplina com este idAva ela exibe o LernResource, do contrário, exibe os cards
	React.useEffect(() => {
		if (params.disciplinas) {
			ecossistema
				.get(`avas/${params.disciplinas}`)
				.then((res: AxiosResponse<any>) => {
					res.data.length > 0 ? setDisciplinaAva(res.data[0]) : (window.location.href = "/");
				});
		} else {
			setDisciplinaAva(null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params.disciplinas]);

    useEffect(() => {
        if(user.typeUser === "tutor") {
            setTab(0);
        } else if (user.typeUser === "comunidade") {
			setTab(2);
		}
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

	return (
		<React.Fragment>
			<Box
				style={{
					backgroundColor: "transparent",
					// padding: "12px",
					borderRadius: "12px",
					minHeight: "56px",
					marginBottom: "12px",
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
                {
                    (user.typeUser === "tutor" || user.typeUser === "administrador")
                        && <div
                        style={
                            tab === 0
                                ? {
                                    borderBottom: `solid 2px ${theme.palette.primary.main}`,
                                    backgroundColor: "#f8f8f8",
                                }
                                : {}
                        }
                    >
                        <Button
                            className="minhas-disciplinas-carousel"
                            onClick={() => {
                                setTab(0);
                            }}
                        >
                            Salas Virtuais
                        </Button>
                    </div>
                }
				 {
                    user.typeUser !== "comunidade" && 
					<div
						style={
							tab === 1
								? {
									borderBottom: `solid 2px ${theme.palette.primary.main}`,
									backgroundColor: "#f8f8f8",
								}
								: {}
						}
					>
						<Button
							className="minhas-disciplinas-carousel"
							onClick={() => {
								setTab(1);
							}}
						>
							Minhas disciplinas
						</Button>
					</div>
				}

				<div
					style={
						tab === 2
							? {
								borderBottom: `solid 2px ${theme.palette.primary.main}`,
								backgroundColor: "#f8f8f8",
							}
							: {}
					}
				>
					<Button
						className="cursos-eventos-carousel"
						onClick={() => {
							setTab(2);
						}}
					>
						Cursos e Projetos
					</Button>
				</div>
			</Box>
			{
				selectLearningResource(
					tab,
					disciplinaAva, 
					carregaDisciplinas, 
					profile, 
					profiles, 
					minhasDisciplinas, 
					user,
				)
			}			
		</React.Fragment>
	);
}
