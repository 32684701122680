import React, { useState } from "react";
import parse from "html-react-parser";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
// import FormControl from "@material-ui/core/FormControl";
// import MenuItem from "@material-ui/core/MenuItem";
import { useTheme } from "@material-ui/core/styles";

import Star from "@material-ui/icons/Star";

import { Paginator } from "primereact/paginator";

import DialogIsis from "../../DialogIsis";
import DialogQuestionsAnswers from "./DialogNewQuestion";
import DialogViewQuestion from "./DialogViewQuestion";

import { useAuth } from "context/Auth/hook";

import { usePedagogicalCommunication } from "context/PedagogicalCommunication/hooks/usePedagogicalCommunication";

import { getListQuestionsAlunoTutor, } from "../dialogquestionsanswers.services";

import "./styles.scss";
import { Badge, CircularProgress, Grid, Hidden } from "@material-ui/core";

// import { getUserCourses } from "services/Profiles";

import { useForm } from "react-hook-form";
import { Assistant, ContactSupport } from "@material-ui/icons";
import { Message } from "@material-ui/icons";

// interface courses {
// 	codigo_curso: string,
// 	codigo_nivel_ensino: number,
// 	idhabilitacaofilial: string,
// 	idperlet: string,
// 	nome_curso: string,
// 	nome_nivel_ensino: string,
// 	periodo: null,
// 	situacao: string,
// }

export default function Questions(): React.ReactElement {
	const { user } = useAuth();
	const { reloadListQuestions, updateNumQuestions } = usePedagogicalCommunication();
	const colorsTheme = useTheme();
	const { getValues } = useForm({ mode: "onBlur" });
	// Paginação
	const [currentPage, setCurrentPage] = React.useState(0);
	const [totalQuestions, setTotalQuestions] = React.useState(0);

	const [loadingPage, setLoadingPage] = React.useState(true);

	const [filterCourse, setFilterCourse] = useState("todos");
	// const [filterClass, setFilterClass] = useState("todos");

	const [openNewQuestion, setOpenNewQuestion] = useState(false);
	const [openViewQuestion, setOpenViewQuestion] = useState(false);
	const [idQuestion, setIdQuestion] = React.useState("");

	const [listQuestions, setListQuestionsView] = useState([]);
	// const [listCourses, setListCourses] = useState<courses[]>([]);

	const [openIsis, setOpenIsis] = React.useState(false);

	const handleFilterListQuestions = (
		key: "curso_id",
		value: string,
		page?: number,
	) => {
		setLoadingPage(true);

		if (!page) {
			setCurrentPage(0);
		}

		let course = filterCourse;

		if (key === "curso_id") {
			course = value;
		}

		if (course !== "todos") {
			getListQuestionsAlunoTutor(value, page, user)
				.then((data) => {
					setLoadingPage(false);
					setListQuestionsView(data.data);
					setTotalQuestions(data.total);
				});
		}

		if (course === "todos") {
			const course = null
			getListQuestionsAlunoTutor(course, page, user)
				.then((data) => {
					setLoadingPage(false);
					setListQuestionsView(data.data);
					setTotalQuestions(data.total);
				});
		}
	};

	// Busca questões

	const getQuestionsPaginator = (page = 1) => {
		try {
			// const disciplina = true;
			getListQuestionsAlunoTutor(
				getValues("selectCourseQuestions") ? (filterCourse === "todos" ? null : filterCourse) : null,
				page,
				user)
				.then((data) => {
					setLoadingPage(false);
					setListQuestionsView(data.data);
					setTotalQuestions(data.total);
					setFilterCourse("todos");
				})
				.catch((e) => {
					console.log(e);
				});
			// getCourses(disciplina).then(({ data }) => {
			// 	// setListCourses(data);
			// })
			// 	.catch((e) => {
			// 		console.log(e);
			// 	});
		} catch (e) {
			// Pass
		}
	};

	React.useEffect(() => {
		setLoadingPage(true);
		// const page = currentPage / 10 + 1;
		getQuestionsPaginator();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reloadListQuestions, updateNumQuestions]);

	return (
		<Container>
			{user?.typeUser === "student" && (
				<Box className='header_questions'>
					<Box className="billboard">
						<Typography variant="h5" component="h5" className="decorator">
							Dúvidas acadêmicas
						</Typography>
						<Hidden mdDown>
							<Typography
								variant="h3"
								component="h3"
								className="billboard_header">
								Olá, <span style={{ textTransform: "capitalize" }}>{user.nome.toLowerCase()}</span>!
								<br />
								O que você precisa saber?
							</Typography>
							<Typography variant="body1" component="p" className="caption">
								Utilize este módulo para enviar dúvidas para a tutoria.
							</Typography>
						</Hidden>
					</Box>
					{/* <Typography className='salutation'>
						Olá{" "}
						<span style={{ color: colorsTheme.palette.primary.main }}>
							{user?.nome.toLowerCase()}
						</span>{" "}
						!
					</Typography>
					<Typography className='what'>O que você precisa saber ?</Typography> */}
					<div className='div_header_questions'>
						<Button
							id='btnSpeakIsis'
							variant='contained'
							disableElevation
							style={{
								color: colorsTheme.palette.primary.main,
								backgroundColor: "#f5f5f5",
								padding: '12px 24px',
								borderRadius: '24px',
								margin: '4px'
							}}
							onClick={() => {
								setOpenIsis(true);
							}}
							endIcon={<Assistant />}
						>
							Falar com a Assistente virtual
						</Button>
						<Button
							id='btnSpeakTutoring'
							variant='contained'
							disableElevation
							style={{
								backgroundColor: colorsTheme.palette.primary.main,
								color: "white",
								padding: '12px 24px',
								borderRadius: '24px',
								margin: '4px',
								marginLeft: '12px',
							}}
							onClick={() => {
								setOpenNewQuestion(true);
							}}
							endIcon={<ContactSupport />}
						>
							Falar com a Tutoria
						</Button>
					</div>
				</Box>
			)}

			<Box className='body_questions'>
				<Box className='filters'>
					{/* <form>
						<FormControl size='small' fullWidth>
							<Controller
								name='selectCourseQuestions'
								control={control}
								rules={{ required: false }}
								render={({
									field: { value, onChange },
								}) => (
									<TextField
										id='selectCourseQuestions'
										select
										label='Curso:'
										disabled={loadingPage}
										variant='outlined'
										value={getValues("selectCourseQuestions") ? value : filterCourse}
										onChange={(e) => {
											onChange(e);
											setFilterCourse(e.target.value as string);
											handleFilterListQuestions("curso_id", e.target.value as string);
										}}
									>
										<MenuItem selected value='todos'>Todos</MenuItem>
										{listCourses?.map((course: any) => (
											<MenuItem value={course.id} key={course.id}>
												{course.nome}
											</MenuItem>
										))}
									</TextField>
								)} />
						</FormControl>
					</form> */}
				</Box>

				<Box className='views_questions'>
					<Typography variant='h5' component="h5" style={{ paddingBottom: "42px" }}>Perguntas com melhor avaliação</Typography>

					{loadingPage ? (
						<Grid item xs={12}>
							<Box className='awaiting'>
								<Typography variant='subtitle2' component='p'>Por favor, aguarde...</Typography>
								<CircularProgress />
							</Box>
						</Grid>
					) : (
						<>
							{listQuestions?.length > 0 ? (
								<>
									{listQuestions?.map((question: any, index) => (
										<Box
											className='view_question'
											key={index}
											id='boxQuestionItem'
											onClick={() => {
												setOpenViewQuestion(true);
												setIdQuestion(question.id);
											}}
										>
											<Typography variant='subtitle1' style={{ overflowWrap: "break-word" }}>
												{parse(question.pergunta)}
											</Typography>

											<Box className='footer_question_view'>
												{/* <Typography variant='subtitle1'>
													{question.respostas_count} Respostas
												</Typography> */}
												<Box
													display={"flex"}
													flexDirection="row"
													style={{
														marginLeft: '12px'
													}}>

													<Badge
														badgeContent={question.respostas_count !== 0 ? question.respostas_count : 0}
														color="primary"
														overlap="circular">
														<Button
															id='btnViewResponses'
															variant='text'
															endIcon={<Message />}
															style={{
																color: colorsTheme.palette.primary.light,
																marginLeft: '12px'
															}}
														>
															RESPOSTA
														</Button>
													</Badge>
												</Box>


												<Box className='avaliations'>
													<Typography variant='subtitle1'>
														<Star className='iconStar' />{" "}
														{parseFloat(question.avaliacoes_avg ?? 0)
															.toFixed(1)
															.replace(".", ",")}
													</Typography>
													{/* <Typography variant='subtitle1'>
														<Favorite className='iconHeart' />{" "}
														{question.favoritos_count}
													</Typography> */}
												</Box>
											</Box>
										</Box>
									))}

									<Paginator
										rows={10}
										totalRecords={totalQuestions}
										first={currentPage}
										onPageChange={(e) => {
											setLoadingPage(true)
											setCurrentPage(e.first);

											if (
												filterCourse !== "todos"
											) {
												handleFilterListQuestions(
													"curso_id",
													filterCourse as string,
													e.page + 1,
												);
											} else {
												getQuestionsPaginator(e.page + 1);
											}
										}}
										style={{
											background: "#f7f7f7",
											marginTop: 10,
										}}
									></Paginator>
								</>
							) : (
								<Box className='nothing'>
									<Typography className='text_no_find'>
										Nenhum resultado encontrado
									</Typography>
									<Typography className='subtext_no_find'>
										Tente filtrar por outro curso
									</Typography>
								</Box>
							)}
						</>
					)}
				</Box>
			</Box>

			<DialogQuestionsAnswers open={openNewQuestion} setOpen={setOpenNewQuestion} />
			<DialogIsis open={openIsis} setOpen={setOpenIsis} />

			<DialogViewQuestion
				open={openViewQuestion}
				id={idQuestion}
				setOpen={setOpenViewQuestion}
				setId={setIdQuestion}
			/>
		</Container >
	);
}
