import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import SearchEngineV2 from "components/SearchEngineV2";
import Disciplinas from "./Subjects";
import Welcome from "components/Welcome";

/**
 *
 * @returns {React.ReactElement} Tela inicial do usuário de nível Aluno
 * Esta tela retorna a lista de disciplinas do estudante, o componente de <Welcome /> e
 * o componente de buscas de mídias do Ecossistema de Aprendizagem.
 */
export default function HomepageView(): React.ReactElement {

    return (
        <React.Fragment>
            <AnimatePresence>
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                >
                    <Disciplinas />
                </motion.div>
            </AnimatePresence>
            <Welcome />
            <SearchEngineV2 />

        </React.Fragment>
    );
}