/* eslint-disable eqeqeq */
import React from "react";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
// model
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
// inputs
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
// list
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
// icons
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import LinkIcon from "@material-ui/icons/Link";
// Collapse
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
// scss
import "./styles.scss";
// services
import { putSubjectCancel } from "../../services/calendar.request";
// componentes
import { AccessTime, DescriptionOutlined } from "@material-ui/icons";
import { useMediaQuery, useTheme } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';

import Notify from "../../../../../../utils/Notification";

const styles = (theme: Theme) =>
	createStyles({
		root: {
			margin: 0,
			padding: theme.spacing(2),
		},
		closeButton: {
			position: "absolute",
			right: theme.spacing(1),
			top: theme.spacing(1),
			color: theme.palette.grey[500],
		},
	});

export interface DialogTitleProps extends WithStyles<typeof styles> {
	id: string;
	children: React.ReactNode;
	onClose: () => void;
}

const DialogActions = withStyles((theme: Theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(1),
	},
}))(MuiDialogActions);

interface DialogoModalProps {
	open: boolean;
	setOpen: (e: boolean) => void;
	titulo: string;
	corpo: {
		id: number;
		nome: string;
		idade: string;
		ra: string;
		email: string;
		dificuldades: string;
		medicamentos: string;
		assunto_nome: string;
		curso_nome: string;
		polo_nome: string;
		telefone: string;
		horario: string;
		data: string;
		link: string;
		parecer: string;
		situacao: string;
		queixa: string;
		medicamento: string;
		diagnostico: string;
		laudo: string;
	};
	reload: boolean;
	setReload: (e: boolean) => void;
}

export default function CustomizedDialogs(props: DialogoModalProps) {
	const theme = useTheme();
	const [open, setOpen] = React.useState(false);
	const [cancelamento, setCancelamento] = React.useState(false);
	const [botao1, setBotao1] = React.useState("Finalizar Atendimento");
	const [botao2, setBotao2] = React.useState(false);
	// textfild
	const [motivoValor, setMotivo] = React.useState("");
	const [placeholderMotivo, setPlaceholderMotivo] = React.useState("Descreva o atendimento");
	const [statusAgendamento, setStatusAgendamento] = React.useState("selecione");
	const [statusAgendamentoError, setStatusAgendamentoError] = React.useState(false);
	const [descricaoAgendamentoError, setDescricaoAgendamentoError] = React.useState(false);

	const fullScreen = useMediaQuery(theme.breakpoints.down(800));

	const handleChangeSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
		setStatusAgendamento(event.target.value);
		if (event.target.value === "cancelado") {
			setPlaceholderMotivo(
				"Descreva o motivo do cancelamento desse atendimento para que o(a) " +
				props.corpo.nome +
				" fique ciente ",
			);
		} else {
			setPlaceholderMotivo("Descreva o atendimento");
		}
	};

	const handleClick = () => {
		setOpen(!open);
	};

	const handleCancelamento = () => {
		if (cancelamento) {
			setBotao1("Finalizar Atendimento");
			setBotao2(!botao2);
		} else {
			setMotivo("");
			setBotao1("Voltar");
			setBotao2(!botao2);
			setStatusAgendamentoError(false);
			setDescricaoAgendamentoError(false);
		}
		setCancelamento(!cancelamento);
	};

	const detalhes = (assunto: any) => {
		if (assunto === "Psicopedagogia") {
			return (
				<Grid container spacing={3}>
					<Grid item md={3} xs={12}>
						<Typography
							variant='subtitle1'
							display='block'
							gutterBottom
							className='list-reset-title'
						>
							Queixa
						</Typography>
					</Grid>
					<Grid item md={9} xs={12}>
						<Typography
							variant='subtitle1'
							display='block'
							gutterBottom
							className='list-reset-subtitle'
						>
							{props.corpo.queixa ? props.corpo.queixa : "Sem detalhes"}
						</Typography>
					</Grid>

					<Grid item md={3} xs={12}>
						<Typography
							variant='subtitle1'
							display='block'
							gutterBottom
							className='list-reset-title'
						>
							Dificuldades
						</Typography>
					</Grid>
					<Grid item md={9} xs={12}>
						<Typography
							variant='subtitle2'
							display='block'
							gutterBottom
							className='list-reset-subtitle'
						>
							{props.corpo.dificuldades.length > 0
								? props.corpo.dificuldades
									.split(":")
									.map((obj: any, index: number) => {
										return (
											<Typography
												key={index}
												variant='subtitle2'
												display='block'
												gutterBottom
											>
												{obj}
											</Typography>
										);
									})
								: "Sem detalhes"}
						</Typography>
					</Grid>

					<Grid item md={3} xs={12}>
						<Typography
							variant='subtitle1'
							display='block'
							gutterBottom
							className='list-reset-title'
						>
							Diagnostico
						</Typography>
					</Grid>
					<Grid item md={9} xs={12}>
						<Typography
							variant='subtitle2'
							display='block'
							gutterBottom
							className='list-reset-subtitle'
						>
							{props.corpo.diagnostico ? props.corpo.diagnostico : "Sem detalhes"}
						</Typography>
					</Grid>

					<Grid item md={3} xs={12}>
						<Typography
							variant='subtitle1'
							display='block'
							gutterBottom
							className='list-reset-title'
						>
							Laudo
						</Typography>
					</Grid>
					<Grid item md={9} xs={12}>
						<Typography
							variant='subtitle2'
							display='block'
							gutterBottom
							className='list-reset-subtitle'
						>
							{props.corpo.laudo ? props.corpo.laudo : "Sem detalhes"}
						</Typography>
					</Grid>

					<Grid item md={3} xs={12}>
						<Typography
							variant='subtitle1'
							display='block'
							gutterBottom
							className='list-reset-title'
						>
							Medicamentos
						</Typography>
					</Grid>
					<Grid item md={9} xs={12}>
						<Typography
							variant='subtitle2'
							display='block'
							gutterBottom
							className='list-reset-subtitle'
						>
							{props.corpo.medicamento ? props.corpo.medicamento : "Sem detalhes"}
						</Typography>
					</Grid>
				</Grid>
			);
		} else if (assunto === "Esclarecimento de Dúvidas") {
			return (
				<Grid container spacing={3}>
					<Grid item xs={3}>
						<Typography
							variant='subtitle1'
							display='block'
							gutterBottom
							className='list-reset-title'
						>
							Queixa
						</Typography>
					</Grid>
					<Grid item xs={9}>
						<Typography
							variant='subtitle1'
							display='block'
							gutterBottom
							className='list-reset-subtitle'
						>
							{props.corpo.queixa ? props.corpo.queixa : "Sem detalhes"}
						</Typography>
					</Grid>
				</Grid>
			);
		}
	};

	const finalizarAtendimento = (id: number) => {
		setStatusAgendamentoError(false);
		setDescricaoAgendamentoError(false);
		if (statusAgendamento === "selecione") {
			Notify("Você precisa selecionar o status do agendamento.", "error");
			setStatusAgendamentoError(true);
		} else if (motivoValor === "") {
			Notify("Você precisa descrever um motivo.", "error");
			setDescricaoAgendamentoError(true);
		} else {
			putSubjectCancel(id, motivoValor, statusAgendamento)
				.then(() => {
					Notify("Atendimento finalizado com sucesso!", "success");
					handleCancelamento();
					props.setOpen(false);
					// props.setReload(!props.reload);
					handleClick();
					setBotao1("");
				})
				.catch((e) => {
					console.log(e);
				});
		}
	};

	const formatarData = (data: string) => {

		let dataArray: string[];
		let dataFormatoBrasileiro: string;

		dataArray = data.split("-");
		let ano = dataArray[0];

		dataArray[0] = dataArray[2];
		dataArray[2] = ano;

		dataFormatoBrasileiro = dataArray.join("/");

		return dataFormatoBrasileiro;
	}

	const exibirContatos = () => {
		const email = props.corpo.email;
		const telefone = props.corpo.telefone;

		if (email !== "" && telefone !== "") {
			return `${email} - ${telefone}`;
		} else if (email !== "" && telefone === "") {
			return `${email}`;
		} else if (email === "" && telefone !== "") {
			return `${telefone}`;
		} else {
			return "Sem informações";
		}
	}

	const exibirHorario = () => {
		const data = formatarData(props.corpo.data);
		const hora = props.corpo.horario;

		if (data !== "" && hora !== "") {
			return `${data} - ${hora}`;
		} else if (data !== "" && hora === "") {
			return `${data}`;
		} else if (data === "" && hora !== "") {
			return `${hora}`;
		} else {
			return "Sem informações";
		}
	}

	const exibirPoloCurso = () => {
		const polo = props.corpo.polo_nome;
		const curso = props.corpo.curso_nome;

		if (polo !== "" && curso !== "") {
			return `${polo} / ${curso}`;
		} else if (polo !== "" && curso === "") {
			return `${polo}`;
		} else if (polo === "" && curso !== "") {
			return `${curso}`;
		} else {
			return "Sem informações";
		}
	}

	const exibirNomeIdadeRa = () => {
		const nome = props.corpo.nome;
		const idade = props.corpo.idade;
		const ra = props.corpo.ra;

		if (nome !== "" && idade !== "0" && ra !== "") {
			return `${nome} / ${idade} - ${ra}`;
		} else if (nome !== "" && idade !== "0" && ra === "") {
			return `${nome} / ${idade}`;
		} else if (nome !== "" && idade === "0" && ra !== "") {
			return `${nome} / ${ra}`;
		} else if (nome !== "" && idade === "0" && ra === "") {
			return `${nome}`;
		} else if (nome === "" && idade !== "0" && ra !== "") {
			return `${idade} - ${ra}`;
		} else if (nome === "" && idade !== "0" && ra === "") {
			return `${idade}`;
		} else if (nome === "" && idade === "0" && ra !== "") {
			return `${ra}`;
		} else {
			return "Sem informações"
		}
	}

	React.useEffect(() => {
		setBotao1("Finalizar Atendimento");
	}, [props.open]);

	return (
		<>
			<Dialog
				onClose={() => props.setOpen(false)}
				aria-labelledby='customized-dialog-title'
				open={props.open}
				fullScreen={fullScreen}
			>
				<div>
					<div className='body'>
						<div className='head'>
							<Typography variant='body1'>Atendimento: {props.titulo} </Typography>
							<CloseIcon
								id='btnCloseAttendance'
								style={{ marginLeft: "25px", cursor: "pointer", position: "absolute", right: "10px" }}
								onClick={() => {
									props.setOpen(false);
								}}
							/>
						</div>
						<div className='content'>
							<div>
								<List component='nav' key={props.corpo.ra}>
									<Collapse
										in={cancelamento ? false : true}
										timeout='auto'
										unmountOnExit
									>
										<ListItem>
											<ListItemAvatar>
												<Avatar>
													<AccountCircleIcon />
												</Avatar>
											</ListItemAvatar>
											<ListItemText
												primary='Nome / Idade - RA'
												secondary={
													exibirNomeIdadeRa()
												}
											/>
										</ListItem>
										<ListItem>
											<ListItemAvatar>
												<Avatar>
													<AccessTime />
												</Avatar>
											</ListItemAvatar>
											<ListItemText
												primary='Horário'
												secondary={
													exibirHorario()
												}
											/>
										</ListItem>
										<ListItem>
											<ListItemAvatar>
												<Avatar>
													<QuestionAnswerIcon />
												</Avatar>
											</ListItemAvatar>
											<ListItemText
												primary='Contatos'
												secondary={
													exibirContatos()
												}
											/>
										</ListItem>
										<ListItem>
											<ListItemAvatar>
												<Avatar>
													<AccountBalanceIcon />
												</Avatar>
											</ListItemAvatar>
											<ListItemText
												primary='Polo / Curso'
												secondary={
													exibirPoloCurso()
												}
											/>
										</ListItem>

										<ListItem id='dropDownAssunto' onClick={handleClick}>
											<ListItemAvatar>
												<Avatar>
													<ContactSupportIcon />
												</Avatar>
											</ListItemAvatar>
											<ListItemText
												primary='Assunto'
												secondary={props.corpo.assunto_nome !== "" ? props.corpo.assunto_nome : ""}
											/>
											{
												(props.corpo.assunto_nome === "Psicopedagogia") || (props.corpo.assunto_nome === "Esclarecimento de Dúvidas") ?
													<Tooltip title='Detalhes'>
														{open ? <ExpandLess /> : <ExpandMore />}
													</Tooltip> :
													<></>
											}
										</ListItem>

										{
											(props.corpo.assunto_nome === "Psicopedagogia") || (props.corpo.assunto_nome === "Esclarecimento de Dúvidas") ?
												<Collapse in={open} timeout='auto' unmountOnExit>
													{detalhes(props.corpo.assunto_nome)}
												</Collapse> :
												<></>
										}

										<ListItem
										    id='linkMeet'
											onClick={() => {
												window.open(props.corpo.link);
											}}
											button
										>
											<ListItemAvatar>
												<Avatar>
													<LinkIcon />
												</Avatar>
											</ListItemAvatar>
											<ListItemText
												primary='Link'
												secondary={props.corpo.link}
												color='primary'
											/>
										</ListItem>

										{
											props.corpo.parecer !== "" ?
												<ListItem>
													<ListItemAvatar>
														<Avatar>
															<DescriptionOutlined />
														</Avatar>
													</ListItemAvatar>
													<ListItemText
														primary='Descrição'
														secondary={
															props.corpo.parecer
														}
													/>
												</ListItem>
												:
												<></>
										}

									</Collapse>

									<Collapse in={cancelamento} timeout='auto' unmountOnExit>
										<TextField
											id='status-agendamento'
											select
											label='Agendamento finalizou como'
											fullWidth
											value={statusAgendamento}
											onChange={handleChangeSelect}
											helperText='Por favor selecione o status do agendamento'
											error={statusAgendamentoError}
										>
											<MenuItem key='selecione' value='selecione' disabled>
												Selecione ↓
											</MenuItem>
											<MenuItem key='concluido' value='concluido'>
												Concluído
											</MenuItem>
											<MenuItem key='cancelado' value='cancelado'>
												Cancelado
											</MenuItem>
										</TextField>

										<TextField
											id='outlined-multiline-static'
											label='Descrição'
											placeholder={placeholderMotivo}
											multiline
											rows={15}
											fullWidth
											variant='outlined'
											value={motivoValor}
											onChange={(e) => setMotivo(e.target.value)}
											error={descricaoAgendamentoError}
										/>
									</Collapse>
								</List>
							</div>
						</div>
					</div>
				</div>
				<DialogActions>
					{props.corpo.situacao === "pendente" && (
						<Button autoFocus id='btnFinishAttendance' onClick={() => handleCancelamento()} color='primary'>
							{botao1}
						</Button>
					)}

					{botao2 && (
						<Button
							id='btnSendInformation'
							autoFocus
							onClick={() => {
								finalizarAtendimento(props.corpo.id);
							}}
							color='secondary'
						>
							Enviar informações
						</Button>
					)}
				</DialogActions>
			</Dialog>
		</>
	);
}
