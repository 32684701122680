import React from "react";

import DialogQuestionsAnswers from "../DialogQuestionsAnswers";
import DialogReviewQuestion from "pages/PedagogicalCommunication/components/DialogReviewQuestion";
import DialogSchuduleTime from "../DialogScheduleTime";
import CardNavigate from "../CardNavegate";
// import DialogIsis from "../DialogIsis";

import Forum from "@material-ui/icons/Forum";
import VoiceChat from "@material-ui/icons/VoiceChat";
import ListAlt from "@material-ui/icons/ListAlt";
import DialogQuestionsAnswersTutor from "../DialogQuestionsAnswersTutor";

export default function CardsMenuStudent() {
	const [openReviewQuestion, setOpenReviewQuestion] = React.useState(false);
	const [openScheduleTime, setOpenScheduleTime] = React.useState(false);
	// const [openIsis, setOpenIsis] = React.useState(false);
	const [openTutor, setOpenTutor] = React.useState(false);
	const [openQA, setOpenQA] = React.useState(false);

	return (
		<>
			{/* Card com CTA para Perguntar e responder */}
			<CardNavigate
				id='btnSpeakTeacher'
				imageCard='/perguntas-respostas.png'
				titleCard='Dúvidas de conteúdo'
				iconStartButton={<Forum style={{ fontSize: 32 }} />}
				titleButton='Falar com o professor'
				actionButton={() => {
					setOpenQA(true);
				}}
			/>

			{/* Card com CTA para abrir ISIS */}
			<CardNavigate
				id='btnSpeakTutor'
				imageCard='/isis.png'
				titleCard='Dúvidas acadêmicas'
				iconStartButton={<ListAlt style={{ fontSize: 32 }} />}
				titleButton='Falar com Tutor'
				actionButton={() => {
					setOpenTutor(true);
				}}
			/>
			{/* Card com CTA para Agendamento com o tutor */}
			<CardNavigate
				id='btnScheduleTutorate'
				imageCard='/agenda-horario.png'
				titleCard='Suporte à aprendizagem'
				iconStartButton={<VoiceChat style={{ fontSize: 32 }} />}
				titleButton='Agendar Mentoria'
				actionButton={() => {
					setOpenScheduleTime(true);
				}}
			/>

			{/* Card com CTA para Revisão de questões */}
			<CardNavigate
				id='btnSolicitationReview'
				imageCard='/revisao-questao.jpg'
				titleCard='Revisão de avaliações'
				iconStartButton={<ListAlt style={{ fontSize: 32 }} />}
				titleButton='Solicitação de revisão'
				actionButton={() => {
					setOpenReviewQuestion(true);
				}}
			/>

			{/* Dialog Perguntar e Responder */}
			<DialogQuestionsAnswers open={openQA} setOpen={setOpenQA} />
			{/* Dialog CTA para abrir ISIS */}
			<DialogQuestionsAnswersTutor open={openTutor} setOpen={setOpenTutor} />
			{/* <DialogIsis open={openIsis} setOpen={setOpenIsis} /> */}
			{/* Dialog do Calendário */}
			<DialogSchuduleTime open={openScheduleTime} setOpen={setOpenScheduleTime} />
			{/* Dialog da revisão de questões */}
			<DialogReviewQuestion open={openReviewQuestion} setOpen={setOpenReviewQuestion} />
		</>
	);
}
