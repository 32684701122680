import { useTheme } from "@material-ui/core";
import { AxiosResponse } from "axios";
import { useAuth } from "context/Auth/hook";
import {
	GamificacaoContextType, Ranking, RankingAluno, RankingUsuarioLogado, ScorePorLabel,
} from "models/gamificacao";
import { useContext, createContext, useState, useEffect } from "react";
import { getRanking, getRankingUsuarioLogado, getScoreAluno, getScorePorLabelAluno } from "services/Gamificacao";
import { calcularScoreComunicacaoPedagogica, calcularScoreEcossistema, calcularScoreMaterialDidatico } from "utils/Gamificacao/calculoScoresPorModulo";

const INI_VALUES: GamificacaoContextType = {
	loadingPontuacao: true,
	coresLinks: ["#000000", "#000000", "#000000"],
	openDialogGamificacao: false,
	score: {
		ra: "",
		scores: {
			ALUNO_ENVIA_DUVIDA_CONTEUDO: 0,
			ALUNO_RESPONDE_DUVIDA_CONTEUDO: 0,
			USUARIO_AVALIA_UNIDADES_DE_ESTUDO: 0,
			ALUNO_SOLICITA_AGENDAMENTO_DEFER_INDEFER: 0,
			ALUNO_AVALIA_RESPOSTA_DUVIDA_CONTEUDO: 0,
			USUARIO_AVALIA_CONTRIB_COM_DOCUMENTO: 0,
			USUARIO_CONTRIBUA_COM_DOCUMENTO: 0,
			USUARIO_ACESSA_RECURSO_APRENDZ: 0,
			USUARIO_REALIZA_COMENTARIO: 0,
			USUARIO_RESPONDE_PESQUISA_AVA_QUALIDADE_MAT_DIDAT: 0,
			ALUNO_COMENTA_RESPOSTA_DUVIDA_CONTEUDO: 0,
			USUARIO_ACESSA_RECURSO_APROF_CONHECIMENTO: 0,
			USUARIO_CONCLUIU_TUTORIAL: 0,
			USUARIO_EDIT_SAVE_BLOCO_DE_NOTAS: 0
		},
	},
	pontuacaoMaximaPorLabel: [1, 6, 4, 3, 15, 32, 3, 4, 16, 4, 3, 4, 5, 1],
	labelsScore: [
		"USUARIO_CONCLUIU_TUTORIAL",
		"USUARIO_CONTRIBUA_COM_DOCUMENTO",
		"USUARIO_REALIZA_COMENTARIO",
		"USUARIO_AVALIA_CONTRIB_COM_DOCUMENTO",
		"USUARIO_ACESSA_RECURSO_APRENDZ",
		"USUARIO_EDIT_SAVE_BLOCO_DE_NOTAS",
		"USUARIO_ACESSA_RECURSO_APROF_CONHECIMENTO",
		"USUARIO_RESPONDE_PESQUISA_AVA_QUALIDADE_MAT_DIDAT",
		"USUARIO_AVALIA_UNIDADES_DE_ESTUDO",
		"ALUNO_ENVIA_DUVIDA_CONTEUDO",
		"ALUNO_RESPONDE_DUVIDA_CONTEUDO",
		"ALUNO_COMENTA_RESPOSTA_DUVIDA_CONTEUDO",
		"ALUNO_AVALIA_RESPOSTA_DUVIDA_CONTEUDO",
		"ALUNO_SOLICITA_AGENDAMENTO_DEFER_INDEFER",
	],
	scoreTotalPontuacao: 0,
	scoreTotalDesafios: 0,
	switchContent: 0,
	switchRankingType: 0,
	exibirLinhaExtra: false,
	ranking: {
		data: [{
			ra: "",
			curso: "",
			polo: "",
			periodo: 0,
			total: 0,
		}],
		meta: {
			total: 0,
			pages: 0,
		}
	},
	rankingUsuarioLogado: {
		ra: "",
		curso: "",
		polo: "",
		periodo: 0,
		total: 0,
		rank_geral: "",
		rank_curso: "",
		rank_polo: "",
	},
	setScore: () => { },
	setRanking: () => { },
	setCoresLinks: () => { },
	setLabelsScore: () => { },
	setSwitchContent: () => { },
	setExibirLinhaExtra: () => { },
	setLoadingPontuacao: () => { },
	setSwitchRankingType: () => { },
	setScoreTotalDesafios: () => { },
	setScoreTotalPontuacao: () => { },
	setRankingUsuarioLogado: () => { },
	setOpenDialogGamificacao: () => { },
	setPontuacaoMaximaPorLabel: () => { },
};

const GamificacaoContext =
	createContext<GamificacaoContextType>(INI_VALUES);

export const GamificacaoProvider: React.FC = ({ children }) => {
	const theme = useTheme();
	const { user } = useAuth();
	const [score, setScore] = useState(INI_VALUES.score);
	const [ranking, setRanking] = useState<Ranking>(INI_VALUES.ranking);
	const [coresLinks, setCoresLinks] = useState(INI_VALUES.coresLinks);
	const [labelsScore, setLabelsScore] = useState(INI_VALUES.labelsScore);
	const [switchContent, setSwitchContent] = useState<number>(INI_VALUES.switchContent);
	const [loadingPontuacao, setLoadingPontuacao] = useState(INI_VALUES.loadingPontuacao);
	const [exibirLinhaExtra, setExibirLinhaExtra] = useState(INI_VALUES.exibirLinhaExtra);
	const [switchRankingType, setSwitchRankingType] = useState<number>(INI_VALUES.switchRankingType);
	const [scoreTotalDesafios, setScoreTotalDesafios] = useState<number>(INI_VALUES.scoreTotalDesafios);
	const [scoreTotalPontuacao, setScoreTotalPontuacao] = useState<number>(INI_VALUES.scoreTotalPontuacao);
	const [pontuacaoMaximaPorLabel, setPontuacaoMaximaPorLabel] = useState(INI_VALUES.pontuacaoMaximaPorLabel);
	const [openDialogGamificacao, setOpenDialogGamificacao] = useState<boolean>(INI_VALUES.openDialogGamificacao);
	const [rankingUsuarioLogado, setRankingUsuarioLogado] = useState<RankingUsuarioLogado>(INI_VALUES.rankingUsuarioLogado);

	//Pontuação
	useEffect(() => {
		if (openDialogGamificacao === true) {
			setSwitchContent(0);
		}
	}, [openDialogGamificacao, setSwitchContent]);

	useEffect(() => {
		if (user.ra && (openDialogGamificacao === true)) {
			getScoreAluno(user.ra)
				.then(({ data }) => {
					setScore(data);
					setLoadingPontuacao(false);
					setCoresLinks([`${theme.palette.primary.main}`, "#000000", "#000000"]);
				})
				.catch((error) => {
					console.log(error);
				});
		}
	}, [user.ra, openDialogGamificacao, theme.palette.primary.main, setCoresLinks]);

	//Calculo da pontuação total
	useEffect(() => {

		if (score && (openDialogGamificacao === true)) {
			setScoreTotalPontuacao(calcularScoreComunicacaoPedagogica(score) + calcularScoreEcossistema(score) + calcularScoreMaterialDidatico(score));
		}

	}, [score, openDialogGamificacao]);

	//Cálculo da quantidade de desafios concluídos
	useEffect(() => {
		if (user.ra && (openDialogGamificacao === true)) {
			let somaPontuacaoDesafios = 0;
			let pontuacaoMaximaPorLabel = [1, 6, 4, 3, 15, 32, 3, 4, 16, 4, 3, 4, 5, 1];
			let arrayPromisesScorePorLabel: Promise<AxiosResponse<ScorePorLabel>>[] = [];
			let labelsGamificacao = [
				"USUARIO_CONCLUIU_TUTORIAL",
				"USUARIO_CONTRIBUA_COM_DOCUMENTO",
				"USUARIO_REALIZA_COMENTARIO",
				"USUARIO_AVALIA_CONTRIB_COM_DOCUMENTO",
				"USUARIO_ACESSA_RECURSO_APRENDZ",
				"USUARIO_EDIT_SAVE_BLOCO_DE_NOTAS",
				"USUARIO_ACESSA_RECURSO_APROF_CONHECIMENTO",
				"USUARIO_RESPONDE_PESQUISA_AVA_QUALIDADE_MAT_DIDAT",
				"USUARIO_AVALIA_UNIDADES_DE_ESTUDO",
				"ALUNO_ENVIA_DUVIDA_CONTEUDO",
				"ALUNO_RESPONDE_DUVIDA_CONTEUDO",
				"ALUNO_COMENTA_RESPOSTA_DUVIDA_CONTEUDO",
				"ALUNO_AVALIA_RESPOSTA_DUVIDA_CONTEUDO",
				"ALUNO_SOLICITA_AGENDAMENTO_DEFER_INDEFER",
			];

			for (let i = 0; i < labelsGamificacao.length; i++) {
				arrayPromisesScorePorLabel.push(getScorePorLabelAluno(user.ra, labelsGamificacao[i]));
			}

			Promise.all(arrayPromisesScorePorLabel)
				.then((promisesResults) => {
					for (let i = 0; i < promisesResults.length; i++) {
						if ((pontuacaoMaximaPorLabel[i] - promisesResults[i].data.logs_count) <= 0) {
							somaPontuacaoDesafios += 1;
						}
					}

					setScoreTotalDesafios(somaPontuacaoDesafios);
				})
		}
	}, [user.ra, openDialogGamificacao]);


	//Ranking
	const efetuarExibicaoExtra = (rankingUserLogado: RankingAluno[]) => {

		if (rankingUserLogado.length === 0) {
			setExibirLinhaExtra(true);
		} else {
			setExibirLinhaExtra(false);
		}

	}

	useEffect(() => {
		if (user.ra && user.typeUser) {
			if (user.typeUser !== "tutor") {

				getRankingUsuarioLogado(user.ra)
					.then(({ data }) => {
						if (user.ra) {
							setRankingUsuarioLogado(data);
						}
					})
					.catch((e) => {
						console.log(e);
					});

				getRanking()
					.then(({ data }) => {
						if (user.ra) {
							let rankingUserLogado = data.data.filter((value) => { return value.ra === user.ra });

							efetuarExibicaoExtra(rankingUserLogado);

							setRanking(data);
						}
					})
					.catch((e) => {
						console.log(e);
					});

			}
		}
	}, [user.ra, user.typeUser]);

	useEffect(() => {

		if (switchRankingType === 0) {

			if (user.ra) {
				getRanking()
					.then(({ data }) => {
						setRanking(data);

						let rankingUserLogado = data.data.filter((value) => { return value.ra === user.ra });

						efetuarExibicaoExtra(rankingUserLogado);

					})
					.catch((e) => {
						console.log(e);
					});
			}

		} else if (switchRankingType === 1) {

			if (rankingUsuarioLogado.polo) {
				getRanking("polo", rankingUsuarioLogado.polo)
					.then(({ data }) => {
						setRanking(data);

						let rankingUserLogado = data.data.filter((value) => { return value.ra === user.ra });

						efetuarExibicaoExtra(rankingUserLogado);
					})
					.catch((e) => {
						console.log(e);
					});
			}

		} else {

			if (rankingUsuarioLogado.curso) {
				getRanking("curso", rankingUsuarioLogado.curso)
					.then(({ data }) => {
						setRanking(data);

						let rankingUserLogado = data.data.filter((value) => { return value.ra === user.ra });

						efetuarExibicaoExtra(rankingUserLogado);
					})
					.catch((e) => {
						console.log(e);
					});
			}
		}

	}, [switchRankingType, rankingUsuarioLogado.curso, rankingUsuarioLogado.polo, user.ra]);

	return (
		<GamificacaoContext.Provider
			value={{
				score,
				ranking,
				coresLinks,
				labelsScore,
				switchContent,
				loadingPontuacao,
				exibirLinhaExtra,
				switchRankingType,
				scoreTotalDesafios,
				scoreTotalPontuacao,
				rankingUsuarioLogado,
				openDialogGamificacao,
				pontuacaoMaximaPorLabel,
				setScore,
				setRanking,
				setCoresLinks,
				setLabelsScore,
				setSwitchContent,
				setLoadingPontuacao,
				setExibirLinhaExtra,
				setSwitchRankingType,
				setScoreTotalDesafios,
				setScoreTotalPontuacao,
				setRankingUsuarioLogado,
				setOpenDialogGamificacao,
				setPontuacaoMaximaPorLabel,
			}}
		>
			{children}
		</GamificacaoContext.Provider>
	);
};

export function useGamificacao(): GamificacaoContextType {
	const context = useContext(GamificacaoContext);
	return context;
}
