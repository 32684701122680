import React from "react";
import { Paper, Box, Typography, Button, useTheme, Popper } from "@material-ui/core";
import { Public } from "@material-ui/icons";
import "./index.scss";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import DisciplinaMoodle from "components/Dialogs/Moodle/Disciplina";
import { getMoodleUrl } from "context/Auth/services";
import { ecossistema } from "services/api";
import { useAuth } from "context/Auth/hook";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		popover: {
			pointerEvents: "none",
		},
		paper: {
			padding: theme.spacing(1),
		},
	}),
);

/**
 *
 * @param curso
 * @returns ReactElement com informações do curso e link de acesso ao curso no AVA
 */
export default function SalasVirtuais(sala: {
	id: number | string;
	nome: string | undefined;
    url: string | undefined;
    idnumber: string | undefined;
}): React.ReactElement {
	const theme = useTheme();
	const classes = useStyles();
    const { user, setSignedInVirtual } = useAuth();

    const [openEvento, setOpenEvento] = React.useState<boolean>(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [remoteURL, setRemoteURL] = React.useState<string|null>(null);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
	const open = Boolean(anchorEl);

	// const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
	// 	setAnchorEl(event.currentTarget);
	// };

	// const handlePopoverClose = () => {
	// 	setAnchorEl(null);
	// };

	function retirarDoDaNomeEventos(nomeEvento: string | undefined): string {
		if (typeof nomeEvento !== "undefined") {
			nomeEvento = nomeEvento?.replace(/da/, "");
			nomeEvento = nomeEvento?.replace(/do/, "");
			return nomeEvento;
		} else {
			return "";
		}
	}

	function exibirCaminhoImagem(nomeEvento: string | undefined): string {
		if (nomeEvento?.search(/Oficina Virtual/i) !== -1) {
			return "https://dirin.s3.amazonaws.com/ecossistema_v5/assets/disciplinas+novo+default+-+eventos+virtuais.png";
		} else if (nomeEvento?.search(/Navegue no novo/i) !== -1) {
			return "https://dirin.s3.amazonaws.com/ecossistema_v5/assets/disciplinas+novo+default+-+eventos+virtuais.png";
		} else if (nomeEvento?.search(/Evento de Extensão/i) !== -1) {
			return "https://dirin.s3.amazonaws.com/ecossistema_v5/assets/disciplinas+novo+default+-+eventos+virtuais.png";
		} else if (nomeEvento?.search(/Laboratório/i) !== -1) {
			return "https://dirin.s3.amazonaws.com/ecossistema_v5/assets/disciplinas+novo+default+-+laboratorios.png";
		} else {
			return "https://dirin.s3.amazonaws.com/ecossistema_v5/assets/disciplinas+novo+default+-+eventos+virtuais.png";
		}
	}

	function exibirTipoEvento(nomeEvento: string | undefined): string {
		if (nomeEvento?.search(/Oficina Virtual/i) !== -1) {
			return "OFICINA VIRTUAL";
		} else if (nomeEvento?.search(/Momento Síncrono|Projeto Navegue no novo/i) !== -1) {
			return "MOMENTO SÍNCRONO";
		} else if (nomeEvento?.search(/Evento de Extensão/i) !== -1) {
			return "EVENTO DE EXTENSÃO";
		} else if (nomeEvento?.search(/Laboratório/i) !== -1) {
			return "LABORATÓRIO VIRTUAL";
		} else if (nomeEvento?.search(/Curso Livre/i) !== -1) {
			return "CURSO LIVRE";
		} else {
			return "EVENTO ";
		}
	}

	return (
		<>
			<Paper
				style={{
					height: "372px",
					minWidth: "256px",
					maxWidth: "256px",
					margin: "10px",
					borderRadius: "12px",
					overflow: "hidden",
					border: "1px solid #e0e0e0",
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-between",
				}}
			>
				<Box>
					<img
						width='100%'
						height='64px'
						src={exibirCaminhoImagem(sala.nome)}
						alt='capa_eventos'
					/>
					<Box p={3}>
						<Typography
							variant='body1'
							color='primary'
							style={{
								textAlign: "center",
								fontWeight: "bold",
							}}
						>
                            <Typography 
                                variant="body1"
                                style={{
                                    color: theme.palette.primary.main,
                                    fontWeight: "bold"
                                }}>
                                {sala.idnumber}
                            </Typography>
							<Popper
								id='mouse-over-popover'
								className={classes.popover}
								open={open}
								anchorEl={anchorEl}
								placement='bottom-start'
							>
								<Paper elevation={6}>
									<Box p={1}>
										<Typography variant={"body1"}>
											{exibirTipoEvento(sala.nome) !== "EVENTO "
												? exibirTipoEvento(sala.nome)
												: exibirTipoEvento(sala.nome)}
										</Typography>
									</Box>
								</Paper>
							</Popper>
						</Typography>
						<Typography
						    className="texto-card-sala-virtual"
							variant='body1'
							style={{
								marginTop: "12px",
								fontSize: ".9em",
								minHeight: "108px",
								maxHeight: "108px"
							}}
						>
							{retirarDoDaNomeEventos(sala.nome)}
						</Typography>
					</Box>
				</Box>
				<Button
					style={{
						margin: "12px",
					}}
					onClick={async() => {
                        // setRemoteURL(`//virtual.uninta.edu.br/course/view.php?id=${curso.id}`)
                        // setOpenEvento(true);

                        await getMoodleUrl(user.ra, ecossistema)
                            .then(async ({ data }: any) => {
                                window.open(`${data}&wantsurl=https://virtual.uninta.edu.br/course/view.php?id=${sala.id}`, "_blank",);
                            })
                            .catch((err) => {
                                setSignedInVirtual(false);
                            })
						// window.open(
						// 	`//virtual.uninta.edu.br/course/view.php?id=${curso.id}`,
						// 	"_blank",
						// );
					}}
				>
					<Public style={{ marginRight: "12px" }} /> Acessar
				</Button>
			</Paper>
            <DisciplinaMoodle open={openEvento} setOpen={setOpenEvento} url={remoteURL}/>
		</>
	);
}
