import React from "react";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Switch from "@material-ui/core/Switch";

import { DatePicker } from "@material-ui/pickers";
import { Typography } from "@material-ui/core";

import { getTimesAvailable } from "../scheduletime.services";
import { FormPsychopedagogyProps } from "./formpsychopedagogy.model";

import { phone } from "utils/masks";

import { useAuth } from "context/Auth/hook";

import "./styles.scss";

export default function FormPsychopedagogy(props: FormPsychopedagogyProps): React.ReactElement {
	const { nivelDeEnsino } = useAuth();

	const [isComputer, setIsComputer] = React.useState(true);

	const [hoursOnline, setHoursOnline] = React.useState([]);

	const [isCheckedReport, setIsCheckedReport] = React.useState(false);
	const [isCheckedMedicine, setIsCheckedMedicine] = React.useState(false);
	const [isCheckedDiagnosis, setIsCheckedDiagnosis] = React.useState(false);

	const handleHoursOnline = (typeHandle: "subject" | "date", value: unknown) => {
		let day = props.data.data.getDay();

		if (typeHandle === "date") {
			props.setData("data", value as Date);
			day = new Date(value as Date).getDay();

			if (((day === 0) || (day === 6))) {
				props.setData("horario", "");
			}
		}

		if (day !== 0 && day !== 6) {
			getTimesAvailable(1, day)
				.then((data) => {
					setHoursOnline(data.data);
				})
				.catch((e) => {
					console.log(e);
				});
		} else {
			setHoursOnline([]);
		}

	};

	const handleCheckboxDif = (position: string, value: boolean) => {
		let getDiff = props.data.dificuldades[0];
		getDiff = { ...getDiff, [position]: { ...getDiff[position], check: value } };

		props.setData("dificuldades", [getDiff]);
	};

	// Receber o tamanho da tela
	React.useEffect(() => {
		const largura =
			window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

		if (largura < 840) {
			setIsComputer(false);
		}
	}, []);

	return (
		<>
			<Grid item xs={4} md={2}>
				<TextField
					variant='outlined'
					label='Idade:'
					fullWidth
					required
					id='idade'
					value={props.data.idade}
					onChange={(e) => {
						if ((e.target.value as any) >= 0) {
							props.setData("idade", e.target.value);
						}
					}}
					inputProps={{ minlength: 2, maxlength: 2 }}
				/>
			</Grid>

			<Grid item xs={8} md={4}>
				<TextField
					variant='outlined'
					label='Telefone:'
					fullWidth
					required
					id='telefone'
					onChange={(e) => {
						props.setData("telefone", phone(e).replace(/\D/g, ""));
					}}
					inputProps={{ minlength: 15, maxlength: 15 }}
				/>
			</Grid>

			<Box className='divider_title'>Detalhes</Box>

			<Grid item xs={12} md={6}>
				<fieldset
					className={`area_outlined ${nivelDeEnsino.nome_nivel_ensino === "FACULDADE ALENCARINA" ||
						nivelDeEnsino.nome_nivel_ensino === "FACULDADE 5 DE JULHO"
						? "focus_f_five"
						: ""
						}`}
				>
					<legend>Fez ou faz uso de algum medicamento?</legend>

					<Box className='area_switch'>
						<Switch
							id="switchAlgumMedicamento"
							checked={isCheckedMedicine}
							onChange={() => setIsCheckedMedicine(!isCheckedMedicine)}
							color='primary'
						/>
						<Typography variant='subtitle2'>
							{isCheckedMedicine ? "Sim" : "Não"}
						</Typography>
					</Box>

					{isCheckedMedicine && (
						<TextField
							id="algumMedicamento"
							variant='outlined'
							fullWidth
							label='Qual?'
							required
							value={props.data.medicamento}
							onChange={(e) => props.setData("medicamento", e.target.value)}
						/>
					)}
				</fieldset>

				<fieldset
					className={`area_outlined ${nivelDeEnsino.nome_nivel_ensino === "FACULDADE ALENCARINA" ||
						nivelDeEnsino.nome_nivel_ensino === "FACULDADE 5 DE JULHO"
						? "focus_f_five"
						: ""
						}`}
				>
					<legend>Já fez algum diagnóstico e acompanhamento anterior?</legend>

					<Box className='area_switch'>
						<Switch
							id="switchDiagnostico"
							checked={isCheckedDiagnosis}
							onChange={() => setIsCheckedDiagnosis(!isCheckedDiagnosis)}
							color='primary'
						/>
						<Typography variant='subtitle2'>
							{isCheckedDiagnosis ? "Sim" : "Não"}
						</Typography>
					</Box>

					{isCheckedDiagnosis && (
						<TextField
							id="diagnostico"
							variant='outlined'
							fullWidth
							label='Qual?'
							required
							value={props.data.diagnostico}
							onChange={(e) => props.setData("diagnostico", e.target.value)}
						/>
					)}
				</fieldset>

				<fieldset
					className={`area_outlined ${nivelDeEnsino.nome_nivel_ensino === "FACULDADE ALENCARINA" ||
						nivelDeEnsino.nome_nivel_ensino === "FACULDADE 5 DE JULHO"
						? "focus_f_five"
						: ""
						}`}
				>
					<legend>Possui laudo de transtorno de aprendizagem?</legend>

					<Box className='area_switch'>
						<Switch
							id="switchTranstorno"
							checked={isCheckedReport}
							onChange={() => setIsCheckedReport(!isCheckedReport)}
							color='primary'
						/>
						<Typography variant='subtitle2'>
							{isCheckedReport ? "Sim" : "Não"}
						</Typography>
					</Box>

					{isCheckedReport && (
						<TextField
							id="transtorno"
							variant='outlined'
							fullWidth
							label='Qual?'
							required
							value={props.data.laudo}
							onChange={(e) => props.setData("laudo", e.target.value)}
						/>
					)}
				</fieldset>
			</Grid>

			<Grid item xs={12} md={6}>
				<fieldset
					className={`area_outlined ${nivelDeEnsino.nome_nivel_ensino === "FACULDADE ALENCARINA" ||
						nivelDeEnsino.nome_nivel_ensino === "FACULDADE 5 DE JULHO"
						? "focus_f_five"
						: ""
						}`}
				>
					<legend>Possui dificuldades de aprendizagem?</legend>

					<FormControl component='fieldset' color='primary'>
						<FormGroup>
							<FormControlLabel
								control={
									<Checkbox
										checked={props.data.dificuldades[0].concentracao.check}
										onChange={() =>
											handleCheckboxDif(
												"concentracao",
												!props.data.dificuldades[0].concentracao.check,
											)
										}
										color='primary'
									/>
								}
								label='Concentração'
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={props.data.dificuldades[0].assimilacao.check}
										onChange={() =>
											handleCheckboxDif(
												"assimilacao",
												!props.data.dificuldades[0].assimilacao.check,
											)
										}
										color='primary'
									/>
								}
								label='Assimilação'
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={props.data.dificuldades[0].memoria.check}
										onChange={() =>
											handleCheckboxDif(
												"memoria",
												!props.data.dificuldades[0].memoria.check,
											)
										}
										color='primary'
									/>
								}
								label='Memória'
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={props.data.dificuldades[0].interpretacao.check}
										onChange={() =>
											handleCheckboxDif(
												"interpretacao",
												!props.data.dificuldades[0].interpretacao.check,
											)
										}
										color='primary'
									/>
								}
								label='Interpretação'
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={props.data.dificuldades[0].interacao.check}
										onChange={() =>
											handleCheckboxDif(
												"interacao",
												!props.data.dificuldades[0].interacao.check,
											)
										}
										color='primary'
									/>
								}
								label='Interação'
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={props.data.dificuldades[0].rendimento.check}
										onChange={() =>
											handleCheckboxDif(
												"rendimento",
												!props.data.dificuldades[0].rendimento.check,
											)
										}
										color='primary'
									/>
								}
								label='Baixo rendimento escolar'
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={props.data.dificuldades[0].raciocinio.check}
										onChange={() =>
											handleCheckboxDif(
												"raciocinio",
												!props.data.dificuldades[0].raciocinio.check,
											)
										}
										color='primary'
									/>
								}
								label='Raciocínio lógico matemático'
							/>
						</FormGroup>
					</FormControl>
				</fieldset>
			</Grid>

			<Box className='divider_title'>Agendamento</Box>

			<Grid item xs={12} md={6}>
				<Box
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						background: "#fff",
						borderRadius: 4,
						border: "1px solid #dbdbdb",
						padding: 4,
						width: "100%",
						overflowX: "scroll"
					}}
				>
					<DatePicker
						autoOk
						orientation={isComputer ? "landscape" : "portrait"}
						variant='static'
						value={props.data.data}
						onChange={(e) => handleHoursOnline("date", e)}
						disablePast
						minDate={new Date(new Date().getTime() + 1 * 24 * 60 * 60 * 1000)}
						fullWidth
					/>
				</Box>
			</Grid>

			<Grid item xs={12} md={6}>
				<Box marginBottom={1}>
					<FormControl variant='outlined' fullWidth required color='primary'>
						<InputLabel>
							Horários {hoursOnline.length > 0 ? "Disponíveis" : "Indisponíveis"}:
						</InputLabel>
						<Select
							id="horarios"
							label='Horários Indisponíveis: *'
							value={props.data.horario}
							onChange={(e) => props.setData("horario", e.target.value as string)}
							disabled={hoursOnline.length <= 0}
						>
							{hoursOnline.map((hour: any) => {
								let getHour = hour.horario.split(":");
								getHour = `${getHour[0]}:${getHour[1]}`;

								return (
									<MenuItem key={hour?.id} value={`${hour?.id}T${hour?.horario}`}>
										{getHour}
									</MenuItem>
								);
							})}
						</Select>
					</FormControl>
				</Box>

				<TextField
				    id="queixa"
					variant='outlined'
					label='Descreva sua queixa (o que lhe incomoda? O que não vai bem com sua aprendizagem? O que precisa melhorar?):'
					fullWidth
					required
					multiline
					minRows={5}
					maxRows={5}
					value={props.data.queixa}
					onChange={(e) => props.setData("queixa", e.target.value)}
				/>
			</Grid>
		</>
	);
}
