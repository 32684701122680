import React from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Hidden from "@material-ui/core/Hidden";

import Header from "./Header";
import HeaderMobile from "./HeaderMobile";
import Questions from "./Questions";
import Response from "./Response";
import Indicador from "./Indicador";

import "./styles.scss";
import { useAuth } from "context/Auth/hook";
import Message from "./Message";

interface DialogQuestionsAnswersProps { open: boolean; setOpen: (e: boolean) => void; }

export default function DialogQuestionsAnswersTutor(props: DialogQuestionsAnswersProps,): React.ReactElement {
	const [componentOpen, setComponentOpen] = React.useState<"question" | "response" | "indicador" | "message">(
		"question",
	);// Gerencia o status de abertura do componente de Dialog de "Perguntar e Responder"
	const { user } = useAuth();

	// Inicializa o componente com a aba de "Perguntas" selecionada
	React.useEffect(() => {
		if (props.open) {
			if (user.typeUser === "tutor") {
				setComponentOpen("response");
				return
			} else {
				setComponentOpen("question");
			}
		}
	}, [props.open, user.typeUser]);

	return (
		<Dialog
			open={props.open}
			onClose={() => { props.setOpen(false); }}
			fullScreen
		>
			<Hidden mdDown>
				{/* Header do Dialog de Perguntar e Responder */}
				<Header
					setOpen={props.setOpen}
					componentOpen={componentOpen}
					setComponentOpen={setComponentOpen}
				/>
			</Hidden>

			<Hidden lgUp>
				{/* Header do Dialog de Perguntar e Responder */}
				<HeaderMobile
					setOpen={props.setOpen}
					componentOpen={componentOpen}
					setComponentOpen={setComponentOpen}
				/>
			</Hidden>

			<DialogContent className='area_list_questions'>
				{componentOpen === "question" ? (
					<Questions />
				) : componentOpen === "response" ? (
					<Response />
				) : componentOpen === "indicador" ? (
					<div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
						<Indicador />
					</div>
				) : (
					<Message />
				)}
			</DialogContent>
		</Dialog>
	);
}
