import { centralPedagogica } from "services/api";

import Notify from "utils/Notification";

import { StoreScheduleTimeProps } from "./scheduletime.model";

export async function getSchedules(situacao = "") {
	return await centralPedagogica
		.get(`aluno/agendamentos?situacao=${situacao}`, { headers: { "Accept": "application/json" } })
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

export async function getScheduleAluno(id: string) {
	return await centralPedagogica
		.get(`aluno/agendamentos/${id}`, { headers: { "Accept": "application/json" } })
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

export async function getSubjects() {
	return await centralPedagogica
		.get("aluno/assuntos", { headers: { "Accept": "application/json" } })
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

export async function getTimesAvailable(subject: number, dayWeek: number) {
	if (!subject) {
		Notify("Selecione um assunto", "warning");
		return;
	}

	if (dayWeek < 0 || dayWeek > 6) {
		Notify("Dia informado é inválido", "warning");
		return;
	}

	return await centralPedagogica
		.get(`aluno/horarios?assunto_id=${subject}&dia=${dayWeek}`, { headers: { "Accept": "application/json" } })
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

export async function storeScheduleEducationMentoring(props: StoreScheduleTimeProps) {
	const cursoId = props.curso.split(":")[0];
	const horarioId = props.horario.split("T")[0];
	const data = props.data.toISOString().split("T")[0];
	
	let difficulties = "";

	const getDifficulties = props.dificuldades[0];

	// eslint-disable-next-line array-callback-return
	Object.keys(getDifficulties).map((dif) => {
		if (getDifficulties[dif].check) {
			difficulties += `${getDifficulties[dif].name}:`;
		}
	});

	return await centralPedagogica
		.post("aluno/agendamentos", {
			polo_id: props.polo_id.toString(),
			curso_id: cursoId,
			assunto_id: props.assunto,
			data: data,
			horario_id: horarioId,
			dificuldades: difficulties ? difficulties : null,
			idade: Number(props.idade) ? props.idade : 0,
			telefone: Number(props.telefone) ? props.telefone : null,
			medicamento: props.medicamento !== "" ? props.medicamento : null,
			diagnostico: props.diagnostico !== "" ? props.diagnostico : null,
			laudo: props.laudo !== "" ? props.laudo : null,
			queixa: props.queixa !== "" ? props.queixa : null,
		}, { headers: { "Content-Type": "application/json" } })
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

export async function deleteScheduleAluno(id: number | undefined) {
	return await centralPedagogica
		.delete(`aluno/agendamentos/${id}`, { headers: { "Accept": "application/json" } })
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}
