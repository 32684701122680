import {
	Disciplina,
	TabelaDisciplinasContextType,
} from "models/tabelaDisciplinas";
import { useContext, createContext, useState, useEffect } from "react";
import getHistoricoAluno from "services/Historico";
import { useAuth } from "../Auth/hook";

const INI_VALUES: TabelaDisciplinasContextType = {
	codigoCurso: "",
	enableCollapse: false,
	enableDialogRecursos: false,
	openGeral: undefined,
	exibirTabelaDisciplinas: false,
	conteudoNaoDisponivelDisciplinas: false,
	exibirConteudoNaoDisponivelDisciplinas: false,
	exibirDisciplinas: false,
	disciplina: {
		id: 0,
		nome: "",
		capa: "",
		link_partilhando_ideias: "",
		curso_id: 0,
		orientacoes_avaliacao: "",
	},
	disciplinas: [{
		id: 0,
		nome: "",
		capa: "",
		link_partilhando_ideias: "",
		curso_id: 0,
		orientacoes_avaliacao: "",
	}],
	setOpenGeral: () => {},
	setEnableCollapse: () => {},
	setEnableDialogRecursos: () => {},
	setExibirDisciplinas: () => {},
	setCodigoCurso: () => {},
	setExibirTabelaDisciplinas: () => {},
	setConteudoNaoDisponivelDisciplinas: () => {},
	setExibirConteudoNaoDisponivelDisciplinas: () => {},
	setDisciplina: () => {},
	setDisciplinas: () => {},
};

const TabelaDisciplinasContext =
	createContext<TabelaDisciplinasContextType>(INI_VALUES);

export const TabelaDisciplinaProvider: React.FC = ({ children }) => {
	const { user, cursos } = useAuth();
	const [codigoCurso, setCodigoCurso] = useState<string>("");
	const [disciplinas, setDisciplinas] = useState<any[]>(INI_VALUES.disciplinas);
	const [disciplina, setDisciplina] = useState<Disciplina>(INI_VALUES.disciplina);
	const [openGeral, setOpenGeral] = useState<boolean | undefined>(INI_VALUES.openGeral);
	const [enableCollapse, setEnableCollapse] = useState<boolean>(INI_VALUES.enableCollapse);
	const [enableDialogRecursos, setEnableDialogRecursos] = useState<boolean>(INI_VALUES.enableDialogRecursos);
	const [exibirDisciplinas, setExibirDisciplinas] = useState<boolean>(INI_VALUES.exibirDisciplinas); 
	const [exibirTabelaDisciplinas, setExibirTabelaDisciplinas] = useState<boolean>(INI_VALUES.exibirTabelaDisciplinas);
	const [conteudoNaoDisponivelDisciplinas, setConteudoNaoDisponivelDisciplinas] = useState(INI_VALUES.conteudoNaoDisponivelDisciplinas);
	const [exibirConteudoNaoDisponivelDisciplinas, setExibirConteudoNaoDisponivelDisciplinas] = useState(INI_VALUES.exibirConteudoNaoDisponivelDisciplinas);
	
	useEffect(() => {
		localStorage.removeItem("idUnidadeDisciplinaBlocoNotas");
		localStorage.removeItem("idUnidadeDisciplinaContribuicao");
	}, []);

	useEffect(() => {
		if(cursos && cursos.length > 0) {
			setCodigoCurso(cursos[0].codigo_curso);
			setExibirTabelaDisciplinas(true);
			setConteudoNaoDisponivelDisciplinas(false);
		} else {
			setExibirTabelaDisciplinas(false);
			setConteudoNaoDisponivelDisciplinas(true);
		}
	}, [cursos]);

	useEffect(() => {
		if(codigoCurso !== "") {
			getHistoricoAluno(user.ra, codigoCurso)
				.then((response) => {
					setDisciplinas(response.data);
					setExibirDisciplinas(true)
					setExibirConteudoNaoDisponivelDisciplinas(false);
				})
				.catch(() => {
					setExibirDisciplinas(false);
					setExibirConteudoNaoDisponivelDisciplinas(true);
				})
		}
	}, [user.ra, codigoCurso]);

	return (
		<TabelaDisciplinasContext.Provider
			value={{
				codigoCurso,
				enableCollapse,
				enableDialogRecursos,
				exibirTabelaDisciplinas,
				exibirDisciplinas,
				conteudoNaoDisponivelDisciplinas,
				exibirConteudoNaoDisponivelDisciplinas,
				openGeral,
				disciplina,
				disciplinas,
				setDisciplina,
				setEnableCollapse,
				setEnableDialogRecursos,
				setOpenGeral,
				setDisciplinas,
				setCodigoCurso,
				setExibirDisciplinas,
				setExibirTabelaDisciplinas,
				setConteudoNaoDisponivelDisciplinas,
				setExibirConteudoNaoDisponivelDisciplinas
			}}
		>
			{children}
		</TabelaDisciplinasContext.Provider>
	);
};

export function useTabelaDisciplinas(): TabelaDisciplinasContextType {
	const context = useContext(TabelaDisciplinasContext);
	return context;
}
