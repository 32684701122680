import React, { useState } from "react";

// Adicionando importações do Material UI 4

import {
	Avatar,
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	CardMedia,
	Collapse,
	IconButton,
	Typography,
	useTheme,
} from "@material-ui/core";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

// Importando interfaces
import { CardMembershipOutlined, PrintOutlined } from "@material-ui/icons";
import { useAuth } from "context/Auth/hook";
import { usePortfolio } from "context/Portfolio";

const useStyles = makeStyles((theme) => ({
	expand: {
		transform: "rotate(0deg)",
		marginLeft: "auto",
		transition: theme.transitions.create("transform", {
			duration: theme.transitions.duration.shortest,
		}),
	},
	expandOpen: {
		transform: "rotate(180deg)",
	},
}));

export default function CardLateralAlunoView(): React.ReactElement {
	const theme = useTheme();
	const classes = useStyles();
	const [expanded, setExpanded] = useState<boolean>(false);
	const { nivelDeEnsino } = useAuth();
	const { state } = usePortfolio();

	/**
	 * @function handleExpandClick
	 * Atribui ao estado expanded o inverso do seu valor anterior.
	 */
	function handleExpandClick() {
		setExpanded(!expanded);
	}

	return (
		<Card className='card-aluno'>
			<CardHeader
				avatar={<Avatar alt="Perfil Aluno" src={state.avatar_path} />}
				action={
					<IconButton
						className={clsx(classes.expand, {
							[classes.expandOpen]: expanded,
						})}
						onClick={handleExpandClick}
						aria-expanded={expanded}
						aria-label='show more'
					>
						<ExpandMoreIcon />
					</IconButton>
				}
				title='Estudante'
				subheader={
					<>
						<span id='subtitulo01'>Curso</span>
						<br />
						<span id='subtitulo02'>Data de conclusão</span>
					</>
				}
			/>

			<Collapse
				in={expanded}
				timeout='auto'
				component='div'
				className='collapse'
				unmountOnExit
			>
				<div className='link'>
					{/* <a href="#">Gerar Certificado Digital</a> */}
					Gerar Certificado Digital
					<CardMembershipOutlined />
				</div>
				<div className='link'>
					{/* <a href="">Imprimir Histórico</a> */}
					Imprimir Histórico
					<PrintOutlined />
				</div>
			</Collapse>

			<CardMedia
				component='img'
				src='imagens-oportunidades/pesquisa-egresso.png'
				title='Imagem aleatória'
			/>

			<CardContent className='box-content-card-aluno-lateral'>
				<Typography variant='body2' component='h2'>
					Pesquisa Egresso
				</Typography>
				<Typography variant='body2' color='textSecondary' component='p'>
					Nossa prioridade é o permanente relacionamento com você nosso aluno egresso, por isso, elaboramos essa pesquisa para que possamos avaliar a relação entre a sua formação e o seu campo profissional.
				</Typography>
			</CardContent>

			<CardActions className='box-botao'>
				<Button
					className='botao-responder-pesquisa'
					style={{ backgroundColor: theme.palette.primary.main }}
					onClick={() =>
						nivelDeEnsino.nome_nivel_ensino === "FACULDADE ALENCARINA" ||
						nivelDeEnsino.nome_nivel_ensino === "FACULDADE 5 DE JULHO"
							? window.open("/", "self")
							: window.open("https://forms.gle/45hPkzK5Ghmm1t958", "blank")
					}
				>
					Responder Pesquisa
				</Button>
			</CardActions>
		</Card>
	);
}
