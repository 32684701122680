import { centralPedagogica } from "services/api";

export async function getQuestionsListStudent(page: any, authorId: any, disciplina: any) {

    const params = disciplina !== null ? { page: page, ra: authorId, disciplina_id: 0 } : { page: page, ra: authorId };

    return await centralPedagogica
        .get(`tutor/perguntas`, { params })
}

export async function postNotificationCentral(data: any) {
    return await centralPedagogica.post(`tutor/mensagens`, data)
}
