import React from "react";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import CircularProgress from "@material-ui/core/CircularProgress";
import parse from "html-react-parser";

import MoreVert from "@material-ui/icons/MoreVert";
import Report from "@material-ui/icons/Report";
import Star from "@material-ui/icons/Star";
import StarBorder from "@material-ui/icons/StarBorder";
import Favorite from "@material-ui/icons/Favorite";
import FavoriteBorder from "@material-ui/icons/FavoriteBorder";
import Comment from "@material-ui/icons/Comment";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Send from "@material-ui/icons/Send";

import DialogRatingOfQuestion from "../DialogRatingOfQuestion";
import DialogReport from "../DialogReport";
// import AvatarProfile from "components/AvatarProfile";

import {
	getListComments,
	storeFavorited,
	storeComment,
} from "../../../dialogquestionsanswers.services";
import { useAuth } from "context/Auth/hook";

import Notify from "utils/Notification";

import { BoxResponseProps } from "./boxresponse.model";

import "./styles.scss";

import { apiLogsDoEcossistema } from "services/Logs";
import { CENTRAL_PEDAGOGICA } from "defaults/modules";
import { ALUNO_COMENTA_RESPOSTA_DUVIDA_CONTEUDO } from "defaults/events";
import { Avatar } from "@material-ui/core";
import { getNameInitials } from "utils/getNameInitials";

export default function BoxResponse(props: BoxResponseProps): React.ReactElement {
	const { user, nivelDeEnsino } = useAuth();

	// Comment
	const [openComments, setOpenComments] = React.useState(false);
	const [loadingComments, setLoadingComments] = React.useState(false);
	const [loadingSendComment, setLoadingSendComment] = React.useState(false);
	const [qtdComments, setQtdComments] = React.useState("0");
	const [comments, setComments] = React.useState<any[]>([]);
	const [comment, setComment] = React.useState("");

	// Favorite
	const [qtdFavotited, setQtdFavorited] = React.useState("0");
	const [favorited, setFavorited] = React.useState(false);

	// Rate
	const [openRating, setOpenRating] = React.useState(false);
	const [qtdRated, setQtdRated] = React.useState("0");
	const [rated, setRated] = React.useState(false);

	// Report
	const [openOptions, setOpenOptions] = React.useState<null | HTMLElement>(null);
	const [openReport, setOpenReport] = React.useState(false);
	const [idForReport, setIdForReport] = React.useState("");

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setOpenOptions(event.currentTarget);
	};

	const handleClose = () => {
		setOpenOptions(null);
	};

	const favorite = () => {
		if (props.idAnswer && user.ra) {
			storeFavorited(user.ra, props.idAnswer, user)
				.then((data) => {
					setQtdFavorited(data.favoritos);
					setFavorited(!favorited);
					props.setOnReload(true);
				})
				.catch((e) => {
					console.log(e);
				});
		}
	};

	const listComments = () => {
		if (props.idAnswer) {
			setLoadingComments(true);

			getListComments(props.idAnswer, user)
				.then((data) => {
					setComments(data.data);
					setQtdComments(data.data?.length);
					setLoadingComments(false);
				})
				.catch((e) => {
					console.log(e);
					setLoadingComments(false);
				});
		}
	};

	const sendComment = (e: React.FormEvent) => {
		e.preventDefault();

		if (comment.length > 245) {
			Notify("O texto do comentário ultrapassou 245 caracteres.", "warning");
			return;
		}

		if (comment && !loadingSendComment) {
			setLoadingSendComment(true);
			storeComment(
				user.ra ?? "",
				props.idAnswer,
				comment,
				user.typeUser === "tutor" ? "1" : "0",
				props.idCourse
			)
				.then(async (data) => {
					listComments();
					setComment("");
					await apiLogsDoEcossistema.post('/',
						{
							ra: user.ra as string,
							module: CENTRAL_PEDAGOGICA,
							evento: {
								label: ALUNO_COMENTA_RESPOSTA_DUVIDA_CONTEUDO,
								log_info: {
									user_type: user.typeUser,
									codigo_nivel_ensino: nivelDeEnsino.codigo_nivel_ensino,
									nome_nivel_ensino: nivelDeEnsino.nome_nivel_ensino,
									nome_curso: nivelDeEnsino.nome_curso,
									periodo: nivelDeEnsino.periodo,
								}
							},
							metadata: {
								curso: nivelDeEnsino.nome_curso,
								polo: nivelDeEnsino.nome_nivel_ensino,
								periodo: nivelDeEnsino.periodo,
							}
						}
					)
					setLoadingSendComment(false);
				})
				.catch((e) => {
					console.log(e);
				});
		}
	};

	function date(data: any) {
		let arrayData = data.split(" ");
		arrayData.splice(1, 1);
		arrayData = arrayData[0].split("-");
		return arrayData.reverse().join("/");
	}

	function hora(hora: any) {
		let arrayHora: string[] = hora.split(" ");
		return arrayHora[1];
	}

	// TODO: Forçar o refresh após enviar a avaliação
	React.useEffect(() => {
		if (props.favorites) {
			setQtdFavorited(props.favorites);
			setFavorited(props.favorited);
		}

		if (props.numVotes) {
			setQtdRated(props.numVotes);
			setRated(props.rated);
		}
	}, [props.favorites, props.favorited, props.numVotes, props.rated]);

	React.useEffect(() => {
		if (props.numComments) {
			setQtdComments(props.numComments ?? 0);
		}
	}, [props.numComments]);

	React.useEffect(() => {
		listComments()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Grid item xs={12}>
			<Box className='area_response'>
				<Box className='header'>
					<Typography variant="body1" component="p">Resposta:</Typography>

					<Box className='view_avaliations'>
						<IconButton
							id='btnOptions'
							title='Denunciar'
							aria-controls='simple-menu'
							aria-haspopup='true'
							onClick={handleClick}
						>
							<MoreVert />
						</IconButton>

						<Menu
							id='simple-menu'
							anchorEl={openOptions}
							keepMounted
							open={Boolean(openOptions)}
							onClose={handleClose}
						>
							<MenuItem
								style={{ color: "#BD0E0E" }}
								button
								id='btnReport'
								onClick={() => {
									setIdForReport(props.idAnswer);
									setOpenReport(true);
									handleClose();
								}}
							>
								<Report />
								&nbsp;Denunciar
							</MenuItem>
						</Menu>
					</Box>
				</Box>

				<Box className='body'>
					<Typography className='answer_text' style={{ overflowWrap: "break-word" }}>{parse(props.answer)}</Typography>

					<Box className='author'>
						{/* <AvatarProfile
							isAuthor={props.authorId === user.ra}
							urlImage={
								props.idCourse === "0" ? "perfil_tutor.svg" : props.authorAvatar
							}
							authorName={props.author}
						/> */}
						<Avatar
							className={"img_perfil_questions"}
							src={props.authorAvatar ? `https://dirin.s3.us-east-1.amazonaws.com/${props.authorAvatar}` : ""}
							alt='Perfil'
						>
							{getNameInitials(props.author)}
						</Avatar>
						<Box className='author_name_course'>
							<Typography
								variant="body1"
								component="p"
								className="answerAuthorName">
								{props.author} - {props.course}
							</Typography>
							<Typography className='author_course'>
								{date(props.created)} - {hora(props.created)}
							</Typography>
						</Box>
					</Box>
				</Box>

				<Box className='footer_response_view'>
					<Typography variant='subtitle2' style={{ color: "gray" }}>
						Avalie para que todos saibam que esta resposta é útil
					</Typography>

					<Box className='area_avaliations'>
						<Button
							id='btnLikeResponse'
							title='Curtir Resposta'
							disabled={user.typeUser === 'tutor' ?? true}
							variant='outlined'
							startIcon={
								favorited ? (
									<Favorite style={{ color: "#bd0e0e" }} />
								) : (
									<FavoriteBorder />
								)
							}
							onClick={() => { if (!favorited) { favorite(); } }}
						>
							{qtdFavotited}
						</Button>

						<Button
							title='Avaliar Resposta'
							id='btnRating'
							variant='outlined'
							disabled={user.typeUser === 'tutor' ?? true}
							startIcon={
								rated ? <Star style={{ color: "#ffc000" }} /> : <StarBorder />
							}
							onClick={() => {
								if (!rated) {
									setOpenRating(true);
								}
							}}
						>
							{parseFloat(qtdRated ?? 0).toFixed(1).replace(".", ",")}
						</Button>
					</Box>

					<Button
						id='showComments'
						startIcon={<Comment />}
						endIcon={openComments ? <ExpandLess /> : <ExpandMore />}
						onClick={() => { listComments(); setOpenComments(!openComments); }}
						style={{
							backgroundColor: "#f8f8f8",
							paddingLeft: "12px",
							paddingRight: "12px",
							marginBottom: "12px"
						}}
					>
						Comentários ({qtdComments})
					</Button>
				</Box>

				{openComments && (
					<Box className='comments'>
						{loadingComments ? (
							<Box
								display='flex'
								justifyContent='center'
								alignItems='center'
								flexDirection='column'
								gridGap={12}
								style={{
									marginBottom: "24px"
								}}
							>
								<CircularProgress />
								<Typography>Buscando Comentários</Typography>
							</Box>
						) : (
							<>
								<Box className='area_comments'>
									{comments?.length > 0 ? (
										<>
											{comments?.map((comment, index) => {
												return (
													<Box
														key={index}
														display='flex'
														flexDirection='column'
														justifyContent={
															comment.profile.ra !== user.ra?.toString()
																? "flex-start"
																: "flex-end"
														}
														alignItems={
															comment.profile.ra !== user.ra?.toString()
																? "flex-start"
																: "flex-end"
														}
													>
														<Typography className='area_author_comment' variant="body2" component="small">
															{comment.profile.name} - {" "}
															{comment.curso.nome}
														</Typography>
														<Typography
															className={
																comment.profile.ra !==
																	user.ra?.toString()
																	? "comment"
																	: "comment_author"
															}
														>
															{comment.comentario}
														</Typography>
													</Box>
												);
											})}
										</>
									) : (
										<Box mt={2}>
											<Typography className='nothing_comment' variant="body1" component="p">
												Nenhum comentário encontrado
											</Typography>
										</Box>
									)}
								</Box>

								<form onSubmit={sendComment}>
									<Box className='commenting'>
										<Box>
											<FormHelperText
												error={comment.length > 245}
												style={{ textAlign: "right" }}
											>
												{comment.length} de 245 caracteres
											</FormHelperText>
											<TextField
												id="digiteComentario"
												variant='outlined'
												placeholder='Digite o seu comentário...'
												value={comment}
												onChange={(e) => setComment(e.target.value)}
												disabled={loadingSendComment}
												error={comment.length > 245}
												inputProps={{ maxLength: 245 }}
												fullWidth
											/>
										</Box>

										<IconButton type='submit' id='btnSendComment' disabled={loadingSendComment}>
											{loadingSendComment ? (
												<CircularProgress
													style={{
														color: "#fff",
														width: "1.5rem",
														height: "1.5rem",
													}}
													thickness={4.4}
												/>
											) : (
												<Send />
											)}
										</IconButton>
									</Box>
								</form>
							</>
						)}
					</Box>
				)}
			</Box>

			<DialogRatingOfQuestion
				open={openRating}
				idAnswer={props.idAnswer}
				setOpen={setOpenRating}
				setQtdRated={setQtdRated}
				setRated={setRated}
				setReload={props.setOnReload}
			/>

			<DialogReport
				typeReport='respostas'
				idForReport={idForReport}
				open={openReport}
				setOpen={setOpenReport}
			/>
		</Grid>
	);
}
