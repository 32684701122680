import { AxiosResponse } from "axios";
import { centralPedagogica, ecossistema } from "services/api";

export async function getCoursesStudent(ra: string | undefined) {
	return await ecossistema
		.get(`nti/cursos/${ra}`)
		.then(({ data }: any) => {
			return data.map((dt) => {
				return {
					id: dt?.codigo_curso,
					name: dt?.nome_curso,
				};
			});
		})
		.catch((e) => {
			console.log(e);
		});
}

export async function getSubjectsStudent(ra: string | undefined) {
	return await ecossistema
		.get(`nti/disciplinas/${ra}`)
		.then(({ data }: any) => {
			return data;
		})
		.catch((e) => {
			console.log(e);
		});
}

export async function getListQuestions(user, type?: string, value?: string, page = 0) {
	// eu testei essa notação e tá correta, só não faço ideia de onde ele seta type e value 🧐
	const params = type && value ? { [`${type}`]: value, page } : { page };
	return await centralPedagogica
		.get(`${user.typeUser === 'student' ? 'aluno' : 'tutor'}/perguntas`, {
			params
		})
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

export async function getListQuestionsByAuthor(user: any) {
	return await centralPedagogica
		.get(`${user.typeUser === 'student' ? 'aluno' : 'tutor'}/perguntas/`, {
			params: { ra: user.ra },
		})
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

export async function getListQuestionsCourseAndSubject(
	user: any,
	course?: string,
	subject?: string,
	page = 0,
) {
	return await centralPedagogica
		.get(`${user.typeUser === 'student' ? 'aluno' : 'tutor'}/perguntas`, {
			params: {
				page: page,
				curso_id: course,
				disciplina_id: subject
			},
		})
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

export async function getListAnswers(idQuestion: string, user?: any) {
	// const params = idQuestion && authorId ? {pergunta_id: idQuestion, ra: authorId } : null;

	return await centralPedagogica
		.get(`${user.typeUser === 'student' ? 'aluno' : 'tutor'}/respostas`, {
			params: {
				pergunta_id: idQuestion
			}
		})
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

export async function getListAnswersByAuthor(authorId: string, user?: any) {
	return await centralPedagogica
		.get(`${user.typeUser === 'student' ? 'aluno' : 'tutor'}/respostas`, {
			params: {
				ra: authorId
			}
		})
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

export async function getListComments(idResponse: string, user?: any) {
	return await centralPedagogica
		.get(`${user.typeUser === 'student' ? 'aluno' : 'tutor'}/comentarios`, {
			params: {
				resposta_id: idResponse
			}
		})
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

export async function getListReport() {
	return centralPedagogica.get("tutor/denuncias").then(({ data }) => {
		return data;
	}).catch((e) => {
		return e;
	});
}

export async function getDetailsReport(type: "pergunta" | "resposta", idReport: string) {
	return centralPedagogica
		.get(`tutor/detalhesDenuncia/${type}/${idReport}`)
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

export async function getQuestion(identify: string, user?: any) {
	return await centralPedagogica
		.get(`${user.typeUser === 'student' ? 'aluno' : 'tutor'}/perguntas/${identify}`)
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

export async function getCountQuestion(ra: string, user?: any) {
	return await centralPedagogica
		.get(`${user.typeUser === 'student' ? 'aluno' : 'tutor'}/perguntas/`, {
			params: {
				ra
			}
		})
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

export async function getCountAnswer(ra: string) {
	return await centralPedagogica
		.get(`aluno/respostas`, {
			params: {
				ra
			}
		})
		.then((res: AxiosResponse<any>) => {
			return res.data.length;
		})
		.catch((e) => {
			return e;
		});
}

export async function storeQuestion(user: any, question: string, subject: any, course: any, nivelDeEnsino: any) {
	return await centralPedagogica
		.post("aluno/perguntas", {
			ra: user.ra,
			name: user.nome,
			email: user.data.email,
			pergunta: question,
			polo_id: String(nivelDeEnsino.codigo_nivel_ensino),
			polo_nome: String(nivelDeEnsino.nome_nivel_ensino),
			curso_id: String(course.id),
			curso_nome: String(course.name),
			disciplina_id: String(subject.id),
			disciplina_nome: String(subject.nome),
		})
}

export async function storeAnswer(
	ra: string,
	questionId: string,
	course: string,
	answer: string,
	isTutor: "0" | "1",
) {
	return await centralPedagogica
		.post(`${isTutor === "1" ? "tutor" : "aluno"}/respostas`, {
			pergunta_id: questionId,
			resposta: answer,
			curso_id: course.split(":")[0]
		})
}

export async function storeFavorited(authorId: string, idResponse: string, user?: any) {
	return await centralPedagogica
		.post(user ? `${user.typeUser === 'student' ? 'aluno' : 'tutor'}/favoritos`: "aluno/favoritos", {
			model: "respostas",
			model_id: idResponse,
		})
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

export async function storeRating(authorId: string, idResponse: string, rate: number, user?:any) {
	return await centralPedagogica
		.post(user ? `${user.typeUser === 'student' ? 'aluno' : 'tutor'}/avaliacoes`: "aluno/avaliacoes", {
			model: "respostas",
			model_id: idResponse,
			nota: rate,
		})
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

/**
 * Armazena um novo comentário
 * @param authorId 
 * @param idResponse 
 * @param rate 
 * @param isTutor 
 * @returns 
 */
export async function storeComment(
	authorId: string,
	idResponse: string,
	rate: string,
	isTutor: "0" | "1",
	curso_id: string,
) {
	return await centralPedagogica
		.post(`${isTutor === '1' ? 'tutor' : 'aluno'}/comentarios`, {
			resposta_id: idResponse,
			comentario: rate,
			tutor: isTutor,
			curso_id: curso_id
		})
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

export async function storeReport(
	type: "perguntas" | "respostas",
	id: string,
	authorId: string,
	reason: string,
) {
	return await centralPedagogica
		.post("aluno/denuncias", {
			ra: authorId,
			model: type,
			model_id: String(id),
			motivo: reason,
		})
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

export async function aproveReport(type: "pergunta" | "resposta", idReport: string) {
	return await centralPedagogica
		.get(`tutor/aprovarDenuncias/${type}/${idReport}`)
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

export async function clearReports(type: "pergunta" | "resposta", idReport: string) {
	return await centralPedagogica
		.get(`tutor/removerDenuncias/${type}/${idReport}`)
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}
