import React from "react";

import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import { useTheme } from "@material-ui/core/styles";

import Close from "@material-ui/icons/Close";
import AvatarProfile from "components/AvatarProfile";

import { useAuth } from "context/Auth/hook";

import "./styles.scss";
import { usePortfolio } from "context/Portfolio";
import { useGamificacao } from "context/Gamificacao";

interface DrawerMenuGamificacaoProps {
	open: boolean;
	setOpen: (e: boolean) => void;
}

export default function DrawerMenuGamificacao({ open, setOpen }: DrawerMenuGamificacaoProps) {
	const { state } = usePortfolio();
	const { user } = useAuth();
	const theme = useTheme();
	const colorsTheme = useTheme();
	const { coresLinks, setSwitchContent, setCoresLinks, rankingUsuarioLogado, scoreTotalPontuacao, scoreTotalDesafios } = useGamificacao();

	return (
		<>
			<Drawer anchor='left' open={open} onClose={() => setOpen(false)}>
				<List style={{ background: colorsTheme.palette.primary.main }}>
					<ListItem className='perfil_studenty'>
						<ListItemAvatar>
							{/* <Avatar alt="Imagem de Perfil" src={user?.image} style={{ width: "3.6rem", height: "3.6rem" }} /> */}
							<AvatarProfile isTutor={user.typeUser === "tutor"} />
						</ListItemAvatar>

						<Box className='info_perfil_secondary'>
							<IconButton onClick={() => setOpen(false)} className='buttonClose'>
								<Close />
							</IconButton>
							<Typography className='name_studenty_menu'>{state?.name}</Typography>
							<Typography variant='inherit' className='ra_studenty_menu'>RA: {state?.ra}</Typography>

							{/* {user.typeUser === "student" && (
								<>
									<Typography variant='inherit' className='ra_studenty_menu'>
										RA: {state?.ra}
									</Typography>
								</>
							)} */}
						</Box>
					</ListItem>
				</List>

				<Divider />

				<List>
					<ListItem
						button
						style={{ color: coresLinks[0] }}
						onClick={() => {
							setCoresLinks([`${theme.palette.primary.main}`, "#000000", "#000000"]);
							setSwitchContent(0);
						}}
					>
						<ListItemIcon className='icon_menu_item'>
							<span className="material-symbols-outlined" style={{ color: coresLinks[0] }}>
								diamond
							</span>
						</ListItemIcon>
						<ListItemText primary='Pontuação' secondary={scoreTotalPontuacao} />
					</ListItem>

					<ListItem
						button
						style={{ color: coresLinks[1] }}
						onClick={() => {
							setCoresLinks(["#000000", `${theme.palette.primary.main}`, "#000000"]);
							setSwitchContent(1);
						}}
					>
						<ListItemIcon className='icon_menu_item'>
							<span className="material-symbols-outlined" style={{ color: coresLinks[1] }}>
								military_tech
							</span>
						</ListItemIcon>
						<ListItemText primary='Desafios' secondary={scoreTotalDesafios} />
					</ListItem>

					<ListItem
						button
						style={{ color: coresLinks[2] }}
						onClick={() => {
							setCoresLinks(["#000000", "#000000", `${theme.palette.primary.main}`]);
							setSwitchContent(2);
						}}
					>
						<ListItemIcon className='icon_menu_item'>
							<span className="material-symbols-outlined" style={{ color: coresLinks[2] }}>
								trending_up
							</span>
						</ListItemIcon>
						<ListItemText primary='Ranking' secondary={rankingUsuarioLogado.rank_geral} />
					</ListItem>
				</List>
			</Drawer>
		</>
	);
}