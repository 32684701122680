import { centralPedagogica } from "services/api";

import { StoreRequestProps } from "./formreviewquestion.model";

function definirRota(curso_id: string, situacao: string, pagina: number): string {
	if(situacao !== "all" && curso_id !== "") {
		return `tutor/revisoes?situacao=${situacao}&page=${pagina}&curso_id=${curso_id}`;
	}
	
	if(situacao !== "all" && curso_id === "") {
		return `tutor/revisoes?situacao=${situacao}&page=${pagina}`;
	}
	
	if(situacao === "all" && curso_id !== "") {
		return `tutor/revisoes?page=${pagina}&curso_id=${curso_id}`;
	}
	
	return `tutor/revisoes?page=${pagina}`;
}

export async function storeRequest(props: StoreRequestProps) {

	const cursoId = props.curso_id.split(":")[0];
	const disciplinaId = props.disciplina_id.split(":")[0];
	const formData = new FormData();
	
	formData.append("polo_id", `${props.polo_id.toString()}`);
	formData.append("curso_id", cursoId);
	formData.append("disciplina_id", disciplinaId);
	formData.append("questao", props.questao);
	formData.append("avaliacao", props.avaliacao);
	formData.append("justificativa", props.justificativa);
	formData.append("anexo", props.anexo);

	return await centralPedagogica.post("aluno/revisoes/", formData, { headers: { "Content-Type": "multipart/form-data" } }).then(({ data }) => {
		return JSON.parse(JSON.stringify(data));
	});
}

export async function getSolicitation(id: string) {
	return await centralPedagogica
		.get(`aluno/revisoes/${id}`, { headers: { "Accept": "applicattion/json" } })
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

export async function getSolicitations(situacao = "") {
	return await centralPedagogica
		.get(`aluno/revisoes?situacao=${situacao}`, { headers: { "Accept": "applicattion/json" } })
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

export async function deleteSolicitation(id: number | undefined) {
	return await centralPedagogica
		.delete(`aluno/revisoes/${id}`)
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

export async function getSolicitationsTutor(curso_id: string, situacao: string, pagina: number) {
	return await centralPedagogica
		.get(definirRota(curso_id, situacao, pagina))
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

export async function getSolicitationTutor(id: number) {
	return await centralPedagogica
		.get(`tutor/revisoes/${id}`)
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

export async function toMeetSolicitation(
	id: number,
	response: string,
	situation: string
) {
	return await centralPedagogica
		.put(`tutor/revisoes/${id}`, {
			parecer: response,
			situacao: situation,
		})
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}
