import {
	Box,
	Button,
	Container,
	Divider,
	FormControl,
	Grid,
	IconButton,
	InputAdornment,
	Link,
	TextField,
	Typography,
} from "@material-ui/core";
import {
	EventNote,
	Keyboard,
	Language,
	Send,
} from "@material-ui/icons";
import React, { useState } from "react";
import Notify from "utils/Notification";

import "./styles.scss";
// import { useAuth } from "context/Auth/hook";

import { useTheme } from "@material-ui/core";
import { useAuth } from "context/Auth/hook";
import { sendConfirmEmail } from "services/EnvioEmailFooter";
import CalendarScreen from "pages/PedagogicalCommunication/components/CalendarScreen";
import DialogSchuduleTime from "pages/PedagogicalCommunication/components/DialogScheduleTime";
import DialogReviewQuestion from "pages/PedagogicalCommunication/components/DialogReviewQuestion";
import DialogQuestionsAnswers from "pages/PedagogicalCommunication/components/DialogQuestionsAnswers";
import ListSolicitationsReviewQuestion from "pages/PedagogicalCommunication/components/ListSolicitationsReviewQuestion";
import { useNavigate } from "react-router-dom";
import { getMoodleUrl } from "context/Auth/services";
import { ecossistema } from "services/api";

// interface RespostaApiEmail {
// 	success: string;
// 	msg: string;
// }

export default function FooterView() {
	const theme = useTheme();
	const tempo = new Date();
	const ano = tempo.getFullYear();
	const { user, nivelDeEnsino } = useAuth();
	// const { devPreview, setDevPreview } = useAuth();

	const [email, setEmail] = useState<string>("");

	const history = useNavigate();

	const [openCalendar, setOpenCalendar] = React.useState(false);
	const [openScheduleTime, setOpenScheduleTime] = React.useState(false);
	const [openQA, setOpenQA] = React.useState(false);
	const [openReviewQuestion, setOpenReviewQuestion] = React.useState(false);

	return (
		<>
			<Box position='static' className='footer'>
				<Container maxWidth='lg'>
					<Grid container spacing={2}>
						<Grid item md={12} sm={12}>
							<Box
								style={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
									justifyContent: "center",
								}}
								className='boxEventosHoje'
							>
								<Typography
									variant='body1'
									color='inherit'>
									Acompanhe a nossa programação de eventos diários
								</Typography>
								<Button
									variant='contained'
									disableElevation
									style={{
										backgroundColor: theme.palette.primary.main,
										color: "white",
									}}
									// style={{
									//     backgroundColor:
									//         nivelDeEnsino.nome_nivel_ensino    !== "FACULDADE ALENCARINA"
									//         && nivelDeEnsino.nome_nivel_ensino !== "FACULDADE 5 DE JULHO"
									//         && nivelDeEnsino.nome_nivel_ensino !== "FACULDADE 5 DE JULHO - EAD"
									//         && nivelDeEnsino.nome_nivel_ensino !== "FAL" ? "#bd0e0e" : "#001D7E",
									//     color: "white",
									// }}
									target='_blank'
									href={
										nivelDeEnsino.codigo_nivel_ensino !== 351 &&
											nivelDeEnsino.nome_nivel_ensino !==
											"FACULDADE ALENCARINA" &&
											nivelDeEnsino.nome_nivel_ensino !==
											"FACULDADE 5 DE JULHO - EAD" &&
											nivelDeEnsino.nome_nivel_ensino !== "FACULDADE 5 DE JULHO"
											? nivelDeEnsino.codigo_nivel_ensino === 345
												? "https://eventosvirtuais.aiamis.com.br/uninta/agenda"
												: "https://eventosvirtuais.aiamis.com.br/uninta/agenda"
											: "https://eventosvirtuais.aiamis.com.br/f5/agenda"
									}
									data-testid='daily-events-link'
									className='buttonFooter eventos-hoje-footer'
								>
									<EventNote style={{ marginRight: "6px" }} />
									Eventos de Hoje
								</Button>
							</Box>
						</Grid>
					</Grid>
					<Divider style={{ backgroundColor: "#3f3f3f" }} />
					<Grid
						container
						direction='row'
						spacing={2}
						style={{
							marginBottom: "5vh",
						}}
					>
						<Grid item xs={12} sm={3}>
							<Typography
								variant='body1'
								color='inherit'
								className='list-item-title-link'
							>
								INSTITUIÇÃO
							</Typography>
							{nivelDeEnsino.codigo_nivel_ensino !== 351 &&
								nivelDeEnsino.nome_nivel_ensino !== "FACULDADE ALENCARINA" &&
								nivelDeEnsino.nome_nivel_ensino !== "FACULDADE 5 DE JULHO - EAD" &&
								nivelDeEnsino.nome_nivel_ensino !== "FACULDADE 5 DE JULHO" ? (
								nivelDeEnsino.codigo_nivel_ensino === 345 ? (
									<Typography variant='body1' className='list-item-link'>
										<Link
											href='https://fortaleza.faculdadeuninta.com.br/instituicao/#historico'
											color='inherit'
											target='_blank'
										>
											Histórico
										</Link>
									</Typography>
								) : (
									<React.Fragment>
										<Typography variant='body1' className='list-item-link'>
											<Link
												href='https://uninta.edu.br/site/instituicao/'
												color='inherit'
												target='_blank'
											>
												Conheça a Instituição
											</Link>
										</Typography>
										<Typography variant='body1' className='list-item-link'>
											<Link
												href='https://uninta.edu.br/site/graduacao-presencial/'
												color='inherit'
												target='_blank'
											>
												Cursos de Graduação
											</Link>
										</Typography>
										<Typography variant='body1' className='list-item-link'>
											<Link
												href='http://www.inta.edu.br/posgraduacao/'
												color='inherit'
												target='_blank'
											>
												Cursos de Pós-graduação
											</Link>
										</Typography>
										<Typography variant='body1' className='list-item-link'>
											<Link
												href='http://biotecnologia.inta.edu.br/'
												color='inherit'
												target='_blank'
											>
												Cursos de Mestrado
											</Link>
										</Typography>
										<Typography variant='body1' className='list-item-link'>
											<Link
												href='http://ead.inta.edu.br/graduacao-ead/'
												color='inherit'
												target='_blank'
											>
												Cursos EaD
											</Link>
										</Typography>
										{/* <Typography variant="body1">
											<Link href="http://mooc.intaead.com.br/login/index.php" color="inherit" target="_blank">
												Cursos online
											</Link>
										</Typography> */}
										<Typography variant='body1' className='list-item-link'>
											<Link
												href='https://uninta.edu.br/site/processos-seletivos/'
												color='inherit'
												target='_blank'
											>
												Processos Seletivos
											</Link>
										</Typography>
									</React.Fragment>
								)
							) : (
								<React.Fragment>
									<Typography variant='body1' className='list-item-link'>
										<Link
											href='https://faculdadef5.com.br/a-f5/'
											color='inherit'
											target='_blank'
										>
											Conheça a F5
										</Link>
									</Typography>
									<Typography variant='body1' className='list-item-link'>
										<Link
											href='https://faculdadef5.com.br/graduacao/'
											color='inherit'
											target='_blank'
										>
											Cursos de Graduação
										</Link>
									</Typography>
									<Typography variant='body1' className='list-item-link'>
										<Link
											href='https://faculdadef5.com.br/processos-seletivos/'
											color='inherit'
											target='_blank'
										>
											Processos Seletivos
										</Link>
									</Typography>
								</React.Fragment>
							)}
						</Grid>

						<Grid item xs={12} sm={3}>
							<Typography
								variant='body1'
								color='inherit'
								className='list-item-title-link'
							>
								ACESSO RÁPIDO
							</Typography>
							<Typography variant='body1' className='list-item-link'>
								<Link href='#' color='inherit' onClick={() => {
									user.typeUser === "tutor" ?
										setOpenCalendar(true)
										:
										setOpenScheduleTime(true)
								}}>
									Agendamento de Mentoria
								</Link>
							</Typography>
							<Typography variant='body1' className='list-item-link'>
								<Link href='#' color='inherit' onClick={() => {
									setOpenQA(true);
								}}>
									Falar com o Professor
								</Link>
							</Typography>
							<Typography variant='body1' className='list-item-link'>
								<Link href='#' color='inherit' onClick={() => {
									setOpenReviewQuestion(true);
								}}>
									Solicitação de Revisão
								</Link>
							</Typography>
							<Typography variant='body1' className='list-item-link'>
								<Link href='#' color='inherit' onClick={() => {
									localStorage.setItem("clicouLinkBlocoNotasFooter", "true");
									history("/portfolio");
								}}>
									Bloco de Notas e Arquivos
								</Link>
							</Typography>
						</Grid>

						<Grid item xs={12} sm={3}>
							<Typography
								variant='body1'
								color='inherit'
								className='list-item-title-link'
							>
								LINKS ÚTEIS
							</Typography>
							<Typography variant='body1' className='list-item-link'>
								<Link
									href='http://prodipeuninta.online/cronograma/'
									color='inherit'
									target='_blank'
								>
									Planejamento de Estudo Online
								</Link>
							</Typography>
							<Typography variant='body1' className='list-item-link'>
								{
									nivelDeEnsino.codigo_nivel_ensino !== 351 &&
										nivelDeEnsino.nome_nivel_ensino !== "FACULDADE ALENCARINA" &&
										nivelDeEnsino.nome_nivel_ensino !== "FACULDADE 5 DE JULHO - EAD" &&
										nivelDeEnsino.nome_nivel_ensino !== "FACULDADE 5 DE JULHO" ? (
										nivelDeEnsino.codigo_nivel_ensino === 345 ? (
											<Link
												href='http://eventosvirtuais.aiamis.com.br/uninta/inicio'
												color='inherit'
												target='_blank'
											>
												Eventos Virtuais
											</Link>
										) : (
											<Link
												href='http://eventosvirtuais.aiamis.com.br/uninta/inicio'
												color='inherit'
												target='_blank'
											>
												Eventos Virtuais
											</Link>
										)
									) : (
										<Link
											href='http://eventosvirtuais.aiamis.com.br/f5/inicio'
											color='inherit'
											target='_blank'
										>
											Eventos Virtuais
										</Link>
									)
								}
								{/* <Link
									href={
										nivelDeEnsino.nome_nivel_ensino ===
											"FACULDADE 5 DE JULHO - EAD" ||
											nivelDeEnsino.nome_nivel_ensino === "FACULDADE ALENCARINA"
											? "https://eventosvirtuais.aiamis.com.br/f5/agenda"
											: "https://eventosvirtuais.aiamis.com.br/uninta/agenda"
									}
									color='inherit'
									target='_blank'
								>
									Eventos Virtuais
								</Link> */}
							</Typography>
							<Typography variant='body1' className='list-item-link'>
								<Link
									href='https://virtual.uninta.edu.br/blocks/verify_certificate/index.php'
									color='inherit'
									target='_blank'
								>
									Validação de Certificados
								</Link>
							</Typography>
							<Typography variant='body1' className='list-item-link'>
								<Link
									href='https://docs.google.com/presentation/d/e/2PACX-1vQvPbShlWq3WORLkLCGVxjFoLayYetmA2SkS2RZd_hJKCltta-MHJR828sz7Qp_u3uitGn-ELwRnQbB/pub?start=false&loop=false&delayms=3000&slide=id.gc6f83aa91_0_56'
									color='inherit'
									target='_blank'
								>
									Ferramentas de Acessibilidade
								</Link>
							</Typography>
							<Typography variant='body1' className='list-item-link'>
								{
									nivelDeEnsino.codigo_nivel_ensino !== 351 &&
										nivelDeEnsino.nome_nivel_ensino !== "FACULDADE ALENCARINA" &&
										nivelDeEnsino.nome_nivel_ensino !== "FACULDADE 5 DE JULHO - EAD" &&
										nivelDeEnsino.nome_nivel_ensino !== "FACULDADE 5 DE JULHO" ? (
										nivelDeEnsino.codigo_nivel_ensino === 345 ? (
											<Link
												href='https://uninta.edu.br/site/cpa'
												color='inherit'
												target='_blank'
											>
												Avaliação CPA
											</Link>
										) : (
											<Link
												href='https://uninta.edu.br/site/cpa'
												color='inherit'
												target='_blank'
											>
												Avaliação CPA
											</Link>
										)
									) : (
										<Link
											href='https://faculdadef5.com.br/cpa/'
											color='inherit'
											target='_blank'
										>
											Avaliação CPA
										</Link>
									)}
							</Typography>
							<Typography variant='body1' className='list-item-link'>
								{
									nivelDeEnsino.codigo_nivel_ensino !== 351 &&
										nivelDeEnsino.nome_nivel_ensino !== "FACULDADE ALENCARINA" &&
										nivelDeEnsino.nome_nivel_ensino !== "FACULDADE 5 DE JULHO - EAD" &&
										nivelDeEnsino.nome_nivel_ensino !== "FACULDADE 5 DE JULHO" ? (
										nivelDeEnsino.codigo_nivel_ensino === 345 ? (
											<Link
												href='https://enade.uninta.edu.br/'
												color='inherit'
												target='_blank'
											>
												Site - ENADE
											</Link>
										) : (
											<Link
												href='https://enade.uninta.edu.br/'
												color='inherit'
												target='_blank'
											>
												Site - 	ENADE
											</Link>
										)
									) : (
										<Link
											href='https://faculdadef5.com.br/enade/ '
											color='inherit'
											target='_blank'
										>
											Site - ENADE
										</Link>
									)}
							</Typography>
							<Typography variant='body1' className='list-item-link'>
								<Link
									style={{cursor: "pointer"}}
									onClick={async () => {
										await getMoodleUrl(user.ra, ecossistema)
											.then(async ({ data }: any) => {
												window.open(`${data}&wantsurl=https://virtual.uninta.edu.br/course/view.php?id=78380`, "_blank",);
											})
									}}
									color='inherit'
								>
									Sala - ENADE
								</Link>
							</Typography>
						</Grid>

						<Grid item xs={12} sm={3}>
							<Typography variant='body1' className='text-helper-email'>
								Receba atualizações de conteúdo em sua caixa de entrada
							</Typography>
							<FormControl variant='outlined' fullWidth>
								<TextField
									id='outlined-email-input'
									label='Digite seu e-mail'
									className='newsletter-footer'
									type='email'
									name='email'
									size='small'
									required={true}
									autoComplete='email'
									margin='normal'
									variant='outlined'
									data-testid='newsletter-input'
									onChange={(e) => setEmail(e.target.value)}
									value={email ? email : ""}
									style={{
										color: "#fff",
									}}
									InputProps={{
										style: {
											color: "#fff",
										},
										startAdornment: (
											<InputAdornment position='start'>
												<Keyboard style={{ color: "#fff" }} />
											</InputAdornment>
										),
										endAdornment: (
											<InputAdornment position='end'>
												<IconButton
													aria-label='toggle email visibility'
													data-testid='newsletter-submit'
													size='small'
													onClick={() => {
														if (email !== "") {
															console.log(email);
															sendConfirmEmail(email)
																.then((response: any) => {
																	console.log(response);
																	Notify(response.data.msg, "success");
																})
																.catch((error) => {
																	console.log(error);
																	Notify(error.response.data.msg, "error");
																});
														} else {
															Notify(
																"Para efetuar o envio é necessário o seu e-mail!",
																"error",
															);
														}
													}}
												>
													<Send style={{ color: "#fff" }} />
												</IconButton>
											</InputAdornment>
										),
									}}
								></TextField>
							</FormControl>
							<Typography variant='body1' className='text-helper-email'>
								Você pode cancelar a assinatura a qualquer momento usando o link em
								nossos e-mails.
								{/* Para mais detalhes, verifique nossa 
                            <Link href="#" color="inherit" target="_blank">política de privacidade.
                            </Link> */}
							</Typography>
						</Grid>
					</Grid>
					<Divider style={{ backgroundColor: "#3f3f3f" }} />
					<Grid container direction='row' spacing={2} style={{ paddingBottom: "6vh" }}>
						<Grid item xs={12} sm={8} md={12} lg={12}>
							<Box
								style={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "space-between",
									alignItems: "flex-start",
								}}
								className='boxPrivacidade'
							>
								<Typography
									variant='body1'
									color='inherit'
									style={{ fontFamily: "inherit" }}
									data-testid='privacy'
								>
									Política de Privacidade
								</Typography>
								<Button
									variant='text'
									style={{ color: "white", fontFamily: "inherit" }}
								>
									<Language style={{ marginRight: "6px", color: "white" }} />{" "}
									Português (Brasil)
								</Button>
							</Box>
						</Grid>
						<Grid
							item
							xs={12}
							sm={12}
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center"
							}}>
							<Typography
								variant='body1'
								className='textoDireitosReservados'
								color='inherit'
								style={{ fontFamily: "inherit", textAlign: "center" }}
							>
								{
									nivelDeEnsino.codigo_nivel_ensino !== 351 &&
										nivelDeEnsino.nome_nivel_ensino !== "FACULDADE ALENCARINA" &&
										nivelDeEnsino.nome_nivel_ensino !== "FACULDADE 5 DE JULHO - EAD" &&
										nivelDeEnsino.nome_nivel_ensino !== "FACULDADE 5 DE JULHO" ? (
										nivelDeEnsino.codigo_nivel_ensino === 345 ? (
											<>© {ano} Todos os direitos reservados. Centro Universitário INTA - UNINTA</>
										) : (
											<>© {ano} Todos os direitos reservados. Centro Universitário INTA - UNINTA</>
										)
									) : (
										<>
											© {ano} Todos os direitos reservados. F5 - Faculdade 05 de
											Julho de Sobral
										</>
									)}
								<br />
								{/* Versão {`${new Date().getFullYear()}-${new Date().getMonth()}-${new Date().getDay()}` } {process.env.REACT_APP_API_JOB_ID ? `(${process.env.REACT_APP_API_JOB_ID})` :  ""} */}
							</Typography>
							{/* <Typography variant="body1" className="text-helper-email">
                            Você pode cancelar a assinatura a qualquer momento usando o link em nossos em-mails. Para mais detalhes, verifique nossa <Link href="#" color="inherit" target="_blank">política de privacidade.</Link>
                        </Typography> */}
						</Grid>
					</Grid>
					{/* </Grid> */}
				</Container>
			</Box>
			<DialogQuestionsAnswers open={openQA} setOpen={setOpenQA} />
			{
				user.typeUser === "tutor" ?
					<CalendarScreen open={openCalendar} onClosed={setOpenCalendar} />
					:
					<DialogSchuduleTime open={openScheduleTime} setOpen={setOpenScheduleTime} />
			}
			{
				user.typeUser === "tutor" ?
					<ListSolicitationsReviewQuestion open={openReviewQuestion} setOpen={setOpenReviewQuestion} />
					:
					<DialogReviewQuestion open={openReviewQuestion} setOpen={setOpenReviewQuestion} />
			}
		</>
	);
}
