import { Dialog, DialogTitle, Typography, Box, useTheme } from "@material-ui/core";
import { useState, useEffect, useRef } from "react";
import { getMoodleUrl } from "context/Auth/services";
import { ecossistema } from "services/api";
import { useAuth } from "context/Auth/hook";
import { Link } from "@material-ui/icons";

export default function MoodlePass(props) {
    const theme                             = useTheme();               // -- Tema da instituição do usuário
    const { user, signedInVirtual, setSignedInVirtual }      = useAuth();                // -- Usuário e sinal do AVA no contexto
    const frameRef                          = useRef(null);             // -- Ref do iframe do AVA

    const [moodleURL, setMoodleURL]         = useState<any>(null);      // -- URL de autenticação do Moodle
    const [moodleStatus, setMoodleStatus]   = 
        useState<'success'|'failed'|'idle'|'pending'>('idle');          // -- Status da autenticação no AVA
    
    /**
     * Método a ser executado quando o AVA for conectado,
     * Seta o status para success e fecha o Dialog após 1,5ss
     */
    function frameLoaded() {
        setMoodleStatus('success');
        setTimeout(()=>{
            setMoodleStatus('idle')
            props.setOpen(false)
        },1500)
        // alert("Frame loaded")
    }

    function frameError() {
        setMoodleStatus('failed')
    }


    /**
     * Método para obter a autenticação do Moodle
     * - Busca na API a URL e em seguida adiciona ao status e ao contexto
     * - Define no localStorage a URL de autenticação obtida
     * - Define no contexto o status de autenticação do AVA
     * - Define no LocalStorage o status de autenticação do AVA
     */
    const handleMoodleURL = async () => {
        try {
            setMoodleStatus('pending')
			await getMoodleUrl(user!.ra!.toUpperCase(), ecossistema)
				.then(async ({ data }: any) => {
                    setMoodleStatus('idle')
                    localStorage.setItem("@moodle:url", JSON.stringify(data))
                    setMoodleURL(data);
				})
                .then(() => {
                    setMoodleStatus('success')
                    setSignedInVirtual(true);
                    localStorage.setItem("@ava:signed:status", JSON.stringify(true));
                });
		} catch (e) {
            setMoodleStatus('failed')
            setSignedInVirtual(false);
		}
    }

    useEffect(()=>{
        setMoodleStatus('idle')
        handleMoodleURL()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return(
        <>
            <Dialog 
                // fullScreen 
                disableBackdropClick
                open={props.open} 
                onClick={() => {
                    setMoodleURL(null)
                    props.setOpen(false)
                }}
                onClose={() => {
                    if(signedInVirtual === false) {
                        return;
                    }
                    props.setOpen(false);
                }}>
                <div
                    className=''
                    style={{
                        backgroundColor: theme.palette.primary.main,
                    }}
                >
                    <DialogTitle
                            style={{ 
                                cursor: "move", 
                                color: "white",             
                            }}
                            id='draggable-dialog-title'
                        >
                            <div
                            style={{                                   
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                alignItems: "center"
                            }}>
                            <Link />
                            <Typography 
                                variant="body1" 
                                style={{ marginLeft: "12px" }}>
                                    Conectando ao Ambiente Virtual
                            </Typography>
                        </div>
                    </DialogTitle>
                </div>
                <div className="body">
                    <Box 
                        p={2}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            minHeight: "256px",
                            backgroundImage: (moodleStatus === 'failed') ? "linear-gradient(pink, white)" : "linear-gradient(#cefad0, white)"
                        }}>
                        <Typography 
                            variant="h4" 
                            style={{ 
                                marginLeft: "12px",
                                marginBottom: "12px",
                                color: (moodleStatus === 'failed') ? "brown" : "#008631"
                            }}>
                                Conectando ao Ambiente Virtual
                        </Typography>
                        <Typography 
                            variant="body1" 
                            style={{ 
                                marginLeft: "12px",
                                textAlign: "center",
                                width: "80%"
                            }}>
                            Olá! Estamos conectando seu Ambiente Virtual ao 
                            novo Ecossistema, dentro de instantes você será liberado.
                        </Typography>

                    </Box>
                    <Box 
                        p={0}
                        style={{
                            width: "35vw",
                            height: "276px",
                            display: "flex",
                            flexDirection: "column",
                            // justifyContent: "center",
                            alignItems: "center"
                        }}>
                        <iframe
                            ref={frameRef}
                            title="This is Uninta"
                            src={moodleURL ? moodleURL : "ecossistema.aiamis.com.br"}
                            onLoad={frameLoaded}
                            onError={frameError}
                            style={{
                                width: "35vw",
                                height: "256px",
                                border: "none",
                                filter: "blur(3px)",
                                opacity: ".5"
                            }}
                        />
                    </Box>
                    <Box p={2} style={{ display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <small style={{ color: "grey", textAlign: "center"}}>
                            {moodleURL}
                        </small>
                    </Box>
                </div>
            </Dialog>
        </>
    )
}