import axios, { AxiosResponse } from "axios";
import { TrilhaAprendizagem } from "models/trilhaAprendizagem";
import { Vaga } from "models/vaga";
import { depoimentos } from "services/api";
import {
	DadosFormularioCadastroDepoimento, DataGetDepoimentos
} from "../../models/depoimento";

export async function getDepoimentoAluno(id: number): Promise<AxiosResponse<any>> {
	return await depoimentos.get(`aluno/depoimentos/${id}`);
}

/**
 * @function getDepoimentosAluno
 * Realiza a requisição do tipo get para a rota 'http://localhost:3001/depoimentos/?situacao=deferido' e retorna um array contendo os depoimentos deferidos pelo tutor.
 */
export async function getDepoimentosAluno(): Promise<AxiosResponse<DataGetDepoimentos>> {
	return await depoimentos.get("aluno/depoimentos");
}

/**
 * @function getDepoimentosTutor
 * Realiza a requisição do tipo get para a rota 'http://localhost:3001/depoimentos/' e retorna um array contendo todos os depoimentos, independente da situação do depoimento.
 */
export async function getDepoimentosTutor(): Promise<AxiosResponse<any>> {
	return await depoimentos.get("tutor/depoimentos", {
		headers: { "Content-Type": "application/json" },
	});
}

export async function postDepoimento(
	data: DadosFormularioCadastroDepoimento,
): Promise<AxiosResponse<any>> {
	return await depoimentos.post("aluno/depoimentos", data, {
		headers: { "Content-Type": "application/json" },
	});
}

export async function deleteDepoimento(id: number): Promise<AxiosResponse<any>> {
	return await axios.delete(`aluno/depoimentos/${id}`);
}

/**
 * @function getTrilhasAprendizagem
 *  Realiza a requisição do tipo get para a rota 'http://localhost:3001/trilhas' e retorna um array contendo todas as trilhas de aprendizagem.
 */
export async function getTrilhasAprendizagem(): Promise<AxiosResponse<TrilhaAprendizagem[]>> {
	return await axios.get("http://localhost:3001/trilhas").then((res) => {
		return res;
	});
}

/**
 * @function getVagas
 *  Realiza a requisição do tipo get para a rota 'http://localhost:3001/vagas' e retorna um array contendo todas as vagas.
 */
export async function getVagas(): Promise<AxiosResponse<Vaga[]>> {
	return await axios.get("http://localhost:3001/vagas").then((res) => {
		return res;
	});
}

/**
 * @function getVagasBusca
 *  Realiza a requisição do tipo get para a rota 'http://localhost:3001/vagas?nome=texto digitado pelo usuário' e retorna um array contendo todas as vagas que são iguais ou iguais em parte com o texto digitado pelo usuário na busca de vagas.
 * @param {string} textoDigitadoPeloUsuario - Texto digitado pelo usuário no campo de busca de vagas na página de Oportunidades do Ecossistema.
 */
export async function getVagasBusca(
	textoDigitadoPeloUsuario: string,
): Promise<AxiosResponse<Vaga[]>> {
	return await axios.get(`http://localhost:3001/vagas?nome_like=${textoDigitadoPeloUsuario}`);
}

/**
 * @function getVagasBusca
 *  Realiza a requisição do tipo get para a rota 'http://localhost:3001/vagas?nome=texto digitado pelo usuário' e retorna um array contendo todas as vagas que são iguais ou iguais em parte com o texto digitado pelo usuário na busca de vagas.
 * @param {string} textoDigitadoPeloUsuario - Texto digitado pelo usuário no campo de busca de vagas na página de Oportunidades do Ecossistema.
 */
export async function getVagasBuscaLocalidade(
	textoDigitadoPeloUsuario: string,
): Promise<AxiosResponse<Vaga[]>> {
	return await axios.get(
		`http://localhost:3001/vagas?localizacao_like=${textoDigitadoPeloUsuario}`,
	);
}
