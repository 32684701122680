import { centralPedagogica } from "services/api";

export async function getSubjectsTutor() {
	return await centralPedagogica
		.get("tutor/assuntos")
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

export async function getSubjectListSpecific(id: number) {
	return await centralPedagogica
		.get(`tutor/agendamentos?assunto_id=${id}`)
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

export async function getScheduling(mes: number, ano: number) {
	var id = sessionStorage.getItem('IdListasCalendario');
	let assunto = id !== undefined && Number(id) > 0 ? `&assunto_id=${id}` : ``;
	return await centralPedagogica
		.get(`tutor/agendamentos?mes=${mes}&ano=${ano}${assunto}`)
		.then((response) => {
			return response;
		})
		.catch((e) => {
			return e;
		});
}

export async function putSubjectCancel(id: number, reason: string, statusReason: string) {
	return await centralPedagogica
		.put(`tutor/agendamentos/${id}`, { parecer: reason, situacao: statusReason })
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}
