import { Grid, Box, Typography, FormControl, useTheme, Button } from "@material-ui/core"
import Select from 'react-select'
import React from "react"
import { useForm, Controller } from 'react-hook-form';
import { usePedagogicalCommunication } from "context/PedagogicalCommunication/hooks/usePedagogicalCommunication";
import "./styles.scss";
import { getCourses, getPolos, getRas } from "pages/PedagogicalCommunication/components/DialogQuestionsAnswersTutor/duvidasacademicastutor.servicer";

export default function DuvidasTutoriaResponder() {
	const theme = useTheme()
	const { handleSubmit, control, reset, getValues } = useForm({ mode: "onSubmit" });
	const {
		listCourses,
		setListCourses,
		listPolos,
		setListPolos,
		handleFilter,
		getQuestionsPaginator,
		listRas,
		setListRas,
		setFilterMessageRas,
		setFilterMessageCourse,
		setFilterMessagePole
	} = usePedagogicalCommunication();

	const [clearP, setClearP] = React.useState<any>([]);
	const [clearC, setClearC] = React.useState<any>([]);
	const [clearR, setClearR] = React.useState<any>([]);

	async function filterAnswers(data: any) {

		let stringPolo = "&";
		let stringCurso = "";
		let stringRas = "";

		if (data?.polos !== undefined) {
			data?.polos.map((polo: { value: any; }) => {
				return stringPolo = `${stringPolo}polo_id=${polo.value}&`
			});
		}
		if (data?.cursos !== undefined) {
			data?.cursos.map((curso: { value: any; }) => {
				return stringCurso = `${stringCurso}curso_id=${curso.value}&`
			});
		}

		if (data?.ras !== undefined) {
			data?.ras.map((curso: { value: any; }) => {
				return stringRas = `${stringRas}ra=${curso.value}&`
			});
		}

		let stringFilter = `${stringPolo}${stringCurso}${stringRas}`
		let page = 0;

		handleFilter(stringFilter, page)

	}

	async function Clearfilter() { getQuestionsPaginator(0) }


	React.useEffect(() => {
		if ((getValues("polos")?.length === 0) || (getValues("polos") === undefined)) {
			setFilterMessageCourse([]);
			setClearC([]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getValues("polos")])

	React.useEffect(() => {
		const disciplina = true;
		getCourses(disciplina).then(({ data }) => {
			//eslint-disable-next-line array-callback-return
			data.map((curso) => {
				curso["label"] = curso["nome"]
				delete curso["nome"]
				curso["value"] = curso["id"]
				delete curso["id"]
			});
			setListCourses(data);
		})
		getPolos(disciplina).then(({ data }) => {
			//eslint-disable-next-line array-callback-return
			data.map((curso) => {
				curso["label"] = curso["nome"]
				delete curso["nome"]
				curso["value"] = curso["id"]
				delete curso["id"]
			});
			setListPolos(data);
		})
		getRas(disciplina).then(({ data }) => {
			setListRas(data);
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<Grid style={{ width: "100%" }} item md={12}>
			<Box style={{ minHeight: '35vh', maxHeight: 'auto', marginBottom: "50px", maxWidth: "auto" }}>
				<Box style={{
					padding: '12px, 24px',
					marginTop: '6vh',
					textAlign: 'center'
				}}>
					<Typography variant='h4' component='h4' style={{ fontWeight: 'bold', marginBottom: '12px' }}>
						DÚVIDAS PEDAGÓGICAS
					</Typography>
					<Typography variant='body1' component='p'>
						Olá, tutor! Este é seu espaço de interação com os alunos. Por meio desta central você
						poderá visualizar e filtrar dúvidas por polo, curso e muito mais!
					</Typography>
				</Box>
				<form onSubmit={handleSubmit((data) => filterAnswers(data))}>
					<Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: '24px', marginBottom: '40px', flexWrap: 'wrap' }}>
						<FormControl size='small' style={{ flexGrow: '1', margin: '4px' }}>
							<Controller
								name='polos'
								control={control}
								rules={{ required: false }}
								render={({
									field: { onChange },
								}) => (
									<>
										<Typography variant="body1" component="label">Polos:</Typography>
										<Select
											className="css-1rhbuit-multiValue-personalizando"
											isClearable={false}
											closeMenuOnSelect={false}
											isDisabled={(
												((getValues("ras")?.length !== 0) && (getValues("ras") !== undefined))
												||
												((getValues("cursos")?.length !== 0) && (getValues("cursos") !== undefined))
											)}
											value={clearP}
											options={listPolos}
											isMulti
											onChange={(e) => { onChange(e); setClearP(e); setFilterMessagePole(e); }}
										/>
									</>
								)} />
						</FormControl>

						<FormControl size='small' style={{ flexGrow: '1', margin: '4px' }}>
							<Controller
								name='cursos'
								control={control}
								rules={{ required: false }}
								render={({
									field: { onChange },
								}) => (
									<>
										<Typography variant="body1" component="label">Cursos:</Typography>
										<Select
											className="css-1rhbuit-multiValue-personalizando"
											isClearable={false}
											closeMenuOnSelect={false}
											isDisabled={(
												((getValues("ras")?.length !== 0) && (getValues("ras") !== undefined))
												||
												((getValues("polos")?.length === 0) || (getValues("polos") === undefined))
											)}
											value={clearC}
											options={listCourses}
											isMulti
											onChange={(e) => { onChange(e); setClearC(e); setFilterMessageCourse(e); }}
										/>
									</>
								)} />
						</FormControl>

						<Typography variant='body1' component='p' style={{ flexGrow: "1", padding: '0px', marginTop: '0px', textAlign: 'center' }}>OU</Typography>

						<FormControl size='small' style={{ flexGrow: '1', margin: '6px' }}>
							<Controller
								name='ras'
								control={control}
								rules={{ required: false }}
								render={({
									field: { onChange },
								}) => (
									<>
										<Typography variant="body1" component="label">RAs:</Typography>
										<Select
											className="css-1rhbuit-multiValue-personalizando"
											isClearable={false}
											closeMenuOnSelect={false}
											isDisabled={((getValues("polos")?.length !== 0) && (getValues("polos") !== undefined))}
											value={clearR}
											options={listRas}
											isMulti
											onChange={(e) => { onChange(e); setClearR(e); setFilterMessageRas(e) }}
										/>
									</>
								)} />

						</FormControl>
					</Box>
					<>
						<Box style={{ display: "flex", gap: "20px", width: "100%", justifyContent: "center", alignItems: "center" }}>
							<Button
								id='btnClearFilters'
								onClick={() => {
									Clearfilter();
									reset();
									setClearP([]);
									setClearC([]);
									setClearR([]);
									setFilterMessageRas([]);
									setFilterMessagePole([]);
									setFilterMessageCourse([]);
								}}
								style={{ backgroundColor: "#ffdada", color: theme.palette.primary.main, }}
							>
								LIMPAR FILTROS
							</Button>
							<Button id='btnSearch' type={"submit"} style={{ backgroundColor: theme.palette.primary.main, color: "white" }}>PESQUISAR</Button>
						</Box>
					</>
				</form>
			</Box >
		</Grid >
	)
}