import { BottomNavigation, BottomNavigationAction } from "@material-ui/core";
import { useGamificacao } from "context/Gamificacao";

export default function NavegacaoTipoRankingView() {
    const { switchRankingType, setSwitchRankingType } = useGamificacao();

    return (
        <BottomNavigation
            value={switchRankingType}
            onChange={(event, newValue) => {
                setSwitchRankingType(newValue);
            }}
            showLabels
            className="navegacao-tipo-ranking"
        >
            <BottomNavigationAction label="Geral" icon={<span className="material-icons icone-tipo-ranking">groups</span>} />
            <BottomNavigationAction label="Polo" icon={<span className="material-icons icone-tipo-ranking">domain</span>} />
            <BottomNavigationAction label="Curso" icon={<span className="material-icons icone-tipo-ranking">school</span>} />
        </BottomNavigation>
    )
}