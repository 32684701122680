import React from "react";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import { DatePicker } from "@material-ui/pickers";

import { getSubjects, getTimesAvailable } from "../scheduletime.services";
import { FormEducationMentoringProps } from "./formeducationmentoring.model";

export default function FormEducationalMentoring(
	props: FormEducationMentoringProps,
): React.ReactElement {
	const [isComputer, setIsComputer] = React.useState(true);
	const [listSubjects, setListSubjects] = React.useState([
		{
			id: undefined,
			nome: "",
		},
	]);
	const [hoursOnline, setHoursOnline] = React.useState([]);

	const handleHoursOnline = (typeHandle: "subject" | "date", value: unknown) => {
		let subjectId = props.data.assunto;
		let day = props.data.data.getDay();

		if (typeHandle === "subject") {
			props.setData("assunto", value as number);
			subjectId = value as number;
		}

		if (typeHandle === "date") {
			props.setData("data", value as Date);
			day = new Date(value as Date).getDay();

			if (((day === 0) || (day === 6))) {
				props.setData("horario", "");
			}
		}

		if ((day !== 0) && (day !== 6)) {
			getTimesAvailable(subjectId, day)
				.then((data) => {
					setHoursOnline(data.data);
				})
				.catch((e) => {
					console.log(e);
				});
		} else {
			setHoursOnline([]);
		}
	};

	// Receber o tamanho da tela
	React.useEffect(() => {
		const largura =
			window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

		if (largura < 840) {
			setIsComputer(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth]);

	React.useEffect(() => {
		getSubjects()
			.then((data) => {
				setListSubjects(data.data);
			})
			.catch((e) => {
				console.log(e);
			});
	}, []);

	return (
		<>
			<Grid item xs={12} md={6}>
				<FormControl variant='outlined' fullWidth required color='primary'>
					<InputLabel>Assunto:</InputLabel>
					<Select
						id="assunto"
						label='Assunto: *'
						value={props.data.assunto ? props.data.assunto : undefined}
						onChange={(e) => props.setData("assunto", e.target.value as number)}
					>
						{
							// eslint-disable-next-line array-callback-return
							listSubjects?.map((subject) => {
								if (subject.id !== 1 && subject.id !== 2 && subject.id !== 3) {
									return (
										<MenuItem key={subject?.id} value={subject?.id}>
											{subject?.nome}
										</MenuItem>
									);
								}
							})
						}
					</Select>
				</FormControl>
			</Grid>

			{props.data.assunto > 0 && (
				<>
					<Grid item xs={12} md={6}>
						<Box
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								background: "#fff",
								borderRadius: 4,
								border: "1px solid #dbdbdb",
								padding: 4,
								width: "100%",
								overflowX: "scroll"
							}}
						>
							<DatePicker
								autoOk
								orientation={isComputer ? "landscape" : "portrait"}
								variant='static'
								value={props.data.data}
								onChange={(e) => handleHoursOnline("date", e)}
								disablePast
								minDate={new Date(new Date().getTime() + 1 * 24 * 60 * 60 * 1000)}
							/>
						</Box>
					</Grid>

					<Grid item xs={12} md={6}>
						<FormControl variant='outlined' fullWidth required color='primary'>
							<InputLabel>
								Horários {(hoursOnline.length > 0) ? "Disponíveis" : "Indisponíveis"}:
							</InputLabel>
							<Select
								id="horarios"
								label='Horários Disponíveis: *'
								value={props.data.horario}
								onChange={(e) => props.setData("horario", e.target.value as string)}
								disabled={(hoursOnline.length <= 0)}
							>
								{hoursOnline.map((hour: any) => {
									let getHour = hour.horario.split(":");
									getHour = `${getHour[0]}:${getHour[1]}`;

									return (
										<MenuItem
											key={hour?.id}
											value={`${hour?.id}T${hour?.horario}`}
										>
											{getHour}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
					</Grid>
				</>
			)}
		</>
	);
}
