import React from "react";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";

import { DatePicker } from "@material-ui/pickers";

import { getTimesAvailable } from "../scheduletime.services";
import { FormClarificationOfDoubtsProps } from "./formclarificationofdoubts.model";

export default function FormClarificationOfDoubts(
	props: FormClarificationOfDoubtsProps,
): React.ReactElement {
	const [isComputer, setIsComputer] = React.useState(true);

	const [hoursOnline, setHoursOnline] = React.useState([]);

	const handleHoursOnline = (typeHandle: "subject" | "date", value: unknown) => {
		let day = props.data.data.getDay();

		if (typeHandle === "date") {
			props.setData("data", value as Date);
			day = new Date(value as Date).getDay();

			if (((day === 0) || (day === 6))) {
				props.setData("horario", "");
			}
		}

		if (day !== 0 && day !== 6) {
			console.log("Olá")
			getTimesAvailable(2, day)
				.then((data) => {
					setHoursOnline(data.data);
				})
				.catch((e) => {
					console.log(e);
				});
		} else {
			setHoursOnline([]);
		}
	};

	// Receber o tamanho da tela
	React.useEffect(() => {
		const largura =
			window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

		if (largura < 840) {
			setIsComputer(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth]);

	return (
		<>
			<Grid item xs={12} md={6}>
				<Box
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						background: "#fff",
						borderRadius: 4,
						border: "1px solid #dbdbdb",
						padding: 4,
						width: "100%",
						overflowX: "scroll"
					}}
				>
					<DatePicker
						autoOk
						orientation={isComputer ? "landscape" : "portrait"}
						variant='static'
						value={props.data.data}
						onChange={(e) => handleHoursOnline("date", e)}
						disablePast
						minDate={new Date(new Date().getTime() + 1 * 24 * 60 * 60 * 1000)}
						fullWidth
					/>
				</Box>
			</Grid>

			<Grid item xs={12} md={6}>
				<Box marginBottom={1}>
					<FormControl variant='outlined' fullWidth required color='primary'>
						<InputLabel>
							Horários {hoursOnline.length > 0 ? "Disponíveis" : "Indisponíveis"}:
						</InputLabel>
						<Select
							id="horarios"
							label='Horários Indisponíveis: *'
							value={props.data.horario}
							onChange={(e) => props.setData("horario", e.target.value as string)}
							disabled={hoursOnline.length <= 0}
						>
							{hoursOnline.map((hour: any) => {
								let getHour = hour.horario.split(":");
								getHour = `${getHour[0]}:${getHour[1]}`;

								return (
									<MenuItem key={hour?.id} value={`${hour?.id}T${hour?.horario}`}>
										{getHour}
									</MenuItem>
								);
							})}
						</Select>
					</FormControl>
				</Box>

				<TextField
					id="queixa"
					variant='outlined'
					label='Descreva o assunto para agendar uma videoconferência com o professor/tutor:'
					fullWidth
					required
					multiline
					minRows={5}
					maxRows={5}
					value={props.data.queixa}
					onChange={(e) => props.setData("queixa", e.target.value)}
				/>
			</Grid>
		</>
	);
}
