/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { CircularProgress, Dialog, DialogContent, DialogTitle, useTheme } from "@material-ui/core";

import ArrowBack from "@material-ui/icons/ArrowBack";
import Close from "@material-ui/icons/Close";
import MoreVert from "@material-ui/icons/MoreVert";

import { LearningResourceInterface } from "./resource.model";
import { useAuth } from "context/Auth/hook";

import ArrowBackIosNew from "@material-ui/icons/ArrowBack";
import ArrowForwardIos from "@material-ui/icons/ArrowForward";

import "./styles.scss";
import Notification from "utils/Notification";
import { apiLogsDoEcossistema } from "services/Logs";
import { USUARIO_ACESSA_AULAS_SINC, USUARIO_ACESSA_MAT_DIDAT, USUARIO_ACESSA_RECURSO_AVA, USUARIO_ACESSA_RECURSO_LAB_VIRTUAL, USUARIO_ACESSA_RECURSO_SIMULADOS, USUARIO_ACESSA_RECURSO_VIDEOAULAS, USUARIO_ACESSA_CPA } from "defaults/events";
import { MATERIAL_DIDATICO, DISCIPLINA } from "defaults/modules";
import { AnimatePresence, motion } from "framer-motion";
import { ArrowForward, MenuBook } from "@material-ui/icons";
import DisciplinaMoodle from "components/Dialogs/Moodle/Disciplina";
import { getMoodleUrl } from "context/Auth/services";
import { ecossistema } from "services/api";
// import DialogAuthAcademico from "components/Dialogs/DialogAuthAcademico";
const Grid = React.lazy(() => import("@material-ui/core/Grid"));
const Box = React.lazy(() => import("@material-ui/core/Box"));
const IconButton = React.lazy(() => import("@material-ui/core/IconButton"));
const Typography = React.lazy(() => import("@material-ui/core/Typography"));
const Hidden = React.lazy(() => import("@material-ui/core/Hidden"));
const Menu = React.lazy(() => import("@material-ui/core/Menu"));
const MenuItem = React.lazy(() => import("@material-ui/core/MenuItem"));

const Resource = React.lazy(() => import("./Components/Resource"));
const VideoClasses = React.lazy(() => import("./Components/VideoClasses"));

export default function LearningResource(props: LearningResourceInterface): React.ReactElement {
	const [open2, setOpen2] = useState<boolean>(false);         // -- Manipula o Dialog com os recursos externos
	const [remoteURL, setRemoteURL] = useState<string | null>(null);      // -- Armazena URL a ser aberta no Dialog

	const { unintaTheme, user, nivelDeEnsino, signedInVirtual, setSignedInVirtual } = useAuth();
	const theme = useTheme();
	const [openOptionsMobile, setOpenOptionsMobile] = React.useState<null | HTMLElement>(null);
	const [openVideoClasses, setOpenVideoClasses] = useState(false);
	const [displayData, setdisplayData] = useState(false);

	const [recursoAva, setRecursoAva] = useState(true);
	const [recursoSimulados, setRecursoSimulados] = useState(true);
	const [aulasSinc, setAulasSinc] = useState(true);
	const [acessaCpa, setAcessaCpa] = useState(true);
	const [acessaCpaCard, setAcessaCpaCard] = useState(true);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [loadedAva, setLoadedAva] = useState<boolean>(false);
	const [openDisplay, setOpenDisplay] = useState<boolean>(false);

	const history = useNavigate();

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setOpenOptionsMobile(event.currentTarget);
	};

	const handleClose = () => {
		setOpenOptionsMobile(null);
	};

	const onClickInDidaticMaterial = async () => {
		const token: any = localStorage.getItem("token");

		// Envia os dados do usuário para o material didático
		if (token) {
			const md = window.btoa(
				JSON.stringify({
					AccessToken: JSON.parse(token),
					idSubject: props.id_curadoria,
					ra: user.ra,
					theme: unintaTheme ? "uninta" : "fal",
					sharingIdeas: props.id_forum_partilhando_ideias,
					parent_url: process.env.REACT_APP_URL_NOVO_ECOSSISTEMA,
					user: {
						user_type: user.typeUser,
						info: user.nome
					},
					metadata: {
						curso: nivelDeEnsino.nome_curso,
						polo: nivelDeEnsino.nome_nivel_ensino,
						periodo: nivelDeEnsino.periodo,
					}
				}),
			);
			await apiLogsDoEcossistema.post('/',
				{
					ra: user.ra as string,
					module: MATERIAL_DIDATICO,
					evento: {
						label: USUARIO_ACESSA_MAT_DIDAT,
						log_info: {
							idSubject: props.id_curadoria,
							user_type: user.typeUser,
							info: user.nome
						}
					},
					metadata: {
						curso: nivelDeEnsino.nome_curso,
						polo: nivelDeEnsino.nome_nivel_ensino,
						periodo: nivelDeEnsino.periodo,
					}
				}
			)
			if (
				nivelDeEnsino.nome_nivel_ensino === "FACULDADE ALENCARINA" ||
				nivelDeEnsino.nome_nivel_ensino === "FACULDADE 5 DE JULHO" ||
				nivelDeEnsino.nome_nivel_ensino === "FACULDADE 5 DE JULHO - EAD" ||
				nivelDeEnsino.nome_nivel_ensino === "POLO F5 EAD - SOBRAL" ||
				nivelDeEnsino.codigo_nivel_ensino === 351
			) {
				return window.location.href = `${process.env.REACT_APP_URL_MATERIAL_DIDATICO}/${props.disciplina.id}/faculdade_5_de_julho/ecossistema/${md}`;
			} else {
				return window.location.href = `${process.env.REACT_APP_URL_MATERIAL_DIDATICO}/${props.disciplina.id}/ecossistema/${md}`;
			}
		} else {
			Notification("Para esta ação é necessário estar autenticado.");
		}
	};

	const onClickClose = () => {

		if (props.setEnableDialogRecursos)
			props.setEnableDialogRecursos(false);

	}

	useEffect(() => {
		const verifyVideos = () => {
			const tmp = props.disciplina.unidades.map((item) => {
				return item.videos.length > 0 ? item.videos.length : null;
			});
			const val = tmp.filter((item) => {
				return item ? (item > 0 ? true : false) : false;
			});
			val.length > 0 ? setdisplayData(true) : setdisplayData(false);
		};
		verifyVideos();
	}, [props.disciplina.unidades]);

	// const [openAuthAcademico, setOpenAuthAcademico] = React.useState(false);

	const iconLeftArrow = useMemo(() => <ArrowBackIosNew />, []);
	const iconRightArrow = useMemo(() => <ArrowForwardIos />, []);

	const scrollRight = () => {
		const area: HTMLElement | null = document.getElementById("slide");

		if (area) {
			area.scrollLeft += 280;
		}
	};

	const scrollLeft = () => {
		const area: HTMLElement | null = document.getElementById("slide");

		if (area) {
			area.scrollLeft -= 280;
		}
	};

	return (
		<div>
			<Hidden mdDown>
				<Box
					display='flex'
					flexDirection={"row"}
					justifyContent={"space-between"}
					alignItems={"center"}
					p={1}
				>
					<Typography variant='body1' style={{ fontWeight: "bold" }}>
						{" "}
						Minhas Disciplinas{" "}
					</Typography>
					<Box>
						{
							props.disciplinaBlocoNotas ?

								<IconButton
									onClick={() => {
										onClickClose();
									}}
								>
									<Close />
								</IconButton>
								:
								<IconButton
									onClick={() => {
										history("/");
									}}
								>
									<Close />
								</IconButton>
						}
					</Box>
				</Box>
			</Hidden>
			<Box
				component='div'
				className='learning_resources'
				style={{
					borderRadius: theme.shape.borderRadius,
				}}
			>
				<Grid container>
					<Grid
						item
						xs={12}
						style={{
							display: openVideoClasses ? "none" : "block",
							marginTop: "24px",
							marginBottom: "24px",
						}}
					>
						<Box
							display='flex'
							justifyContent='space-between'
							alignItems='center'
							className='header'
						>
							<Box
								display='flex'
								justifyContent='flex-start'
								alignItems='flex-start'
							// className="area_close"
							>
								{
									props.disciplinaBlocoNotas ?
										<></>
										:
										<IconButton
											onClick={() => {
												history("/");
											}}
										>
											<ArrowBack className='iconClose' />
										</IconButton>
								}
							</Box>

							<Box
								display='flex'
								justifyContent='center'
								alignItems='center'
								flexDirection='column'
								className='area_title_learning_resourses'
							>
								<Typography variant='h5' style={{ fontWeight: "bold" }}>
									{props.disciplina.name
										? props.disciplina.name.toUpperCase()
										: ""}
								</Typography>
								<Typography variant='body1'>
									Acesse os Recursos de Aprendizagem
								</Typography>
							</Box>

							<IconButton
								aria-controls='simple-menu'
								aria-haspopup='true'
								onClick={handleClick}
							>
								<MoreVert />
							</IconButton>
							<Menu
								id='simple-menu'
								anchorEl={openOptionsMobile}
								keepMounted
								open={Boolean(openOptionsMobile)}
								onClose={handleClose}
							>
								<MenuItem
									onClick={() => {
										window.open(
											"https://view.genial.ly/60df74ce3d2af40d5e922951",
										);
									}}
								>
									Como Estudar?
								</MenuItem>
								<MenuItem onClick={handleClose}>Plano de Ensino</MenuItem>
							</Menu>
						</Box>
					</Grid>

					{/**
					 * Cards de recursos de Aprendizagem
					 */}
					{/** -----------------------------------------------------------------------------------------  */}
					<Box className="slideResourcesScrow" style={{ overflow: "auto", height: openVideoClasses ? "0px" : "420px" }}>
						<Box className="slideResources">
							<IconButton style={{ display: openVideoClasses ? "none" : "flex" }} className="btn_slide_left" onClick={scrollLeft}>
								{iconLeftArrow}
							</IconButton>

							<Box className="slide" id="slide">
								<Box style={{
									display: openVideoClasses ? "none" : "flex",
									boxSizing: "border-box",
									gap: "10px"
								}}>
									<Resource
										title='Material Didático'
										subtitle='Acesse aqui o seu material didático em curadoria digital.'
										backgroundColor='#007AD9'
										display={openVideoClasses ? "none" : "flex"}
										actionClick={onClickInDidaticMaterial}
									/>

									{displayData ? (
										<Resource
											title='VideoAulas'
											subtitle='Acesse as videoaulas da sua disciplina.'
											backgroundColor='#B00F14'
											actionClick={
												async () => {
													setOpenVideoClasses(true);
													await apiLogsDoEcossistema.post('/',
														{
															ra: user.ra as string,
															module: MATERIAL_DIDATICO,
															evento: {
																label: USUARIO_ACESSA_RECURSO_VIDEOAULAS,
																log_info: {
																	idSubject: props.id_curadoria,
																	user_type: user.typeUser,
																	info: user.nome
																}
															},
															metadata: {
																curso: nivelDeEnsino.nome_curso,
																polo: nivelDeEnsino.nome_nivel_ensino,
																periodo: nivelDeEnsino.periodo,
															}
														}
													)
												}
											}
											display={openVideoClasses ? "none" : "flex"}
										/>
									) : null}

									{props.disciplina.id_simulado ? (
										<Resource
											title='Simulados'
											subtitle='Acesse os testes de simulados.'
											backgroundColor='#2C2C2C'
											display={openVideoClasses ? "none" : "flex"}
											actionClick={async () => {
												if (recursoSimulados) {
													setRecursoSimulados(false);
													await apiLogsDoEcossistema.post('/',
														{
															ra: user.ra as string,
															module: MATERIAL_DIDATICO,
															evento: {
																label: USUARIO_ACESSA_RECURSO_SIMULADOS,
																log_info: {
																	idSubject: props.id_curadoria,
																	user_type: user.typeUser,
																	info: user.nome
																}
															},
															metadata: {
																curso: nivelDeEnsino.nome_curso,
																polo: nivelDeEnsino.nome_nivel_ensino,
																periodo: nivelDeEnsino.periodo,
															}
														}
													)
													await getMoodleUrl(user.ra, ecossistema)
														.then(async ({ data }: any) => {
															setLoadedAva(false);
															window.open(`${data}&wantsurl=https://virtual.uninta.edu.br/mod/quiz/view.php?id=${props.disciplina.id_simulado}`, "_blank",);
														})
														.catch((err) => {
															setLoadedAva(false);
															setSignedInVirtual(false);
														}).finally(() => {
															setRecursoSimulados(true);
														})
													// setOpen2(true);
													// setRemoteURL(`https://virtual.uninta.edu.br/course/view.php?id=${props.id_ava}`);
													// window.open(`https://virtual.uninta.edu.br/mod/quiz/view.php?id=${props.disciplina.id_simulado}`,);
												}
											}}
										// actionClick={()=>{window.open(`https://virtual.uninta.edu.br/course/view.php?id=${props.disciplina.id_simulado}`);}}
										// https://virtual.uninta.edu.br/mod/quiz/view.php?id=:IdSIMULADO
										/>
									) : (
										<></>
									)}

									<Resource
										title='Avaliações'
										subtitle='Realize as avaliações da sua disciplina.'
										backgroundColor='#007AD9'
										display={openVideoClasses ? "none" : "flex"}
										actionClick={async () => {
											if (recursoAva) {
												setRecursoAva(false);
												/**
												 * Realiza a tentativa de abertura da sessão do AVA
												 */
												await apiLogsDoEcossistema.post('/',
													{
														ra: user.ra as string,
														module: MATERIAL_DIDATICO,
														evento: {
															label: USUARIO_ACESSA_RECURSO_AVA,
															log_info: {
																idSubject: props.id_curadoria,
																user_type: user.typeUser,
																info: user.nome
															}
														},
														metadata: {
															curso: nivelDeEnsino.nome_curso,
															polo: nivelDeEnsino.nome_nivel_ensino,
															periodo: nivelDeEnsino.periodo,
														}
													}
												)

												await getMoodleUrl(user.ra, ecossistema)
													.then(async ({ data }: any) => {
														setLoadedAva(false);
														window.open(`${data}&wantsurl=https://virtual.uninta.edu.br/course/view.php?id=${props.id_ava}`, "_blank",);
													})
													.catch((err) => {
														setLoadedAva(false);
														setSignedInVirtual(false);
													}).finally(() => {
														setRecursoAva(true);
													})

												// if(signedInVirtual) {
												//     setOpen2(true);
												//     setRemoteURL(`https://virtual.uninta.edu.br/course/view.php?id=${props.id_ava}`);
												//     // window.open(`https://virtual.uninta.edu.br/course/view.php?id=${props.id_ava}`,);
												// } else {
												//     setOpen2(true);
												//     setRemoteURL(`https://virtual.uninta.edu.br/course/view.php?id=${props.id_ava}`);
												// setOpenDisplay(true);
												// setLoadedAva(true);
												// await getMoodleUrl(user.ra, ecossistema)
												//     .then(async ({ data }: any) => {
												//         setLoadedAva(false);
												//         window.open(data, "_blank",);
												//     })
												//     .then(async() => {
												//         // setSignedInVirtual(true);
												//         setOpen2(true);
												//         setRemoteURL(`https://virtual.uninta.edu.br/course/view.php?id=${props.id_ava}`);
												//         window.open(`https://virtual.uninta.edu.br/course/view.php?id=${props.id_ava}`,);
												//     })
												//     .catch((err) => {
												//         setLoadedAva(false);
												//         setSignedInVirtual(false);
												//     })
											}
										}}
									/>

									{
										(nivelDeEnsino.codigo_nivel_ensino !== 351) &&
											(nivelDeEnsino.nome_nivel_ensino !== "FACULDADE ALENCARINA") &&
											(nivelDeEnsino.nome_nivel_ensino !== "FACULDADE 5 DE JULHO - EAD") &&
											(nivelDeEnsino.nome_nivel_ensino !== "FACULDADE 5 DE JULHO") &&
											(nivelDeEnsino.nome_nivel_ensino.search("SEMIPRESENCIAL")) &&
											props.disciplina.link_aula_sincrona ? (
											<Resource
												title='Aulas Síncronas'
												subtitle='Acompanhe os momentos síncronos.'
												backgroundColor='#B00F14'
												display={openVideoClasses ? "none" : "flex"}
												actionClick={async () => {
													if (aulasSinc) {
														setAulasSinc(false);
														await apiLogsDoEcossistema.post('/',
															{
																ra: user.ra as string,
																module: MATERIAL_DIDATICO,
																evento: {
																	label: USUARIO_ACESSA_AULAS_SINC,
																	log_info: {
																		idSubject: props.id_curadoria,
																		user_type: user.typeUser,
																		info: user.nome
																	}
																},
																metadata: {
																	curso: nivelDeEnsino.nome_curso,
																	polo: nivelDeEnsino.nome_nivel_ensino,
																	periodo: nivelDeEnsino.periodo,
																}
															}
														).finally(() => {
															window.open(`${props.disciplina.link_aula_sincrona}`);
															setAulasSinc(true);
														})
													}
												}}
											/>
										) : (
											<></>
										)
									}

									{props.disciplina.link_playlist_lab ? (
										<Resource
											title='Laboratórios'
											subtitle='Acesse os recursos para as aulas práticas.'
											backgroundColor='#0A7541'
											display={openVideoClasses ? "none" : "flex"}
											actionClick={async () => {
												if (acessaCpa) {
													setAcessaCpa(false)
													await apiLogsDoEcossistema.post('/',
														{
															ra: user.ra as string,
															module: MATERIAL_DIDATICO,
															evento: {
																label: USUARIO_ACESSA_CPA,
																log_info: {
																	idSubject: props.id_curadoria,
																	user_type: user.typeUser,
																	info: user.nome
																}
															},
															metadata: {
																curso: nivelDeEnsino.nome_curso,
																polo: nivelDeEnsino.nome_nivel_ensino,
																periodo: nivelDeEnsino.periodo,
															}
														}
													).finally(() => {
														window.open(`${props.disciplina.link_playlist_lab}`);
														setAcessaCpa(true)
													})
												}
											}}
										/>
									) : (
										<></>
									)}

									<Resource
										title='CPA'
										subtitle='Avaliação institucional da disciplina.'
										backgroundColor='#333333'
										display={openVideoClasses ? "none" : "flex"}
										actionClick={async () => {
											if (acessaCpaCard) {
												setAcessaCpaCard(false);
												// const AuthAcademico = localStorage.getItem("@AuthAcademico") as string;
												// if (AuthAcademico === null) {setOpenAuthAcademico(true);} else if (AuthAcademico === "true") {
												//  window.open('https://academico.aiamis.com.br/FrameHTML//web/app/edu/PortalEducacional/#/avaliacoes');
												// }
												await apiLogsDoEcossistema.post('/',
													{
														ra: user.ra as string,
														module: DISCIPLINA,
														evento: {
															label: USUARIO_ACESSA_CPA,
															log_info: {
																idSubject: props.id_curadoria,
																user_type: user.typeUser,
																info: user.nome
															}
														},
														metadata: {
															curso: nivelDeEnsino.nome_curso,
															polo: nivelDeEnsino.nome_nivel_ensino,
															periodo: nivelDeEnsino.periodo,
														}
													}
												).finally(() => {
													window.open('https://academico.aiamis.com.br/FrameHTML//web/app/edu/PortalEducacional/#/avaliacoes');
													setAcessaCpaCard(true);
												})
											}
										}}
									/>

								</Box>
							</Box>

							<IconButton style={{ display: openVideoClasses ? "none" : "flex" }} className="btn_slide_right" onClick={scrollRight}>
								{iconRightArrow}
							</IconButton>
						</Box>
					</Box>
					{/** -----------------------------------------------------------------------------------------  */}

					<Grid item xs={12} style={{ background: "#b00f14" }}>
						<VideoClasses
							open={openVideoClasses}
							setOpen={setOpenVideoClasses}
							display={openVideoClasses ? "block" : "none"}
							unidades={props.disciplina.unidades}
							nome_disciplina={props.disciplina?.name}//
						/>
					</Grid>
				</Grid>
			</Box>

			<Dialog
				open={openDisplay}
				onClose={() => {
					setOpenDisplay(false)
				}}>
				<div
					className=''
					style={{
						backgroundColor: theme.palette.primary.main,
						color: 'white'
					}}>
					<DialogTitle>
						<div style={{
							display: "flex",
							justifyContent: "flex-start",
							alignItems: "center"
						}}>
							<MenuBook />
							<Typography variant="body1" style={{ marginLeft: "12px" }}>
								Acessando recurso externo
							</Typography>
						</div>
					</DialogTitle>
				</div>
				<DialogContent style={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					minHeight: "396px",
					alignItems: "center"
				}}>
					<img src="/imagens-react-joyride/ecossistema-aprendizagem.svg" alt="" />
					<Typography
						variant="h5"
						style={{
							marginTop: "12px",
							marginBottom: "12px"
						}}>
						Avaliação
					</Typography>
					<Typography variant="body1">
						Atenção! Você será redirecionado para o recurso de avaliação da disciplina. Clique em continuar para prosseguir.
					</Typography>
				</DialogContent>
				<AnimatePresence>
					<motion.div
						initial={{ height: 0 }}
						animate={{
							height: 96,
						}}
						// animate={{ y: -100 }}
						transition={{ type: "spring", bounce: 0.15, duration: 0.3 }}
					>
						<div
							style={{
								cursor: "pointer",
								width: "100%",
								height: "96px",
								backgroundColor: theme.palette.primary.main,
								// color: theme.palette.primary.light,
								color: "white",
								display: "flex",
								flexDirection: "row",
								justifyContent: "center",
								padding: "0px 24px",
								alignItems: "center",
								bottom: "0px",
							}}
							onClick={() => {
								!loadedAva && window.open(`https://virtual.uninta.edu.br/course/view.php?id=${props.id_ava}`,);
							}}
						>
							{
								loadedAva
									? <CircularProgress color="secondary" />
									: <>
										<Typography variant='body1'>
											CONTINUAR PARA A AVALIAÇÃO
										</Typography>
										<span> </span>
										<ArrowForward />
									</>
							}
						</div>
					</motion.div>
				</AnimatePresence>
				{/* Animate presence com Framer Motion */}
			</Dialog>
			{/* Dialog com recursos da disciplina a ser aberta */}
			<DisciplinaMoodle open={open2} setOpen={setOpen2} url={remoteURL} />
			{/* <DialogAuthAcademico open={openAuthAcademico} setOpen={setOpenAuthAcademico}/> */}
		</div>
	);
}

