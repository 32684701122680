import React from "react";

import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import DialogDetails from "./DialogDetails";

import { getListReport } from "../dialogquestionsanswers.services";

import "./styles.scss";

export default function ListReports(): React.ReactElement {
	const [reloadDataReports, setReloadDataReports] = React.useState(false);

	const [reviewsOnView, setReviewOnView] = React.useState<any>([]);
	const [reviews, setReview] = React.useState<any>([]);

	const [openViewReport, setOpenViewReport] = React.useState(false);
	const [idReported, setIdReported] = React.useState("");
	const [typeReported, setTypeReported] = React.useState<"pergunta" | "resposta">("pergunta");

	React.useEffect(() => {
		getListReport().then((data) => {
			setReviewOnView(
				data.data.map((denuncia: any) => {
					return {
						...denuncia,
						btnAcoes: (
							<Button
								variant='contained'
								color='primary'
								onClick={() => {
									setOpenViewReport(true);
									setReview(denuncia)
									setIdReported(denuncia.id);
									setTypeReported(denuncia?.pergunta ? "pergunta" : "resposta");
								}}
							>
								Detalhes
							</Button>
						),
						qtd: denuncia.denuncias.length,
						type: denuncia?.pergunta ? "pergunta" : "resposta",
					};
				}),
			);
		}).catch((e) => { console.log(e); });
	}, [reloadDataReports]);

	return (
		<Container maxWidth='lg'>
			<Box className='area_title'>
				<Typography className='title'>Listagem de Denúncias</Typography>
			</Box>

			<div className='datatable-responsive-demo'>
				<DataTable
					value={reviewsOnView}
					paginator
					rows={10}
					className='p-datatable-responsive-demo'
					emptyMessage='Nenhum registro encontrado...'
				>
					<Column header='iD' field='id' style={{ textAlign: "center" }} />
					<Column header='Tipo' field='type' style={{ textAlign: "center" }} />
					<Column header='Qtd. Denuncias' field='qtd' style={{ textAlign: "center" }} />
					<Column header='Ações' field='btnAcoes' style={{ textAlign: "center" }} />
				</DataTable>
			</div>

			<DialogDetails
				detailsReport={reviews}
				idReported={idReported}
				typeReported={typeReported}
				open={openViewReport}
				setOpen={setOpenViewReport}
				reload={reloadDataReports}
				setReload={setReloadDataReports}
			/>
		</Container>
	);
}
