/* eslint-disable @typescript-eslint/no-unused-vars */
import { Grid, Box, Typography, FormControl } from "@material-ui/core"
import Select from 'react-select'
import React from "react"
import { useForm, Controller } from 'react-hook-form';
import { usePedagogicalCommunication } from "context/PedagogicalCommunication/hooks/usePedagogicalCommunication";
import "./styles.scss";
import { getPoleAll, getRasAll } from "../DuvidasTutoria/duvidastutoria.servicer";
import { getCouserAll } from "components/DuvidasTutoria/duvidastutoria.servicer";

export default function DuvidasTutoriaMensagem() {
    const { control, getValues } = useForm({ mode: "onSubmit" });
    const { setFilterMessageRas, setFilterMessageCourse, setFilterMessagePole } = usePedagogicalCommunication();

    const [clearP, setClearP] = React.useState<any>([]);
    const [clearC, setClearC] = React.useState<any>([]);
    const [clearR, setClearR] = React.useState<any>([]);

    const [listCourses, setListCourses] = React.useState<any>([]);
    const [listPolos, setListPolos] = React.useState<any>([]);
    const [listRas, setListRas] = React.useState<any>([]);

    React.useEffect(() => {
        setFilterMessageRas([]);
        setFilterMessageCourse([]);
        setFilterMessagePole([]);

        getPoleAll().then(({ data }) => {
            // eslint-disable-next-line array-callback-return
            data.map((polo) => {
                if (polo["nome"]) {
                    polo["label"] = polo["nome"]
                    delete polo["nome"]
                    polo["value"] = polo["id"]
                    delete polo["id"]
                }
            });
            setListPolos(data);
        }).catch((e) => { console.log(e) });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        if ((getValues("polos")?.length === 0) || (getValues("polos") === undefined)) {
            setFilterMessagePole([]);
            setFilterMessageCourse([]);
            setFilterMessageRas([]);
        } else {
            setFilterMessagePole(getValues("polos"));

            let polos = ""
            getValues("polos").map((id) => {
                return polos = `${id.value},${polos}`
            })
            polos = polos.slice(0, -1);
            getCouserAll(polos).then(({ data }) => {
                // eslint-disable-next-line array-callback-return
                data.map((polo) => {
                    if (polo["nome"]) {
                        polo["label"] = polo["nome"]
                        delete polo["nome"]
                        polo["value"] = polo["id"]
                        delete polo["id"]
                    }
                });
                setListCourses(data)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getValues("polos")])

    React.useEffect(() => {
        if ((getValues("cursos")?.length === 0) || (getValues("cursos") === undefined)) {
            setFilterMessageRas([]);
            setFilterMessageCourse([]);
        } else {
            setFilterMessageCourse(getValues("cursos"));
            let cursos = ""
            // eslint-disable-next-line array-callback-return
            getValues("cursos").map((id) => {
                console.log(id.value);
                return cursos = `${id.value},${cursos}`
            })
            cursos = cursos.slice(0, -1);
            getRasAll(cursos).then(({ data }) => {
                // eslint-disable-next-line array-callback-return
                data.map((ra) => {
                    if (ra["nome"]) {
                        ra["label"] = ra["nome"]
                        delete ra["nome"]
                        ra["value"] = ra["id"]
                        delete ra["id"]
                    }
                });
                setListRas(data);
            }).catch((e) => { console.log(e) });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getValues("cursos")])

    React.useEffect(() => {
        if ((getValues("ras")?.length === 0) || (getValues("ras") === undefined)) {
            setFilterMessageRas([])
            return
        } else {
            setFilterMessageRas(getValues("ras"))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getValues("ras")])


    return (
        <Grid style={{ width: "100%" }} item md={12}>
            <Box style={{ minHeight: '35vh', maxHeight: 'auto', marginBottom: "50px", maxWidth: "auto" }}>
                <form>
                    <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: '24px', marginBottom: '40px', flexWrap: 'wrap' }}>
                        <FormControl size='small' style={{ flexGrow: '1', margin: '4px' }}>
                            <Controller
                                name='polos'
                                control={control}
                                rules={{ required: false }}
                                render={({
                                    field: { onChange },
                                }) => (
                                    <>
                                        <Typography variant="body1" component="label">Polos:</Typography>
                                        <Select
                                            className="css-1rhbuit-multiValue-personalizando"
                                            isClearable={false}
                                            closeMenuOnSelect={false}
                                            isDisabled={(
                                                ((getValues("ras")?.length !== 0) && (getValues("ras") !== undefined))
                                                ||
                                                ((getValues("cursos")?.length !== 0) && (getValues("cursos") !== undefined))
                                            )}
                                            value={clearP}
                                            options={listPolos}
                                            isMulti
                                            onChange={(e) => { onChange(e); setClearP(e); setFilterMessagePole(e); }}
                                        />
                                    </>
                                )} />
                        </FormControl>

                        <FormControl size='small' style={{ flexGrow: '1', margin: '4px' }}>
                            <Controller
                                name='cursos'
                                control={control}
                                rules={{ required: false }}
                                render={({
                                    field: { onChange },
                                }) => (
                                    <>
                                        <Typography variant="body1" component="label">Cursos:</Typography>
                                        <Select
                                            className="css-1rhbuit-multiValue-personalizando"
                                            isClearable={false}
                                            closeMenuOnSelect={false}
                                            isDisabled={(
                                                ((getValues("ras")?.length !== 0) && (getValues("ras") !== undefined))
                                                ||
                                                ((getValues("polos")?.length === 0) || (getValues("polos") === undefined))
                                            )}
                                            value={clearC}
                                            options={listCourses}
                                            isMulti
                                            onChange={(e) => { onChange(e); setClearC(e); setFilterMessageCourse(e); }}
                                        />
                                    </>
                                )} />
                        </FormControl>

                        <Typography variant='body1' component='p' style={{ flexGrow: "1", padding: '0px', marginTop: '0px', textAlign: 'center' }}>OU</Typography>

                        <FormControl size='small' style={{ flexGrow: '1', margin: '6px' }}>
                            <Controller
                                name='ras'
                                control={control}
                                rules={{ required: false }}
                                render={({
                                    field: { onChange },
                                }) => (
                                    <>
                                        <Typography variant="body1" component="label">RAs:</Typography>
                                        <Select
                                            className="css-1rhbuit-multiValue-personalizando"
                                            isClearable={false}
                                            closeMenuOnSelect={false}
                                            isDisabled={((getValues("cursos")?.length === 0) || (getValues("cursos") === undefined))}
                                            value={clearR}
                                            options={listRas}
                                            isMulti
                                            onChange={(e) => { onChange(e); setClearR(e); setFilterMessageRas(e) }}
                                        />
                                    </>
                                )} />

                        </FormControl>
                    </Box>
                </form>
            </Box >
        </Grid >
    )
}