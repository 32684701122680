import React from "react";
import { useTheme } from "@material-ui/core";
import NavegacaoTipoRanking from "./NavegacaoTiposRanking";
import TabelaRankings from "./TabelaRankings";

export default function RankingView() {
    const theme = useTheme();

    return (
        <>
            <div className="container-icone">
                <div className="icone-ranking">
                    <span className="material-symbols-outlined" style={{ color: theme.palette.primary.main }}>
                        trending_up
                    </span>

                    <h2>Ranking</h2>
                </div>
            </div>
            <div className="ranking-gamificacao">
                <NavegacaoTipoRanking />
                <TabelaRankings />
            </div>
        </>
    );
}