import React, { useState, useEffect, useRef, Dispatch, SetStateAction } from "react";
import { Dialog, AppBar, Toolbar, IconButton, Box, useTheme, Button } from "@material-ui/core";
import { Close, Replay } from "@material-ui/icons";
import { useAuth } from "context/Auth/hook";
import { getMoodleUrl } from "context/Auth/services";
import { ecossistema } from "services/api";
import MoodlePass from "../MoodlePass";

interface DisciplinaMoodleProps {
    url?: string|null;
	open: boolean;
	setOpen: Dispatch<SetStateAction<boolean>>;
}


/**
 * 
 * @param props 
 * @returns Dialog com iFrame da URL da disciplina a ser acessada, caso
 * a aplicação não tenha conectado com o Moodle anteriormente é disparado o método de erro, que deverá forçar o Login no AVA novamente
 */
export default function DisciplinaMoodle(props: DisciplinaMoodleProps) {

    const theme                         = useTheme();
    const { user, setSignedInVirtual }  = useAuth();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [moodleURL, setMoodleURL]     = useState<any>(null);
    const frameRef = useRef<any>(null);

    const [open, setOpen] = useState<boolean>(false);

    /**
     * Define a URL a ser exibida dentro do AVA 
     */
    const handleMoodleURL = async () => {
        try {
			await getMoodleUrl(user!.ra!.toUpperCase(), ecossistema)
				.then(async ({ data }: any) => {
                    localStorage.setItem("@moodle:url", JSON.stringify(data))
                    setMoodleURL(data);
				})
                .then(() => {
                    // Notify("Autenticação no Ambiente Virtual concluída", "success");
                    setSignedInVirtual(true);
                });
		} catch (e) {
            setSignedInVirtual(false);
			// Notify("Atenção, não foi possível conectar ao Ambiente Virtual! Desabilite bloqueadores de Pop-up para navegar no Ecossitema!.", "error");
		}
    }

    const handleClose = () => {
        props.setOpen(false);
    }

    useEffect(()=>{
        const moodle = localStorage.getItem("@moodle:url")
        if(moodle) {
            setMoodleURL(JSON.parse(moodle))
        } else {
            handleMoodleURL()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return(
        <>
            <Dialog 
                fullScreen 
                open={props.open} 
                // onClick={() => {
                //     setMoodleURL(null)
                //     props.setOpen(false)
                // }}
                onClose={handleClose}>
                <AppBar
                    elevation={0} 
                    style={{
                        backgroundColor: theme.palette.primary.main
                    }}>
                    <Toolbar
                        variant="regular"
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            alignItems: "center"
                        }}>
        
                            <IconButton 
                                edge="start" 
                                color="inherit" 
                                onClick={() => {
                                    setMoodleURL(null)
                                    props.setOpen(false)
                                }}>
                                <Close />
                            </IconButton>
                            <Button 
                                color="inherit"
                                startIcon={<Replay />}
                                size="small"
                                onClick={() => {
                                    // setMoodleURL(null)
                                    setOpen(true)
                                }}
                                style={{
                                    backgroundColor: theme.palette.secondary.main,
                                    color: "black"
                                }}>
                                    Para acessar o conteúdo clique aqui
                            </Button>
                        {/* <Typography variant="body1" style={{
                            border: "solid white ",
                            padding: "6px",
                        }}>
                            Para acessar o conteúdo clique aqui
                        </Typography> */}
                    </Toolbar>
                </AppBar>
                <Toolbar />
                <Box 
                    p={0}
                    style={{
                        width: "100vw",
                        height: "100vh",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        
                        {
                            !open 
                            ?  <iframe
                                ref={frameRef}
                                title="Avaliações da Disciplina"
                                src={props.url ? props.url : "virtual.uninta.edu.br"}
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    border: "none"
                                }}
                            />
                            : <></>
                        }
                        
                </Box>
                <MoodlePass open={open} setOpen={setOpen} />
            </Dialog>
        </>
    )
}