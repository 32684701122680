import { PedagogicalCommunicationContextProps } from "./models/PedagogicalCommunicationContext";

export const INI_VALUES: PedagogicalCommunicationContextProps = {
	updateNumQuestions: false,
	updateNumAnswers: false,
	reloadListQuestions: false,
	updateNumReviews: false,
	updateNumSchedule: false,
	setUpdateNumQuestions: () => { },
	setUpdateNumAnswers: () => { },
	setReloadListQuestions: () => { },
	setUpdateNumReviews: () => { },
	setUpdateNumSchedule: () => { },

	listCourses: [""],
	setListCourses: () => { },

	listPolos: [""],
	setListPolos: () => { },

	listRas: [""],
	setListRas: () => { },

	loadingPage: [""],
	setLoadingPage: () => { },

	currentPage: 0,
	setCurrentPage: () => { },

	totalQuestions: [""],
	setTotalQuestions: () => { },

	listQuestionsView: [""],
	setListQuestionsView: () => { },

	filterCourse: [""],
	setFilterCourse: () => { },

	getQuestionsPaginator: () => { },
	handleFilterListQuestions: () => { },
	handleFilter: () => { },

	filterMessageCourse: [""],
	setFilterMessageCourse: () => { },

	filterMessagePole: [""],
	setFilterMessagePole: () => { },

	filterMessageRas: [""],
	setFilterMessageRas: () => { },
};
