import React, { useRef } from "react";
import FullCalendar, { EventClickArg, EventContentArg } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import pt from "@fullcalendar/core/locales/pt-br";
import { getScheduling } from "../../services/calendar.request";
import DialogModal from "../DialogModal";
import { Avatar, Grid, Typography } from "@material-ui/core";
import LiveHelpTwoToneIcon from "@material-ui/icons/LiveHelpTwoTone";

// interface HomeProps {
//   title: string;
//   usuario?: {
//     nome: string;
//     email: string;
//   }
// }

interface CalendarioProps {
	id: number;
	reload: boolean;
	setReload: (e: boolean) => void;
}

export default function Calendario(props: CalendarioProps): React.ReactElement {
	const [data, setData] = React.useState([]);
	const [open, setOpen] = React.useState(false);
	const [titulo, setTitulo] = React.useState("");
	const [corpo, setCorpo] = React.useState({
		id: 0,
		nome: "",
		idade: "",
		ra: "",
		email: "",
		dificuldades: "",
		medicamentos: "",
		assunto_nome: "",
		curso_nome: "",
		polo_nome: "",
		telefone: "",
		horario: "",
		data: "",
		link: "",
		situacao: "",
		queixa: "",
		parecer: "",
		medicamento: "",
		diagnostico: "",
		laudo: "",
	});
	const calendarEl = useRef<any>(null);
	let currentDate: any = new Date();

	function getMonthNumber(monthName) {
		const monthNumbers = {
			'janeiro': 1,
			'fevereiro': 2,
			'março': 3,
			'abril': 4,
			'maio': 5,
			'junho': 6,
			'julho': 7,
			'agosto': 8,
			'setembro': 9,
			'outubro': 10,
			'novembro': 11,
			"dezembro": 12
		};
		return monthNumbers[monthName];
	}

	React.useEffect(() => {
		let tituloCalendario = calendarEl.current._calendarApi.currentDataManager.data.viewTitle;

		let arrayTituloCalendario = tituloCalendario.split(" ");
		const currentMonth = getMonthNumber(arrayTituloCalendario[0]);
		const currentYear = arrayTituloCalendario[2];

		getScheduling(currentMonth, currentYear).then((response) => {
			setData(response.data);
		}).catch(console.log);

	}, [props.id, props.reload]);

	React.useEffect(() => {
		let prevButton = document.getElementsByClassName("fc-prev-button")[0];
		let nextButton = document.getElementsByClassName("fc-next-button")[0];
		prevButton.addEventListener('click', () => {
			currentDate.setMonth(currentDate.getMonth() - 1);
			let current: any = currentDate.toLocaleDateString({ month: "long", year: "numeric" });

			current = current.split("/");
			let currentMonth = current[1];
			let currentYear = current[2];

			getScheduling(currentMonth, currentYear).then((response) => {
				setData(response.data);
			}).catch(console.log);
		})
		nextButton.addEventListener('click', () => {
			currentDate.setMonth(currentDate.getMonth() + 1);
			let current: any = currentDate.toLocaleDateString({ month: "long", year: "numeric" });

			current = current.split("/");
			let currentMonth = current[1];
			let currentYear = current[2];

			getScheduling(currentMonth, currentYear).then((response) => {
				setData(response.data);
			}).catch(console.log);
		})

		const linkContainer: any = document.querySelector('.fc-scrollgrid-sync-table');
		linkContainer.addEventListener('click', (event) => {
			if (event.target.tagName === 'A') {
				let buttonGroup: any = document.getElementsByClassName("fc-button-group")[0];
				buttonGroup.setAttribute("style", "display: none;");
			}
		});
		let dayGridMonth = document.getElementsByClassName("fc-dayGridMonth-button")[0];
		dayGridMonth.addEventListener('click', () => {
			let buttonGroup: any = document.getElementsByClassName("fc-button-group")[0];
			buttonGroup.setAttribute("style", "display: flex;");
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const EventInput: any[] = [];
	let nomeClass: string;

	if (Array.isArray(data)) {
		// eslint-disable-next-line array-callback-return
		data.map((obj: any) => {
			if (obj.situacao === "pendente") {
				nomeClass = "pendente";
			} else if (obj.situacao === "concluido") {
				nomeClass = "concluido";
			} else {
				nomeClass = "cancelado";
			}

			EventInput.push({
				id: obj.assunto ? obj.assunto.id + "-" + obj.assunto.nome : "",
				title: obj.assunto ? obj.assunto.nome ?? "" : "",
				start: obj.data,
				classNames: nomeClass,
				DragEvent: false,
				extendedProps: {
					id: obj.id,
					nome: obj.profile ? obj.profile.name ?? "" : "",
					ra: obj.profile ? obj.profile.ra ?? "" : "",
					idade: obj.idade,
					email: obj.profile ? obj.profile.email ?? "" : "",
					dificuldades: obj.dificuldades,
					assunto_nome: obj.assunto ? obj.assunto.nome ?? "" : "",
					curso_nome: obj.curso ? obj.curso.nome ?? "" : "",
					polo_nome: obj.polo ? obj.polo.nome ?? "" : "",
					telefone: obj.telefone ?? "",
					data: obj.data ?? "",
					horario: obj.horario ? obj.horario.horario ?? "" : "",
					link: obj.horario ? obj.horario.link ?? "" : "",
					parecer: obj.parecer ?? "",
					situacao: obj.situacao,
					queixa: obj.queixa,
					medicamento: obj.medicamento,
					diagnostico: obj.diagnostico,
					laudo: obj.laudo,
				},
			});
		});
	}

	const handleEventClick = (clickInfo: EventClickArg) => {
		setOpen(true);
		setTitulo(clickInfo.event.title);
		setCorpo({
			id: clickInfo.event.extendedProps.id,
			nome: clickInfo.event.extendedProps.nome,
			idade: clickInfo.event.extendedProps.idade,
			ra: clickInfo.event.extendedProps.ra,
			email: clickInfo.event.extendedProps.email,
			dificuldades: clickInfo.event.extendedProps.dificuldades,
			medicamentos: clickInfo.event.extendedProps.medicamentos,
			assunto_nome: clickInfo.event.extendedProps.assunto_nome,
			curso_nome: clickInfo.event.extendedProps.curso_nome,
			polo_nome: clickInfo.event.extendedProps.polo_nome,
			telefone: clickInfo.event.extendedProps.telefone,
			horario: clickInfo.event.extendedProps.horario,
			data: clickInfo.event.extendedProps.data,
			link: clickInfo.event.extendedProps.link,
			parecer: clickInfo.event.extendedProps.parecer,
			situacao: clickInfo.event.extendedProps.situacao,
			queixa: clickInfo.event.extendedProps.queixa,
			medicamento: clickInfo.event.extendedProps.medicamento,
			diagnostico: clickInfo.event.extendedProps.diagnostico,
			laudo: clickInfo.event.extendedProps.laudo,
		});
	};

	function renderEventContent(eventContent: EventContentArg) {
		return (
			<div className={"evento"}>
				<b>{eventContent.timeText}h</b> - <span>{eventContent.event.title}</span>
			</div>
		);
	}

	return (
		<>
			<FullCalendar
				ref={calendarEl}
				plugins={[dayGridPlugin, interactionPlugin]}
				headerToolbar={{
					left: "prev,next",
					center: "title",
					right: "dayGridMonth",
				}}
				navLinks
				eventClick={handleEventClick}
				locale={pt}
				initialView='dayGridMonth'
				events={EventInput}
				eventContent={renderEventContent}
			/>

			<Grid container justifyContent='center' alignItems='center' className='footerBar'>
				<Grid container item md={4} xs={4}>
					<Grid item md={3} xs={12} container alignItems='center' justifyContent='center'>
						<Avatar className='pendente'>
							<LiveHelpTwoToneIcon />
						</Avatar>
					</Grid>
					<Grid item md={9} xs={12} container alignItems='center' justifyContent='center'>
						<Typography variant='caption'>Pendentes</Typography>
					</Grid>
				</Grid>

				<Grid item container md={4} xs={4}>
					<Grid item md={3} xs={12} container alignItems='center' justifyContent='center'>
						<Avatar className='concluido'>
							<LiveHelpTwoToneIcon />
						</Avatar>
					</Grid>
					<Grid item md={9} xs={12} container alignItems='center' justifyContent='center'>
						<Typography variant='caption'>Concluídos</Typography>
					</Grid>
				</Grid>

				<Grid item container md={4} xs={4}>
					<Grid item md={3} xs={12} container alignItems='center' justifyContent='center'>
						<Avatar className='cancelado'>
							<LiveHelpTwoToneIcon />
						</Avatar>
					</Grid>
					<Grid item md={9} xs={12} container alignItems='center' justifyContent='center'>
						<Typography variant='caption'>Cancelados</Typography>
					</Grid>
				</Grid>
			</Grid>

			<DialogModal
				titulo={titulo}
				corpo={corpo}
				open={open}
				setOpen={setOpen}
				reload={props.reload}
				setReload={props.setReload}
			/>
		</>
	);
}

export function createEventId() {
	return String(1);
}