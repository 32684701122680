import { useTheme } from "@material-ui/core";
import ListaDesafios from "./ListaDesafios";

export default function DesafiosView() {

    const theme = useTheme();

    return (
        <>
            <div className="container-icone">
                <div className="icone-desafio">
                    <span className="material-symbols-outlined" style={{ color: theme.palette.primary.main }}>
                        military_tech
                    </span>

                    <h2>Desafios</h2>
                </div>
            </div>
            <div className="desafios-gamificacao">
                <ListaDesafios />
            </div>
        </>
    );
}