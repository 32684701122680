import { AppBar, Avatar, Box, Dialog, Divider, IconButton, List, ListItem, Theme, Toolbar, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTheme } from "@material-ui/core/styles";
import { getNameInitials } from "utils/getNameInitials";
import parse from "html-react-parser";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./styles.scss";
import { getQuestionsListStudent } from "./duvidasacademicastutor.servicer";
import useNivelEnsino from "hooks/useNivelEnsino";
import { ArrowBack } from "@material-ui/icons";
import { useAuth } from "context/Auth/hook";
import makeStyles from "@material-ui/core/styles/makeStyles";
import DialogAnswer from "../DialogQuestionsAnswers/Response/DialogAnswer";
import DialogViewQuestion from "../DialogQuestionsAnswersTutor/Questions/DialogViewQuestion";
import { DialogAnswerProps } from "../DialogQuestionsAnswersTutor/Response/DialogAnswer/dialoganswer.model";
import { usePedagogicalCommunication } from "context/PedagogicalCommunication/hooks/usePedagogicalCommunication";
import { Paginator } from "primereact/paginator";
import ChatViewProfile from "./Chat";
import { motion, AnimatePresence } from "framer-motion";
import Disciplinas from "./Subjects";

const useStyles = makeStyles((theme: Theme) => ({ appBar: { zIndex: theme.zIndex.drawer + 1 } }));

export default function ViewProfile({ dialogViewPerfil, setDialogViewPerfil, ra, avatar_path, name, curso, polo, disciplina }): JSX.Element {
    const theme = useTheme();
    const [listQuestionsView, setListQuestionsView] = useState<any>([]);
    const [totalQuestions, setTotalQuestions] = useState<any>();
    const [lastPage, setLastPage] = useState<number>();
    const [currentPage, setCurrentPage] = useState<any>(0);
    const [loadingPage, setLoadingPage] = useState(true);

    const colorsTheme = useTheme();
    const { nivelDeEnsino } = useAuth();
    const classes = useStyles();

    const [openAnswer, setOpenAnswer] = React.useState(false);
    const [filterCourseSelected, setFilterCourseSelected] = React.useState('');
    const [detailsAnswer, setDetailsAnswer] = React.useState<Omit<DialogAnswerProps, "open" | "setOpen" | "course">>();
    const onOpenAnswer = (
        authorId = "",
        authorAvatar = "",
        authorName: string,
        authorCourse: string,
        authorSubject: string,
        answer: string,
        answerId: string,
        view: boolean
    ) => {
        setOpenAnswer(view ? false : true);
        setDetailsAnswer({
            authorId,
            authorAvatar,
            authorName,
            authorCourse,
            authorSubject,
            answer,
            answerId,
        });
    };

    const [openViewQuestion, setOpenViewQuestion] = React.useState(false);
    const [idQuestion, setIdQuestion] = React.useState("");

    const { updateNumAnswers } = usePedagogicalCommunication();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [loadingSendAnswer, setLoadingSendAnswer] = React.useState(false);

    function date(data: string) {
        let arrayData = data.split(" ");
        arrayData.splice(1, 1);
        arrayData = arrayData[0].split("-");
        return arrayData.reverse().join("/");
    }

    function hora(hora: string) {
        let arrayHora = hora.split(" ");
        return arrayHora[1];
    }
    const getQuestionsPaginator = async (page = 1) => {
        setLoadingPage(true);
        try {
            if(ra) {

                await getQuestionsListStudent(page, ra, disciplina).then(({ data }: any) => {
                    setLastPage(data?.last_page)
                    setTotalQuestions(data?.total);
                    setListQuestionsView(data?.data);
                    setTimeout(() => {
                        setLoadingPage(false);
                    }, 2000);
                }).catch((e) => {
                    setListQuestionsView([]);
                    return e;
                });
            }

        } catch (e) {
            // Pass
            setLastPage(1)
            setListQuestionsView([]);
            setTotalQuestions([]);
        }
    };

    useEffect(() => {
        setLoadingPage(true)
        const page = currentPage / 10 + 1;
        getQuestionsPaginator(page);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dialogViewPerfil, updateNumAnswers])

    return (
        <>
            <Dialog open={dialogViewPerfil} fullScreen={true}>
                <div>
                    <div>
                        <AppBar position='static' className={classes.appBar} elevation={1}>
                            <Toolbar
                                className='navbar_question_answers'
                                style={{ background: colorsTheme.palette.primary.main }}
                            >
                                <Box display='flex' justifyContent='flex-start' alignItems='center'>
                                    <IconButton
                                        edge='start'
                                        color='inherit'
                                        aria-label='menu'
                                        onClick={() => { setDialogViewPerfil(false); setLoadingPage(true) }}
                                    >
                                        <ArrowBack />
                                    </IconButton>

                                    <img
                                        src={useNivelEnsino(nivelDeEnsino)}
                                        className='logo'
                                        alt='Logo UNINTA'
                                        style={{
                                            padding: "12px"
                                        }}
                                    />
                                </Box>
                            </Toolbar>
                        </AppBar>

                        <Box style={{ border: "done", display: "flex", width: "100%", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                            <Box style={{ marginTop: "40px" }} >
                                <List style={{ minWidth: "40vw", maxWidth: "40vw" }}>
                                    <ListItem>
                                        <Box className='infoViewPerfil' >
                                            <Avatar
                                                src={avatar_path ? `https://dirin.s3.us-east-1.amazonaws.com/${avatar_path}` : ""}
                                                alt='Perfil'
                                                className={"img_perfil_questions"}
                                            >
                                                {getNameInitials(name ?? " ")}
                                            </Avatar>
                                            <Box>
                                                <Typography className='name_studenty'>
                                                    {name ?? " "}
                                                </Typography>
                                                <Typography className='ra_studenty'>
                                                    {curso ?? " "} - {ra ?? " "} - {polo ?? " "}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </ListItem>
                                </List>
                                <Divider />
                            </Box>
                            <Box style={{ width: "70%" }}>
                                {/* <Disciplinas userRa={ra} userName={name} /> */}
                                <AnimatePresence>
                                    <motion.div
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        exit={{ opacity: 0 }}
                                    >
                                        <Disciplinas ra={ra} name={name} />
                                    </motion.div>
                                </AnimatePresence>
                            </Box>

                            <div style={{
                                width: "100%",
                                height: "90vh"
                            }}>
                                <Box className="container-view-perfil">
                                    <>
                                        <Box className="container-view-perfil_first-child"
                                        >
                                            <Typography
                                                style={{
                                                    width: "100%",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    marginBottom: "5px",
                                                    fontSize: "20px", height: "auto"
                                                }}
                                                variant='h5'
                                            >
                                                PERGUNTAS ENVIADAS PELO ESTUDANTE
                                            </Typography>
                                            <Box
                                                style={{
                                                    width: "100%",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    // justifyContent: "center",
                                                    flexDirection: "column",
                                                }}
                                                className="containerView"
                                            >
                                                <Box className="box_view_perfil" >
                                                    <>
                                                        {loadingPage ? (
                                                            <Grid item xs={12}>
                                                                <Box className='awaiting'>
                                                                    <Typography variant='subtitle2' component='p'>Por favor, aguarde...</Typography>
                                                                    <CircularProgress />
                                                                </Box>
                                                            </Grid>
                                                        ) : (
                                                            <>
                                                                {listQuestionsView?.map((qt: any) => (
                                                                    <Box
                                                                        className='questions_area_response'
                                                                        key={qt?.id}
                                                                    >
                                                                        <Box className='question_author'>
                                                                            <Avatar
                                                                                src={qt?.profile?.avatar_path ? `https://dirin.s3.us-east-1.amazonaws.com/${qt?.profile?.avatar_path}` : ""}
                                                                                alt='Perfil'
                                                                                className={""}
                                                                            >
                                                                                {getNameInitials(qt?.profile?.name ?? " ")}
                                                                            </Avatar>
                                                                            <Box className='author_name_course'>
                                                                                <Typography className='author_name'>
                                                                                    {qt?.profile?.name ?? " "}
                                                                                </Typography>
                                                                                <Typography className='author_course'>
                                                                                    {qt?.curso?.nome ?? " "} - {qt?.profile?.ra ?? " "} - {qt?.polo?.nome ?? " "}
                                                                                </Typography>
                                                                                <Typography className='author_course'>
                                                                                    {date(qt?.created_at ?? " ")}  - {hora(qt?.created_at ?? " ")}
                                                                                </Typography>
                                                                            </Box>
                                                                        </Box>

                                                                        <Box className='question_view'>
                                                                            <Typography variant='subtitle2' style={{ overflowWrap: "break-word" }}>
                                                                                {parse(qt?.pergunta ?? " ")}
                                                                            </Typography>
                                                                        </Box>

                                                                        <Box className='question_view_actions'>
                                                                            <Button
                                                                                variant='contained'
                                                                                disableElevation
                                                                                onClick={() => {
                                                                                    if (qt?.respostas_count > 0) {
                                                                                        setOpenViewQuestion(true);
                                                                                        setIdQuestion(qt?.id);
                                                                                    } else {
                                                                                        setOpenAnswer(true);
                                                                                        setFilterCourseSelected(qt?.curso.id)
                                                                                        onOpenAnswer(
                                                                                            qt.profile.ra,
                                                                                            qt.profile.avatar_path,
                                                                                            qt.profile.name,
                                                                                            qt?.curso.nome,
                                                                                            qt?.disciplina.nome,
                                                                                            qt.pergunta,
                                                                                            qt.id,
                                                                                            false
                                                                                        );
                                                                                    }
                                                                                }}
                                                                                style={{
                                                                                    backgroundColor: qt?.respostas_count > 0 ? "#363434" : theme.palette.primary.main,
                                                                                    color: "white"
                                                                                }}
                                                                            >
                                                                                {
                                                                                    qt?.respostas_count > 0 ? `Respondido (${qt?.respostas_count})` : "Responder"
                                                                                }
                                                                            </Button>
                                                                        </Box>
                                                                    </Box>
                                                                ))}
                                                                {lastPage !== 1 ?
                                                                    <Paginator
                                                                        rows={10}
                                                                        totalRecords={totalQuestions}
                                                                        first={currentPage}
                                                                        onPageChange={(e) => {
                                                                            setLoadingPage(true)
                                                                            setCurrentPage(e.first);

                                                                            getQuestionsPaginator(e.page + 1);
                                                                        }}
                                                                        style={{
                                                                            background: "#f7f7f7",
                                                                            marginTop: 10,
                                                                        }}
                                                                    ></Paginator>
                                                                    : <></>
                                                                }
                                                            </>
                                                        )}
                                                    </>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <ChatViewProfile ra={ra} avatar_path={avatar_path} name={name} curso={curso} polo={polo} />
                                    </>
                                </Box>
                            </div>
                        </Box>
                    </div>
                </div>
            </Dialog >

            <DialogViewQuestion
                open={openViewQuestion}
                id={idQuestion}
                setOpen={setOpenViewQuestion}
                setId={setIdQuestion}
            />

            <DialogAnswer
                open={openAnswer}
                course={filterCourseSelected}
                authorId={detailsAnswer?.authorId ?? ""}
                authorName={detailsAnswer?.authorName}
                authorCourse={detailsAnswer?.authorCourse}
                authorSubject={detailsAnswer?.authorSubject}
                authorAvatar={detailsAnswer?.authorAvatar ?? ""}
                answer={detailsAnswer?.answer}
                answerId={detailsAnswer?.answerId}
                setOpen={setOpenAnswer}
            />
        </>
    );
}
