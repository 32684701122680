import { CircularProgress, Divider } from "@material-ui/core";
import { useAuth } from "context/Auth/hook";
import { Chart } from "primereact/chart";
import React, { useEffect, useState } from "react";
import { getScorePorLabelAluno } from "services/Gamificacao";
import Lottie from "lottie-react";
import ConquistaJson from "assets/Lottie/conquista.json";

interface ScorePorLabel {
    ra: string;
    logs_count: number;
    score: number;
}

export default function ItemListaDesafiosView({ numeroImagem, labelScore, numeroLabelData, pontuacaoMaximaLabel }) {
    const { user } = useAuth();

    const [loading, setLoading] = useState<boolean>(true);

    const [scorePorLabel, setScorePoLabel] = useState<ScorePorLabel>({
        ra: "",
        logs_count: 0,
        score: 0
    });

    const [labelData, setLabelData] = useState({
        label: "",
        texto: "",
    });

    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [
            {
                data: [1, 1],
                backgroundColor: [
                    "#CC0000",
                    "#34A853",
                ],
                hoverBackgroundColor: [
                    "#CC0000",
                    "#34A853",
                ]
            }]
    });

    const [lightOptions] = useState({
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                }
            }
        }
    });

    const calcularPorcentagemProgresso = () => {

        if ((scorePorLabel.logs_count !== 0) && (pontuacaoMaximaLabel !== 0)) {
            let porcentagem = Math.round((scorePorLabel.logs_count / pontuacaoMaximaLabel) * 100);

            if (porcentagem > 100) {
                return 100;
            }

            return porcentagem;
        }

        return 0;
    }

    useEffect(() => {
        if (user.ra) {
            getScorePorLabelAluno(user.ra, labelScore)
                .then(({ data }) => {
                    setLoading(false);
                    setScorePoLabel(data);
                })
                .catch((error) => {
                    setLoading(false);
                    console.log(error);
                })
        }

    }, [labelScore, user.ra]);

    useEffect(() => {

        switch (numeroLabelData) {
            case 0:
                setLabelData({
                    label: "Concluir tutorial de utilização do Ecossistema",
                    texto: "Finalizar o tutorial de apresentação do Ecossistema.",
                });
                break;
            case 1:
                setLabelData({
                    label: "Contribuições com documento",
                    texto: `Realizar ${pontuacaoMaximaLabel} contribuições de documentos nas unidades dos materiais didáticos.`,
                });
                break;
            case 2:
                setLabelData({
                    label: "Comentar contribuições de conteúdo feitas por colegas",
                    texto: `Realizar ${pontuacaoMaximaLabel} comentário(s) em conteúdos compartilhados na ferramenta “Contribua com um documento” no material didático.`,
                });
                break;
            case 3:
                setLabelData({
                    label: "Avaliar contribuições de documentos feitas por colegas",
                    texto: `Avaliar ${pontuacaoMaximaLabel} contribuições de recursos na ferramenta “Contribua com um documento” no material didático.`,
                });
                break;
            case 4:
                setLabelData({
                    label: "Realizar a leitura dos materiais indicados pelos professores",
                    texto: `Fazer a leitura de ${pontuacaoMaximaLabel} “recursos de aprendizagem” indicados nos materiais didáticos`,
                });
                break;
            case 5:
                setLabelData({
                    label: "Utilizar o “Bloco de Notas”",
                    texto: `Realize ${pontuacaoMaximaLabel} anotações no seu “Bloco de Notas” disponível no material didático.`,
                });
                break;
            case 6:
                setLabelData({
                    label: "Acessar recursos de aprofundando o conhecimento",
                    texto: `Finalizar ${pontuacaoMaximaLabel} recurso(s) do “Aprofundando o conhecimento” indicados nos materiais didáticos.`,
                });
                break;
            case 7:
                setLabelData({
                    label: "Responder pesquisa de qualidade das disciplinas",
                    texto: `Responder ${pontuacaoMaximaLabel} pesquisa(s) “Avaliar disciplina” disponível na unidade final de cada material didático.`,
                });
                break;
            case 8:
                setLabelData({
                    label: "Avaliar unidades de estudo",
                    texto: `Avaliar ${pontuacaoMaximaLabel} unidade(s) de estudo dos materiais didáticos.`,
                });
                break;
            case 9:
                setLabelData({
                    label: "Enviar dúvidas de conteúdo (Fale com o Professor)",
                    texto: `Enviar ${pontuacaoMaximaLabel} dúvida(s) de conteúdos na ferramenta “Falar com o Professor” na Central de Comunicação Pedagógica.`,
                });
                break;
            case 10:
                setLabelData({
                    label: "Responder dúvidas",
                    texto: `Responder ${pontuacaoMaximaLabel} dúvida(s) de colegas enviada(s) na central de Comunicação Pedagógica.`,
                });
                break;
            case 11:
                setLabelData({
                    label: "Comentar respostas de dúvidas de conteúdo",
                    texto: `Realizar ${pontuacaoMaximaLabel} comentário(s) em respostas de dúvidas de conteúdo na Central de Comunicação Pedagógica.`,
                });
                break;
            case 12:
                setLabelData({
                    label: "Avaliar respostas dadas por colegas e professores",
                    texto: `Realizar ${pontuacaoMaximaLabel} avaliações de respostas dada(s) por colegas e professores na ferramenta “Dúvidas de Conteúdo” na Central de Comunicação Pedagógica.`,
                });
                break;
            case 13:
                setLabelData({
                    label: "Realizar agendamento de vídeo conferência com mentoria ou tutorial",
                    texto: `Realizar ${pontuacaoMaximaLabel} agendamento(s) de vídeo conferência.`,
                });
                break;
        }

    }, [scorePorLabel, numeroLabelData, pontuacaoMaximaLabel]);

    // eslint-disable-next-line
    useEffect(() => {
        setChartData({
            labels: [],
            datasets: [
                {
                    data: [(scorePorLabel.logs_count !== 0) && (scorePorLabel.logs_count > pontuacaoMaximaLabel) ? pontuacaoMaximaLabel : scorePorLabel.logs_count, ((pontuacaoMaximaLabel !== 0) && (scorePorLabel.logs_count !== 0)) && (pontuacaoMaximaLabel - scorePorLabel.logs_count) >= 0 ? (pontuacaoMaximaLabel - scorePorLabel.logs_count) : pontuacaoMaximaLabel],
                    backgroundColor: [
                        "#34A853",
                        "#CC0000",
                    ],
                    hoverBackgroundColor: [
                        "#34A853",
                        "#CC0000",
                    ]
                }]
        })
    }, [scorePorLabel.logs_count, pontuacaoMaximaLabel, scorePorLabel]);

    return (
        <>
            <li>
                <div className="imagem-desafio">
                    <img src={`imagens-desafios-gamificacao/desafio-0${numeroImagem}.svg`} alt={`Desafio 0${numeroImagem}`} />
                </div>

                <div className="conteudo-desafio">
                    <h3>{labelData.label}</h3>
                    <p>{labelData.texto}</p>
                </div>


                {
                    loading ?
                        <div className="progresso-desafio">
                            <CircularProgress />
                        </div>
                    :
                        (scorePorLabel.logs_count !== 0) && (scorePorLabel.logs_count >= pontuacaoMaximaLabel) ?

                            <div className="lottie-desafio">
                                <Lottie className="lottie-conquista" animationData={ConquistaJson} width="100%" height="100%" />
                            </div>
                            :
                            <div className="grafico-desafio">
                                <Chart className="grafico" type="doughnut" data={chartData} options={lightOptions} width="50%" height="50%" />
                                <div className="progresso-desafio">
                                    <span>{calcularPorcentagemProgresso()}%</span>
                                </div>
                            </div>
                }
            </li>
            <Divider />
        </>
    );
}
