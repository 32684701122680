import React from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import FormLabel from "@material-ui/core/FormLabel";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import ArrowBack from "@material-ui/icons/ArrowBack";
import Replay from "@material-ui/icons/Replay";
import School from "@material-ui/icons/School";
import Search from "@material-ui/icons/Search";
import LabelImportant from "@material-ui/icons/LabelImportant";

import DialogDetails from "./DialogDetails";

import { getSolicitationsTutor } from "../DialogReviewQuestion/FormReviewQuestion/formreviewquestion.services";
import { getListCourses } from "./listsolicitationreview.services";

import "./styles.scss";
import { makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@material-ui/core";

const useStyles = makeStyles({
	root: {
		width: '100%',
	},
	// container: {
	// 	maxHeight: 440,
	// },
});

export default function ListSolicitationsReviewQuestion(props: {
	open: boolean;
	setOpen: (e: boolean) => void;
}): React.ReactElement {
	const classes = useStyles();
	const [reviewsOnView, setReviewOnView] = React.useState([] as any);
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const [totalPages, setTotalPages] = React.useState(1);

	// Dialogo de Detalhes
	const [openDetails, setOpenDetails] = React.useState(false);
	const [idReview, setIdReview] = React.useState(0);
	const [reload, setReload] = React.useState(false);

	// Filters
	const [filterStatus, setFilterStatus] = React.useState("pendente");
	const [allCourses, setAllCourses] = React.useState([]);
	const [courseFiltered, setCourseFiltered] = React.useState<any[]>([]);
	const [nameFilteredCourse, setNameFilteredCourse] = React.useState("");
	const [courseSelected, setCourseSelected] = React.useState({
		id: "",
		name: "",
	});

	const [openFilterCourse, setOpenFilterCourse] = React.useState(false);

	const onHandleFilterHead = (type: "status" | "course", value: any) => {
		if (type === "status") {
			setFilterStatus(value as string);
			setPage(0);
		} else if (type === "course") {
			setCourseSelected(value);
			setOpenFilterCourse(false);
			handleFilter({ target: { value: "" } });
			setPage(0);
		}
	};

	const handleChangePage = (
		event: React.MouseEvent<HTMLButtonElement> | null,
		newPage: number,
	) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
	) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleFilter = (search: any) => {
		setNameFilteredCourse(search.target.value);

		if (search.target.value) {
			const lowerSearch = search.target.value.toLowerCase();
			const results = allCourses.filter((data: any) => {
				return data.nome.toLowerCase().includes(lowerSearch);
			});
			setCourseFiltered(results);
		} else {
			setCourseFiltered(allCourses);
		}
	};

	React.useEffect(() => {
		if (props.open) {
			setFilterStatus("pendente");
			setPage(0);
			setCourseSelected({ id: "", name: "" });
		}
	}, [props.open, reload]);

	React.useEffect(() => {
		getSolicitationsTutor(courseSelected.id ? courseSelected.id : "" ,filterStatus, page + 1)
			.then((data) => {
				console.log(data);
				const results: any[] = data.data
					.map((revisao: any) => {
						let dateFormat = revisao.created_at.split(" ")?.[0];
						dateFormat = dateFormat.split("-");
						dateFormat = `${dateFormat[2]}/${dateFormat[1]}/${dateFormat[0]}`;

						const situationColor =
							revisao.situacao === "deferido"
								? "deferred"
								: revisao.situacao === "indeferido"
									? "rejected"
									: "";

						return {
							date: dateFormat,
							subject: revisao.disciplina.nome,
							course: revisao.curso.nome,
							ra: revisao.profile.ra,
							situation: revisao.situacao,
							course_id: revisao.curso.id,
							status: (
								<FormLabel className={`tag_situation ${situationColor}`}>
									{revisao.situacao}
								</FormLabel>
							),
							clerk:
								revisao.tutor && revisao.tutor.name !== "NULL" ? revisao.tutor.name : "-",
							actions: (
								<Button
									id='btnDetailAttendance'
									variant='contained'
									style={{ background: "#1488aa", color: "#fff" }}
									onClick={() => {
										setIdReview(revisao.id);
										setOpenDetails(true);
									}}
								>
									Detalhes
								</Button>
							),
						};
					})
					.reverse();

				setRowsPerPage(data.per_page);
				setTotalPages(data.total);
				setReviewOnView(results);
			})
			.catch((e) => {
				console.log(e);
			});

	}, [courseSelected, filterStatus, page]);

	React.useEffect(() => {
		getListCourses()
			.then((data) => {
				setAllCourses(data);
				setCourseFiltered(data);
			})
			.catch((e) => {
				console.log(e);
			});
	}, []);

	return (
		<>
			<Dialog
				open={props.open}
				onClose={() => {
					props.setOpen(false);
				}}
				fullScreen
			>
				<AppBar position='static'>
					<Toolbar className='navbar_header'>
						<Box display='flex' justifyContent='flex-start' alignItems='center'>
							<IconButton
								id='btnArrowBack'
								edge='start'
								color='inherit'
								aria-label='menu'
								onClick={() => {
									props.setOpen(false);
								}}
							>
								<ArrowBack />
							</IconButton>

							<img src='/logo_white.svg' alt='Imagem UNINTA' className='logo' />
						</Box>
					</Toolbar>
				</AppBar>

				<DialogContent style={{ paddingBottom: "24px" }}>
					<Box className='area_title'>
						<Typography className='title'>Solicitações de Revisão</Typography>
					</Box>

					<Box className='filters_header'>
						<FormControl variant='standard' style={{ minWidth: 100 }}>
							<InputLabel>Status:</InputLabel>
							<Select
								id='selectStatus'
								label='Status:'
								value={filterStatus}
								onChange={(e) =>
									onHandleFilterHead("status", e.target.value as string)
								}
							>
								<MenuItem value='all'>Todos</MenuItem>
								<MenuItem value='pendente'>Pendente</MenuItem>
								<MenuItem value='deferido'>Deferido</MenuItem>
								<MenuItem value='indeferido'>Indeferido</MenuItem>
							</Select>
						</FormControl>

						<Box className='divider_filter' />

						<Button
							id='filterCourse'
							variant='text'
							startIcon={<School />}
							onClick={() => setOpenFilterCourse(true)}
						>
							{courseSelected.id !== "" ? courseSelected.name : "Filtrar por Curso"}
						</Button>

						<Box className='divider_filter' />

						<Button
							id='btnDateRefresh'
							variant='text'
							startIcon={<Replay />}
							onClick={() => {
								setReload(!reload);
							}}
						>
							Recarregar Dados
						</Button>
					</Box>

					<Paper className={classes.root}>
						<TableContainer>
							<Table stickyHeader aria-label="sticky table">
								<TableHead>
									<TableRow>
										<TableCell align="center"> Data </TableCell>
										<TableCell align="center"> Curso </TableCell>
										<TableCell align="center"> Disciplina </TableCell>
										<TableCell align="center"> Estudante </TableCell>
										<TableCell align="center"> Status </TableCell>
										<TableCell align="center"> Atendido Por </TableCell>
										<TableCell align="center"> Ações </TableCell>
									</TableRow>
								</TableHead>

								<TableBody>
									{
										reviewsOnView.length > 0 ?
											reviewsOnView.map((row, index) => {
												return (
													<TableRow hover key={index}>
														<TableCell align="center"> {row.date} </TableCell>
														<TableCell align="center"> {`${row.course} - ${row.course_id}`} </TableCell>
														<TableCell align="center"> {row.subject} </TableCell>
														<TableCell align="center"> {row.ra} </TableCell>
														<TableCell align="center"> {row.status} </TableCell>
														<TableCell align="center"> {row.clerk} </TableCell>
														<TableCell align="center"> {row.actions} </TableCell>
													</TableRow>
												);
											})
											:
											<TableRow>
												<TableCell align="center" colSpan={7}>
													<Typography variant='h6'> Nenhum conteúdo disponível </Typography>
												</TableCell>
											</TableRow>
									}
								</TableBody>
							</Table>
						</TableContainer>
						{
							totalPages > 10 ?
								<TablePagination
									style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}
									rowsPerPageOptions={[]}
									component="div"
									align="center"
									count={totalPages}
									rowsPerPage={rowsPerPage}
									page={page}
									onPageChange={handleChangePage}
									onRowsPerPageChange={handleChangeRowsPerPage}
								/>
								:
								<></>
						}
					</Paper>
				</DialogContent>
			</Dialog>

			<DialogDetails
				open={openDetails}
				idReview={idReview}
				reload={reload}
				setOpen={setOpenDetails}
				setReload={setReload}
			/>

			<Dialog
				open={openFilterCourse}
				onClose={() => {
					setOpenFilterCourse(false);
					handleFilter({ target: { value: "" } });
				}}
				fullWidth
				maxWidth='sm'
			>
				<DialogTitle style={{ background: "#bd0e0e" }}>
					<Typography
						style={{
							fontSize: 24,
							fontWeight: "bold",
							color: "#fff",
							textAlign: "center",
						}}
					>
						Cursos
					</Typography>
				</DialogTitle>

				<DialogContent>
					<FormControl variant='outlined' fullWidth>
						<OutlinedInput
							style={{ fontSize: 18 }}
							id='outlined-adornment-weight'
							endAdornment={<Search />}
							aria-describedby='outlined-weight-helper-text'
							placeholder='Digite o nome do curso...'
							onChange={(e) => {
								handleFilter(e);
							}}
							value={nameFilteredCourse}
						/>
					</FormControl>

					<List>
						<ListItem
							button
							onClick={() => {
								onHandleFilterHead("course", { id: "", name: "" });
							}}
						>
							<Typography
								className={`item_menu_course ${!(courseSelected?.id !== "") && "selected"}`}
							>
								<LabelImportant style={{ color: "#555" }} />
								Todos
							</Typography>
						</ListItem>

						{courseFiltered?.map((curso: any, index: number) => {
							return (
								<ListItem
									key={index}
									button
									onClick={() => {
										onHandleFilterHead("course", {
											id: curso.id,
											name: curso.nome,
										});
									}}
								>
									<Typography
										className={`item_menu_course ${courseSelected?.id === curso.id && "selected"
											}`}
									>
										<LabelImportant style={{ color: "#555" }} />
										{`${curso.nome.toLowerCase()} - ${curso.id}`}
									</Typography>
								</ListItem>
							);
						})}
					</List>
				</DialogContent>
			</Dialog>
		</>
	);
}
