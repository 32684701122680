import React from "react";
import parse from "html-react-parser";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormHelperText from "@material-ui/core/FormHelperText";
import { CENTRAL_PEDAGOGICA } from "defaults/modules";
import { ALUNO_RESPONDE_DUVIDA_CONTEUDO } from "defaults/events";
import { apiLogsDoEcossistema } from "services/Logs";
import { removerTags } from "utils/removeTagsHTML";

import Notify from "utils/Notification";

import { usePedagogicalCommunication } from "context/PedagogicalCommunication/hooks/usePedagogicalCommunication";
import { useAuth } from "context/Auth/hook";

import { storeAnswer } from "../../dialogquestionsanswers.services";
import { DialogAnswerProps } from "./dialoganswer.model";
// import AvatarProfile from "../../../../../../components/AvatarProfile";
import { Avatar, IconButton, useMediaQuery, useTheme } from "@material-ui/core";
import { Editor } from "primereact/editor";
import { Close } from "@material-ui/icons";
import { getNameInitials } from "utils/getNameInitials";
import { Send } from "@material-ui/icons";

export default function DialogAnswer(props: DialogAnswerProps) {
	const { user, nivelDeEnsino } = useAuth();
	const { updateNumAnswers, setUpdateNumAnswers, reloadListQuestions, setReloadListQuestions, updateNumQuestions, setUpdateNumQuestions } = usePedagogicalCommunication();

	const [answer, setAnswer] = React.useState("");
	const [loadingSendAnswer, setLoadingSendAnswer] = React.useState(false);

	const theme = useTheme();

	const handleCloseDialog = () => {
		props.setOpen(false);
		setAnswer("");
	};

	const sendAnswer = async () => {
		if (!answer || !user.ra || !props.answerId || !props.course) {
			Notify("Preencha todos campos", "warning");
			return;
		}

		if (removerTags(answer).length > 1000) {
			Notify("O texto da resposta ultrapassou 1000 caracteres.", "warning");
			return;
		}

		setLoadingSendAnswer(true);
		await storeAnswer(
			user.ra ?? "",
			props.answerId ?? "",
			props.course,
			answer,
			user.typeUser === "tutor" ? "1" : "0",
		)
			.then(async (data) => {
				Notify("Sua resposta foi enviada!", "success");
				setUpdateNumAnswers(!updateNumAnswers);
				setReloadListQuestions(!reloadListQuestions)
				setUpdateNumQuestions(!updateNumQuestions)
				await apiLogsDoEcossistema.post('/',
					{
						ra: user.ra as string,
						module: CENTRAL_PEDAGOGICA,
						evento: {
							label: ALUNO_RESPONDE_DUVIDA_CONTEUDO,
							log_info: {
								user_type: user.typeUser,
								codigo_nivel_ensino: nivelDeEnsino.codigo_nivel_ensino,
								nome_nivel_ensino: nivelDeEnsino.nome_nivel_ensino,
								nome_curso: nivelDeEnsino.nome_curso,
								periodo: nivelDeEnsino.periodo,
							}
						},
						metadata: {
							curso: nivelDeEnsino.nome_curso,
							polo: nivelDeEnsino.nome_nivel_ensino,
							periodo: nivelDeEnsino.periodo,
						}
					}
				)
				handleCloseDialog();
				setLoadingSendAnswer(false);
			})
			.catch((e) => {
				Notify("Sua resposta não foi cadastrada!", "error");
				handleCloseDialog();
				setLoadingSendAnswer(false);
				console.log(e);
			});
	};

	const fullScreen = useMediaQuery(theme.breakpoints.down(800));

	return (
		<Dialog open={props.open} onClose={() => handleCloseDialog()} fullScreen={fullScreen} fullWidth maxWidth='md'>
			<DialogTitle style={{ background: theme.palette.primary.main }}>
				<div style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					position: "relative"
				}}>
					<Typography className='titleDialogQuestion'>Questão Selecionada</Typography>
					<IconButton id='btnCloseViewSendResponse' style={{ color: "white", position: "absolute", right: "0px" }} onClick={() => props.setOpen(false)}>
						<Close />
					</IconButton>
				</div>
			</DialogTitle>
			<DialogTitle style={{ padding: "0.9rem" }}>
				<Box className='questions_area_response_dialog'>
					<Box className='question_author'>
						<Avatar
							className={"img_perfil_questions"}
							src={props.authorAvatar ?? ""}
							alt='Perfil'
						>
							{getNameInitials(props.authorName ? props.authorName.toLocaleLowerCase() : "")}
						</Avatar>

						<Box className='author_name_course'>
							<Typography className='author_name'>
								{props.authorName ? props.authorName.toLocaleLowerCase() : ""}
							</Typography>
							<Typography className='author_course'>
								{props.authorCourse} - {props.authorSubject}
							</Typography>
						</Box>
					</Box>

					<Box className='question_view'>
						<Typography variant='subtitle2'>{parse(props.answer ?? "")}</Typography>
					</Box>
				</Box>
			</DialogTitle>

			<DialogContent dividers style={{ padding: "0.9rem" }}>
				{/* <FormHelperText
					error={answer.length > 1000}
					style={{ margin: 0, padding: 0, textAlign: "right" }}
				>
					{answer.length} de 1000 caracteres
				</FormHelperText>
				<TextField
					label='Resposta:'
					placeholder='Digite sua resposta aqui'
					variant='outlined'
					fullWidth
					multiline
					minRows={4}
					maxRows={4}
					value={answer}
					onChange={(e) => setAnswer(e.target.value)}
					disabled={loadingSendAnswer}
					id='inputNewResponse'
					inputProps={{ maxLength: 1000 }}
					error={answer.length > 1000}
					style={{ margin: 0, padding: 0 }}
				/> */}
				<FormHelperText
					error={removerTags(answer).length > 2000}
					style={{ textAlign: "right" }}
				>
					{removerTags(answer).length} de 2000 caracteres
				</FormHelperText>
				<Editor
					style={{ height: "200px" }}
					value={answer}
					onTextChange={(e) => {
						setAnswer(e.htmlValue as string);
					}}
					id='editorNewQuestion'
				/>

				<Box display='flex' justifyContent='flex-end' style={{ marginTop: '12px' }}>
					<Button
						id='btnCancel'
						variant='text'
						color='primary'
						onClick={() => handleCloseDialog()}
						disabled={loadingSendAnswer}
						style={{
							marginRight: "12px",
							color: theme.palette.primary.main
						}}>
						Cancelar
					</Button>
					<Button
						id='btnSendResponse'
						disableElevation
						variant='contained'
						// color='primary'
						onClick={() => sendAnswer()}
						disabled={loadingSendAnswer}
						style={{
							color: "white",
							backgroundColor: theme.palette.primary.main
						}}
					>
						{loadingSendAnswer ? (
							<CircularProgress
								style={{ color: "#fff", width: "1.5rem", height: "1.5rem" }}
								thickness={4.4}
							/>
						) : (
							<>
								{/* <EmojiPeople /> */}
								<span>Enviar Resposta</span>
								<Send />
							</>
						)}
					</Button>
				</Box>
			</DialogContent>
		</Dialog>
	);
}
