// components/Image/index.tsx 
import { Skeleton } from "@material-ui/lab";
import React, { useCallback, useEffect, useState } from "react";

interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
    heightPlaceholder?: string;
}

export default function Imagem({ heightPlaceholder, src, alt, ...props }: ImageProps) {
    const [imgSrc, setSrc] = useState<string | undefined>("/");

    const onLoad = useCallback(() => {
        setSrc(src);
    }, [src]);

    useEffect(() => {
        const img = new Image();
        
        img.src = src as string;
        img.addEventListener("load", onLoad);

        return () => {
            img.removeEventListener("load", onLoad);
        };
    }, [src, onLoad]);

    return (
        <>
            {
                imgSrc === src ?
                    <img {...props} src={imgSrc} alt={alt} />
                :
                    <Skeleton variant="rect" height={heightPlaceholder} />
            }
        </>
    );

};