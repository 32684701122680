import { Box, Typography, Dialog, useTheme } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import ListaContribuicoes from "components/Tabelas/TabelaDisciplinas/ListaContribuicoes";
import NoContentAvailable from "components/NoContentAvailable";
import { Contribuicao } from "models/tabelaDisciplinas";
// import { Contribuicao, MetaContribuicao } from "models/tabelaDisciplina";
import { useEffect, useState } from "react";
import { getContribuicoes } from "services/TabelaDisciplinas";

export default function DialogContribuicoesView(props): JSX.Element {
	const theme = useTheme();
	const [excessaoNaRequisicao, setExcessaoNaRequisicao] = useState<boolean>(true);
	const [contribuicoes, setContribuicoes] = useState<Contribuicao[]>([]);
	const idUnidadeDisciplinaContribuicao = Number(
		localStorage.getItem("idUnidadeDisciplinaContribuicao") as string,
	);

	useEffect(() => {
		if (idUnidadeDisciplinaContribuicao !== 0) {
			getContribuicoes(idUnidadeDisciplinaContribuicao.toString())
				.then((res) => {
					setContribuicoes(res.data.data);
					setExcessaoNaRequisicao(false);
				})
				.catch(() => {
					setExcessaoNaRequisicao(true);
				});
		}
	}, [idUnidadeDisciplinaContribuicao]);

	function retornarConteudo(): JSX.Element {
		switch (excessaoNaRequisicao) {
			case false:
				return <ListaContribuicoes contribuicoes={contribuicoes} />;
			case true:
				return <NoContentAvailable />;
		}
	}

	return (
		<Dialog
			fullScreen
			open={props.dados.enableDialogContribuicoes}
			onClose={() => {
				localStorage.removeItem("idUnidadeDisciplinaContribuicao");
				props.dados.setEnableDialogContribuicoes(false);
			}}
		>
			<div>
				<div className='body'>
					<div className='head' style={{ backgroundColor: theme.palette.primary.main }}>
						<Typography
							variant='body1'
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								marginLeft: "0px",
							}}
						>
							<ArrowBack
								style={{ color: "#FFFFFF", cursor: "pointer", marginRight: "10px" }}
								onClick={() => {
									localStorage.removeItem("idUnidadeDisciplinaContribuicao");
									props.dados.setEnableDialogContribuicoes(false);
								}}
							/>
							MINHAS CONTRIBUIÇÕES
						</Typography>
					</div>

					<Box p={3} style={{ border: "none" }}>
						{retornarConteudo()}
					</Box>
				</div>
			</div>
		</Dialog>
	);
}
