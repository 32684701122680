import Joyride, { CallBackProps } from "react-joyride";
import { useAuth } from "context/Auth/hook";
import "./styles.scss";
import { useTheme } from "@material-ui/core";
import { useNavigate } from "react-router";
import { apiLogsDoEcossistema } from "services/Logs";
import { TUTORIAL } from "defaults/modules";
import { USUARIO_CONCLUIU_TUTORIAL } from "defaults/events";
import { useState } from "react";

export function ReactJoyrideView() {
	const theme = useTheme();
	const history = useNavigate();
	const { user, runReactJoyride, setRunReactJoyride, nivelDeEnsino } = useAuth();
	// eslint-disable-next-line
	const [clicouLink, setClicouLink] = useState<boolean>(false);
	let botao;
	let botaoClose;

	const handleJoyrideCallback = async (data: CallBackProps) => {
		const { action, status, index, type, lifecycle } = data;

		if (action === "next" && type === "step:after" && index === 14) {
			history("/portfolio");
		}

		if (action === "prev" && type === "step:after" && index === 15) {
			history("/");
		}

		if (action === "next" && status === "finished") {
			setClicouLink(false);
			history("/");
		}

		if (!clicouLink) {
			if (action === "next" && lifecycle === "complete" && index === 22) {
				botao = document.querySelector("button[title='Próxima etapa']");
				botao?.setAttribute("class", "esconder-botao-proxima-etapa");

				botaoClose = document.querySelector("button[title='Close']");
				botaoClose?.setAttribute("class", "esconder-botao-close");
			}
		}

		if (action === "skip" && lifecycle === "complete" && index === 23) {
			setClicouLink(false);
		}

		if (status === "finished") {

			if (user.ra && user.typeUser && (user.typeUser !== "tutor") && nivelDeEnsino.nome_curso && nivelDeEnsino.nome_nivel_ensino && nivelDeEnsino.periodo) {
				await apiLogsDoEcossistema.post('/',
					{
						ra: user.ra as string,
						module: TUTORIAL,
						evento: {
							label: USUARIO_CONCLUIU_TUTORIAL,
							log_info: {
								ra: user.ra,
								user_type: user.typeUser,
								message: `O usuário ${user.ra} finalizou o tutorial com sucesso!`
							}
						},
						metadata: {
							curso: nivelDeEnsino.nome_curso,
							polo: nivelDeEnsino.nome_nivel_ensino,
							periodo: nivelDeEnsino.periodo,
						}
					}
				).catch((error) => {
					console.log(error);
				});
			}

			setRunReactJoyride(false);

		} else if (status === "skipped") {
			setRunReactJoyride(false);
		}
	};

	return (
		<>
			{user.signed ? (
				<Joyride
					callback={handleJoyrideCallback}
					run={runReactJoyride}
					continuous
					showSkipButton
					disableOverlayClose={true}
					disableCloseOnEsc={true}
					steps={[
						{
							content: (
								<div>
									<img
										alt='Boas Vindas'
										src='imagens-react-joyride/boas-vindas.svg'
									/>
									<h3>Vamos iniciar a sua jornada!</h3>
								</div>
							),
							locale: {
								skip: <strong aria-label='skip'>Pular</strong>,
								next: "Próxima etapa",
							},
							placement: "center",
							target: "body",
						},
						{
							content: (
								<div>
									<img
										alt='Minhas Disciplinas'
										src='imagens-react-joyride/minhas-disciplinas.svg'
									/>
									<h3 className='titulo-react-joyride'>Suas disciplinas</h3>
									<p>
										Aqui você encontra todas as disciplinas disponíveis nesse
										período letivo. Você poderá acessar todos os recursos de
										aprendizagem disponíveis.
									</p>
								</div>
							),
							locale: {
								skip: <strong aria-label='skip'>Pular</strong>,
								next: "Próxima etapa",
								back: "Voltar",
							},
							spotlightClicks: true,
							placement: "left",
							target: ".minhas-disciplinas-carousel",
						},
						{
							content: (
								<div>
									<img
										alt='Cursos e Eventos'
										src='imagens-react-joyride/cursos-eventos.svg'
									/>
									<h3 className='titulo-react-joyride'>Seus Cursos e Eventos</h3>
									<p>
										Todos os cursos e eventos que você estiver participando
										estarão disponíveis nesse espaço. Você receberá notificações
										de eventos novos toda semana.
									</p>
								</div>
							),
							locale: {
								skip: <strong aria-label='skip'>Pular</strong>,
								next: "Próxima etapa",
								back: "Voltar",
							},
							spotlightClicks: true,
							placement: "right",
							target: ".cursos-eventos-carousel",
						},
						{
							content: (
								<div>
									<img
										alt='Sistema Acadêmico'
										src='imagens-react-joyride/sistema-academico.svg'
									/>
									<h3 className='titulo-react-joyride'>
										Acesso ao Sistema Acadêmico
									</h3>
									<p>
										Você encontrará rapidamente todos os links de acesso as
										principais atividades do setor acadêmico como: rematrícula,
										boletos e demais solicitações de atendimento.
									</p>
								</div>
							),
							spotlightClicks: true,
							locale: {
								skip: <strong aria-label='skip'>Pular</strong>,
								next: "Próxima etapa",
								back: "Voltar",
							},
							target: ".academico-navbar",
						},
						{
							content: (
								<div>
									<img
										alt='Sistema Acadêmico'
										src='imagens-react-joyride/biblioteca-virtual.svg'
									/>
									<h3 className='titulo-react-joyride'>
										Acesso a Biblioteca Virtual
									</h3>
									<p>
										Acesse a qualquer hora milhares de títulos técnicos,
										acadêmicos e científicos de diversas áreas do conhecimento.
									</p>
								</div>
							),
							locale: {
								skip: <strong aria-label='skip'>Pular</strong>,
								next: "Próxima etapa",
								back: "Voltar",
							},
							target: ".biblioteca-navbar",
						},
						{
							content: (
								<div>
									<img
										alt='Comunicação Pedagógica'
										src='imagens-react-joyride/comunicacao-pedagogica.svg'
									/>
									<h3 className='titulo-react-joyride'>Comunicação Pedagógica</h3>
									<p>
										Este é o espaço destinado a realização de solicitações de
										atendimentos pedagógicos como: esclarecimentos de dúvidas de
										conteúdos com professores e colegas, chat com o tutor dentre
										outros recursos de comunicação e interação.
									</p>
								</div>
							),
							locale: {
								skip: <strong aria-label='skip'>Pular</strong>,
								next: "Próxima etapa",
								back: "Voltar",
							},
							target: ".comunicacao-navbar",
						},
						{
							content: (
								<div>
									<img
										alt='Calendário de Eventos'
										src='imagens-react-joyride/calendario-eventos.svg'
									/>
									<h3 className='titulo-react-joyride'>
										Calendário de Eventos e Atividades
									</h3>
									<p>
										Acompanhe o cronograma das atividades do seu curso e agenda
										de eventos.
									</p>
								</div>
							),
							locale: {
								skip: <strong aria-label='skip'>Pular</strong>,
								next: "Próxima etapa",
								back: "Voltar",
							},
							target: ".calendario-navbar",
						},
						{
							content: (
								<div>
									<img
										alt='Gamificação'
										src='imagens-react-joyride/gamificacao.svg'
									/>
									<h3 className='titulo-react-joyride'>Gamificação</h3>
									<p>
										Toda a sua interação com as ferramentas e recursos durante o
										seu curso está distribuída e acompanhada através de
										desafios, pontuações, rankings, metas e premiações.
										Acompanhe aqui!
									</p>
								</div>
							),
							locale: {
								skip: <strong aria-label='skip'>Pular</strong>,
								next: "Próxima etapa",
								back: "Voltar",
							},
							target: ".gamificacao-navbar",
						},
						{
							content: (
								<div>
									<img
										alt='Oportunidades'
										src='imagens-react-joyride/oportunidades.svg'
									/>
									<h3 className='titulo-react-joyride'>Oportunidades</h3>
									<p>
										Este é o espaço dedicado a você nosso futuro aluno egresso,
										estamos preparando diversos recursos e conteúdos exclusivos
										para você integrar-se no mercado profissional com sucesso.
									</p>
								</div>
							),
							locale: {
								skip: <strong aria-label='skip'>Pular</strong>,
								next: "Próxima etapa",
								back: "Voltar",
							},
							target: ".oportunidades-navbar",
						},
						{
							content: (
								<div>
									<img
										alt='Telegram'
										src='imagens-react-joyride/telegram_comuni.svg'
									/>
									<h3 className='titulo-react-joyride'> Comunidade Uninta EaD</h3>
									<p>
										Faça parte da nossa comunidade no Telegram e fique por dentro das novidades do Uninta EaD
									</p>
								</div>
							),
							locale: {
								skip: <strong aria-label='skip'>Pular</strong>,
								next: "Próxima etapa",
								back: "Voltar",
							},
							target: ".telegram-navbar",
						},
						{
							content: (
								<div>
									<img
										alt='Notificações'
										src='imagens-react-joyride/notificacoes.svg'
									/>
									<h3 className='titulo-react-joyride'>Notificações</h3>
									<p>
										Acompanhe todas as mensagens enviadas a você como: lembretes,
										comunicados de tutores, professores e colegas ou outras
										informações oportunas do ecossistema.
									</p>
								</div>
							),
							locale: {
								skip: <strong aria-label='skip'>Pular</strong>,
								next: "Próxima etapa",
								back: "Voltar",
							},
							disableBeacon: true,
							spotlightClicks: true,
							target: ".notificacoes-navbar",
						},
						{
							content: (
								<div>
									<img
										alt='Ecossistema de Aprendizagem'
										src='imagens-react-joyride/ecossistema-aprendizagem.svg'
									/>
									<h3 className='titulo-react-joyride'>
										Busca de objetos de aprendizagem
									</h3>
									<p>
										Busque novos recursos de aprendizagem e crie suas próprias
										trilhas de acordo com o seu ritmo e objetivos. Encontre:
										laboratórios, cursos livres, podcasts, palestras e mais de
										10 mil objetos de aprendizagem.
									</p>
								</div>
							),
							locale: {
								skip: <strong aria-label='skip'>Pular</strong>,
								next: "Próxima etapa",
								back: "Voltar",
							},
							target: ".ecossistema-aprendizagem",
						},
						{
							content: (
								<div>
									<img
										alt='Eventos de Hoje'
										src='imagens-react-joyride/eventos-virtuais.svg'
									/>
									<h3 className='titulo-react-joyride'>Eventos Virtuais</h3>
									<p>
										Veja a programação de hoje de eventos virtuais como: aulas
										síncronas, palestras, oficinas e eventos.
									</p>
								</div>
							),
							locale: {
								skip: <strong aria-label='skip'>Pular</strong>,
								next: "Próxima etapa",
								back: "Voltar",
							},
							placement: "left",
							target: ".eventos-hoje-footer",
						},
						{
							content: (
								<div>
									<img
										alt='Newsletter'
										src='imagens-react-joyride/newsletter.svg'
									/>
									<h3 className='titulo-react-joyride'>Newsletter</h3>
									<p>Assine nossa newsletter e não perca nenhuma novidade.</p>
								</div>
							),
							locale: {
								skip: <strong aria-label='skip'>Pular</strong>,
								next: "Próxima etapa",
								back: "Voltar",
							},
							placement: "left",
							target: ".newsletter-footer",
						},
						{
							content: (
								<div>
									<img
										alt='Portfólio'
										src='imagens-react-joyride/meu-portfolio.svg'
									/>
									<h3 className='titulo-react-joyride'>Seu Portfólio</h3>
									<p>
										O portfólio é uma coleção com todo o seu desenvolvimento
										profissional e de habilidades construídas ao longo do curso.
										Acompanhe: estágios e projetos realizados, registre suas
										experiências profissionais, seus eventos e atividades
										realizadas.
									</p>
								</div>
							),
							locale: {
								skip: <strong aria-label='skip'>Pular</strong>,
								next: "Próxima etapa",
								back: "Voltar",
							},
							spotlightClicks: true,
							target: ".button_portfolio_avatar",
						},
						{
							content: (
								<div>
									<img
										alt='Conheça o seu portfólio'
										src='imagens-react-joyride/conheca-seu-portfolio.svg'
									/>
									<h3 className='titulo-react-joyride'>
										Conheça o seu portfólio !
									</h3>
								</div>
							),
							locale: {
								skip: <strong aria-label='skip'>Pular</strong>,
								next: "Próxima etapa",
								back: "Voltar",
							},
							placement: "center",
							target: "body",
						},
						{
							content: (
								<div>
									<img
										alt='Editar seu perfil'
										src='imagens-react-joyride/editar-perfil.svg'
									/>
									<h3 className='titulo-react-joyride'>Editar seu perfil</h3>
									<p>
										É através da edição de perfil que podemos manter dados
										pessoais, foto e bio, construindo dessa forma o seu
										currículo profissional.
									</p>
								</div>
							),
							locale: {
								skip: <strong aria-label='skip'>Pular</strong>,
								next: "Próxima etapa",
								back: "Voltar",
							},
							placement: "right",
							target: ".editar-perfil",
						},
						{
							content: (
								<div>
									<img
										alt='Experiências Profissionais'
										src='imagens-react-joyride/experiencias-profissionais.svg'
									/>
									<h3 className='titulo-react-joyride'>
										Experiências Profissionais
									</h3>
									<p>
										A experiência profissional é o campo do currículo em que
										você relata a sua trajetória no mercado de trabalho,
										informando alguns pontos principais, como: cargo, atividades
										exercidas, nome de empresas e período trabalhado.
									</p>
								</div>
							),
							locale: {
								skip: <strong aria-label='skip'>Pular</strong>,
								next: "Próxima etapa",
								back: "Voltar",
							},
							placement: "left",
							target: ".experiencias-profissionais",
						},
						{
							content: (
								<div>
									<img
										alt='Indicadores de Desenvolvimento'
										src='imagens-react-joyride/indicadores-desenvolvimento.svg'
									/>
									<h3 className='titulo-react-joyride'>
										Indicadores de desenvolvimento
									</h3>
									<p>
										Esses indicadores mostram o que está sendo trabalhado nas
										disciplinas do curso e nas atividades extras que você
										realiza, conforme sua experiência evoluir no curso esses
										gráficos serão atualizados com as suas conquistas.
									</p>
								</div>
							),
							locale: {
								skip: <strong aria-label='skip'>Pular</strong>,
								next: "Próxima etapa",
								back: "Voltar",
							},
							target: ".indicadores-desenvolvimento",
						},
						{
							content: (
								<div>
									<img
										alt='Experiências do Ambiente de Imersão Profissional'
										src='imagens-react-joyride/ambiente-imersao-profissional.svg'
									/>
									<h3 className='titulo-react-joyride'>
										Experiências realizadas no ambiente de imersão profissional
									</h3>
									<p>
										As atividades realizadas nas disciplinas de seminários
										interdisciplinares e unidades curriculares de extensão serão
										armazenadas nesse seu portfólio com os relatos das suas
										experiências.
									</p>
								</div>
							),
							locale: {
								skip: <strong aria-label='skip'>Pular</strong>,
								next: "Próxima etapa",
								back: "Voltar",
							},
							placement: "top",
							target: ".ambiente-imersao-profissional",
						},
						{
							content: (
								<div>
									<img
										alt='Estágios, Projetos e Notas'
										src='imagens-react-joyride/estagios-projetos-notas.svg'
									/>
									<h3 className='titulo-react-joyride'>
										Experiências realizadas no ambiente de imersão profissional
									</h3>
									<p>
										As atividades realizadas nas disciplinas de estágio estarão
										registradas no seu portfólio assim como os certificados de
										participação em laboratórios e cursos livres.
									</p>
								</div>
							),
							locale: {
								skip: <strong aria-label='skip'>Pular</strong>,
								next: "Próxima etapa",
								back: "Voltar",
							},
							placement: "bottom",
							target: ".estagios-projetos-notas",
						},
						{
							content: (
								<div>
									<img
										alt='Eventos e Publicações'
										src='imagens-react-joyride/eventos-publicacoes.svg'
									/>
									<h3 className='titulo-react-joyride'>Eventos e Publicações</h3>
									<p>
										Todos os eventos promovidos pela instituição que você
										participar e emitir a certificação ficam registrados nessa
										seção. Aqui você também poderá registrar sua participação em
										outros eventos realizados em qualquer lugar do mundo.
									</p>
								</div>
							),
							locale: {
								skip: <strong aria-label='skip'>Pular</strong>,
								next: "Próxima etapa",
								back: "Voltar",
							},
							placement: "bottom",
							target: ".eventos-publicacoes",
						},
						{
							content: (
								<div>
									<img
										alt='Suas anotações e arquivos'
										src='imagens-react-joyride/bloco-notas-arquivos.svg'
									/>
									<h3 className='titulo-react-joyride'>
										Suas anotações e arquivos
									</h3>
									<p>
										Sempre que você finalizar uma disciplina, as suas anotações,
										arquivos compartilhados e todos os recursos de aprendizagem
										estarão disponíveis aqui, podendo serem acessados até mesmo
										após a conclusão do seu curso. Tenha todo seu portfólio
										sempre com você.
									</p>
								</div>
							),
							locale: {
								skip: <strong aria-label='skip'>Pular</strong>,
								next: "Próxima etapa",
								back: "Voltar",
							},
							placement: "bottom",
							target: ".bloco-notas-arquivos",
						},
						{
							content: (
								<div>
									<img
										alt='Questionário Socioeconômico'
										src='imagens-react-joyride/formulario.svg'
									/>
									<h3 className='titulo-react-joyride'>Responda ao Questionário Socioeconômico</h3>
									<p>
										<a
											href="https://docs.google.com/forms/d/e/1FAIpQLSesddGTHZAhBmNNZLccRVFQgkIFqxYel3qQ5YOnmyfoZx3TOw/viewform"
											target="_blank"
											rel="noopener noreferrer"
											onClick={() => {
												setClicouLink(true);
												botaoClose?.setAttribute("class", "exibir-botao-close");
												botao?.setAttribute("class", "exibir-botao-proxima-etapa");
											}}
										>
											Formulário
										</a>
									</p>
								</div>
							),
							locale: {
								skip: <strong aria-label='skip'>Pular</strong>,
								next: "Próxima etapa",
								back: "Voltar",
							},
							placement: "center",
							target: "body",
						},
						{
							content: (
								<div>
									<img
										alt='Conclusão do Tutorial'
										src='imagens-react-joyride/conclusao-tutorial.svg'
									/>
									<p>
										Parabéns por concluir o tutorial! Você conquistou 20 pontos
										na gamificação!!
									</p>
								</div>
							),
							locale: {
								skip: <strong aria-label='skip'>Pular</strong>,
								next: "Próxima etapa",
								back: "Voltar",
								last: "Finalizar",
							},
							placement: "center",
							target: "body",
						},
					]}
					styles={{
						options: {
							zIndex: 10000,
						},
						buttonBack: { color: theme.palette.primary.main },
						buttonNext: { backgroundColor: theme.palette.primary.main },
					}}
				/>
			) : (
				<></>
			)}
		</>
	);
}
