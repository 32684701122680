import { Dialog } from "@material-ui/core";
import { AxiosResponse } from "axios";
import { LearningResourceInterface } from "components/LearningResources/resource.model";
import { useAuth } from "context/Auth/hook";
import { motion, AnimatePresence } from "framer-motion";
import React, { useState } from "react";
import { ecossistema } from "services/api";
import { apiLogsDoEcossistema } from "services/Logs";
// import { ArrowBack } from "@material-ui/icons";
// import { Carousel } from "primereact/carousel";
import "./styles.scss";

import { DISCIPLINA } from "defaults/modules";

const Container = React.lazy(() => import("@material-ui/core/Container"));
const Grid = React.lazy(() => import("@material-ui/core/Grid"));

const LearningResource = React.lazy(() => import("components/LearningResources"));

export default function DialogRecursosView(props): JSX.Element {
	const { user, nivelDeEnsino } = useAuth();
	const [disc, setDisc] = useState<LearningResourceInterface>();

	React.useEffect(() => {
		if (props.dados.idCursoMoodle) {
			ecossistema.get(`avas/${props.dados.idCursoMoodle}`)
				.then((res: AxiosResponse<any>) => {
					res.data ? setDisc(res.data) : (window.location.href = "/");
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.dados]);

	React.useEffect(() => {
		apiLogsDoEcossistema.post('/',
			{
				ra: user.ra as string,
				module: DISCIPLINA,
				evento: {
					label: "DISCIPLINA",
					log_info: {
						user_type: user.typeUser,
						disciplina: props.idCursoMoodle,
						page: `Página de disciplina ${props.idCursoMoodle}`,
						description: `O usuário ${user.ra} do tipo ${user.typeUser} acessou a DISCIPLINA nos dias ${new Date().toLocaleDateString()}`,
						date: new Date(),
					}
				},
				metadata: {
					curso: nivelDeEnsino.nome_curso,
					polo: nivelDeEnsino.nome_nivel_ensino,
					periodo: nivelDeEnsino.periodo,
				}
			}
		)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<Dialog
			maxWidth="xl"
			open={props.dados.enableDialogRecursos}
			onClose={() => {
				props.dados.setEnableDialogRecursos(false);
			}}
			className='dialog-tutorial-mobile'
		>
			<Container>
				<Grid container>
					<Grid item xs={12}>
						<AnimatePresence>
							<motion.div
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								exit={{ opacity: 0 }}
							>
								{disc
									? <LearningResource {...disc} disciplinaBlocoNotas={true} setEnableDialogRecursos={props.dados.setEnableDialogRecursos} /> : <div style={{ height: "532px" }}></div>
								}
							</motion.div>
						</AnimatePresence>
					</Grid>
				</Grid>
			</Container>
		</Dialog>
	);
}
