import { centralPedagogica } from "services/api";

export async function getQuestionsListTutor(course, page = 0) {
    const curso_id = course;
    const disciplina_id = "0";

    const params = (course)
        ? { page, disciplina_id, curso_id }
        : { page, disciplina_id };


    return await centralPedagogica
        .get(`tutor/perguntas`, {
            params
        })
}

export async function getQuestionsListTutorFilter(stringFilter, page = 0) {
    const disciplina_id = "0";

    return await centralPedagogica
        .get(`tutor/perguntas?page=${page}&disciplina_id=${disciplina_id}${stringFilter}`)
        .then(({ data }) => {
            return data;
        })
        .catch((e) => {
            return e;
        });
}


export async function getCourses(disciplina_id?: boolean) {
    const params = disciplina_id ? { [`disciplina_id`]: "0" } : "";
    return await centralPedagogica.get(`cursos`, { params }).then(({ data }: any) => {
        return data
    }).catch((e) => { console.log(e); });
}

export async function getPolos(disciplina_id?: boolean) {
    const params = disciplina_id ? { [`disciplina_id`]: "0" } : "";
    return await centralPedagogica.get(`polos`, { params }).then(({ data }: any) => {
        return data
    }).catch((e) => { console.log(e); });
}

export async function getRas(disciplina_id?: boolean) {
    const params = disciplina_id ? { [`disciplina_id`]: "0" } : "";
    return await centralPedagogica.get(`profiles?model=perguntas`, { params }).then(({ data }: any) => {
        return data
    }).catch((e) => { console.log(e); });
}
