import React from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useTheme } from "@material-ui/core/styles";

import BoxQuestion from "./BoxQuestion";
import BoxResponse from "./BoxResponse";

import { getQuestionTutor, getListAnswersTutor } from "../../dialogquestionsanswers.services";

import { useAuth } from "context/Auth/hook";

import { usePedagogicalCommunication } from "context/PedagogicalCommunication/hooks/usePedagogicalCommunication";

import "./styles.scss";
import { AxiosResponse } from "axios";
import { IconButton, useMediaQuery } from "@material-ui/core";
import { Close } from "@material-ui/icons";
interface DialogViewQuestionProps {
	open: boolean;
	id: string;
	setOpen: (e: boolean) => void;
	setId: (e: string) => void;
}

export default function DialogViewQuestion(props: DialogViewQuestionProps) {
	const { user } = useAuth();
	const { reloadListQuestions, setReloadListQuestions } = usePedagogicalCommunication();
	const colorsTheme = useTheme();
	const [onRealod, setOnReload] = React.useState(false);

	const [loadingQuestion, setLoadingQuestion] = React.useState(true);

	const [detailsQuestion, setDetailsQuestin] = React.useState<
		| {
			id: string;
			pergunta: string;
			profile: {
				ra: string
				name: string;
				email: string;
				avatar_path: string;
			}
			curso: {
				id: string;
				nome: string;
			}
			respostas: any[];
		}
		| undefined
	>(undefined);

	React.useEffect(() => {
		if (props.open && props.id) {
			setLoadingQuestion(true);

			getQuestionTutor(props.id, user)
				.then(async (data) => {
					let getDetails: any;
					const getDataDetails = data.data;

					await getListAnswersTutor(getDataDetails.id, user)
						.then((data: AxiosResponse<any>) => {
							getDetails = { ...getDataDetails, respostas: data.data };

							setLoadingQuestion(false);
						})
						.catch((e) => {
							console.log(e);
						});

					setDetailsQuestin(getDetails);
				})
				.catch((e) => {
					console.log(e);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.open, props.id, user.ra]);

	const fullScreen = useMediaQuery(colorsTheme.breakpoints.down(800));

	return (
		<Dialog
			fullWidth
			maxWidth='lg'
			open={props.open}
			onClose={() => {
				if (onRealod) {
					setReloadListQuestions(!reloadListQuestions);
				}
				props.setOpen(false);
			}}
			fullScreen={fullScreen}
		>
			<DialogTitle style={{ background: colorsTheme.palette.primary.main }}>
				<div style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					position: "relative"
				}}>
					<Typography className='titleDialogQuestion'>Questão Selecionada</Typography>
					<IconButton style={{ color: "white", position: "absolute", right: "0px" }} id='btnCloseViewQuestionTutor' onClick={() => {
						if (onRealod) {
							setReloadListQuestions(!reloadListQuestions);
						}
						props.setOpen(false);
					}}>
						<Close />
					</IconButton>
				</div>
			</DialogTitle>

			<DialogContent>
				<Grid container>
					{loadingQuestion ? (
						<Grid item xs={12}>
							<Box
								display='flex'
								justifyContent='center'
								alignItems='center'
								flexDirection='column'
							>
								<Typography className='await_send_question'>
									Por favor, aguarde...
								</Typography>
								<CircularProgress
									style={{ color: "#bd0e0e", width: "1.5rem", height: "1.5rem" }}
									thickness={4.4}
								/>
							</Box>
						</Grid>
					) : (
						<>
							<BoxQuestion
								idQuestion={detailsQuestion?.id ?? ""}
								pergunta={detailsQuestion?.pergunta ?? ""}
								authorId={detailsQuestion?.profile ? detailsQuestion?.profile.ra : ""}
								author={detailsQuestion?.profile ? detailsQuestion?.profile.name : ""}
								authorAvatar={detailsQuestion?.profile ? detailsQuestion?.profile.avatar_path : ""}
								course={detailsQuestion?.curso ? detailsQuestion?.curso.nome : ""}
								setOnReload={setOnReload}
							/>

							<Box className='area_responses'>
								{detailsQuestion?.respostas &&
									detailsQuestion?.respostas.length <= 0 ? (
									<Box>
										<Typography className='nullValue'>
											( Sem Respostas e Comentários )
										</Typography>
									</Box>
								) : (
									<>
										{detailsQuestion?.respostas?.map((dt) => (
											<BoxResponse
												key={dt.id}
												idAnswer={dt.id}
												answer={dt.resposta}
												authorId={dt.profile.ra}
												author={dt.profile.name}
												authorAvatar={dt.profile.avatar_path}
												idCourse={dt.curso.id}
												course={dt.curso.nome}
												favorites={dt.favoritos_count}
												stars={dt.avaliacoes_count}
												numComments={dt.comentarios_count}
												numVotes={dt.avaliacoes_avg}
												favorited={!!dt.favoritou}
												rated={!!dt.avaliou}
												setOnReload={setOnReload}
												created={dt.created_at}
											/>
										))}
									</>
								)}
							</Box>
						</>
					)}
				</Grid>
			</DialogContent>
		</Dialog>
	);
}
