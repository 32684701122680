import React from "react";

// Adicionando importações do Material UI 4

import { Avatar } from "@material-ui/core";

// Importando estilos

import "./styles.scss";

// Importando interfaces
import { cardInterface } from "./card.model";
import { formatarTexto } from "utils/Oportunidades/formatarNome";

export default function CardDepoimentoView({ depoimento, cursoNome }: cardInterface): React.ReactElement {
 
	return (
		<div className="card-depoimento">
			<div className="box-header-depoimento">
				<Avatar alt="Perfil Aluno" src={`https://dirin.s3.us-east-1.amazonaws.com/${depoimento.profile.avatar_path}`} />

				<div className="box-sub-header">
					<h3>{ formatarTexto(depoimento.profile.name) }</h3>
					<div>
						<span id='subtitulo01'>{formatarTexto(cursoNome)}</span>
						<br />
						<span id='subtitulo02'>{depoimento.profile.city_address} - {depoimento.profile.uf_address}</span>
					</div>
				</div>
			</div>

			<div className="box-content-depoimento">
				<p className='texto-depoimento'>
					{depoimento.depoimento}
				</p>
			</div>
		</div>
	);
}
