/**
 * Define as variáveis iniciais do profile
 */

import { Dispatch, SetStateAction } from "react";

export interface ProfileInterface {
	ra: 			string;
	nome: 			string;	
	disciplinas: 	any|any[];
	cursosLivres: 	any|any[];
	salasMoodle: 	any|any[];
}
export interface ProfileContextInterface  {
	ra: any;
	loader: boolean;
	profile: 		ProfileInterface|null|undefined;
	profiles: 		ProfileInterface[]|any[],
	profileStep: 	number;
	setRaAluno: 	Dispatch<SetStateAction<any>>;
	setLoader: 		Dispatch<SetStateAction<boolean>>;
	setProfile: 	Dispatch<SetStateAction<ProfileInterface|null|undefined>>;
	setProfiles: 	Dispatch<SetStateAction<ProfileInterface[]|any[]>>;
	setProfileStep: Dispatch<SetStateAction<number>>;
	addProfileAndSubjectsToCollection: (perfil: any) => void,
}

export const INI_VALUES: ProfileContextInterface = {
	ra: "",
	loader: false,
	profile: 		undefined,
	profiles: 		[],
	profileStep: 	0,
	setRaAluno: 	() => {},
	setLoader:	 	() => {},
	setProfile: 	() => {},
	setProfiles: 	() => {},
	setProfileStep: () => {},
	addProfileAndSubjectsToCollection: 		() => {},
};
