import { centralPedagogica } from "services/api";

export async function getMessagesCentral() {
	return await centralPedagogica
		.get(`/aluno/mensagens`)
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

export async function getMessagesToUserCentral(ra) {
	return await centralPedagogica
		.get(`/tutor/mensagens?ra=${ra}`)
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

export async function getMessagesToUserCentralSeen(data: any) {
	return await centralPedagogica.post(`/aluno/mensagens`, data)
}