import React from "react";
import parse from "html-react-parser";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
// import FormControl from "@material-ui/core/FormControl";
// import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Paginator } from "primereact/paginator";
import DialogAnswer from "./DialogAnswer";
import { DialogAnswerProps } from "./DialogAnswer/dialoganswer.model";
import { getNameInitials } from "utils/getNameInitials";
import { usePedagogicalCommunication } from "context/PedagogicalCommunication/hooks/usePedagogicalCommunication";
import "./styles.scss";
import { Avatar, useTheme } from "@material-ui/core";
// import { Controller, useForm } from "react-hook-form";
import DuvidasTutoria from "components/DuvidasTutoria";
import DialogViewQuestion from "../Questions/DialogViewQuestion";
import { useAuth } from "context/Auth/hook";
import ViewProfile from "../../DialogViewProfile";

export default function Response() {
	const theme = useTheme()
	const {
		filterCourse,
		loadingPage,
		listQuestionsView,
		getQuestionsPaginator,
		currentPage,
		setCurrentPage,
		totalQuestions,
		handleFilterListQuestions
	} = usePedagogicalCommunication();

	const [filterCourseSelected, setFilterCourseSelected] = React.useState("");

	const { user } = useAuth();

	const [openAnswer, setOpenAnswer] = React.useState(false);
	const [detailsAnswer, setDetailsAnswer] = React.useState<Omit<DialogAnswerProps, "open" | "setOpen" | "course">>();

	const [openViewQuestion, setOpenViewQuestion] = React.useState(false);
	const [idQuestion, setIdQuestion] = React.useState("");

	const [viewPerfil, setViewPerfil] = React.useState<any>(""); // Perfil
	const [dialogViewPerfil, setDialogViewPerfil] = React.useState<boolean>(false); // Perfil

	const onOpenAnswer = (
		authorId = "",
		authorAvatar = "",
		authorName: string,
		authorCourse: string,
		authorSubject: string,
		answer: string,
		answerId: string,
	) => {
		setOpenAnswer(true);
		setDetailsAnswer({
			authorId,
			authorAvatar,
			authorName,
			authorCourse,
			authorSubject,
			answer,
			answerId,
		});
	};

	function date(data: string) {
		let arrayData = data.split(" ");
		arrayData.splice(1, 1);
		arrayData = arrayData[0].split("-");
		return arrayData.reverse().join("/");
	}

	function hora(hora: string) {
		let arrayHora = hora.split(" ");
		return arrayHora[1];
	}


	return (
		<Container>
			<Grid container style={{ justifyContent: 'center', marginBottom: '5vh' }}>
				<DuvidasTutoria />
				{loadingPage ? (
					<Grid item xs={12}>
						<Box className='awaiting'>
							<Typography variant='subtitle2' component='p'>Por favor, aguarde...</Typography>
							<CircularProgress />
						</Box>
					</Grid>
				) : (
					<>
						{listQuestionsView?.length <= 0 ? (
							<Box className='nothing'>
								<Typography className='text_no_find'>
									Nenhum resultado encontrado
								</Typography>
								<Typography className='subtext_no_find'>
									Tente filtrar por outro curso ou disciplina
								</Typography>
							</Box>
						) : (
							<>
								{listQuestionsView?.map((qt: any) => (
									<Grid
										item
										xs={12}
										className='questions_area_response'
										key={qt?.id}
									>
										<Box className='question_author'
											style={{ cursor: "pointer" }}
											onClick={() => {
												setViewPerfil({
													ra: qt.profile.ra,
													avatar_path: qt.profile.avatar_path,
													name: qt.profile.name,
													curso: qt.curso.nome,
													polo: qt.polo.nome,
												});
												setDialogViewPerfil(user.typeUser === "tutor" ? true : false)
											}}
										>
											<Avatar
												src={qt?.profile?.avatar_path ?? ""}
												alt='Perfil'
												className={""}
											>
												{getNameInitials(qt?.profile?.name ?? " ")}
											</Avatar>
											<Box className='author_name_course'>
												<Typography className='author_name'>
													{qt?.profile?.name ?? " "}
												</Typography>
												<Typography className='author_course'>
													{qt?.curso?.nome ?? " "} - {qt?.profile?.ra ?? " "} - {qt?.polo?.nome ?? " "}
												</Typography>
												<Typography className='author_course'>
													{date(qt?.created_at ?? " ")}  - {hora(qt?.created_at ?? " ")}
												</Typography>
											</Box>
										</Box>

										<Box className='question_view'>
											<Typography variant='subtitle2' style={{ overflowWrap: "break-word" }}>
												{parse(qt?.pergunta)}
											</Typography>
										</Box>

										<Box className='question_view_actions'>
											<Button
												id='btnResponse'
												variant='contained'
												disableElevation
												onClick={() => {
													if (qt?.respostas_count > 0) {
														setOpenViewQuestion(true);
														setIdQuestion(qt?.id);
													} else {
														setFilterCourseSelected(qt?.curso.id)
														onOpenAnswer(
															qt?.profile?.ra ?? " ",
															qt?.profile?.avatar_path ?? " ",
															qt?.profile?.name ?? " ",
															qt?.curso?.nome ?? " ",
															qt?.disciplina?.nome ?? " ",
															qt?.pergunta,
															qt?.id,
														);
													}
												}}
												style={{
													backgroundColor: qt?.respostas_count > 0 ? "#363434" : theme.palette.primary.main,
													color: "white"
												}}
											>
												{
													qt?.respostas_count > 0 ? `Respondido (${qt?.respostas_count})` : "Responder"
												}
											</Button>
										</Box>
									</Grid>
								))}

								<Paginator
									rows={10}
									totalRecords={totalQuestions}
									first={currentPage}
									onPageChange={(e) => {
										setCurrentPage(e.first);

										if (
											filterCourse !== "todos"
										) {
											handleFilterListQuestions(
												"curso_id",
												filterCourse as string,
												e.page + 1,
											);
										} else {
											getQuestionsPaginator(e.page + 1);
										}
									}}
									style={{
										background: "#f7f7f7",
										marginTop: 10,
										width: "100%",
									}}
								></Paginator>
							</>
						)}
					</>
				)}
			</Grid>

			<DialogAnswer
				open={openAnswer}
				course={filterCourseSelected}
				authorId={detailsAnswer?.authorId ?? ""}
				authorName={detailsAnswer?.authorName}
				authorCourse={detailsAnswer?.authorCourse}
				authorSubject={detailsAnswer?.authorSubject}
				authorAvatar={detailsAnswer?.authorAvatar ?? ""}
				answer={detailsAnswer?.answer}
				answerId={detailsAnswer?.answerId}
				setOpen={setOpenAnswer}
			/>

			<DialogViewQuestion
				open={openViewQuestion}
				id={idQuestion}
				setOpen={setOpenViewQuestion}
				setId={setIdQuestion}
			/>

			<ViewProfile dialogViewPerfil={dialogViewPerfil} setDialogViewPerfil={setDialogViewPerfil} ra={viewPerfil.ra} avatar_path={viewPerfil.avatar_path} name={viewPerfil.name} curso={viewPerfil.curso} polo={viewPerfil.polo} disciplina={0} />
		</Container>
	);
}
