import { AxiosResponse } from "axios";
import { Ranking, RankingUsuarioLogado, ScorePorLabel } from "models/gamificacao";
import { gamificacao } from "services/api";

export async function getScoreAluno(ra: string) {
    return gamificacao.get(`score/ra/${ra}`);
}

export async function getScorePorLabelAluno(ra: string, label: string): Promise<AxiosResponse<ScorePorLabel>> {
    return gamificacao.get(`score/ra/${ra}/label/${label}`);
}

export async function getScoreModulosAluno(): Promise<AxiosResponse<any[]>> {
    return gamificacao.get(`ecossistema/logs`);
}

export async function getRankingUsuarioLogado(ra: string): Promise<AxiosResponse<RankingUsuarioLogado>> {
    return gamificacao.get(`score/ranking?ra=${ra}`);
}

export async function getRanking(parametro?: string, valor?: string): Promise<AxiosResponse<Ranking>> {
    if(parametro && valor) {
        return gamificacao.get(`score/ranking?${parametro}=${valor}`);
    }

    return gamificacao.get(`score/ranking`);
}