import React, { useState, useEffect } from "react";
import { LearningResourceInterface } from "components/LearningResources/resource.model";
import { selectLearningResource } from "../SelectLearningResources";
import { ecossistema } from "services/api";
import { useSearchParams } from "react-router-dom";
import { AxiosResponse } from "axios";
import { Box, Button, useTheme } from "@material-ui/core";

export default function DisciplinasView(ra, name): React.ReactElement {
	const theme = useTheme(); 										// Tema global
	const [searchParams] = useSearchParams(); 								// Hook para obter query params
	const params = { disciplinas: searchParams.get("disciplina"), }; 	// Query params 
	const [tab, setTab] = useState<number>(1); 								// Define tab 1: disciplinas, tab 2: cursos livres
	const [carregaDisciplinas, setCarregaDisciplinas] = useState<boolean>(false); 	// Habilita o carregamento de disciplinas	
	const [disciplinaAva, setDisciplinaAva] = React.useState<LearningResourceInterface | null>(null); // Define a disciplina a ser passada para o componente
	const [profileSearch, setProfileSearch] = React.useState<any>();
	const [profilesSearch, setProfilesSearch] = React.useState<any>();

	/**
	 * Busca AVAs 
	 */
	const CarregarDisciplinasV3 = async (ra) => {
		const moodle = await ecossistema.get(`nti/inscricoes-moodle/${ra}`)
			.then((res: AxiosResponse<any>) => {
				return res.data;
			})


		if (moodle.length > 0) {
			const disciplinas = moodle.filter((disciplina) => {
				return (disciplina.idturmadisc_rm !== null)
			});

			var cursosLivres = moodle.filter((cursoLivre) => {
				return ((
					cursoLivre.role_shortname === "student" || cursoLivre.role_archetype === "student"
				) && (
						cursoLivre.idturmadisc_rm === null
					)
				)
			});

			cursosLivres = cursosLivres.reduce((r, a) => {
				r[a.categoria_nome] = r[a.categoria_nome] || [];
				r[a.categoria_nome].push(a);
				return r;
			}, Object.create(null));

			setProfilesSearch(ra ? [{
				ra: ra,
				nome: name,
				disciplinas: disciplinas,
				cursosLivres: cursosLivres,
			},] : []);
			setProfileSearch(ra ? {
				ra: ra,
				nome: name,
				disciplinas: disciplinas,
				cursosLivres: cursosLivres,
			} : {});

			// setDisciplinas(disciplinas);
			setCarregaDisciplinas(false);
		}
	}

	// Verifica se existe alguma disciplina localmente, caso não exista realiza a busca na API
	useEffect(() => {
		setCarregaDisciplinas(true);
		CarregarDisciplinasV3(ra);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	// Este useEffect busca a disciplina a partir do query param, caso exista disciplina com este idAva ela exibe o LernResource, do contrário, exibe os cards
	React.useEffect(() => {
		if (params.disciplinas) {
			ecossistema
				.get(`avas/${params.disciplinas}`)
				.then((res: AxiosResponse<any>) => {
					res.data.length > 0 ? setDisciplinaAva(res.data[0]) : (window.location.href = "/");
				});
		} else {
			setDisciplinaAva(null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params.disciplinas]);

	return (
		<React.Fragment>
			<Box
				style={{
					backgroundColor: "transparent",
					borderRadius: "12px",
					minHeight: "56px",
					marginBottom: "12px",
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<div
					style={
						tab === 1
							? {
								borderBottom: `solid 2px ${theme.palette.primary.main}`,
								backgroundColor: "#f8f8f8",
							}
							: {}
					}
				>
					<Button
						className="minhas-disciplinas-carousel"
						onClick={() => {
							setTab(1);
						}}
					>
						Minhas disciplinas
					</Button>
				</div>
				<div
					style={
						tab === 2
							? {
								borderBottom: `solid 2px ${theme.palette.primary.main}`,
								backgroundColor: "#f8f8f8",
							}
							: {}
					}
				>
					<Button
						className="cursos-eventos-carousel"
						onClick={() => {
							setTab(2);
						}}
					>
						Cursos e Projetos
					</Button>
				</div>
			</Box>
			{
				selectLearningResource(
					tab,
					disciplinaAva,
					carregaDisciplinas,
					profileSearch,
					profilesSearch,
				)
			}
		</React.Fragment>
	);
}
