import React, { Dispatch, SetStateAction} from "react"
import { Dialog, DialogTitle, Typography, Box, CircularProgress, Avatar, FormControl, TextField, Chip, List, ListItem, ListItemAvatar, ListItemText, ListItemSecondaryAction, IconButton, Button, useTheme  } from "@material-ui/core";
import { AccountCircle, Delete } from "@material-ui/icons";
import { ShowProfileLoadingStep } from "components/ShowProfileLoadingStep";
import { AnimatePresence, motion } from "framer-motion";
import { PaperComponent } from "components/PaperComponent";
import { useProfiles } from "context/Profile/hook";
import { useAuth } from "context/Auth/hook";

interface DialogTutorGerenciaDisciplinasProps {
	open: boolean;
	setOpen: Dispatch<SetStateAction<boolean>>;
}

export default function DialogTutorGerenciaPerfis(
    props: DialogTutorGerenciaDisciplinasProps
) {
    let theme                           = useTheme();               // Busca o tema a partir do contexto global do Theme
    let {user, setUser}                 = useAuth();
    const { 
        profile, 
        setProfile, 
        profiles, 
        setProfiles, 
        profileStep, 
        setProfileStep, 
        ra, 
        setRaAluno, 
        loader,
        addProfileAndSubjectsToCollection
    } = useProfiles(); // Busca dados dos perfis a partir do contexto
    
    React.useEffect(()=>{
        let collection = sessionStorage.getItem("@user:profiles:collection");
        if(collection) {
            let parsedCollection = JSON.parse(collection as string);
            setProfiles(parsedCollection);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return (
        <Dialog   
            open={props.open}
            onClose={() => {
                props.setOpen(!props.open)
                // setToggleAddPerfil(false);
                // Ao sair configura o perfil para a localStorage					
                if(profile) {
                    setProfile(profile);
                    // setToggleAddPerfil(false);
                    sessionStorage.setItem("@my-profile", JSON.stringify(profile));
                }
            }}
            PaperComponent={PaperComponent}
            aria-labelledby='draggable-dialog-title'
            maxWidth='lg'
            className='modal'
        >
            <div
                className=''
                style={{
                    minWidth: "470px",
                    maxWidth: "470px"
                }}
            >
                {/* Titulo do dialog */}
                <div
                    className=''
                    style={{
                        backgroundColor: theme.palette.primary.main,
                    }}
                >
                    <DialogTitle
                        style={{ 
                            cursor: "move", 
                            color: "white", 
                            display: "flex", 
                            flexDirection: "row", 
                            justifyContent: "flex-start" 
                        }}
                        id='draggable-dialog-title'
                    >
                        <Typography
                            variant='body1'
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                            }}
                        >
                            <AccountCircle />
                            <span style={{ marginLeft: "12px" }}>Gerenciar perfis</span>
                        </Typography>
                    </DialogTitle>
                </div>
                <Box p={3}>
                    {loader && (
                        <Box
                            mt={3}
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                                marginBottom: "12px",
                            }}
                        >
                            <CircularProgress color='secondary' />
                            <AnimatePresence>
                                <motion.div
                                    initial={{ opacity: 0 }}
                                    animate={{
                                        opacity: 1,
                                        transition: {
                                            duration: 0.4,
                                            easing: "easeInOut",
                                        },
                                    }}
                                >
                                    {/* <Box mt={3}>
                                            <Typography variant="body1">
                                                {
                                                    loader 
                                                    && profileStepMessage
                                                }										
                                            </Typography>
                                        </Box> */}
                                </motion.div>
                            </AnimatePresence>
                        </Box>
                    )}
                    {/* <Box style={{ display: !loader && profile === undefined ? "flex" : "none" }}>
                        <Typography variant="body1">Insira o RA do estudante:</Typography>						
                    </Box>	 */}
                    <Box
                        style={{
                            display: !loader && profile ? "flex" : "none",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            textAlign: "center",
                        }}
                    >
                        <Avatar
                            style={{
                                width: "72px",
                                height: "72px",
                                marginBottom: "12px",
                            }}
                        >
                            {profile ? (
                                profile.nome ? (
                                    profile.nome.substring(0, 1).toUpperCase()
                                ) : (
                                    user.nome.substring(0, 1).toUpperCase()
                                )
                            ) : (
                                <AccountCircle />
                            )}
                        </Avatar>
                        <Box
                            style={{
                                width: "90%",
                            }}
                        >
                            <Typography variant='h5' style={{ marginBottom: "12px" }}>
                                {profile && profile.nome.split(" ")[0]} —{" "}
                                {profile && profile.ra}
                            </Typography>
                        </Box>
                    </Box>
                    {/* Alerta  */}
                    {ShowProfileLoadingStep(profileStep)}
                    <Box mt={3}>
                        <Box
                            style={{
                                display: !loader && profile === undefined ? "block" : "none",
                            }}
                        >
                            <form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                }}
                            >
                                <FormControl fullWidth>
                                    <TextField
                                        variant='outlined'
                                        label='Ra do aluno'
                                        onChange={(e) => {
                                            setRaAluno(e.target.value);
                                        }}
                                    />
                                </FormControl>
                            </form>
                        </Box>

                        {/* Chips com sugestões */}
                        {!loader && !profile && profiles.length > 0 && (
                            <React.Fragment>
                                <Box
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        flexWrap: "wrap",
                                        alignItems: "flex-start",
                                    }}
                                >
                                    {profiles &&
                                        profiles.map((profile, index) => {
                                            return (													
                                                <Box key={index} mr={1} mb={1}>
                                                    <Chip
                                                        label={profile.ra || user.nome }
                                                        // variant="outlined"
                                                        icon={<AccountCircle />}
                                                        onDelete={() => {
                                                            setProfileStep(7);
                                                            setProfiles(
                                                                profiles.filter(
                                                                    (_, i) => i !== index,
                                                                ),
                                                            );
                                                            sessionStorage.setItem(
                                                                "@user:profiles:collection", 
                                                                JSON.stringify(profiles.filter(
                                                                    (_, i) => i !== index,
                                                                ),
                                                            ));
                                                            sessionStorage.removeItem("@my-profile");
                                                        }}
                                                        onClick={() => {
                                                            setProfile(profiles[index]);
                                                            setUser({
                                                                ...user,
                                                                ra: ra,
                                                                nome: profiles[index].nome,
                                                            });
                                                            localStorage.setItem(
                                                                "@Application:User", 
                                                                JSON.stringify({
                                                                    ...user,
                                                                    ra: ra,
                                                                    nome: profiles[index].nome,
                                                                })
                                                            );
                                                        }}
                                                    />
                                                </Box>
                                            );
                                        })}
                                </Box>
                            </React.Fragment>
                        )}
                        {/* Chips com sugestões */}
                        {/* Perfil selecionado */}
                        <Box>
                            <List
                                style={{
                                    width: "100%",
                                    maxHeight: "300px",
                                    overflowY: "auto",
                                }}
                            >
                                {
                                    profile && (
                                        <React.Fragment>
                                            <ListItem
                                                // key={index}
                                                alignItems='flex-start'
                                                button
                                                onClick={() => {
                                                    // sessionStorage.setItem("@currentCollection", JSON.stringify(index))
                                                    // setPrevCollection(currentCollection)
                                                    // setCurrentCollection(index)
                                                }}
                                                style={{
                                                    // backgroundColor: index === currentCollection ? "lavender" : ""
                                                    backgroundColor: "#f6f6f6",
                                                }}
                                            >
                                                <ListItemAvatar>
                                                    <Avatar>
                                                        <AccountCircle />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={
                                                        profile.nome ? profile.nome : user.nome
                                                    }
                                                    secondary={
                                                        <React.Fragment>
                                                            <Typography variant='body1'>
                                                                {" "}
                                                                RA: {profile.ra}{" "}
                                                            </Typography>
                                                            <Typography variant='body1'>
                                                                {" "}
                                                                Disciplinas:{" "}
                                                                {
                                                                    profile.disciplinas.length
                                                                }{" "}
                                                            </Typography>																
                                                        </React.Fragment>
                                                    }
                                                />
                                                <ListItemSecondaryAction>
                                                    <IconButton
                                                        onClick={() => {
                                                            // Remove perfil a partir do index																
                                                            sessionStorage.removeItem("@my-profile");
                                                            setProfileStep(7);
                                                            setProfile(undefined);
                                                            setUser({
                                                                ...user,
                                                                ra: profiles && profiles[0].ra,
                                                                nome: profiles[0].nome,
                                                            });
                                                            localStorage.setItem(
                                                                "@Application:User", 
                                                                JSON.stringify({
                                                                    ...user,
                                                                    ra: profiles[0].ra,
                                                                    nome: profiles[0].nome,
                                                                })
                                                            );
                                                        }}
                                                    >
                                                        <Delete />
                                                    </IconButton>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                            {/* <Divider /> */}
                                        </React.Fragment>
                                    )
                                    // })
                                }
                            </List>
                        </Box>
                    </Box>
                </Box>
            </div>
            <Box
                p={3}
                style={{
                    // display: "flex",
                    display: !loader && profile === undefined ? "flex" : "none",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "center",
                }}
            >
                <Button
                    onClick={() => {
                        props.setOpen(!props.open)
                    }}
                    style={{
                        marginRight: "12px",
                    }}
                >
                    Sair
                </Button>
                <Button
                    variant='contained'
                    disableElevation
                    onClick={() => {
                        addProfileAndSubjectsToCollection(ra);
                    }}
                    style={{
                        backgroundColor: theme.palette.primary.main,
                        color: "white",
                    }}
                >
                    Adicionar
                </Button>
            </Box>
            {/* Animate presence com Framer Motion */}
            <AnimatePresence>
                {profile && (
                    <motion.div
                        initial={{ height: 0 }}
                        animate={{
                            height: 96,
                        }}
                        // animate={{ y: -100 }}
                        transition={{ type: "spring", bounce: 0.15, duration: 0.3 }}
                    >
                        <div
                            style={{
                                cursor: "pointer",
                                width: "100%",
                                height: "96px",
                                backgroundColor: theme.palette.primary.main,
                                // color: theme.palette.primary.light,
                                color: "white",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                padding: "0px 24px",
                                alignItems: "center",
                                bottom: "0px",
                            }}
                            onClick={() => {
                                setProfile(profile);
                                props.setOpen(!props.open);
                                sessionStorage.setItem("@my-profile", JSON.stringify(profile));
                            }}
                        >
                            {/* <span></span> */}
                            <Typography variant='body1'>
                                CONTINUAR COMO {profile && profile.nome.split(" ")[0]}{" "}
                            </Typography>								
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
            {/* Animate presence com Framer Motion */}
        </Dialog>
    );
}