import React, { useState } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DateRangeIcon from "@material-ui/icons/DateRangeTwoTone";
import "./styles.scss";
import { getSubjectsTutor } from "../../services/calendar.request";
import { Typography, useTheme } from "@material-ui/core";

interface listasProps {
	setId: (e: number) => void;
	reload: boolean;
}

interface SelecaoItemsAssunto {
	assunto_id: number;
	estadoSelecao: boolean;
}

export default function Listas(props: listasProps): any {

	const theme = useTheme();
	const [subjects, setSubjects] = React.useState<
		undefined | { id: number; titulo: string; pendencias: 0 }[]
	>(undefined);
	const [totalAgendamentosPendentes, setTotalAgendamentosPendentes] = useState<number>(0);
	const [estadosSelecaoItemsAssuntos, setEstadosSelecaoItemsAssuntos] = useState<SelecaoItemsAssunto[]>([{ assunto_id: 0, estadoSelecao: true }]);

	React.useEffect(() => {
		getSubjectsTutor()
			.then((data) => {
				let totalPendentes = 0;
				let arrayEstadosSelecaoItemsAssuntos: SelecaoItemsAssunto[] = [];

				for (let i = 0; i < data.data.length; i++) {
					arrayEstadosSelecaoItemsAssuntos.push({ assunto_id: data.data[i].id, estadoSelecao: false });
					totalPendentes = totalPendentes + data.data[i].agendamentos_pendentes;
				}

				arrayEstadosSelecaoItemsAssuntos.unshift({ assunto_id: 0, estadoSelecao: true });

				setTotalAgendamentosPendentes(totalPendentes);
				setEstadosSelecaoItemsAssuntos(arrayEstadosSelecaoItemsAssuntos);
				setSubjects(data.data);

			})
			.catch((e) => {
				console.log(e);
			});
	}, [props.reload, totalAgendamentosPendentes, setEstadosSelecaoItemsAssuntos, setTotalAgendamentosPendentes]);

	function atualizarEstadosSelecaoItemsAssuntos(idAssunto: number) {
		let arrayEstadosSelecaoItemsAssuntos = estadosSelecaoItemsAssuntos;

		for (let i = 0; i < arrayEstadosSelecaoItemsAssuntos.length; i++) {

			if (arrayEstadosSelecaoItemsAssuntos[i].assunto_id === idAssunto) {
				arrayEstadosSelecaoItemsAssuntos[i].estadoSelecao = true;
			} else {
				arrayEstadosSelecaoItemsAssuntos[i].estadoSelecao = false;
			}

		}
	}

	return (
		<section className='lista'>
			<List component='nav'>
				<ListItem
					button
					className='scheduleFilter'
					selected={estadosSelecaoItemsAssuntos[0].estadoSelecao ?? false}
					style={estadosSelecaoItemsAssuntos[0].estadoSelecao === true ? { backgroundColor: theme.palette.primary.main, color: "#FFFFFF" } : undefined}
					onClick={() => {
						props.setId(0);
						sessionStorage.setItem('IdListasCalendario', "0");
						atualizarEstadosSelecaoItemsAssuntos(0);
					}}
				>
					<ListItemIcon>
						<DateRangeIcon style={estadosSelecaoItemsAssuntos[0].estadoSelecao === true ? { color: "#FFFFFF" } : undefined} />
					</ListItemIcon>
					<ListItemText
						style={estadosSelecaoItemsAssuntos[0].estadoSelecao === true ? { color: "#FFFFFF" } : undefined}
						primary="Selecionar Todos"
						secondary={
							<Typography
								style={estadosSelecaoItemsAssuntos[0].estadoSelecao === true ? { color: "#FFFFFF" } : undefined}
							>
								{
									totalAgendamentosPendentes > 0
										? totalAgendamentosPendentes === 1
											? `${totalAgendamentosPendentes} agendamento pendente`
											: `${totalAgendamentosPendentes} agendamentos pendentes`
										: "Sem agendamentos"
								}
							</Typography>
						}
					/>
				</ListItem>

				{subjects?.map((subject: any) => {
					return (
						<ListItem
							className='scheduleFilter'
							key={subject?.id}
							button
							selected={estadosSelecaoItemsAssuntos[subject.id].estadoSelecao ?? false}
							style={estadosSelecaoItemsAssuntos[subject.id].estadoSelecao === true ? { backgroundColor: theme.palette.primary.main, color: "#FFFFFF" } : undefined}
							onClick={() => {
								props.setId(subject.id);
								sessionStorage.setItem('IdListasCalendario', subject.id);
								atualizarEstadosSelecaoItemsAssuntos(subject.id);
							}}
						>
							<ListItemIcon>
								<DateRangeIcon style={estadosSelecaoItemsAssuntos[subject.id].estadoSelecao === true ? { color: "#FFFFFF" } : undefined} />
							</ListItemIcon>
							<ListItemText
								style={estadosSelecaoItemsAssuntos[subject.id].estadoSelecao === true ? { color: "#FFFFFF" } : undefined}
								primary={subject?.nome}
								secondary={
									<Typography
										style={estadosSelecaoItemsAssuntos[subject.id].estadoSelecao === true ? { color: "#FFFFFF" } : undefined}
									>
										{
											subject.agendamentos_pendentes > 0
												? subject.agendamentos_pendentes === 1
													? `${subject.agendamentos_pendentes} agendamento pendente`
													: `${subject.agendamentos_pendentes} agendamentos pendentes`
												: "Sem agendamentos"
										}
									</Typography>
								}
							/>
						</ListItem>
					);
				})}
			</List>
		</section>
	);
}
