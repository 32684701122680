import { Backdrop, CircularProgress, Container } from "@material-ui/core";
import { useEffect, useState } from "react";
import HomepageView from "./Homepage.view";

/**
 *
 * @returns {React.ReactElement} Tela inicial do sistema para o aluno
 * Retorna a tela de disciplinas e a tela de boas vindas
 * Logo abaixo exibe a interface de busca, que é um componente separado
 */

export default function Homepage(): React.ReactElement {
    
    const [open, setOpen] = useState<boolean>(false);

    useEffect(() => {
        if(localStorage.getItem("@redirect")) {
            setOpen(true);
        }
    },[]);

	return (
        <>
            <Backdrop
                open={open}
                // onClick={()=>{
                //     setOpen(!open)
                //     localStorage.removeItem("@redirect")
                // }}
                style={{
                    color: "white",
                    zIndex: 10000
                }}>
                    <CircularProgress color="inherit" /> 
            </Backdrop>
            <Container>
                <HomepageView />
            </Container>
        </>
	);
}
