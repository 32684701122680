import React from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import FormHelperText from "@material-ui/core/FormHelperText";

import Notify from "utils/Notification";

import { storeReport } from "../../../dialogquestionsanswers.services";

import { useAuth } from "context/Auth/hook";

import { DialogReportProps } from "./dialogreport.model";

import "./styles.scss";

export default function DialogReport(props: DialogReportProps) {
	const { user } = useAuth();
	const [disable, setDisable] = React.useState(false);
	const [reason, setReason] = React.useState("");

	const onCloseDialog = () => {
		props.setOpen(false);
		setReason("");
	};

	const sendReport = () => {
		if (reason.length > 245) {
			Notify("O texto da denúncia ultrapassou 245 caracteres.", "warning");
			return;
		}

		if (reason) {
			setDisable(true)
			storeReport(props.typeReport, props.idForReport, user.ra ?? "", reason)
				.then((data) => {
					Notify("Sua denuncia foi recebida e será revisada.");
					if (props.setReload) {
						setDisable(false);
						props.setReload(true);
					}
					onCloseDialog();
				})
				.catch((e) => {
					console.log(e);
				});
		} else {
			Notify("Informe o motivo.", "warning");
		}
	};

	return (
		<Dialog open={props.open} onClose={() => onCloseDialog()} fullWidth maxWidth='xs'>
			<DialogContent className='body_report'>
				<Typography className='title_report'>Denunciar</Typography>

				<Typography className='subtitle_report'>
					Para a concretização da denúncia, por favor justique detalhadamente o motivo.
				</Typography>

				<Box width={"100%"}>
					<FormHelperText error={reason.length > 245} style={{ textAlign: "right" }}>
						{reason.length} de 245 caracteres
					</FormHelperText>
					<TextField
						id="campoMotivo"
						fullWidth
						variant='outlined'
						label='Motivo:'
						placeholder='Digite o motivo da denúncia...'
						multiline
						minRows={3}
						maxRows={3}
						value={reason}
						onChange={(e) => setReason(e.target.value)}
						error={reason.length > 245}
						inputProps={{ maxLength: 245 }}
					/>
				</Box>
			</DialogContent>

			<DialogActions className='footer_report'>
				<Button variant='contained' color='primary' id='btnReport' onClick={() => { sendReport(); }} disabled={disable}>
					Denunciar
				</Button>
			</DialogActions>
		</Dialog>
	);
}
