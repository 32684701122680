import { centralPedagogica } from "services/api";

export async function getNotifications(ra: string) {
	return await centralPedagogica
		.get(`aluno/notificacoes/`)
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

export async function getNotificationsCentral() {
	return await centralPedagogica
		.get(`/aluno/mensagens/total`)
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}


export async function getNumberPendingReviewsTutor() {
	return await centralPedagogica
		.get("tutor/revisoes?situacao=pendente")
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

export async function readedNotification(idNotification: string) {
	return await centralPedagogica
		.get(`aluno/notificacoes/${idNotification}`)
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}

export async function clearAllNotifications() {
	return await centralPedagogica
		.get(`aluno/notificacoes/all`)
		.then(({ data }) => {
			return data;
		})
		.catch((e) => {
			return e;
		});
}
