import { AxiosResponse } from "axios";
import { useAuth } from "context/Auth/hook";
import { useState } from "react";
import ProfileContext from "./context";
import { INI_VALUES, ProfileInterface } from "./defaults";
import Notify from "utils/Notification";
import { ecossistema } from "services/api";


export const ProfileProvider = (props) => {		
	const { user, setUser }             = useAuth();                // Busca dados do usuário e setter para dado do usuário        
	
	// Variáveis manipuladas pelo perfil de tutor
	
	const [ra, setRaAluno]              = useState<any>(null);      // RA do aluno a ser utilizado para buscar seu perfil
	const [loader, setLoader]           = useState<boolean>(false); // Verifica se a chamada na API está em execução 
	const [profile, setProfile]         = useState<ProfileInterface|null|undefined>(INI_VALUES.profile); 	// Armazena perfil atual buscado pelo tutor
	const [profiles, setProfiles]       = useState<ProfileInterface[]|any[]>(INI_VALUES.profiles);      	// Armazena perfis que foram consultados pelo tutor
	const [profileStep, setProfileStep] = useState<number>(INI_VALUES.profileStep);      					// Define o estágio de carregamento e mensagem a ser exibida

	// Perfil Adiciona um novo perfil, disciplinas e cursos livres à lista
	const addProfileAndSubjectsToCollection = (perfil: any) => {
        setLoader(true);    // Define o estado do spinner e feedback
                            // Busca nome do perfil e o adiciona ao perfil
		ecossistema(`nti/profile/${ra}`)
			.then((response: AxiosResponse<any>) => {
				setProfileStep(1);				
				const { nome } = response.data;
				setUser({
					...user,
					ra: ra,
					nome: nome,
				});
				localStorage.setItem(
					"@Application:User", 
					JSON.stringify({
						...user,
						ra: ra,
						nome: nome,
					})
				);
				return nome;
			})
			.then((nome: string) => {
				//Busca disciplinas e adiciona ao perfil
				ecossistema.get(`/nti/disciplinas/${perfil}`)
					.then((res: AxiosResponse<any>) => {
						setProfileStep(2);						
						return {
							nome,
							disciplinas: res.data,
						};
					})
					.then((dados) => {
						// Busca cursos livres no Moodle e os adiciona ao perfil
						ecossistema.get(`/nti/inscricoes-moodle/${perfil}`).then((res: AxiosResponse<any>) => {
							// Adiciona perfil ini
							setProfileStep(3);		

							let disciplinas: any = []
							disciplinas = res.data.filter((disciplina) => {
								return (disciplina.idturmadisc_rm !== null)
							});

							let cursosLivres: any = []
							cursosLivres = res.data.filter((cursoLivre) => {
								return ((
									cursoLivre.role_shortname === "student" || cursoLivre.role_archetype === "student"
								) && (
										cursoLivre.idturmadisc_rm === null
									)
								)
							});
							// eslint-disable-next-line @typescript-eslint/no-unused-vars
							cursosLivres = cursosLivres.reduce((r,a) => {
								r[a.categoria_nome] = r[a.categoria_nome] || [];
								r[a.categoria_nome].push(a);
								return r;
							}, Object.create(null));

							let salasMoodle: any = []
							salasMoodle = res.data.filter(item => {
								return item.role_shortname === "editingteacher" || item.role_shortname === "teacher"
							})

							setProfiles([
								...profiles,
								{
									ra: ra,
									nome: dados.nome,
									disciplinas: disciplinas,
									cursosLivres: cursosLivres,
									salasMoodle: salasMoodle,
								},
							]);
							setProfile({
								ra: ra,
								nome: dados.nome,
								disciplinas: disciplinas,
								cursosLivres: cursosLivres,
								salasMoodle: salasMoodle,
							});
							setProfileStep(4);
							setLoader(false);

							Notify("Perfil adicionado com sucesso", "success");

							sessionStorage.setItem(
								"@user:profiles:collection",
								JSON.stringify([
									...profiles,
									{
										ra: ra,
										nome: nome,
										disciplinas: disciplinas,
										cursosLivres: cursosLivres,
										salasMoodle: salasMoodle,
									},
								]),
							);
						});
					});
			})
			.catch(() => {
				setProfileStep(5);
				Notify("Erro ao adicionar perfil", "error");
				setLoader(false);
			});
	};
	// TODO: Carregar a salvar o dados no localStorage em formato apropriado	

	return (
		<ProfileContext.Provider value={{
			ra,
			loader,
			profile,
			profiles,
			profileStep,
			setRaAluno,
			setLoader,
			setProfile,
			setProfiles,
			setProfileStep,
			addProfileAndSubjectsToCollection,
		}}>
			{props.children}
		</ProfileContext.Provider>
	);
};
