export const calcularScoreComunicacaoPedagogica = (score: any): number => {
    if(Object.entries(score).length > 0) {
        let scoreTotalComunicacaoPedagogica = 0;

        scoreTotalComunicacaoPedagogica = scoreTotalComunicacaoPedagogica + score.scores?.ALUNO_ENVIA_DUVIDA_CONTEUDO;
        scoreTotalComunicacaoPedagogica = scoreTotalComunicacaoPedagogica + score.scores?.ALUNO_RESPONDE_DUVIDA_CONTEUDO;
        scoreTotalComunicacaoPedagogica = scoreTotalComunicacaoPedagogica + score.scores?.ALUNO_COMENTA_RESPOSTA_DUVIDA_CONTEUDO;
        scoreTotalComunicacaoPedagogica = scoreTotalComunicacaoPedagogica + score.scores?.ALUNO_AVALIA_RESPOSTA_DUVIDA_CONTEUDO;
        scoreTotalComunicacaoPedagogica = scoreTotalComunicacaoPedagogica + score.scores?.ALUNO_SOLICITA_AGENDAMENTO_DEFER_INDEFER;

        return scoreTotalComunicacaoPedagogica;
    } else {
        return 0;
    }
}

export const calcularScoreEcossistema = (score: any): number => {
    if(Object.entries(score).length > 0) {
        let scoreTotalEcossistema = 0;

        scoreTotalEcossistema = scoreTotalEcossistema + score.scores?.USUARIO_CONCLUIU_TUTORIAL;

        return scoreTotalEcossistema;
    } else {
        return 0;
    }
}

export const calcularScoreMaterialDidatico = (score: any): number => {
    if(Object.entries(score).length > 0) {  
        let scoreTotalMaterialDidatico = 0;
    
        scoreTotalMaterialDidatico = scoreTotalMaterialDidatico + score.scores?.USUARIO_CONTRIBUA_COM_DOCUMENTO;
        scoreTotalMaterialDidatico = scoreTotalMaterialDidatico + score.scores?.USUARIO_REALIZA_COMENTARIO;
        scoreTotalMaterialDidatico = scoreTotalMaterialDidatico + score.scores?.USUARIO_AVALIA_CONTRIB_COM_DOCUMENTO;
        scoreTotalMaterialDidatico = scoreTotalMaterialDidatico + score.scores?.USUARIO_ACESSA_RECURSO_APRENDZ;
        scoreTotalMaterialDidatico = scoreTotalMaterialDidatico + score.scores?.USUARIO_ACESSA_RECURSO_APROF_CONHECIMENTO;
        scoreTotalMaterialDidatico = scoreTotalMaterialDidatico + score.scores?.USUARIO_EDIT_SAVE_BLOCO_DE_NOTAS;
        scoreTotalMaterialDidatico = scoreTotalMaterialDidatico + score.scores?.USUARIO_RESPONDE_PESQUISA_AVA_QUALIDADE_MAT_DIDAT;
    
        return scoreTotalMaterialDidatico;
    } else {
        return 0;
    }
}