import React from "react";
import { useParams } from "react-router-dom";

import DialogQuestionsAnswers from "../DialogQuestionsAnswers";
import ListSolicitationsReviewQuestion from "../ListSolicitationsReviewQuestion";
import CardNavigate from "../CardNavegate";
import DialogQuestionsAnswersTutor from "../DialogQuestionsAnswersTutor";

import Forum from "@material-ui/icons/Forum";
import VoiceChat from "@material-ui/icons/VoiceChat";
import ListAlt from "@material-ui/icons/ListAlt";
import CalendarScreen from "../CalendarScreen";

export default function CardsMenuTutor() {
	const { area } = useParams<{ area: "reviews" | "schedules" | "questions" }>();

	const [openQA, setOpenQA] = React.useState(false);
	const [openReviewQuestion, setOpenReviewQuestion] = React.useState(false);
	const [openCalendar, setOpenCalendar] = React.useState(false);
	const [openTutor, setOpenTutor] = React.useState(false);

	React.useEffect(() => {
		if (area) {
			if (area === "reviews") {
				setOpenReviewQuestion(true);
			}
		}
	}, [area]);

	return (
		<>
			{/* Card com CTA para Perguntar e responder */}
			<CardNavigate
				id='btnSpeakTeacher'
				type='tutor'
				imageCard='/perguntas-respostas.png'
				titleCard='Dúvidas de conteúdo'
				iconStartButton={<Forum style={{ fontSize: 32 }} />}
				titleButton='Falar com o professor'
				actionButton={() => {
					setOpenQA(true);
				}}
			/>

			<CardNavigate
				id='btnSpeakTutor'
				imageCard='/isis.png'
				titleCard='Dúvidas acadêmicas'
				iconStartButton={<ListAlt style={{ fontSize: 32 }} />}
				titleButton='Falar com Tutor'
				actionButton={() => {
					setOpenTutor(true);
				}}
			/>

			{/* Card com CTA para abrir ISIS */}
			<CardNavigate
				id='btnPerformAttendance'
				type='tutor'
				imageCard='/agenda-horario.png'
				titleCard='Suporte à aprendizagem'
				iconStartButton={<VoiceChat style={{ fontSize: 32 }} />}
				titleButton='Realizar Atendimento'
				actionButton={() => {
					setOpenCalendar(true);
				}}
			/>

			{/* Card com CTA para Revisão de questões */}
			<CardNavigate
				id='btnReviewsAttendance'
				type='tutor'
				imageCard='/revisao-questao.jpg'
				titleCard='Revisão de avaliações'
				iconStartButton={<ListAlt style={{ fontSize: 32 }} />}
				titleButton='Atendimento de Revisões'
				actionButton={() => {
					setOpenReviewQuestion(true);
				}}
			/>

			{/* Dialog Perguntar e Responder */}
			<DialogQuestionsAnswers open={openQA} setOpen={setOpenQA} />
			<DialogQuestionsAnswersTutor open={openTutor} setOpen={setOpenTutor} />
			{/* Dialog do Calendário */}
			<CalendarScreen open={openCalendar} onClosed={setOpenCalendar} />
			{/* Dialog da revisão de questões */}
			<ListSolicitationsReviewQuestion
				open={openReviewQuestion}
				setOpen={setOpenReviewQuestion}
			/>
		</>
	);
}
