import React from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogActions from "@material-ui/core/DialogActions";
import { useTheme } from "@material-ui/core/styles";

import Check from "@material-ui/icons/Check";
import AttachFile from "@material-ui/icons/AttachFile";
import FileCopy from "@material-ui/icons/FileCopy";

import { getScheduleAluno, deleteScheduleAluno } from "../scheduletime.services";

import { DialogScheduleConfirmationProps, StoreScheduleReturn } from "./scheduleConfirmation.model";

import Notify from "utils/Notification";
import { useAuth } from "context/Auth/hook";

import { usePedagogicalCommunication } from "context/PedagogicalCommunication/hooks/usePedagogicalCommunication";

import "./styles.scss";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";

export default function DialogScheduleConfirmation(
	props: DialogScheduleConfirmationProps,
): React.ReactElement {
	const { user } = useAuth();
	const colorsTheme = useTheme();
	const { updateNumSchedule, setUpdateNumSchedule } = usePedagogicalCommunication();

	const [data, setData] = React.useState<StoreScheduleReturn>();
	const [loading, setLoading] = React.useState(true);
	const [loadingDel, setLoadingDel] = React.useState(false);

	const [openDelete, setOpenDelete] = React.useState(false);

	const deleteSchedule = () => {
		setLoadingDel(true);

		if (!user?.ra) {
			Notify("RA não está inserido", "warning");
			return;
		}

		if (!data?.id) {
			Notify("Id de agendamento não inserido", "warning");
			return;
		}

		deleteScheduleAluno(data?.id)
			.then((data) => {
				if (data.deleted) {
					props.setOpen(false);
					setOpenDelete(false);
					setLoadingDel(false);
					setUpdateNumSchedule(!updateNumSchedule);
					Notify("Agendamento excluído.", "success");
				}
			})
			.catch(() => {
				Notify("[Erro]: Ocorreu um erro, por favor, tente novamente.", "error");
				setLoadingDel(false);
			});
	};

	React.useEffect(() => {
		if (props.open && props.idSchedule) {
			getScheduleAluno(props.idSchedule)
				.then(({ data }) => {

					let getDate = data.data.split("-");
					getDate = `${getDate[2]}/${getDate[1]}/${getDate[0]}`;

					const insertValuesData = {
						id: data.id,
						email: data.profile.email.toLocaleLowerCase(),
						data: getDate,
						horario: data.horario.horario,
						tema: data.assunto.nome,
						link: data.horario.link,
						situacao: data.situacao,
					};

					setData(insertValuesData);
					setLoading(false);
				})
				.catch(() => {
					setLoading(false);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.open, props.idSchedule]);

	return (
		<>
			<Dialog
				open={props.open}
				onClose={
					loading
						? () => {
							return;
						}
						: () => {
							props.setOpen(false);
						}
				}
				fullScreen={window.innerWidth <= 1279 ? true : false}
				maxWidth={window.innerWidth <= 1279 ? false : "md"}
			>
				<DialogContent>
					{loading ? (
						<Box
							display='flex'
							justifyContent='center'
							alignItems='center'
							flexDirection='column'
						>
							<Typography className='await_request'>Por favor, aguarde...</Typography>
							<CircularProgress />
						</Box>
					) : (
						<>
							<Box style={{ display: "flex", justifyContent: "right", alignItems: "center" }}>
								<IconButton id='btnCloseScheduleConfirmation' onClick={() => props.setOpen(false)}>
									<Close />
								</IconButton>
							</Box>

							<Box className='area_header_dialog'>
								<Check
									style={{
										backgroundColor: colorsTheme.palette.primary.main,
									}}
								/>
								<Typography>Seu agendamento foi enviado</Typography>
							</Box>

							<Box className='area_data_confirmation'>
								<Typography className='info_data'>
									O comprovante da solicitação de revisão de questão foi enviado
									para o e-mail: <span>{data?.email}</span>
								</Typography>
								<Typography className='info_data'>
									Data: <span>{data?.data}</span>
								</Typography>
								<Typography className='info_data'>
									Horário: <span>{data?.horario}</span>
								</Typography>
								<Typography className='info_data'>
									Tema: <span>{data?.tema}</span>
								</Typography>

								<Box className='area_link'>
									<Button
										id='btnAcessarSalaVideo'
										startIcon={<AttachFile />}
										href={data?.link ? data?.link : ""}
										target='_blank'
										variant='contained'
										color='primary'
									>
										Acessar Sala de Videoconferência
									</Button>

									<Button
										id='btnCopiarLink'
										variant='outlined'
										color='primary'
										title='Copiar Link'
										onClick={() => {
											navigator.clipboard.writeText(
												data?.link ? data?.link : "",
											);
											Notify("Copiado.");
										}}
										startIcon={<FileCopy />}
									>
										Copiar Link
									</Button>
								</Box>

								{data?.situacao === "pendente" && (
									<Box className='footer_undo'>
										<Typography variant='subtitle2'>
											Agendamento de Mentoria
										</Typography>
										<Button id='btnExcluirAgendamento' onClick={() => setOpenDelete(true)}>Excluir</Button>
									</Box>
								)}
							</Box>
						</>
					)}
				</DialogContent>
			</Dialog>

			{/* Dialog para confirmar exclusão de solicitação */}
			<Dialog
				open={openDelete}
				onClose={
					loadingDel
						? () => {
							return;
						}
						: () => {
							setOpenDelete(false);
						}
				}
			>
				<DialogTitle>
					<Typography className='textDelete'>
						{loadingDel ? (
							<CircularProgress />
						) : (
							"Confirma a exclusão permanente desta solicitação ?"
						)}
					</Typography>
				</DialogTitle>

				{!loadingDel && (
					<DialogActions>
						<Button
							id='btnConfirmaExclusao'
							variant='contained'
							color='primary'
							onClick={() => deleteSchedule()}
						>
							Sim
						</Button>

						<Button
							id='btnNegaExclusao'
							variant='outlined'
							color='primary'
							onClick={() => {
								setOpenDelete(false);
							}}
						>
							Não
						</Button>
					</DialogActions>
				)}
			</Dialog>
		</>
	);
}
