import React, { useState } from "react";

// Adicionando importações do Material UI 4

import {
	Card,
	Button,
	CardMedia,
	Typography,
	useTheme,
	CardContent,
	CardActions,
	MobileStepper,
} from "@material-ui/core";

// Importando estilos

import "./styles.scss";

// Importando interfaces
import { CardInterface } from "./card.model";

export default function CardTutorialMobileView({
	etapaTutorialMobile,
	etapa,
	setEtapa,
	setOpenDialog,
	qtdEtapas,
}: CardInterface): React.ReactElement {
	const theme = useTheme();
	const [exibirBotoes, setExibirBotoes] = useState<boolean>(true);

	return (
		<Card className='card-etapa-tutorial-mobile'>
			{etapaTutorialMobile.imagem ? (
				<CardMedia
					className='box-image'
					component='img'
					alt='banner'
					src={etapaTutorialMobile.imagem}
					title='Imagem aleatória'
				/>
			) : (
				<CardMedia
					className='box-image'
					component='img'
					alt='banner'
					src='https://dirin.s3.amazonaws.com/assets_eco_front/Imersao_em_Ambiente_Profissional_-_img_de_erro.png'
					title='Imagem aleatória'
				/>
			)}

			<CardContent className='box-content'>
				<Typography component='h2'>
					<strong>{etapaTutorialMobile.titulo}</strong>
				</Typography>
				{
					etapa === 4 ?
						<Typography component='p'>
							<a
								href="https://docs.google.com/forms/d/e/1FAIpQLSesddGTHZAhBmNNZLccRVFQgkIFqxYel3qQ5YOnmyfoZx3TOw/viewform"
								target="_blank"
								rel="noopener noreferrer"
								onClick={() => {
									setExibirBotoes(true);
								}}
							>
								{etapaTutorialMobile.texto}
							</a>
						</Typography>
						:
						<Typography component='p'>{etapaTutorialMobile.texto}</Typography>

				}
				<MobileStepper
					variant='dots'
					position='static'
					activeStep={etapa}
					steps={qtdEtapas}
					backButton={<></>}
					nextButton={<></>}
					style={{
						display: "flex",
						justifyContent: "center",
						marginTop: "60px",
						backgroundColor: "transparent",
					}}
				/>
			</CardContent>

			<CardActions className='box-botoes'>
				{etapa !== 5 ?
					exibirBotoes ?
						<React.Fragment>
							<Button
								className='botao-pular'
								onClick={() => {
									setOpenDialog(false);
									setEtapa(0);
								}}
								style={{ backgroundColor: theme.palette.primary.main }}
							>
								Pular
							</Button>
							{
								etapa === 3 ?
									<Button
										className='botao-avancar'
										onClick={() => {
											setEtapa(etapa + 1);
											setExibirBotoes(false);
										}}
										style={{ backgroundColor: theme.palette.primary.main }}
									>
										Avançar
									</Button>
									:
									<Button
										className='botao-avancar'
										onClick={() => {
											setEtapa(etapa + 1);
										}}
										style={{ backgroundColor: theme.palette.primary.main }}
									>
										Avançar
									</Button>
							}
						</React.Fragment>
						:
						<></>
					:
					<Button
						className='botao-pular'
						onClick={() => {
							setOpenDialog(false);
							setEtapa(0);
						}}
						style={{ backgroundColor: theme.palette.primary.main }}
					>
						Iniciar
					</Button>
				}
			</CardActions>
		</Card>
	);
}
