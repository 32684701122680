import React, { useState, useEffect } from "react";
import {
	Box,
	Button,
	ButtonGroup,
	Container,
	Dialog,
	Divider,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	useTheme,
} from "@material-ui/core";
import Notify from "utils/Notification";
import Welcome from "components/Welcome";
import CardMock from "components/CardMock";
import Cards from "components/Cards";
import { AxiosResponse } from "axios";
import { useAuth } from "context/Auth/hook";
import Subject from "./Subject";
import SearchEngineV2 from "components/SearchEngineV2";
import SubjectView from "./Subject/Subject.view";
import { Delete, Add } from "@material-ui/icons";
import "./styles.scss";
import { ecossistema } from "services/api";

export default function MockScreen(): React.ReactElement {
	const theme = useTheme();
	const [disciplinas, setDisciplinas] = useState<any[] | any | null | undefined>(null);
	// Toggle add disciplinas
	const [toggleAddDisciplina, setToggleAddDisciplina] = useState<boolean>(false);

	const [curso, setCurso] = useState<any | null | undefined>(null);
	const [cursos, setCursos] = useState<any[] | any | null | undefined>(null);
	const [subjects, setSubjects] = useState<any[] | any | null | undefined>(null);
	const [subject, setSubject] = useState<any | null | undefined>(null);
	const [minhasDisciplinas, setMinhasDisciplinas] = useState<any[] | any>([]);
	// const { results } = useSearch();
	const [carregaDisciplinas, setCarregaDisciplinas] = useState<boolean>(true);

	const [errorInSubjectSelection, setErrorInSubjectSelection] = useState<boolean>(false);

	const { user } = useAuth();

	useEffect(() => {
		const userDisciplinas = sessionStorage.getItem("@user:disciplinas");
		if (userDisciplinas) {
			setCarregaDisciplinas(false);
			setDisciplinas(JSON.parse(userDisciplinas || ""));
		} else {
			ecossistema.get(`/nti/disciplinas/${user.ra}`).then((res: AxiosResponse<any>) => {
				setDisciplinas(res.data);
				setCarregaDisciplinas(false);
				sessionStorage.setItem("@user:disciplinas", JSON.stringify(res.data));
				return res.data;
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		ecossistema.get("/cursos").then((res: AxiosResponse<any>) => {
			setCursos(res.data);
		});
	}, []);

	useEffect(() => {
		if(curso) {
			try {
				ecossistema.get(`/cursos/${curso}/disciplinas`)
					.then((res: AxiosResponse<any>) => {
						setSubjects(res.data);
					})
					.catch((err: any) => {
						console.error(err);
					});
			} catch (e) {
				console.log("Erro ao carregar disciplinas");
			}
		}
	}, [curso, setCurso]);

	// Add minhas disciplinas ao sessionStorage e ao recarregar as telas
	useEffect(() => {
		if (minhasDisciplinas.length > 0) {
			sessionStorage.setItem("@user:minhasDisciplinas", JSON.stringify(minhasDisciplinas));
		} else {
			try {
				const minhasDisciplinasLocais = sessionStorage.getItem("@user:minhasDisciplinas");
				if (minhasDisciplinasLocais !== null && minhasDisciplinasLocais.length > 0) {
					setMinhasDisciplinas(JSON.parse(minhasDisciplinasLocais));
				}
			} catch (e) {
				console.log(e);
			}
		}
	}, [minhasDisciplinas]);

	useEffect(() => {
		setErrorInSubjectSelection(true);
	}, [errorInSubjectSelection]);

	return (
		<>
			<Container
				style={{
					position: "relative",
				}}
			>
				{user.typeUser === "student" && (
					<>
						<Box
						// style={{
						// 	height: (disciplinas && disciplinas.length > 0) || (minhasDisciplinas && minhasDisciplinas.length > 0) || carregaDisciplinas ? "45vh" : "0",
						// 	// position: "relative",
						// 	// zIndex:5
						// }}>
						>
							<Box mb={3} mt={3}>
								<Box
									display='flex'
									flexDirection='row'
									justifyContent={"flex-start"}
									alignItems={"center"}
									style={{
										marginLeft: "70px",
									}}
								>
									<Typography style={{ color: "#333", marginRight: "12px" }}>
										MINHAS DISCIPLINAS
									</Typography>
									<IconButton
										size='small'
										onClick={() => {
											setToggleAddDisciplina(true);
										}}
									>
										<Add />
									</IconButton>
								</Box>
								<Cards>
									{minhasDisciplinas &&
										minhasDisciplinas.map((disciplina) => {
											const data = {
												nome: disciplina.name,
												id:
													disciplina.avas.length > 0
														? disciplina.avas[0].id_ava
														: "",
												idturmadisc_rm: disciplina.id,
											};
											return data.id !== "" ? (
												<SubjectView {...data} />
											) : null;
										})}

									{disciplinas ? (
										disciplinas.map((disciplina) => {
											return (
												<>
													<Subject {...disciplina} />
												</>
											);
										})
									) : (
										<>
											<CardMock />
											<CardMock />
											<CardMock />
											<CardMock />
											<CardMock />
										</>
									)}
								</Cards>
							</Box>
						</Box>
					</>
				)}
				<Box style={{ marginTop: "5vh", marginBottom: "10vh" }}></Box>
				<Box
					mt={5}
					className={
						(disciplinas && disciplinas.length > 0) ||
						(minhasDisciplinas && minhasDisciplinas.length > 0) ||
						carregaDisciplinas
							? "welcome_subject"
							: ""
					}
				>
					<Welcome />
				</Box>

				<div
					style={{
						zIndex: 5,
					}}
				>
					<SearchEngineV2 />
				</div>
			</Container>
			<Dialog
				open={toggleAddDisciplina}
				onClose={() => {
					setToggleAddDisciplina(false);
				}}
				maxWidth='lg'
				className='modal'
				style={{
					width: "80%",
				}}
			>
				<div className='body'>
					<div
						className='head'
						style={{
							backgroundColor: theme.palette.primary.main,
						}}
					>
						<Typography variant='body1'>GERENCIAR DISCIPLINAS</Typography>
					</div>
					<Box p={3}>
						<Typography
							variant='body1'
							style={{
								marginBottom: "12px",
							}}
						>
							{"Selecione o curso e disciplina a ser adicionado".toUpperCase()}
						</Typography>
						<form
							style={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "space-between",
							}}
						>
							<div style={{ flexGrow: 2 }}>
								<select
									onChange={(e) => {
										setCurso(e.target.value);
										setSubject(null);
									}}
									style={{
										padding: "12px",
										border: "solid #ccc 1px",
										borderRadius: "4px",
										marginRight: "4px",
									}}
								>
									<option value=''>Selecione o curso</option>
									{cursos &&
										cursos.map((curso) => {
											return (
												<>
													<option value={curso.id}>
														{curso.nome_curso}
													</option>
												</>
											);
										})}
								</select>
								{curso && subjects ? (
									<select
										onChange={(e) => {
											setSubject(e.target.value);
										}}
										style={{
											padding: "12px",
											border: "solid #ccc 1px",
											borderRadius: "4px",
											marginRight: "4px",
										}}
									>
										<option value=''>Selecione a disciplina</option>
										{subjects &&
											subjects.map((subject) => {
												return (
													<>
														<option value={JSON.stringify(subject)}>
															{subject.name}
														</option>
													</>
												);
											})}
									</select>
								) : null}
							</div>
							<ButtonGroup
								style={{ flexGrow: 1, marginTop: "12px", marginBottom: "12px" }}
							>
								<Button
									variant={"contained"}
									style={{
										backgroundColor: theme.palette.primary.main,
										color: "white",
									}}
									disableElevation
									onClick={() => {
										if (subject) {
											if (JSON.parse(subject).avas.length === 0) {
												Notify(
													"A disciplina não pode ser adicionada",
													"error",
												);
											} else {
												setMinhasDisciplinas([
													...minhasDisciplinas,
													JSON.parse(subject),
												]);
												Notify(
													"Disciplina adicionada com sucesso",
													"success",
												);
											}
										}
										// TODO:  O código abaixo deveria estar funcionando:
										// let minhaDisciplinaSelecionada = {
										// 	id: minhasDisciplinas[-1].avas.length > 0 ? minhasDisciplinas[-1].avas[0].id_ava : "",
										// 	nome: minhasDisciplinas[-1].name
										// }
										// setDisciplinas([...disciplinas, minhaDisciplinaSelecionada]);
									}}
								>
									<Add />
									Adicionar disciplina
								</Button>
								<Button
									variant={"text"}
									style={{
										backgroundColor: "transparent",
										color: theme.palette.primary.main,
									}}
									disableElevation
									onClick={() => {
										setMinhasDisciplinas([]);
										sessionStorage.removeItem("@user:minhasDisciplinas");
										Notify("Todas as disciplinas foram removidas", "success");
									}}
								>
									<Delete />
									Limpar seleção
								</Button>
							</ButtonGroup>
						</form>
						<Divider />
						<Box>
							<Typography
								variant='body1'
								style={{ marginBottom: "12px", marginTop: "12px" }}
							>
								{"Disciplinas selecionadas".toUpperCase()}
							</Typography>
							<TableContainer component={Paper}>
								<Table size='small' aria-label='a dense table'>
									<TableHead>
										<TableRow>
											<TableCell>Disciplina</TableCell>
											<TableCell>ID AVA</TableCell>
											<TableCell>Ação</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{minhasDisciplinas &&
											minhasDisciplinas.map((disciplina, index) => {
												return (
													<TableRow key={index}>
														<TableCell>
															<a
																target='_blank'
																href={`https://virtual.uninta.edu.br/course/view.php?id=${
																	disciplina.avas.length > 0
																		? disciplina.avas[0].id_ava
																		: "Sem ID AVA"
																}`}
																rel='noreferrer'
																style={{
																	color: "#333",
																	textDecoration: "none",
																}}
															>
																<Typography variant='body2'>
																	{disciplina.name.toUpperCase()}{" "}
																</Typography>
															</a>
														</TableCell>
														<TableCell>
															{disciplina.avas.length > 0
																? disciplina.avas[0].id_ava
																: "Sem ID AVA"}
														</TableCell>
														<TableCell>
															<IconButton
																onClick={() => {
																	// setMinhasDisciplinas([...minhasDisciplinas, JSON.parse(subject)]);
																	// let minhasNovasDisciplinas = minhasDisciplinas.splice(index, 1);
																	if (
																		minhasDisciplinas.length ===
																		1
																	) {
																		setMinhasDisciplinas([]);
																		sessionStorage.removeItem(
																			"@user:minhasDisciplinas",
																		);
																	} else {
																		const minhasNovasDisciplinas =
																			minhasDisciplinas.filter(
																				(
																					disciplina,
																					indice,
																				) => {
																					return (
																						indice !==
																						index
																					);
																				},
																			);
																		setMinhasDisciplinas(
																			minhasNovasDisciplinas,
																		);
																	}
																	Notify(
																		"Disciplina removida com sucesso",
																		"success",
																	);
																}}
															>
																<Delete />
															</IconButton>
														</TableCell>
													</TableRow>
												);
											})}
									</TableBody>
								</Table>
							</TableContainer>
						</Box>
					</Box>
				</div>
			</Dialog>
		</>
	);
}
