import { Avatar, Box, Button, CircularProgress, Divider, FormHelperText, List, ListItem, Typography } from "@material-ui/core";
import { Send } from "@material-ui/icons";
import { Editor } from "primereact/editor";
import React, { useEffect, useRef, useState } from "react";
import { getNameInitials } from "utils/getNameInitials";
import { removerTags } from "utils/removeTagsHTML";
import { useTheme } from "@material-ui/core/styles";
import Notify from "utils/Notification";
import { postNotificationCentral } from "../duvidasacademicastutor.servicer";
import parse from "html-react-parser";
import { getMessagesToUserCentral } from "components/PopupNotificationsMessage/notificationsmessage.services";
import "./styles.scss";

export default function ChatViewProfile({ ra, avatar_path, name, curso, polo }): JSX.Element {
    const theme = useTheme();
    const [loadingSendAnswer, setLoadingSendAnswer] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [answer, setAnswer] = React.useState("");
    const [dados, setDados] = useState<any>([]);

    const newRef = useRef<any>(null);

    const sendMessage = async () => {
        if (!answer) {
            Notify("Preencha todos campos", "warning");
            return;
        }

        if (removerTags(answer).length > 1000) {
            Notify("O texto da resposta ultrapassou 500 caracteres.", "warning");
            return;
        }

        setLoadingSendAnswer(true);
        await postNotificationCentral({
            "tipo": "tutor",
            "para": [ra],
            "mensagem": answer
        }).then((data: any) => {
            if (data.data.data?.length !== 0) {
                Notify("Sua resposta foi enviada!", "success");
            } else {
                Notify("Seu usuário não tem autorização para enviar mensagens!", "warning");
            }
            setAnswer("");
            setLoadingSendAnswer(false);
        }).catch((e) => {
            Notify("Sua resposta não pode ser enviada!", "warning");
            setLoadingSendAnswer(false);
            console.log(e);
        });
    };

    function date(data: any) {
        let arrayData = data.split(" ");
        arrayData.splice(1, 1);
        arrayData = arrayData[0].split("-");
        return arrayData.reverse().join("/");
    }

    function hora(hora: any) {
        let arrayHora: string[] = hora.split(" ");
        return arrayHora[1];
    }


    React.useEffect(() => {
        try {
            getMessagesToUserCentral(ra).then((data) => {
                setDados(data.data)
                setLoading(false)
            }).catch(() => {
                setLoading(false)
                setDados([])
            });
        } catch (e) {
            // Pass
            setLoading(false)
            setDados([])
        }
    }, [loadingSendAnswer, ra])

    useEffect(() => {
        newRef.current.scrollTo(0, newRef.current.scrollHeight);
    }, [dados, loading]);

    return (
        <>
            <Box className="container-view-perfil_first-child" >
                <Typography
                    style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginBottom: "5px",
                        fontSize: "20px"
                    }}
                    variant='h5'
                >
                    MENSAGENS ENVIADAS
                </Typography>
                <Box
                    style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        // justifyContent: "center",
                        flexDirection: "column",
                    }}
                    className="containerView"
                >
                    <Box style={{ width: "100%" }} >
                        <List style={{ width: "100%" }}>
                            <ListItem>
                                <Box className='infoViewPerfil' >
                                    <Avatar
                                        src={avatar_path ? `https://dirin.s3.us-east-1.amazonaws.com/${avatar_path}` : ""}
                                        alt='Perfil'
                                        className={" "}
                                    >
                                        {getNameInitials(name ?? " ")}
                                    </Avatar>
                                    <Box>
                                        <Typography className='name_studenty'>
                                            {name ?? " "}
                                        </Typography>
                                        <Typography className='ra_studenty'>
                                            {curso ?? " "} - {ra ?? " "} - {polo ?? " "}
                                        </Typography>
                                    </Box>
                                </Box>
                            </ListItem>
                        </List>
                        <Divider style={{ width: "100%" }} />
                    </Box>
                    <div ref={newRef} className="box_view_perfil" id="scroll-to-bottom'" >
                        <Box style={{ width: "100%" }}>
                            {
                                (loading || (dados.length === 0)) ? <></> :
                                    <>
                                        <List style={{ width: "100%" }} >
                                            {
                                                dados["mensagens"].map((data: any) => (
                                                    <>
                                                        <ListItem style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                                                            <Box className='question_author'
                                                                style={{
                                                                    display: "flex",
                                                                    flexDirection: "row",
                                                                    gap: "15px",
                                                                    width: "100%"
                                                                }}>
                                                                <Avatar
                                                                    src={data?.profile.avatar_path ?? ""}
                                                                    alt='Perfil'
                                                                    className={""}
                                                                >
                                                                    {getNameInitials(data?.profile.name ?? "")}
                                                                </Avatar>
                                                                <Box className='boxListItem'>
                                                                    <div
                                                                        style={{
                                                                            width: "100%",
                                                                            flexDirection: "row",
                                                                            // justifyContent: "space-between",
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                        }}>
                                                                        <div style={{ width: "100%" }}>
                                                                            <Box
                                                                                style={{
                                                                                    display: "flex",
                                                                                    flexDirection: "row",
                                                                                    fontWeight: "bold",
                                                                                    justifyContent: "space-between",
                                                                                    alignItems: "center",
                                                                                    width: "100%",
                                                                                    paddingBottom: "15px",
                                                                                }}>
                                                                                <div>
                                                                                    <Typography style={{ fontWeight: "bold", color: "black", fontSize: "14px" }}>
                                                                                        Tutor(a) &nbsp;{(data.profile.name ?? "")}
                                                                                    </Typography>
                                                                                    <Typography style={{ fontSize: "12px", alignSelf: "flex-end" }} className='author_name'>
                                                                                        {date(data.created_at ?? "")}
                                                                                        - {hora(data.created_at ?? "")}
                                                                                    </Typography>
                                                                                </div>
                                                                            </Box>

                                                                            <Box className='question_author'>
                                                                                <Typography className='author_name'
                                                                                    style={{ minWidth: "100%", maxWidth: "100%" }}>
                                                                                    {parse(data.mensagem ?? "")}
                                                                                </Typography>
                                                                            </Box>
                                                                            <Box
                                                                                style={{
                                                                                    minWidth: "100%",
                                                                                    display: "flex",
                                                                                    justifyContent: "flex-end",
                                                                                }}>
                                                                            </Box>
                                                                        </div>
                                                                    </div>
                                                                </Box>
                                                                <>
                                                                    {/* <div>
                                                                    <Box
                                                                        style={{
                                                                            display: "flex",
                                                                            flexDirection: "column",
                                                                            padding: "0.6rem",
                                                                            margin: "0px",
                                                                            border: "1px solid #ddd ",
                                                                            borderRadius: "4px",
                                                                            width: "100%"
                                                                        }}
                                                                    >
                                                                        <Box className='question_author'>
                                                                            <Typography className='author_name'>
                                                                                {parse(data.mensagem ?? "")}
                                                                            </Typography>
                                                                        </Box>
                                                                    </Box>
                                                                    <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                                                        <Typography className='author_name' style={{ fontSize: "14px" }}>
                                                                            Enviada por Tutor(a): {(data.profile.name ?? "")}
                                                                        </Typography>
                                                                        <Typography className='author_name' style={{ fontSize: "14px" }}>
                                                                            {date(data.created_at ?? "")}
                                                                        </Typography>
                                                                    </div>
                                                                </div> */}
                                                                </>
                                                            </Box>
                                                        </ListItem>
                                                    </>
                                                ))
                                            }
                                        </List>
                                    </>
                            }
                        </Box>
                    </div>
                    <Box style={{ width: "100%" }} >
                        <FormHelperText
                            error={removerTags(answer).length > 1000}
                            style={{ textAlign: "right" }}
                        >
                            {removerTags(answer).length} de 1000 caracteres
                        </FormHelperText>
                        <Editor
                            style={{ height: "150px" }}
                            value={answer}
                            onTextChange={(e) => {
                                setAnswer(e.htmlValue as string);
                            }}
                            id='editorNewQuestion'
                        />
                        <Box display='flex' justifyContent='flex-end' style={{ marginTop: '12px' }}>
                            <Button
                                disableElevation
                                variant='contained'
                                // color='primary'
                                onClick={() => {
                                    sendMessage();
                                }}
                                disabled={loadingSendAnswer}
                                id='btnNewResponse'
                                style={{
                                    color: "white",
                                    backgroundColor: theme.palette.primary.main
                                }}
                            >
                                {loadingSendAnswer ? (
                                    <CircularProgress
                                        style={{ color: "#fff", width: "1.5rem", height: "1.5rem" }}
                                        thickness={4.4}
                                    />
                                ) : (
                                    <>
                                        <span>Enviar Resposta</span>
                                        <Send />
                                    </>
                                )}
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
}
