import React from "react";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Theme } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";

import ArrowBack from "@material-ui/icons/ArrowBack";
// import Message from "@material-ui/icons/Message";
import HelpOutline from "@material-ui/icons/HelpOutline";
import SignalCellularAltIcon from '@material-ui/icons/SignalCellularAlt';
import SendIcon from '@material-ui/icons/Send';
import ButtonInfo from "../ButtonsInfo";
import PopupNotifications from "../PopupNotifications";
import DialogViewQuestion from "../Questions/DialogViewQuestion";
// import AvatarProfile from "components/AvatarProfile";

import { usePedagogicalCommunication } from "context/PedagogicalCommunication/hooks/usePedagogicalCommunication";
import { useAuth } from "context/Auth/hook";

import { HeaderProps } from "./header.model";

import { getCountQuestionTutor, getListQuestionsByAuthorTutor, getListAnswer } from "../dialogquestionsanswers.services";

import "./styles.scss";
import { QuestionAnswer } from "@material-ui/icons";
import useNivelEnsino from "hooks/useNivelEnsino";
import Message from "@material-ui/icons/Message";
import { Avatar } from "@material-ui/core";
import { getNameInitials } from "utils/getNameInitials";
import { usePortfolio } from "context/Portfolio";

const useStyles = makeStyles((theme: Theme) => ({
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
	},
}));

export default function Header(props: HeaderProps): React.ReactElement {
	const { user, nivelDeEnsino } = useAuth();
	const { state } = usePortfolio();
	const { updateNumQuestions } = usePedagogicalCommunication();
	const classes = useStyles();
	const colorsTheme = useTheme();

	const [listAnswer, setListAnswer] = React.useState([]);

	const [numQuestions, setNumQuestions] = React.useState(0);
	const [numAnswer, setNumAnswer] = React.useState(0);

	const [openViewQuestion, setOpenViewQuestion] = React.useState(false);
	const [openListQuestions, setOpenListQuestions] = React.useState(false);
	const [openListResponse, setOpenListResponse] = React.useState(false);
	const [listQuestions, setListQuestions] = React.useState([]);
	const [idQuestionSelected, setIdQuestionSelected] = React.useState("");

	const [titlePopup, setTitlePopup] = React.useState("");

	const handleActiveNavigate = (value: "question" | "response" | "indicador" | "message") => {
		props.setComponentOpen(value);
	};

	// Busca quantidade de perguntas e perguntas do usuário
	React.useEffect(() => {
		getCountQuestionTutor(user.ra ?? "", user)
			.then((data) => {
				setNumQuestions(data.total);
			})
			.catch((e) => {
				console.log(e);
			});

		getListQuestionsByAuthorTutor(user.ra ?? "", user)
			.then((data) => {
				const results = data.data.map((dt: any) => {
					return {
						title: dt.pergunta.replace(/(&nbsp;|<([^>]+)>)/gi, ""),
						actionClick: () => {
							setOpenViewQuestion(true);
							setIdQuestionSelected(dt.id);
							setOpenListQuestions(false);
						},
					};
				});

				setListQuestions(results);
			})
			.catch((e) => {
				console.log(e);
			});

		getListAnswer(user.ra ?? "", user)
			.then((data) => {
				setNumAnswer(data.data.length)
				const results = data.data.map((dt: any) => {
					return {
						title: dt.resposta.replace(/(&nbsp;|<([^>]+)>)/gi, ""),
						actionClick: () => {
							setOpenViewQuestion(true);
							setIdQuestionSelected(dt.pergunta_id);
							setOpenListQuestions(false);
						},
					};
				});

				setListAnswer(results);
			})
			.catch((e) => {
				console.log(e);
			});

	}, [user, updateNumQuestions]);

	return (
		<>
			<AppBar position='static' className={classes.appBar}>
				<Toolbar
					className='navbar_question_answers'
					style={{ background: colorsTheme.palette.primary.main }}
				>
					<Box display='flex' justifyContent='flex-start' alignItems='center'>
						<IconButton
							id='btnArrowBack'
							edge='start'
							color='inherit'
							aria-label='menu'
							onClick={() => props.setOpen(false)}
						>
							<ArrowBack />
						</IconButton>

						<img
							onClick={() => props.setOpen(false)}
							style={{ cursor: "pointer" }}
							src={useNivelEnsino(nivelDeEnsino)}
							className='logo'
							alt='Logo UNINTA'
						/>
					</Box>

					<Box display='flex' justifyContent='flex-start' alignItems='center'>
						{user.typeUser === "student" && (
							<ButtonInfo
								startIconButton={
									<HelpOutline
										className='iconButtonAppBar'
										style={{
											color: colorsTheme.palette.secondary.main,
											background: colorsTheme.palette.primary.main,
										}}
									/>
								}
								titleButton='Perguntas Feitas'
								value={numQuestions}
								actionButton={() => {
									if (openListResponse) {
										setOpenListResponse(false);
									}

									setOpenListQuestions(!openListQuestions);
									setTitlePopup("Suas Perguntas");
								}}
								id='btnQtdQuestions'
							>
								<PopupNotifications
									title={titlePopup}
									list={listQuestions}
									open={openListQuestions}
									setOpen={setOpenListQuestions}
								/>
							</ButtonInfo>
						)}

					</Box>
					{user.typeUser === "tutor" && (
						<ButtonInfo
							startIconButton={
								<Message
									className='iconButtonAppBar'
									style={{
										color: colorsTheme.palette.secondary.main,
										background: colorsTheme.palette.primary.main,
									}}
								/>
							}
							titleButton='Respostas Dadas'
							value={numAnswer}
							actionButton={() => {
								if (openListQuestions) {
									setOpenListQuestions(false);
								}

								setOpenListResponse(!openListResponse);
								setTitlePopup("Suas Respostas");
							}}
							id='btnQtdResponses'
						>
							<PopupNotifications
								title={titlePopup}
								list={listAnswer}
								open={openListResponse}
								setOpen={setOpenListResponse}
							/>
						</ButtonInfo>
					)}

				</Toolbar>
			</AppBar>

			<Drawer variant='permanent'>
				<Toolbar style={{ minWidth: "15vw", maxWidth: "15vw" }} />
				<List style={{ minWidth: "15vw", maxWidth: "15vw" }}>
					<ListItem>
						<Box className='infoPerfil'>
							{/* <AvatarProfile
								classname='img_perfil_questions'
								isTutor={user.typeUser === "tutor"}
							/> */}
							<Avatar
								className={"img_perfil_questions"}
								src={state.avatar_path}
								alt='Perfil'
							>
								{getNameInitials(user.nome)}
							</Avatar>
							<Typography className='name_studenty' style={{ textAlign: 'center', margin: '6px' }}>{user.nome}</Typography>
							<Typography className='ra_studenty'>RA: {user.ra}</Typography>
						</Box>
					</ListItem>
				</List>
				<Divider />
				<List style={{ minWidth: "15vw", maxWidth: "15vw", padding: '12px' }}>
					{
						user.typeUser === "tutor"
							?
							<></>
							:
							<ListItem
								id='itemNavQuestion'
								button
								onClick={() => handleActiveNavigate("question")}
								style={{
									backgroundColor: props.componentOpen === "response" ? '#f8f8f8' : ''
								}}
							>
								<ListItemIcon>
									<HelpOutline
										className='iconNavigate'
										style={{
											color:
												props.componentOpen === "question"
													? colorsTheme.palette.primary.main
													: "#707070",
										}}
									/>
								</ListItemIcon>
								<Typography
									className='itemNavigate'
									style={{
										color:
											props.componentOpen === "question"
												? colorsTheme.palette.primary.main
												: "#707070",
									}}
								>
									Perguntar
								</Typography>
							</ListItem>

					}
					{user.typeUser === "tutor" && (
						<>
							<ListItem
								id='itemNavAnswer'
								button
								onClick={() => handleActiveNavigate("response")}
								style={{
									backgroundColor: props.componentOpen === "response" ? '#f8f8f8' : '',
								}}
							>
								<ListItemIcon>
									<QuestionAnswer
										className='iconNavigate'
										style={{
											color:
												props.componentOpen === "response"
													? colorsTheme.palette.primary.main
													: "#707070",
										}}
									/>
								</ListItemIcon>
								<Typography
									className='itemNavigate'
									style={{
										color:
											props.componentOpen === "response"
												? colorsTheme.palette.primary.main
												: "#707070",
									}}
								>
									Responder
								</Typography>
							</ListItem>
							<ListItem id='itemNavIndicator' button onClick={() => handleActiveNavigate("indicador")}>
								<ListItemIcon>
									<SignalCellularAltIcon
										className={`iconNavigate ${props.componentOpen === "indicador" ? "active" : ""
											}`}
									/>
								</ListItemIcon>
								<Typography
									className={`itemNavigate ${props.componentOpen === "indicador" ? "active" : ""
										}`}
								>
									Indicador
								</Typography>
							</ListItem>
							<ListItem id='itemNavSendMessage' button onClick={() => handleActiveNavigate("message")}>
								<ListItemIcon>
									<SendIcon
										className={`iconNavigate ${props.componentOpen === "message" ? "active" : ""
											}`}
									/>
								</ListItemIcon>
								<Typography
									className={`itemNavigate ${props.componentOpen === "message" ? "active" : ""
										}`}
								>
									Enviar Mensagem
								</Typography>
							</ListItem>
						</>
					)}
				</List>
			</Drawer>

			<DialogViewQuestion
				id={idQuestionSelected}
				setId={setIdQuestionSelected}
				open={openViewQuestion}
				setOpen={setOpenViewQuestion}
			/>
		</>
	);
}
