/* eslint-disable @typescript-eslint/no-unused-vars */
import { FormEvent, useState } from 'react';
import './styles.scss';
import { Box, Button, CircularProgress, Dialog, DialogContent, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Typography, useTheme } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import axios from 'axios';
import ViewProfile from 'pages/PedagogicalCommunication/components/DialogViewProfile';
import Notify from "utils/Notification";

interface IDialogCorfirmPerfil { open: boolean; setOpen: (e: boolean) => void; }

const apigateway = axios.create({ baseURL: `https://apigateway.uninta.edu.br/` });

apigateway.interceptors.request.use((config) => {
    let user: any = localStorage.getItem("@Application:User");
    let token: any = localStorage.getItem("token");

    if (user && token && config.headers) {
        user = JSON.parse(user);
        token = JSON.parse(token);

        config.headers["Authorization"] = `Bearer ${token}`;
        config.headers["ra_aluno"] = user?.ra;
    }

    return config;
});

export async function getPerfil(ra: string) { return apigateway.get(`ead/ecossistema/alunos/${ra}`); }

export async function getPerfilCursos(ra: string) { return apigateway.get(`ead/ecossistema/alunos/${ra}/cursos`); }

export default function DialogCorfirmPerfil({ open, setOpen }: IDialogCorfirmPerfil) {
    const theme = useTheme();

    const [raPerfil, setRaPerfil] = useState('');
    const [isLoadingAuthLibrary, setIsLoadingAuthLibrary] = useState(false);

    const [dialogViewPerfil, setDialogViewPerfil] = useState<boolean>(false); // Perfil

    const onClosed = () => { setOpen(false); setRaPerfil(''); setIsLoadingAuthLibrary(false); }

    const [perfilView, setPerfilView] = useState<any>({});
    const [perfilViewCursos, setPerfilViewCursos] = useState<any>([]);

    const onConfirm = (e: FormEvent) => {
        e.preventDefault();
        setIsLoadingAuthLibrary(true);

        try {
            getPerfil(raPerfil as string).then((data: any) => {
                if (data.data.success) {
                    setPerfilView(data.data.data)
                    getPerfilCursos(raPerfil as string).then((data: any) => {
                        if (data.data.success) {
                            setPerfilViewCursos(data.data.data)
                            onClosed();
                            setDialogViewPerfil(true);
                            setIsLoadingAuthLibrary(false);
                        }
                    })
                }
            }).catch((e) => {
                onClosed();
                Notify("Aluno não encontrado. Por favor tente novamente", "error");
            })
        } catch (e) {
            onClosed();
            Notify("Aluno não encontrado. Por favor tente novamente", "error");
            // Pass
        }
    }

    return (
        <>
            <Dialog open={open} onClose={onClosed} fullWidth maxWidth="sm">
                <form onSubmit={onConfirm}>
                    <DialogContent>
                        <Typography className="info_confirmation">Digite um RA para buscar um perfil de um aluno.</Typography>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel>RA:</InputLabel>
                            <OutlinedInput
                                fullWidth
                                label="Ra:"
                                required
                                placeholder="Digite o ra aqui..."
                                value={raPerfil}
                                onChange={(e) => setRaPerfil(e.target.value)}
                                type={'text'}
                                disabled={isLoadingAuthLibrary}
                            />
                        </FormControl>

                        <Box className="area_btn_confirm" style={{ gap: "20px" }}>
                            <Button
                                variant="contained"
                                style={{ color: "black" }}
                                disableElevation
                                onClick={() => onClosed()}
                            >
                                Cancelar
                            </Button>
                            <Button
                                variant="contained"
                                style={{ background: isLoadingAuthLibrary ? "#d5d5d5" : theme.palette.primary.main, color: "white", width: "108px", height: "36px" }}
                                type="submit"
                                disabled={isLoadingAuthLibrary}
                                disableElevation
                            >
                                {isLoadingAuthLibrary ? <CircularProgress style={{ width: 20, height: 20 }} /> : 'Confirmar'}
                            </Button>
                        </Box>

                    </DialogContent>
                </form>
            </Dialog>
            <ViewProfile
                dialogViewPerfil={dialogViewPerfil}
                setDialogViewPerfil={setDialogViewPerfil}
                ra={perfilView.ra ?? ""}
                avatar_path={""}
                name={perfilView.nome ?? ""}
                curso={perfilViewCursos[perfilViewCursos.length - 1]?.nome_curso ?? " "}
                polo={perfilViewCursos[perfilViewCursos.length - 1]?.nome_nivel_ensino ?? " "}
                disciplina={0} />
        </>
    )
}
