import React from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormHelperText from "@material-ui/core/FormHelperText";
import { useTheme } from "@material-ui/core/styles";
import { apiLogsDoEcossistema } from "services/Logs";
import { CENTRAL_PEDAGOGICA } from "defaults/modules";
import { ALUNO_ENVIA_DUVIDA_CONTEUDO } from "defaults/events";
import { Editor } from "primereact/editor";

import Notify from "utils/Notification";
import { removerTags } from "utils/removeTagsHTML";

import { getCoursesStudent, storeQuestionTutor, } from "../../dialogquestionsanswers.services";

import { usePedagogicalCommunication } from "context/PedagogicalCommunication/hooks/usePedagogicalCommunication";
import { useAuth } from "context/Auth/hook";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useMediaQuery } from "@material-ui/core";

interface DialogNewQuestionProps { open: boolean; setOpen: (e: boolean) => void; }

export default function DialogNewQuestion(props: DialogNewQuestionProps): React.ReactElement {
	const { user, nivelDeEnsino } = useAuth();

	const { updateNumQuestions, setUpdateNumQuestions, setReloadListQuestions, reloadListQuestions } = usePedagogicalCommunication();
	const colorsTheme = useTheme();

	const [loading, setLoading] = React.useState(true);
	const [loadingSendQuestion, setLoadingSendQuestion] = React.useState(false);

	const [question, setQuestion] = React.useState("");

	const [listCourses, setListCourses] = React.useState<{ id: string; name: string }[]>([]);

	const [filterCourseSelected, setFilterCourseSelected] = React.useState("");

	// Fecha o Dialog da tela de "Perguntar "
	const handleCloseDialog = () => {
		props.setOpen(false);
		setQuestion("");
		// setFilterSubjectSelected("");
	};

	// Envia a pergunta do usuário
	const accomplishQuestion = (e: React.FormEvent) => {
		e.preventDefault();

		if (removerTags(question).length > 1000) {
			Notify("O texto da pergunta ultrapassou 1000 caracteres.", "warning");
			return;
		}

		if (filterCourseSelected && question) {
			setLoadingSendQuestion(true);
			storeQuestionTutor(question, nivelDeEnsino, filterCourseSelected, user)
				.then(() => {
					Notify("Sua pergunta foi enviada.");
					setUpdateNumQuestions(!updateNumQuestions);
					setLoadingSendQuestion(false);
					// Refresh da lista de perguntas funcionando corretamente
					setReloadListQuestions(!reloadListQuestions);
					handleCloseDialog();
					apiLogsDoEcossistema.post('/',
						{
							ra: user.ra as string,
							module: CENTRAL_PEDAGOGICA,
							evento: {
								label: ALUNO_ENVIA_DUVIDA_CONTEUDO,
								log_info: {
									user_type: user.typeUser,
									codigo_nivel_ensino: nivelDeEnsino.codigo_nivel_ensino,
									nome_nivel_ensino: nivelDeEnsino.nome_nivel_ensino,
									nome_curso: nivelDeEnsino.nome_curso,
									periodo: nivelDeEnsino.periodo,
								}
							},
							metadata: {
								curso: nivelDeEnsino.nome_curso,
								polo: nivelDeEnsino.nome_nivel_ensino,
								periodo: nivelDeEnsino.periodo,
							}
						}
					)
				})
				.catch((e) => {
					Notify("Sua pergunta não foi enviada!", "warning");
					setLoadingSendQuestion(false);
					setReloadListQuestions(true);
					handleCloseDialog();
					console.log(e);
				});
		} else {
			Notify("Preencha todos campos", "warning");
		}
	};

	React.useEffect(() => {
		if (props.open && listCourses.length <= 0) {
			getCoursesStudent(user.ra)
				.then((data: any) => {
					setListCourses(data);
					setFilterCourseSelected(data[data.length - 1]);
					setLoading(false);
				})
				.catch((e) => {
					console.log(e);
				});

		} else if (props.open) {
			setLoading(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.open, user.ra]);

	const fullScreen = useMediaQuery(colorsTheme.breakpoints.down(800));

	return (
		<Dialog
			fullWidth
			maxWidth='lg'
			open={props.open}
			onClose={() => {
				props.setOpen(false);
			}}
			fullScreen={fullScreen}
		>
			<DialogTitle style={{ background: colorsTheme.palette.primary.main }}>
				<div style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					position: "relative"
				}}>
					<Typography className='titleDialogQuestion'>Informe sua dúvida</Typography>
					<IconButton style={{ color: "white", position: "absolute", right: "0px" }} id='btnCloseTutor' onClick={() => props.setOpen(false)}>
						<Close />
					</IconButton>
				</div>
			</DialogTitle>

			<DialogContent>
				{loading || loadingSendQuestion ? (
					<Box
						display='flex'
						justifyContent='center'
						alignItems='center'
						flexDirection='column'
					>
						{loadingSendQuestion && (
							<Typography className='await_send_question'>
								Sua pergunta está sendo enviada...
							</Typography>
						)}
						<CircularProgress style={{ width: "1.6rem", height: "1.6rem" }} />
					</Box>
				) : (
					<form onSubmit={accomplishQuestion}>
						<Grid container>
							<Grid item xs={12} md={6} style={{ padding: 4 }}>
							</Grid>

							<Grid item xs={12} md={6} style={{ padding: 4 }}>
								<FormControl variant='outlined' fullWidth>
									<InputLabel>Curso:</InputLabel>
									<Select
										id='selectCourseNewQuestion'
										label='Curso:'
										defaultValue={listCourses[listCourses.length - 1]}
										onChange={(e) => {
											setFilterCourseSelected(e.target.value as string)
										}}
										required
										disabled={listCourses.length <= 0}
									>
										{listCourses.map((course: any) => (
											<MenuItem
												key={course.id}
												value={course}
											>
												{course.name}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>

							<Grid item xs={12} style={{ padding: 4 }}>
								<FormHelperText
									error={removerTags(question).length > 1000}
									style={{ textAlign: "right" }}
								>
									{removerTags(question).length} de 1000 caracteres
								</FormHelperText>
								<Editor
									style={{ height: "320px" }}
									value={question}
									onTextChange={(e) => {
										setQuestion(e.htmlValue as string);
									}}
									id='editorNewQuestion'
								/>
							</Grid>

							<Grid
								item
								xs={12}
								style={{ padding: 4, display: "flex", justifyContent: "flex-end" }}
							>
								<Button
									variant='contained'
									style={{
										backgroundColor: colorsTheme.palette.primary.main,
										color: "white",
									}}
									type='submit'
									id='submitNewQuestion'
								>
									Perguntar
								</Button>
							</Grid>
						</Grid>
					</form>
				)}
			</DialogContent>
		</Dialog >
	);
}
