import React from "react";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
// import IconButton from "@material-ui/core/IconButton";
// import Menu from "@material-ui/core/Menu";
// import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
// import TextField from "@material-ui/core/TextField";
// import FormHelperText from "@material-ui/core/FormHelperText";
// import CircularProgress from "@material-ui/core/CircularProgress";

// import MoreVert from "@material-ui/icons/MoreVert";
// import Report from "@material-ui/icons/Report";
import Star from "@material-ui/icons/Star";
import StarBorder from "@material-ui/icons/StarBorder";
// import Favorite from "@material-ui/icons/Favorite";
// import FavoriteBorder from "@material-ui/icons/FavoriteBorder";
// import Comment from "@material-ui/icons/Comment";
// import ExpandLess from "@material-ui/icons/ExpandLess";
// import ExpandMore from "@material-ui/icons/ExpandMore";
// import Send from "@material-ui/icons/Send";
import parse from "html-react-parser";

import DialogRatingOfQuestion from "../DialogRatingOfQuestion";
// import DialogReport from "../DialogReport";
// import AvatarProfile from "components/AvatarProfile";

// import {
// 	getListCommentsTutor,
// 	storeFavoritedTutor,
// 	storeCommentTutor,
// } from "../../../dialogquestionsanswers.services";
import { useAuth } from "context/Auth/hook";

// import Notify from "utils/Notification";

import { BoxResponseProps } from "./boxresponse.model";

import "./styles.scss";
import { Avatar } from "@material-ui/core";
import { getNameInitials } from "utils/getNameInitials";

// import { apiLogsDoEcossistema } from "services/Logs";
// import { CENTRAL_PEDAGOGICA } from "defaults/modules";
// import { ALUNO_COMENTA_RESPOSTA_DUVIDA_CONTEUDO } from "defaults/events";

export default function BoxResponse(props: BoxResponseProps): React.ReactElement {
	const { user } = useAuth();

	// Comment
	// const [openComments, setOpenComments] = React.useState(false);
	// const [loadingComments, setLoadingComments] = React.useState(false);
	// const [loadingSendComment, setLoadingSendComment] = React.useState(false);
	// const [qtdComments, setQtdComments] = React.useState("0");
	// const [comments, setComments] = React.useState<any[]>([]);
	// const [comment, setComment] = React.useState("");

	// Favorite
	// const [qtdFavotited, setQtdFavorited] = React.useState("0");
	// const [favorited, setFavorited] = React.useState(false);

	// Rate
	const [openRating, setOpenRating] = React.useState(false);
	const [qtdRated, setQtdRated] = React.useState("0");
	const [rated, setRated] = React.useState(false);

	// Report
	// const [openOptions, setOpenOptions] = React.useState<null | HTMLElement>(null);
	// const [openReport, setOpenReport] = React.useState(false);
	// const [idForReport, setIdForReport] = React.useState("");

	// const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
	// 	setOpenOptions(event.currentTarget);
	// };

	// const handleClose = () => {
	// 	setOpenOptions(null);
	// };

	// const favorite = () => {
	// 	if (props.idAnswer && user.ra) {
	// 		storeFavoritedTutor(user.ra, props.idAnswer)
	// 			.then((data) => {
	// 				setQtdFavorited(data.favoritos);
	// 				setFavorited(!favorited);
	// 				props.setOnReload(true);
	// 			})
	// 			.catch((e) => {
	// 				console.log(e);
	// 			});
	// 	}
	// };

	// const listComments = () => {
	// 	if (props.idAnswer) {
	// 		setLoadingComments(true);

	// 		getListCommentsTutor(props.idAnswer)
	// 			.then((data) => {
	// 				setComments(data.data);
	// 				setQtdComments(data.data?.length);
	// 				setLoadingComments(false);
	// 			})
	// 			.catch((e) => {
	// 				console.log(e);
	// 				setLoadingComments(false);
	// 			});
	// 	}
	// };

	// const sendComment = (e: React.FormEvent) => {
	// 	e.preventDefault();

	// 	if (comment.length > 245) {
	// 		Notify("O texto do comentário ultrapassou 245 caracteres.", "warning");
	// 		return;
	// 	}

	// 	if (comment && !loadingSendComment) {
	// 		setLoadingSendComment(true);
	// 		storeCommentTutor(
	// 			props.idAnswer,
	// 			comment,
	// 		)
	// 			.then(async (data) => {
	// 				listComments();
	// 				setComment("");
	// 				await apiLogsDoEcossistema.post('/',
	// 					{
	// 						ra: user.ra as string,
	// 						module: CENTRAL_PEDAGOGICA,
	// 						evento: {
	// 							label: ALUNO_COMENTA_RESPOSTA_DUVIDA_CONTEUDO,
	// 							log_info: {
	// 								user_type: user.typeUser,
	// 								codigo_nivel_ensino: nivelDeEnsino.codigo_nivel_ensino,
	// 								nome_nivel_ensino: nivelDeEnsino.nome_nivel_ensino,
	// 								nome_curso: nivelDeEnsino.nome_curso,
	// 								periodo: nivelDeEnsino.periodo,
	// 							}
	// 						},
	// 						metadata: {
	// 							curso: nivelDeEnsino.nome_curso,
	// 							polo: nivelDeEnsino.nome_nivel_ensino,
	// 							periodo: nivelDeEnsino.periodo,
	// 						}
	// 					}
	// 				)
	// 				setLoadingSendComment(false);
	// 			})
	// 			.catch((e) => {
	// 				console.log(e);
	// 			});
	// 	}
	// };

	// TODO: Forçar o refresh após enviar a avaliação
	React.useEffect(() => {
		// if (props.favorites) {
		// setQtdFavorited(props.favorites);
		// setFavorited(props.favorited);
		// }

		if (props.numVotes) {
			setQtdRated(props.numVotes);
			setRated(props.rated);
		}
	}, [props.favorites, props.favorited, props.numVotes, props.rated]);

	// React.useEffect(() => {
	// 	if (props.numComments) {
	// 		setQtdComments(props.numComments ?? 0);
	// 	}
	// }, [props.numComments]);

	function date(data: string) {
		let arrayData = data.split(" ");
		arrayData.splice(1, 1);
		arrayData = arrayData[0].split("-");
		return arrayData.reverse().join("/");
	}

	function hora(hora: string) {
		let arrayHora = hora.split(" ");
		return arrayHora[1];
	}

	return (
		<Grid item xs={12}>
			<Box className='area_response'>
				<Box className='header'>
					<Typography>Resposta:</Typography>
					{/* <Box className='view_avaliations'>
						<IconButton
							title='Denunciar'
							aria-controls='simple-menu'
							aria-haspopup='true'
							onClick={handleClick}
						>
							<MoreVert />
						</IconButton>

						<Menu
							id='simple-menu'
							anchorEl={openOptions}
							keepMounted
							open={Boolean(openOptions)}
							onClose={handleClose}
						>
							<MenuItem
								style={{ color: "#BD0E0E" }}
								button
								onClick={() => {
									setIdForReport(props.idAnswer);
									setOpenReport(true);
									handleClose();
								}}
							>
								<Report />
								&nbsp;Denunciar
							</MenuItem>
						</Menu>
					</Box> */}
				</Box>

				<Box className='body'>
					<Typography className='answer_text'>{parse(props.answer)}</Typography>

					<Box className='author'>
						{/* <AvatarProfile
							isAuthor={props.authorId === user.ra}
							urlImage={
								props.idCourse === "0" ? "perfil_tutor.svg" : props.authorAvatar
							}
							authorName={props.author}
						/> */}
						<Avatar
							className={"img_perfil_questions"}
							src={props.authorAvatar ? `https://dirin.s3.us-east-1.amazonaws.com/${props.authorAvatar}` : ""}
							alt='Perfil'
						>
							{getNameInitials(props.author ?? " ")}
						</Avatar>
						<div style={{ display: "flex", flexDirection: "column" }}>
							<Typography variant="body2">
								TUTOR(A): {props.author ?? " "}
							</Typography>
							<Typography variant="body2">
								{date(props.created ?? " ")} - {hora(props.created ?? " ")}
							</Typography>
						</div>
					</Box>
				</Box>

				<Box className='footer_response_view'>

					{
						user.typeUser === "student" ?
							<Typography variant='subtitle2'>
								Avalie para que todos saibam que essa resposta é útil
							</Typography> : <></>
					}

					<Box className='area_avaliations'>
						{/* <Button
							title='Curtir Resposta'
							variant='outlined'
							startIcon={
								favorited ? (
									<Favorite style={{ color: "#bd0e0e" }} />
								) : (
									<FavoriteBorder />
								)
							}
							onClick={() => { if (!favorited) { favorite(); } }}
						>
							{qtdFavotited}
						</Button> */}

						<Button
							id='btnRating'
							title='Avaliar Resposta'
							variant='outlined'
							startIcon={
								rated ? <Star style={{ color: "#ffc000" }} /> : <StarBorder />
							}
							onClick={() => {
								if (user.typeUser === "student") {
									if (!rated) {
										setOpenRating(true);
									}
								}
							}}
						>
							{parseFloat(qtdRated ?? 0).toFixed(1).replace(".", ",")}
						</Button>
					</Box>

					{/* <Button
						startIcon={<Comment />}
						endIcon={openComments ? <ExpandLess /> : <ExpandMore />}
						onClick={() => { listComments(); setOpenComments(!openComments); }}
					>
						Comentários ({qtdComments})
					</Button> */}
				</Box>


				{/* {openComments && (
					<Box className='comments'>
						{loadingComments ? (
							<Box
								display='flex'
								justifyContent='center'
								alignItems='center'
								flexDirection='column'
								gridGap={12}
							>
								<CircularProgress />
								<Typography>Buscando Comentários</Typography>
							</Box>
						) : (
							<>
								<Box className='area_comments'>
									{comments?.length > 0 ? (
										<>
											{comments?.map((comment, index) => {
												return (
													<Box
														key={index}
														display='flex'
														flexDirection='column'
														justifyContent={
															comment.profile.ra !== user.ra?.toString()
																? "flex-start"
																: "flex-end"
														}
														alignItems={
															comment.profile.ra !== user.ra?.toString()
																? "flex-start"
																: "flex-end"
														}
													>
														<Typography className='area_author_comment'>
															{comment.profile.name} -{" "}
															{comment.curso.nome}
														</Typography>
														<Typography
															className={
																comment.profile.ra !==
																	user.ra?.toString()
																	? "comment"
																	: "comment_author"
															}
														>
															{comment.comentario}
														</Typography>
													</Box>
												);
											})}
										</>
									) : (
										<Typography className='nothing_comment'>
											Nenhum comentário feito...
										</Typography>
									)}
								</Box>

								<form onSubmit={sendComment}>
									<Box className='commenting'>
										<Box>
											<FormHelperText
												error={comment.length > 245}
												style={{ textAlign: "right" }}
											>
												{comment.length} de 245 caracteres
											</FormHelperText>
											<TextField
												variant='outlined'
												placeholder='Digite o seu comentário...'
												value={comment}
												onChange={(e) => setComment(e.target.value)}
												disabled={loadingSendComment}
												error={comment.length > 245}
												inputProps={{ maxLength: 245 }}
												fullWidth
											/>
										</Box>

										<IconButton type='submit' disabled={loadingSendComment}>
											{loadingSendComment ? (
												<CircularProgress
													style={{
														color: "#fff",
														width: "1.5rem",
														height: "1.5rem",
													}}
													thickness={4.4}
												/>
											) : (
												<Send />
											)}
										</IconButton>
									</Box>
								</form>
							</>
						)}
					</Box>
				)} */}
			</Box>

			<DialogRatingOfQuestion
				open={openRating}
				idAnswer={props.idAnswer}
				setOpen={setOpenRating}
				setQtdRated={setQtdRated}
				setRated={setRated}
				setReload={props.setOnReload}
			/>
			{/* 
			<DialogReport
				typeReport='respostas'
				idForReport={idForReport}
				open={openReport}
				setOpen={setOpenReport}
			/> */}
		</Grid>
	);
}
