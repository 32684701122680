/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { getQuestionsListTutor, getQuestionsListTutorFilter } from "pages/PedagogicalCommunication/components/DialogQuestionsAnswersTutor/duvidasacademicastutor.servicer";
import { useAuth } from "context/Auth/hook";
import { INI_VALUES } from "./defaults";
import { PedagogicalCommunicationContextProps } from "./models/PedagogicalCommunicationContext";

export const PedagogicalCommunicationContext = React.createContext<PedagogicalCommunicationContextProps>(INI_VALUES);

export const PedagogicalCommunicationProvider: React.FC = ({ children }) => {
	const { user } = useAuth();
	const [updateNumQuestions, setUpdateNumQuestions] = React.useState(false);
	const [updateNumAnswers, setUpdateNumAnswers] = React.useState(false);
	const [reloadListQuestions, setReloadListQuestions] = React.useState(false);

	const [updateNumReviews, setUpdateNumReviews] = React.useState(false);
	const [updateNumSchedule, setUpdateNumSchedule] = React.useState(false);

	const [filterMessageCourse, setFilterMessageCourse] = React.useState([]);
	const [filterMessagePole, setFilterMessagePole] = React.useState([]);
	const [filterMessageRas, setFilterMessageRas] = React.useState([]);

	// Contexto para Dúvidas acadêmicas -----------------------------------------------------------
	const [listCourses, setListCourses] = React.useState<{ id: string; name: string }[]>([]);
	const [listPolos, setListPolos] = React.useState([]);
	const [listRas, setListRas] = React.useState([]);;
	const [loadingPage, setLoadingPage] = React.useState(true);
	const [currentPage, setCurrentPage] = React.useState(0);
	const [totalQuestions, setTotalQuestions] = React.useState(0);
	const [listQuestionsView, setListQuestionsView] = React.useState<any>([]);
	const [filterCourse, setFilterCourse] = React.useState("todos");
	const [refresh, setRefresh] = React.useState(true);

	const handleFilterListQuestions = (key: "curso_id", value: string, page?: number) => {
		setLoadingPage(true);

		if (!page) { setCurrentPage(0); }

		let course = filterCourse;

		if (key === "curso_id") { course = value; }

		if (course !== "todos") {
			getQuestionsListTutor(value, page).then(({ data }: any) => {
				setLoadingPage(false);
				setListQuestionsView(data?.data);
				setTotalQuestions(data?.total);
			});
		}

		if (course === "todos") {
			const course = null
			getQuestionsListTutor(course, page).then(({ data }: any) => {
				setLoadingPage(false);
				setListQuestionsView(data?.data);
				setTotalQuestions(data?.total);
			});
		}
	};

	const handleFilter = (stringFilter: string, page?: number) => {
		setLoadingPage(true);

		getQuestionsListTutorFilter(stringFilter, page).then((data) => {
			setLoadingPage(false);
			setListQuestionsView(data.data);
			setTotalQuestions(data.total);
		});
	};

	const getQuestionsPaginator = async (page = 1) => {
		setLoadingPage(true);
		try {

			await getQuestionsListTutor(filterCourse === "todos" ? null : filterCourse, page).then(({ data }: any) => {
				setLoadingPage(false);
				setListQuestionsView(data?.data);
				setTotalQuestions(data?.total);
				setFilterCourse("todos");
			}).catch((e) => {
				setListQuestionsView([]);
				setTotalQuestions(10);
				setLoadingPage(true);
				setTimeout(() => {
					setRefresh(!refresh)
				}, 5000);
				return e;
			});

			// const cache_polos = localStorage.getItem('@centralPolos')
			// const cache_cursos = localStorage.getItem('@centralCursos')
			// const cache_ras = localStorage.getItem('@centralras')

			// if (cache_polos && cache_cursos && cache_ras) {
			// 	setListPolos(JSON.parse(cache_polos))
			// 	setListCourses(JSON.parse(cache_cursos))
			// 	setListRas(JSON.parse(cache_ras))
			// } else {

			// 	const disciplina = true;
			// 	getCourses(disciplina).then(({ data }) => {
			// 		// eslint-disable-next-line array-callback-return
			// 		data.map((curso) => {
			// 			curso["label"] = curso["nome"]
			// 			delete curso["nome"]
			// 			curso["value"] = curso["id"]
			// 			delete curso["id"]
			// 		});
			// 		setListCourses(data);
			// 		localStorage.setItem("@centralCursos", JSON.stringify(data));
			// 	})

			// 	getPolos(disciplina).then(({ data }) => {
			// 		//eslint-disable-next-line array-callback-return
			// 		data.map((curso) => {
			// 			curso["label"] = curso["nome"]
			// 			delete curso["nome"]
			// 			curso["value"] = curso["id"]
			// 			delete curso["id"]
			// 		});
			// 		setListPolos(data);
			// 		localStorage.setItem("@centralPolos", JSON.stringify(data));
			// 	})

			// 	getRas(disciplina).then(({ data }) => {
			// 		setListRas(data);
			// 		localStorage.setItem("@centralras", JSON.stringify(data));
			// 	})
			// }
		} catch (e) {
			// Pass
		}
	};

	React.useEffect(() => {
		if (user.signed && user.typeUser === 'tutor') {
			const page = currentPage / 10 + 1;
			getQuestionsPaginator(page)
		}

	}, [user.signed, refresh, reloadListQuestions, updateNumAnswers, updateNumQuestions])

	return (
		<PedagogicalCommunicationContext.Provider
			value={{
				updateNumQuestions,
				updateNumAnswers,
				reloadListQuestions,
				updateNumReviews,
				updateNumSchedule,
				setUpdateNumQuestions,
				setUpdateNumAnswers,
				setReloadListQuestions,
				setUpdateNumReviews,
				setUpdateNumSchedule,

				listCourses,
				setListCourses,

				listPolos,
				setListPolos,

				listRas,
				setListRas,

				loadingPage,
				setLoadingPage,

				currentPage,
				setCurrentPage,

				totalQuestions,
				setTotalQuestions,

				listQuestionsView,
				setListQuestionsView,

				filterCourse,
				setFilterCourse,

				getQuestionsPaginator,
				handleFilterListQuestions,
				handleFilter,

				filterMessageCourse,
				setFilterMessageCourse,

				filterMessagePole,
				setFilterMessagePole,

				filterMessageRas,
				setFilterMessageRas,
			}}
		>
			{children}
		</PedagogicalCommunicationContext.Provider>
	);
};
